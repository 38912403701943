<template>
 <div id="yogo-roleManager" class="eltables" v-loading.fullscreen.lock="listLoading" element-loading-background="rgba(0, 0, 0, 0.8)">
  <div class="toolbar-container">
   <span class="yogo-title toolbar-title">角色管理</span>
   <el-button type="primary" class="myicon iconadd" @click="handleAdd()"> <i></i>新增角色 </el-button>
  </div>
  <el-table :data="roleList" class="data-table" stripe>
   <el-table-column prop="roleName" label="角色名称" align="center" />
   <el-table-column prop="remark" label="角色描述" align="center" />

   <el-table-column prop="createTime" label="建立时间" align="center">
    <template slot-scope="{ row }">
     {{ !_.isEmpty(row.createTime) ? formatTime(row.createTime, '{y}-{m}-{d}') : null }}
    </template>
   </el-table-column>

   <el-table-column prop="modifyTime" label="失效时间" align="center">
    <template slot-scope="{ row }">
     {{ !_.isEmpty(row.modifyTime) ? formatTime(row.modifyTime, '{y}-{m}-{d}') : null }}
    </template>
   </el-table-column>
   <el-table-column prop="actions" label="操作" width="120" align="left">
    <template slot-scope="{ row }">
     <el-button type="primary" size="small" class="el-icon-edit" circle @click="handleEdit(row)"></el-button>
     <el-button type="danger" size="small" class="el-icon-delete" circle @click="handleDelete(row)"></el-button>
    </template>
   </el-table-column>
  </el-table>
  <delDialog ref="DelDialogForm" />
  <modifyRole ref="modifyRole" />
 </div>
</template>
<script>
import delDialog from '@/views/template/delDialog'
import modifyRole from '@/views/xtgl/modifyRole'
import { getRoleList, updateRole, addRole, deleteRole } from '@/api/roles'
import { parseTime } from '@/utils'
export default {
 name: 'roleManager',
 components: {
  delDialog,
  modifyRole
 },

 data() {
  return {
   roleList: []
  }
 },

 computed: {},

 mounted() {},

 created() {
  // Mock: get all routes and roles list from server
  // this.getRoutes()
  this.getRoles()
 },

 methods: {
  doCreate(data) {
   addRole(data).then(response => {
    console.log(response)
    this.getRoles()
   })
  },

  doUpdate(data) {
   updateRole(data).then(response => {
    console.log(response)
   })
  },

  doDelete(data) {
   deleteRole(data).then(response => {
    console.log(response)
   })
  },

  handleAdd() {
   this.$refs['modifyRole'].showDialog('新增角色', null, this.doCreate)
  },

  handleEdit(item) {
   const target = item
   this.selectedItem = item
   this.$refs['modifyRole'].showDialog('修改角色', target, this.doUpdate)
  },

  handleDelete(item) {
   this.selectedItem = item
   item.roleId
   this.$refs['DelDialogForm'].showDialog('删除角色', '是否删除当前角色', item.roleId, this.doDelete)
  },

  formatTime(v, fmt) {
   return parseTime(v, fmt)
  },

  getRoles() {
   this.listLoading = true

   getRoleList().then(response => {
    this.roleList = response.data.rows
    this.listLoading = false
   })
  },

  query() {
   this.$refs.multipleTable.query()
  }
 }
}
</script>
<style lang="less">
@import url(../../statics/css/eltable.css);
</style>
// <style scoped></style>
