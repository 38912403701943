import request from '@/utils/request'

export function getSytemEntryList(params) {
 return request({
  url: '/api/SystemEntries',
  method: 'get',
  params,
 })
}

// export function getSytemEntry(id) {
//   return request({
//     url: '/api/sytemEntry/' + id,
//     method: 'get',
//   })
// }

export function saveOrUpdateSytemEntry(data) {
 return request({
  url: '/api/SystemEntry',
  method: 'post',
  data,
 })
}

export function deleteSytemEntry(id) {
 return request({
  url: '/api/SystemEntries/delete/' + id,
  method: 'delete',
 })
}
