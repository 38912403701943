<template>
 <div id="themereserve">
  <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" @close="onClosed_dialog" @opened="onOpened_dialog" center class="subscribeContentList subscribe" title="预定信息">
   <el-collapse v-model="elCollapse_activeName">
    <el-collapse-item class="reserve" name="reserve">
     <template slot="title">
      <div class="icon"></div>
      预定及订单信息
     </template>
     <el-container class="content" direction="vertical" v-if="scene && scene.themeSession && scene.themeSession.statistics">
      <el-container :key="orderIdx" :style class="info" direction="vertical" v-for="(order, orderIdx) in scene.themeSession.statistics">
       <el-form :disabled="selectedDayStatus <= -3 || (!_.isNil(order.unreservedStatus) && order.unreservedStatus == 1)" :inline="true" :model="order" :rules="elFormOrderRules" ref="elFormOrder">
        <el-form-item label="客户姓名" prop="reserverName">
         <el-input placeholder="请输入" v-model="order.reserverName"></el-input>
        </el-form-item>
        <el-form-item label="客户电话" prop="reserverPhone">
         <el-input placeholder="请输入" :maxlength="11" v-model.number="order.reserverPhone"></el-input>
        </el-form-item>
        <el-form-item label="预估年龄" prop="ages">
         <el-select placeholder="请选择" v-model="order.ages">
          <el-option :key="idx" :label="ageRange.range" :value="ageRange.range" v-for="(ageRange, idx) in demoData.ageRangeArr"></el-option>
         </el-select>
        </el-form-item>
        <el-form-item label="订单人数" prop="players">
         <el-input :placeholder="'请填写（包场人数：' + theme.lockPeople + '）'" :maxlength="3" v-model.number="order.players" @input="onInput_players(theme, order)"></el-input>
        </el-form-item>
        <!-- ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ -->
        <el-form-item label="备注">
         <el-input placeholder="请填写" v-model="order.remark"></el-input>
        </el-form-item>

        <el-form-item label="是否包场">
         <!-- <el-switch
  v-model="order.team"
  active-text="包场"
  inactive-text="不包场"
  active-value="1"
  inactive-value="0"
  :disabled="orderIdx !== 0 || scene.themeSession.statistics.length > 1 || _.isNaN(order.players) || order.players < theme.lockPeople"
 ></el-switch> -->
         <el-switch v-model="order.team" active-text="包场" inactive-text="不包场" :active-value="1" :inactive-value="0" :disabled="calc_disabled_blockWholeScene(orderIdx, order, scene.themeSession.statistics, theme)"></el-switch>
        </el-form-item>
        <!-- ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ -->
        <!-- 支付entry -->
        <template v-for="(payEntry, payEntryIdx) in order.costs">
         <el-form-item :label="payEntryIdx === order.costs.length - 1 ? `付款方式` : `付款方式` + (payEntryIdx + 1)" :style="payEntryIdx === order.costs.length - 1 ? `font-weight: bold; font-style: italic;` : ``">
          <el-select placeholder="请选择" v-model="payEntry.payChannelId" @change="onChange_calcMoney(payEntry, order)" :disabled="calc_disabled_payEntryOperation(order, payEntryIdx)">
           <el-option :key="payMethodIdx" :label="payMethod.channelName" :value="payMethod.id" v-for="(payMethod, payMethodIdx) in payMethodList"></el-option>
          </el-select>
         </el-form-item>

         <el-form-item label="付款金额" :style="payEntryIdx === order.costs.length - 1 ? `font-weight: bold; font-style: italic;` : ``">
          <el-input placeholder="请填写金额数字" :maxlength="4" v-model.number="payEntry.money" @input="onChange_calcMoney(payEntry, order)" :disabled="calc_disabled_payEntryOperation(order, payEntryIdx)"></el-input>
          <el-button v-if="_.isNumber(payEntry.payChannelId) || _.isNumber(payEntry.money)" icon="el-icon-remove-outline" circle class="delete-pay-item" @click="onClick_deletePayEntry(payEntryIdx, order.costs, order)" :disabled="calc_disabled_payEntryOperation(order, payEntryIdx)"></el-button>
         </el-form-item>
        </template>
        <!-- ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ -->
        <el-form-item class="num" label v-show="!isSolo">
         <div class="customNum">
          <p>{{ orderIdx + 1 }}</p>
         </div>
        </el-form-item>

        <el-container class="action" direction="horizontal" style="justify-content:center; align-items:center;">
         <el-button v-show="!isSolo && selectedDayStatus > -3" @click="onClick_unreserve(orderIdx)" plain style type="danger" :class="{ 'is-unreserved': !_.isNil(order.unreservedStatus) && order.unreservedStatus == 1 }">
          {{ !_.isNil(order.unreservedStatus) && order.unreservedStatus == 1 ? '已退订' : '删除/退订' }}
         </el-button>
        </el-container>
       </el-form>
      </el-container>
      <el-container class="action" direction="horizontal" style="justify-content:center; align-items:center;">
       <el-button v-show="!isSolo && selectedDayStatus >= -1" @click="onClick_addReserve()" plain style="width:300px" type="primary" :disabled="calc_disabled_addReserve(scene.themeSession.statistics)">增加预定</el-button>
      </el-container>
     </el-container>
    </el-collapse-item>

    <el-collapse-item class="pay-amount" name="payAmount" v-show="!isSolo">
     <template slot="title">
      <div class="icon"></div>
      支付金额
     </template>
     <el-container class="content" direction="vertical">
      <el-container :key="idx" class="line" direction="horizontal" v-for="(payMethod, idx) in payMethodList">
       <el-container class="title" direction="horizontal">{{ payMethod.channelName }}</el-container>
       <el-container class="separator" direction="horizontal"></el-container>
       <!-- <el-container
  class="money"
  direction="horizontal"
 >￥{{ payMethod.totalMoney }}/{{ payMethod.totalPeople }}人</el-container> -->
       <el-container class="money" direction="horizontal">￥{{ payMethod.totalMoney }}</el-container>
      </el-container>

      <el-container class="line" direction="horizontal">
       <el-container class="title" direction="horizontal">总金额</el-container>
       <el-container class="separator" direction="horizontal"></el-container>
       <el-container class="money" direction="horizontal">
        <span>￥</span>
        <span>{{ payMethodList.totalMoney }}</span>
       </el-container>
      </el-container>
     </el-container>
    </el-collapse-item>
   </el-collapse>
   <span class="dialog-footer" slot="footer">
    <el-button @click="dialogVisible = false" type="info">取消</el-button>
    <el-button @click="onClick_confirm()" type="success" v-show="selectedDayStatus > -3">确定/提交</el-button>
   </span>
  </el-dialog>
  <delDialog ref="delDialog" />
 </div>
</template>

<script>
import delDialog from '@/views/template/delDialog'

import { getPayChannelList, getPayChannel, saveOrUpdatePayChannel, deletePayChannel } from '@/api/paychannel'

import { getThemeSessionById, getThemeSessionList, saveOrUpdateThemeSession } from '@/api/theme/themeSession'

import { getThemeStatisticsById, getThemeStatisticsList, getThemeStatisticsSearch, saveOrUpdateThemeStatistics, deleteThemeStatisticsById } from '@/api/theme/themeStatistics'

import demoData from './themedukudata.js'

var _this

export default {
 components: {
  delDialog,
 },
 data() {
  var check_string = (rule, value, callback) => {
   if (_.isString(value) && !_.isEmpty(value)) {
    callback()
   } else {
    callback(new Error('不能为空'))
   }
  }
  var check_num = (rule, value, callback) => {
   if (_.isNumber(value) && value >= 0) {
    callback()
   } else {
    callback(new Error('不能为空'))
   }
  }
  var check_reserverPhone = (rule, value, callback) => {
   if (value === '') {
    callback(new Error('联系电话不能为空'))
   } else {
    if (!/^1\d{10}$/.test(value)) {
     callback(new Error('请输入正确的电话号码'))
    } else {
     callback()
    }
   }
  }
  var check_players = (rule, value, callback) => {
   const reg = /^[0-9]*$/
   if (reg.test(value)) {
    callback()
   } else {
    callback(new Error('请输入数字'))
   }
  }
  var check_money = (rule, value, callback) => {
   const reg = /^[0-9]*$/
   if (reg.test(value)) {
    callback()
   } else {
    callback(new Error('请输入数字'))
   }
  }

  return {
   // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
   selectedDayStatus: null,
   // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
   isSolo: null,
   callback: null,
   // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
   scene: null,
   sceneSrc: null,
   sessionStartDay: null,
   theme: null,
   // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

   demoData: null,

   elFormOrderRules: {
    reserverName: [
     {
      validator: check_string,
      required: true,
      trigger: ['blur', 'change'],
     },
    ],
    reserverPhone: [
     {
      required: true,
      validator: check_reserverPhone,
      trigger: ['blur', 'change'],
     },
    ],
    ages: [
     {
      validator: check_string,
      required: true,
      trigger: ['blur', 'change'],
     },
    ],
    players: [
     {
      required: true,
      // validator: check_players,
      validator: check_num,
      trigger: ['blur', 'change'],
     },
    ],
    payChannelId: [
     {
      validator: check_num,
      required: true,
      trigger: ['blur', 'change'],
     },
    ],
    money: [
     {
      required: true,
      validator: check_money,
      trigger: ['blur', 'change'],
     },
    ],
   },

   // list
   payMethodList: [],
   payMethodListAll: [],
   listQuery: {
    pageNum: 1,
    limit: 10,
    importance: undefined,
    title: undefined,
    type: undefined,
    status: undefined,
    sort: '+id',
   },

   elCollapse_activeName: 'reserve',

   orders: 3,

   dialogVisible: false,
   isShow: false,
   isShow1: false,
   isShow2: true,
   readOnly: [false, false, false, true, true, true, true, true, true],
   // 开启收起
   openOrclose: true,
   openOrclose1: true,
   openOrclose2: true,
   // 支付方式
   payWayData: [
    {
     pic1: require('../../statics/images/select13@2x.png'),
     pic2: require('../../statics/images/勾选框 拷贝 13@2x.png'),
     way: '支付宝[扫码]',
     isSelected: true,
    },
    {
     pic1: require('../../statics/images/select13@2x.png'),
     pic2: require('../../statics/images/勾选框 拷贝 13@2x.png'),
     way: '支付宝[验券]',
     isSelected: true,
    },
    {
     pic1: require('../../statics/images/select13@2x.png'),
     pic2: require('../../statics/images/勾选框 拷贝 13@2x.png'),
     way: '支付宝[验券]',
     isSelected: true,
    },
    {
     pic1: require('../../statics/images/select13@2x.png'),
     pic2: require('../../statics/images/勾选框 拷贝 13@2x.png'),
     way: '大众/美团[验券]',
     isSelected: false,
    },
    {
     pic1: require('../../statics/images/select13@2x.png'),
     pic2: require('../../statics/images/勾选框 拷贝 13@2x.png'),
     way: '大众/美团[订座]',
     isSelected: false,
    },
    {
     pic1: require('../../statics/images/select13@2x.png'),
     pic2: require('../../statics/images/勾选框 拷贝 13@2x.png'),
     way: '大众/美团[闪惠]',
     isSelected: false,
    },
    {
     pic1: require('../../statics/images/select13@2x.png'),
     pic2: require('../../statics/images/勾选框 拷贝 13@2x.png'),
     way: '会员储值金消费',
     isSelected: false,
    },
    {
     pic1: require('../../statics/images/select13@2x.png'),
     pic2: require('../../statics/images/勾选框 拷贝 13@2x.png'),
     way: '刷卡',
     isSelected: false,
    },
    {
     pic1: require('../../statics/images/select13@2x.png'),
     pic2: require('../../statics/images/勾选框 拷贝 13@2x.png'),
     way: '现金',
     isSelected: false,
    },
   ],
   // 预定弹窗
   isActiveLine: true,
   subscribeFlag: false,
   subscribeArr: [
    {
     itemId: 0,
     customName: '客户姓名',
     customPhone: '客户电话',
     guessAge: '预估年龄',
     orderNum: '订单人数',
    },
   ],

   form: {
    name: '',
    region: '',
    date1: '',
    date2: '',
    delivery: false,
    type: [],
    resource: '',
    desc: '',
   },
  }
 },
 beforeCreate() {
  getPayChannelList().then(response => {
   this.payMethodListAll = response.data
  })
 },
 mounted() {
  _this = this
  _this.demoData = demoData
 },
 methods: {
  // todo 当订单是已经提交了的，付款参数就不可更改了，因为涉及到退款
  calc_disabled_payEntryOperation(order, payEntryIdx) {
   if (!_.isNil(order.id)) {
    return true
   } else {
    return false
   }
  },

  pushEmptyOrder() {
   this.scene.themeSession.statistics.push({
    reserverName: null,
    reserverPhone: null,
    ages: null,
    players: null,

    remark: null,
    team: null,

    payChannelId: null,
    money: null,
    payChannelRate: null,

    unreservedStatus: 0,

    costs: [
     {
      payChannelId: null,
      money: null,
      payChannelRate: null,

      inAccountStatus: _this.scene.themeSession.sessionStatus > 0 ? 1 : null,
      inAccountTime: _this.scene.themeSession.sessionStatus > 0 ? new Date().format('yyyy-MM-dd HH:mm:ss') : null,
     },
    ],
   })
  },

  calc_disabled_addReserve(statistics) {
   let filtered_statistics = statistics.filter((eleOrder, idx, arr) => {
    return eleOrder.unreservedStatus == 0
   })

   return filtered_statistics.length == 1 && filtered_statistics[0].team == 1
  },

  calc_disabled_blockWholeScene(orderIdx, order, statistics, theme) {
   let filtered_statistics = statistics.filter((eleOrder, idx, arr) => {
    return eleOrder.unreservedStatus == 0
   })

   let foundOrderIdx = filtered_statistics.findIndex((eleOrder, idx, arr) => {
    return eleOrder.id === order.id
   })
   if (foundOrderIdx !== -1) {
    // 找到了!
    return foundOrderIdx !== 0 || filtered_statistics.length > 1 || _.isNaN(order.players) || order.players < theme.lockPeople
   } else {
    return true
   }
  },

  onInput_players(theme, order) {
   if (_.isNil(order.players) || order.players < theme.lockPeople) {
    order.team = '0'
   }
  },

  onClick_deletePayEntry(payEntryIdx, costs, order) {
   costs.splice(payEntryIdx, 1)

   if (costs.length === 0) {
    costs.push({
     payChannelId: null,
     money: null,
     payChannelRate: null,

     inAccountStatus: _this.scene.themeSession.sessionStatus > 0 ? 1 : null,
     inAccountTime: _this.scene.themeSession.sessionStatus > 0 ? new Date().format('yyyy-MM-dd HH:mm:ss') : null,
    })
   } else {
    let isHaveEmptyPayItem = costs.some((elePayLine, idx, arr) => {
     return !_.isNumber(elePayLine.payChannelId) || !_.isNumber(elePayLine.money)
    })
    if (!isHaveEmptyPayItem) {
     costs.push({
      payChannelId: null,
      money: null,
      payChannelRate: null,

      inAccountStatus: _this.scene.themeSession.sessionStatus > 0 ? 1 : null,
      inAccountTime: _this.scene.themeSession.sessionStatus > 0 ? new Date().format('yyyy-MM-dd HH:mm:ss') : null,
     })
    }

    order.costs = []
    order.costs = costs
   }
  },
  calc_money() {
   let temp_payMethodList = this.payMethodListAll.filter((ele_payMethod, idx, arr) => {
    return ele_payMethod.storeId === this.theme.storeId
   })
   temp_payMethodList.forEach((ele_payMethod, idx, arr) => {
    ele_payMethod.totalMoney = 0

    this.scene.themeSession.statistics.forEach(ele_order => {
     ele_order.costs.forEach(elePayEntry => {
      if (_.isNumber(elePayEntry.money) && elePayEntry.money > 0 && _.isNumber(elePayEntry.payChannelId) && elePayEntry.payChannelId === ele_payMethod.id) {
       ele_payMethod.totalMoney += elePayEntry.money
      }
     })
    })
   })

   temp_payMethodList.totalMoney = temp_payMethodList.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.totalMoney
   }, 0)

   return temp_payMethodList
  },

  saveSession() {
   this.scene.themeSession.sessionStartDay = this.sessionStartDay
   this.scene.themeSession.themeId = this.scene.themeId
   this.scene.themeSession.schedulesId = this.scene.id
   this.scene.themeSession.storeId = this.theme.storeId

   // +++++++++++++++++++++++++++++++++++++++
   this.scene.themeSession.statistics.forEach((eleOrder, idx, arr) => {
    // 把列尾的空支付去掉
    eleOrder.costs = eleOrder.costs.filter((elePayEntry, idx, arr) => {
     return !_.isNil(elePayEntry.payChannelId) && !_.isNil(elePayEntry.money)
    })

    // 对所有的'支付条目'处理,如果是新增的,则更新入账状态和入账时间;如不是新增,不动
    eleOrder.costs.forEach((elePayEntry, idx, arr) => {
     // _.isNil(elePayEntry.id) 以此判断为新增
     if (_.isNil(elePayEntry.id)) {
      let foundElePayMethod = _this.payMethodListAll.find((elePayMethod, idx, arr) => {
       return elePayMethod.id == elePayEntry.payChannelId
      })
      if (!_.isUndefined(foundElePayMethod)) {
       // 找到了!
       if (!_.isNil(foundElePayMethod.preIncomeStatus)) {
        if (foundElePayMethod.preIncomeStatus == 0) {
         // 非预收
         elePayEntry.inAccountStatus = _this.scene.themeSession.sessionStatus > 0 ? 1 : 0
         elePayEntry.inAccountTime = _this.scene.themeSession.sessionStatus > 0 ? new Date().format('yyyy-MM-dd HH:mm:ss') : null
        } else if (foundElePayMethod.preIncomeStatus == 1) {
         // 预收
         elePayEntry.inAccountStatus = 1
         elePayEntry.inAccountTime = new Date().format('yyyy-MM-dd HH:mm:ss')
        }
       } else {
        alert('支付方式没有标明预收状态,不允许这种情况')
        return
       }
      } else {
       alert('找不到的支付方式,不允许这种情况')
       return
      }
     } else {
      // todo 但是要注意,对此进行修改的case
      console.log('[yygl/themereserve.vue FUNCTION] [474:04:46] ▉ 非新增的支付方式,不用更新', 33)
     }
    })
   })

   // +++++++++++++++++++++++++++++++++++++++
   saveOrUpdateThemeSession(this.scene.themeSession).then(response => {
    // sync
    _.assign(this.sceneSrc.themeSession, response.data.data)

    this.$message.success('订单信息变更成功')

    if (!_.isNil(this.callback)) {
     this.callback()
    }
   })
  },

  onChange_calcMoney(payItem, order) {
   this.payMethodList = this.calc_money()

   // +++++++++++++++++++++++++++++++++++++++
   // 找费率
   if (_.isNumber(payItem.payChannelId) && payItem.payChannelId > 0) {
    let foundEle = this.payMethodListAll.find((elePayMethod, idx, arr) => {
     return elePayMethod.id === payItem.payChannelId
    })
    if (!_.isUndefined(foundEle)) {
     // 找到了!
     payItem.payChannelRate = foundEle.rates
    }
   }

   // +++++++++++++++++++++++++++++++++++++++
   // 数组尾新增payItem, 有空不增, 全合法值才增
   let isHaveEmptyPayItem = order.costs.some((elePayLine, idx, arr) => {
    return !_.isNumber(elePayLine.payChannelId) || !_.isNumber(elePayLine.money)
   })
   if (!isHaveEmptyPayItem) {
    order.costs.push({
     payChannelId: null,
     money: null,
     payChannelRate: null,

     inAccountStatus: _this.scene.themeSession.sessionStatus > 0 ? 1 : null,
     inAccountTime: _this.scene.themeSession.sessionStatus > 0 ? new Date().format('yyyy-MM-dd HH:mm:ss') : null,
    })
   }
   // +++++++++++++++++++++++++++++++++++++++
  },

  onOpened_dialog() {
   if (_.isArray(_this.$refs['elFormOrder']) && !_.isEmpty(_this.$refs['elFormOrder'])) {
    _this.$refs['elFormOrder'].forEach(element => {
     element.clearValidate()
    })
   }
  },
  onClosed_dialog() {},
  onClick_edit(order, orderIdx) {},
  onClick_confirm() {
   let allValidateRst = true
   _this.$refs['elFormOrder'].forEach(element => {
    element.validate(valid => {
     if (!valid) {
      allValidateRst = false
     }
    })
   })

   // +++++++++++++++++++++++++++++++++++++++
   // `付款方式`另外校验
   if (allValidateRst) {
    let isHaveEmptyPayItem = this.scene.themeSession.statistics.some(eleOrder => {
     if (eleOrder.costs.length === 1) {
      return true
     } else {
      return eleOrder.costs.some((elePayLine, idx, arr) => {
       if (idx === eleOrder.costs.length - 1) {
        if (!_.isNumber(elePayLine.payChannelId) && !_.isNumber(elePayLine.money)) {
         return false
        } else {
         return true
        }
       } else {
        return !_.isNumber(elePayLine.payChannelId) || !_.isNumber(elePayLine.money)
       }
      })
     }
    })

    if (isHaveEmptyPayItem) {
     this.$message.error(`'付款方式'的参数填写不完整,无法新增!`)
     return
    }

    // +++++++++++++++++++++++++++++++++++++++
    // 保存
    this.saveSession()
    this.dialogVisible = false
   } else {
    this.$message.error('有订单的参数填写不完整或不正确,无法提交,请检查!')
   }
  },

  showDialog(title = '预定信息', scene, theme, date, isSolo = false, callback, selectedDayStatus = -100) {
   this.sceneSrc = scene
   this.scene = _.cloneDeep(scene)
   this.theme = _.cloneDeep(theme)
   this.sessionStartDay = date
   this.isSolo = isSolo
   this.callback = callback
   this.selectedDayStatus = selectedDayStatus

   if (_.isNil(this.scene.themeSession.statistics) || !_.isArray(this.scene.themeSession.statistics) || _.isEmpty(this.scene.themeSession.statistics)) {
    this.scene.themeSession.statistics = []
    this.pushEmptyOrder()
   } else {
    this.scene.themeSession.statistics.forEach((eleOrder, idx, arr) => {
     eleOrder.costs.push({
      payChannelId: null,
      money: null,
      payChannelRate: null,

      inAccountStatus: _this.scene.themeSession.sessionStatus > 0 ? 1 : null,
      inAccountTime: _this.scene.themeSession.sessionStatus > 0 ? new Date().format('yyyy-MM-dd HH:mm:ss') : null,
     })
    })
   }

   this.payMethodList = this.calc_money()
   this.dialogVisible = true
  },

  onClick_unreserve(orderIdx) {
   let toUnreserveOrder = _this.scene.themeSession.statistics[orderIdx]
   if ((!_.isNil(toUnreserveOrder.id) && _.isNumber(toUnreserveOrder.id)) || toUnreserveOrder.id > 0) {
    function _unreserveOrder(order) {
     order.unreservedStatus = 1
     order.unreservedTime = new Date().format('yyyy-MM-dd HH:mm:ss')

     let orderClone = _.cloneDeep(order)
     orderClone.costs = orderClone.costs.filter((elePayEntry, idx, arr) => {
      return !_.isNil(elePayEntry.payChannelId) && !_.isNil(elePayEntry.money)
     })

     orderClone.costs.forEach((elePayEntry, idx, arr) => {
      if (elePayEntry.inAccountStatus == 1) {
       elePayEntry.inAccountStatus = 2
       elePayEntry.outAccountTime = new Date().format('yyyy-MM-dd HH:mm:ss')
      }
     })

     saveOrUpdateThemeStatistics(orderClone).then(response => {
      _this.$message.success('退订成功')

      // sync to local
      let foundEleOrderIdx = _this.sceneSrc.themeSession.statistics.findIndex((eleOrder, idx, arr) => {
       return eleOrder.id === order.id
      })
      if (foundEleOrderIdx !== -1) {
       // 找到了!
       _this.sceneSrc.themeSession.statistics[foundEleOrderIdx].unreservedStatus = order.unreservedStatus
       _this.sceneSrc.themeSession.statistics[foundEleOrderIdx].unreservedTime = order.unreservedTime
      }
     })
    }

    _this.$refs['delDialog'].showDialog('退订', '当前订单已保存在服务器, 是否退订？', toUnreserveOrder, _unreserveOrder)
   } else {
    _this.scene.themeSession.statistics.splice(orderIdx, 1)
    if (_.isEmpty(_this.scene.themeSession.statistics)) {
     _this.pushEmptyOrder()
    }
   }
  },

  onClick_addReserve() {
   let allValidateRst = true
   _this.$refs['elFormOrder'].forEach(element => {
    element.validate(valid => {
     if (!valid) {
      allValidateRst = false
     }
    })
   })

   if (allValidateRst) {
    // +++++++++++++++++++++++++++++++++++++++
    // `付款方式`另外校验
    let isHaveEmptyPayItem = this.scene.themeSession.statistics.some(eleOrder => {
     if (eleOrder.costs.length === 1) {
      return true
     } else {
      return eleOrder.costs.some((elePayLine, idx, arr) => {
       if (idx === eleOrder.costs.length - 1) {
        if (!_.isNumber(elePayLine.payChannelId) && !_.isNumber(elePayLine.money)) {
         return false
        } else {
         return true
        }
       } else {
        return !_.isNumber(elePayLine.payChannelId) || !_.isNumber(elePayLine.money)
       }
      })
     }
    })

    if (isHaveEmptyPayItem) {
     this.$message.error(`'付款方式'的参数填写不完整,无法新增!`)
     return
    }

    // +++++++++++++++++++++++++++++++++++++++
    // 新增一个参数全空的订单
    this.pushEmptyOrder()
   } else {
    this.$message.error('有订单的参数填写不完整,无法新增')
   }
  },
  cancelDialog() {
   this.dialogVisible = false
  },
  // 增加客户
  addCustomArr() {
   this.itemId++
   this.subscribeArr.push({
    itemId: this.itemId,
    customName: '客户姓名',
    customPhone: '客户电话',
    guessAge: '预估年龄',
    orderNum: '订单人数',
   })
  },
  // 删除客户
  delArr(index) {
   this.subscribeArr.splice(index, 1)
  },
  changelan() {
   this.openOrclose1 = !this.openOrclose1
   this.isShow = !this.isShow
  },
  changelan1() {
   this.openOrclose2 = !this.openOrclose2
   this.isShow1 = !this.isShow1
  },
  changelan2() {
   this.openOrclose2 = !this.openOrclose2
   this.isShow2 = !this.isShow2
  },
  select(index) {
   this.payWayData[index].isSelected = !this.payWayData[index].isSelected
   this.readOnly[index] = !this.readOnly[index]
  },
 },
}
</script>

<style lang="less">
// width: @themeWidth;
// min-width: @themeWidth;
// max-width: @themeWidth;
// height: @themesHeight;
// min-height: @themesHeight;
// max-height: @themesHeight;
#themereserve {
 .subscribeContentList {
  .el-dialog {
   // border: solid 1px red;
   width: 8.56rem;

   .el-dialog__header {
    width: 8.56rem;
    background: url('../../statics/images/other/标题栏BG@2x.png') no-repeat;
    background-size: cover;
    // background-position-x: -0.5rem;
    height: 0.4rem;
    position: relative;
    padding: 0.1rem 0.11rem 0.09rem;

    .el-dialog__title {
     width: 0.72rem;
     height: 0.18rem;
     white-space: nowrap;
     font-size: 0.18rem;
     position: absolute;
     left: 0rem;
     padding-left: 0.41rem;
     color: white;
    }

    .el-dialog__title::before {
     content: '';
     display: block;
     width: 0.23rem;
     height: 0.21rem;
     background: url('../../statics/images/other/yogo@2x.png') center center;
     background-size: cover;
     position: absolute;
     left: 0.11rem;
    }

    .el-dialog__headerbtn {
     width: 0.29rem;
     height: 0.29rem;
     background-color: #fff;
     top: 0.05rem;
     right: -0.47rem;
     border-radius: 0.15rem;

     i::before {
      font-size: 0.2rem;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
     }
    }
   }

   // 滚动条的轨道（里面装有Thumb）
   .el-dialog__body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
   }

   // 滚动条整体部分
   .el-dialog__body::-webkit-scrollbar {
    width: 6px;
    background-color: #fff;
   }

   // 滚动条里面的小方块
   .el-dialog__body::-webkit-scrollbar-thumb {
    background-color: #d2d2d2;
   }

   .el-dialog__body,
   .el-dialog__footer {
    // border: solid 1px red;
    width: 8.56rem;
    height: auto;
    background-color: #fff;
   }

   .el-collapse {
    border: solid 0px blue;
    width: 7.76rem;

    // width: 90%;
    // min-width: 90%;
    // max-width: 90%;
    .el-collapse-item {
     margin-bottom: 40px;
     border: solid 1px rgba(0, 0, 0, 0.2);
     padding: 0 30px;

     // height: 400px;
     // overflow: auto;

     .el-collapse-item__header {
      .icon {
       height: 30%;
       border: 2px solid #208def;
       margin-right: 10px;
      }
     }

     .el-form {
      position: relative;
      padding: 10px;

      .el-form-item {
       width: 40%;
       margin-right: 0;
       padding: 0 0px;
       position: relative;

       @labelWidth: 7em;

       .el-form-item__label {
        width: @labelWidth;
       }

       .el-form-item__content {
        width: calc(100% - @labelWidth);

        .el-select {
         width: 100%;
        }

        .el-switch.is-disabled {
         opacity: 0.3;
        }
       }
      }

      .el-form-item.num {
       width: 15%;
       pointer-events: none;
      }

      .customNum {
       //  border: solid 1px red;
       //  position: relative;
       //  width: 0.36rem;
       //  height: 0.36rem;
       // background-color: skyblue;
       p {
        // border: solid 1px red;
        position: absolute;
        top: 0;
        left: 40px;
        line-height: 12px;
        height: 0.27rem;
        font-size: 0.96rem;
        color: rgba(193, 193, 193, 1);
       }
      }
     }
    }

    .el-collapse-item__header {
     font-size: 0.22rem;
     line-height: 0.22rem;
     height: 84px;
     border: solid 0px rgb(241, 241, 241);
    }

    .el-collapse-item__content {
     border: solid 0px rgb(241, 241, 241);
    }

    .el-collapse-item__wrap {
     border: solid 0px red;
     height: fit-content;
     //  width: 7.16rem;
     //  height: 2.36rem;
     //  background: rgba(235, 235, 235, 1);
     //  border-radius: 0.06rem;
     //  padding: 0.3rem;
     //  margin-top: 0.3rem;
    }

    // .el-collapse-item__header.is-active {
    //  //  height: 84px;
    //  font-size: 0.22rem;
    //  line-height: 0.22rem;
    //  height: 84px;
    //  margin-bottom: 0px;
    //  border-top: solid 1px rgb(241, 241, 241);
    // }
    .reserve {
     .content {
      // border: solid 1px red;
      //  width: 7.16rem;
      //  height: 2.36rem;
      //  background: rgba(235, 235, 235, 1);
      //  border-radius: 0.06rem;
      //  padding: 0.3rem;
      //  margin-top: 0.3rem;
      width: 100%;

      //  height: 100px;
      .info {
       // border: solid 1px red;
       width: 100%;
       // width: 7.16rem;
       // height: 2.36rem;
       background: rgba(235, 235, 235, 1);
       border-radius: 0.06rem;
       // padding: 0.3rem;
       margin-bottom: 0.3rem;
       padding-top: 10px;
      }

      .action {
       width: 100%;

       .el-button.is-disabled {
        opacity: 0.5;
       }

       .el-button.is-unreserved {
        background-color: rgba(255, 0, 0, 0.3);
        // width: 30%;
        color: white;
       }
      }

      .delete-pay-item {
       font-size: 20px;
       color: red;
       position: absolute;
       bottom: 10px;
       right: -25px;
       padding: 0px;
       background-color: rgba(255, 0, 0, 0);
       border: solid 0px red;
      }
     }
    }

    .pay-amount {
     .content {
      // border: solid 1px red;
      //  width: 7.16rem;
      //  height: 2.36rem;
      //  background: rgba(235, 235, 235, 1);
      //  border-radius: 0.06rem;
      //  padding: 0.3rem;
      //  margin-top: 0.3rem;
      width: 100%;
      height: auto;

      .line {
       //  border: solid 1px rgb(39, 211, 82);
       justify-content: space-between;
       align-items: center;
       margin: 5px 0;

       .title {
        // border: solid 1px red;
        max-width: fit-content;
        margin: 0 10px;
        font-size: 16px;
       }

       .separator {
        border: solid 0.5px grey;
        margin: 0 10px;
        border-style: dashed;
       }

       .money {
        // border: solid 1px red;
        height: fit-content;
        // width: 100px;
        // min-width: 100px;
        max-width: fit-content;
        font-size: 16px;
        margin: 0 10px;
        justify-content: center;
        align-items: center;

        span:nth-of-type(1) {
         // border:solid 1px red;
         //  height: 0.21rem;
         font-size: 0.21rem;
         font-weight: 400;
         color: rgba(242, 94, 35, 1);
        }

        span:nth-of-type(2) {
         // border:solid 1px red;
         //  height: 0.28rem;
         font-size: 0.36rem;
         font-weight: bold;
         color: rgba(242, 94, 35, 1);
        }
       }
      }

      .info {
       // border: solid 1px red;
       width: 100%;
       // width: 7.16rem;
       // height: 2.36rem;
       background: rgba(235, 235, 235, 1);
       border-radius: 0.06rem;
       // padding: 0.3rem;
       margin-bottom: 0.3rem;
       padding-top: 10px;
      }

      .action {
       width: 100%;
      }
     }
    }
   }
  }

  &.subscribe {
   // border: solid 1px red;
   .el-dialog__body {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 6rem;
    padding: 0;
    padding-top: 0.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .customInfo,
    .orderForm,
    .payMoney {
     width: 7.76rem;
     padding: 0.3rem;
     border: 1px solid #f1f1f1;
     border-radius: 0.06rem;
     margin-bottom: 0.4rem;

     .customTitle,
     .orderFormTitle,
     .payMoneyTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.22rem;
      line-height: 0.22rem;
      color: #000;

      p:nth-of-type(1) {
       display: flex;
       align-items: center;
       justify-content: center;

       img {
        width: 0.04rem;
        height: 0.22rem;
        margin-right: 0.09rem;
       }
      }
     }
    }

    .customInfo {
     .contentInfo {
      ul {
       width: 7.16rem;
       height: 2.36rem;
       background: rgba(235, 235, 235, 1);
       border-radius: 0.06rem;
       padding: 0.3rem;
       margin-top: 0.3rem;

       li {
        margin-bottom: 0.3rem;
        display: flex;

        input {
         width: 2.8rem;
         height: 0.36rem;
         background: rgba(255, 255, 255, 1);
         border: 1px solid rgba(210, 210, 210, 1);
         border-radius: 0.06rem;
         padding-left: 0.09rem;
         font-size: 0.14rem;
         margin-right: 0.3rem;
        }

        p {
         line-height: 0.18rem;
         height: 0.18rem;
         font-size: 0.18rem;
         color: rgba(0, 0, 0, 1);
         margin-bottom: 0.19rem;
        }

        .activeLine {
         border: 1px solid rgba(32, 141, 239, 1);
        }
       }

       li:nth-of-type(1) {
        align-items: flex-end;

        .delCustom {
         width: 0.36rem;
         height: 0.36rem;
         background: rgba(230, 105, 105, 1);
         border-radius: 0.06rem;
         position: relative;

         img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
         }
        }
       }

       li:nth-of-type(2) {
        align-items: flex-end;

        .guessAge {
         position: relative;

         .downIcon {
          position: absolute;
          top: 51%;
          right: 0.3rem;
          width: 0.36rem;
          height: 0.36rem;
          background: rgba(210, 210, 210, 1);
          border: 1px solid rgba(210, 210, 210, 1);
          border-radius: 0px 0.06rem 0.06rem 0px;

          img {
           width: 0.16rem;
           height: 0.1rem;
           position: absolute;
           top: 50%;
           left: 50%;
           transform: translate(-50%, -50%);
          }
         }
        }

        .customNum {
         position: relative;
         width: 0.36rem;
         height: 0.36rem;

         // background-color: skyblue;
         p {
          position: absolute;
          top: 0;
          right: 0;
          line-height: 0.36rem;
          height: 0.27rem;
          font-size: 0.36rem;
          color: rgba(193, 193, 193, 1);
         }
        }
       }
      }

      .addCustom {
       width: 100%;
       margin-top: 0.3rem;

       > p {
        width: 1.18rem;
        height: 0.4rem;
        border: 1px solid rgba(32, 141, 239, 1);
        border-radius: 0.06rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.18rem;
        font-weight: 400;
        color: rgba(32, 141, 239, 1);
       }
      }
     }
    }

    .orderForm {
     .orderFormContent {
      padding: 0.3rem 0.3rem 0;

      ul {
       display: flex;

       li {
        margin-right: 0.3rem;

        p {
         padding: 0;
         line-height: 0.18rem;
         height: 0.18rem;
         font-size: 0.18rem;
         color: rgba(0, 0, 0, 1);
         margin-bottom: 0.19rem;
        }

        input {
         width: 2.8rem;
         height: 0.36rem;
         background: rgba(255, 255, 255, 1);
         border: 1px solid rgba(210, 210, 210, 1);
         border-radius: 0.06rem;
         padding-left: 0.09rem;
         font-size: 0.14rem;
        }

        .isOrNotTeam {
         position: relative;

         .downIcon {
          position: absolute;
          top: 51%;
          right: 0;
          width: 0.36rem;
          height: 0.36rem;
          background: rgba(210, 210, 210, 1);
          border: 1px solid rgba(210, 210, 210, 1);
          border-radius: 0px 0.06rem 0.06rem 0px;

          img {
           width: 0.16rem;
           height: 0.1rem;
           position: absolute;
           top: 50%;
           left: 50%;
           transform: translate(-50%, -50%);
          }
         }
        }
       }
      }

      .payWay {
       display: flex;

       > div {
        width: 2.8rem;
        margin-right: 0.3rem;
        margin-top: 0.3rem;

        > p {
         padding: 0;
         height: 0.17rem;
         line-height: 0.17rem;
         font-size: 0.18rem;
         color: rgba(0, 0, 0, 1);
         margin-bottom: 0.2rem;
        }

        .payWayList {
         width: 2.8rem;
         display: flex;
         height: 0.36rem;
         // justify-content: center;
         align-items: center;
         margin-bottom: 0.2rem;

         img {
          margin-top: -0.04rem;
          margin-right: 0.06rem;
         }
        }

        input {
         width: 2.8rem;
         height: 0.36rem;
         border: 1px solid rgba(210, 210, 210, 1);
         border-radius: 0.06rem;
         margin-bottom: 0.2rem;
         padding-left: 0.1rem;
         font-size: 0.14rem;
        }

        form:nth-of-type(9) {
         input {
          margin-bottom: 0rem;
         }
        }

        .payWayList:nth-of-type(9) {
         margin-bottom: 0rem;
        }
       }
      }
     }
    }

    .payMoney {
     .payMoneyList {
      padding: 0.3rem 0.3rem 0;

      ul li {
       display: flex;
       margin-bottom: 0.29rem;

       p {
        padding: 0;
        line-height: 0.16rem;
        height: 0.17rem;
        font-size: 0.16rem;
        font-weight: normal;
        color: rgba(129, 129, 129, 1);
        margin-right: 0.2rem;
       }

       p:nth-of-type(2) {
        margin-right: 0.15rem;
       }

       p:nth-of-type(3) {
        margin-right: 0rem;
       }
      }

      li:nth-of-type(4) {
       margin-bottom: 0rem;

       .total {
        span:nth-of-type(1) {
         height: 0.16rem;
         font-size: 0.21rem;
         font-weight: 400;
         color: rgba(242, 94, 35, 1);
        }

        span:nth-of-type(2) {
         height: 0.28rem;
         font-size: 0.36rem;
         font-weight: bold;
         color: rgba(242, 94, 35, 1);
        }
       }
      }
     }
    }
   }

   .el-dialog__footer {
    padding-bottom: 0.4rem;
    padding-top: 0;
    font-size: 14px;

    .el-button {
     width: 1rem;
     height: 0.5rem;
     margin: 20px;
    }

    // .el-button--primary {
    //   width: 0.8rem;
    //   height: 0.4rem;
    //   //   // // background: #ea4335;
    //   //   // border: none;
    //   //   // border-radius: 0.06rem;
    //   //   // padding: 0;
    // }
    // .el-button--default {
    //   width: 0.8rem;
    //   height: 0.4rem;
    //   //   border-radius: 0.06rem;
    //   //   padding: 0;
    // }
   }
  }
 }
}
</style>
