import request from '@/utils/request'
// +++++++++++++++++++++++++++++++++++++++
export function getStaffUserList(params) {
 // return request.get('/users')
 return request({
  url: '/api/staffUsers',
  method: 'get',
  params
 })
}
// +++++++++++++++++++++++++++++++++++++++
export function login(data) {
 // return request.post('/api/login', data)
 return request({
  url: '/api/login',
  method: 'post',
  params: data
 })
}

export function get_user_routes(data) {
 // return request.get('/api/menu/'+ data)
 return request({
  url: '/api/menu/' + data,
  method: 'get'
 })
}

export function get_user_detail(id) {
 // return request.get('/api/menu/'+ data)
 return request({
  url: '/api/user_by_id/' + id,
  method: 'get'
 })
}

export function logout(username) {
 // return request.get('/api/logout/'+username)
 return request({
  url: '/api/logout/' + username,
  method: 'get'
 })
}
// 新增用户
export function createUser(data) {
 return request({
  url: '/api/user',
  method: 'post',
  data: data
 })
}

// 更新用户
export function updateUser(data) {
 return request({
  url: '/api/user',
  method: 'put',
  data: data
 })
}

// 删除用户
export function deleteUser(data) {
 return request({
  url: '/api/user',
  method: 'delete',
  data: data
 })
}
// 获取用户列表
// export function getUserList(parms) {
//   return request({
//     url: '/user',
//     method: 'get',
//     params: parms
//   })
// }
// 编辑用户
export function editUser(data) {
 return request({
  url: '/user',
  method: 'put',
  data: data
 })
}
// 重置密码
export function restPass(id) {
 return request({
  url: '/user/' + id,
  method: 'put'
 })
}
// 删除用户
export function deleteUserById(id) {
 return request({
  url: '/api/user/' + id,
  method: 'delete'
 })
}
// 获取用户个人信息
export function getUserInfo() {
 return request({
  url: '/user/info',
  method: 'get'
 })
}
// 修改密码
export function updatePassword(id, data) {
 return request({
  url: '/api/user/updatePwd/' + id,
  method: 'put',
  data: data
 })
}
// 修改邮箱
export function updateEmail(id, data) {
 return request({
  url: '/api/user/updateEmail/' + id,
  method: 'put',
  data: data
 })
}
// 修改邮箱
export function updatePhone(id, data) {
 return request({
  url: '/api/user/updatePhone/' + id,
  method: 'put',
  data: data
 })
}

// 发送邮箱验证码
export function resetEmail(parms) {
 return request({
  url: '/user/sendMailCode',
  method: 'post',
  params: parms
 })
}
// 发送短信验证码
export function sendSms(phone) {
 return request({
  url: '/sendCode/' + phone,
  method: 'post'
 })
}
// 绑定用户
export function bindUser(data) {
 return request({
  url: '/bind',
  method: 'post',
  data: data
 })
}
// 绑定用户
export function registerUser(data) {
 return request({
  url: '/register',
  method: 'post',
  data: data
 })
}
export function getUserList() {
 // return request.get('/users')
 return request({
  url: '/api/users',
  method: 'get'
 })
}
export function create(data) {
 return request.post('/users', data)
}
export function update(data) {
 return request.put('/users/' + data.id, data)
}
export function delete_(data) {
 return request.delete('/users/' + data.id)
}
