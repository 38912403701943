<template>
 <div id="yogo-passrate" class="eltables" v-loading.fullscreen.lock="listLoading" element-loading-background="rgba(0, 0, 0, 0.8)">
  <div class="toolbar-container duku">
   <div>
    <span class="yogo-title toolbar-title">上座率和通关率</span>
   </div>

   <div class="toolbar-box">
    <el-form label-position="top">
     <el-form-item label="门店" class="filter-item">
      <el-select class="item-content" v-model="formModel.storeId" placeholder="" clearable size="small" style="" @change="formModel.themeId = null">
       <el-option v-for="(item, idx) of shopList" :key="idx" :value="item.id" :label="item.name" />
      </el-select>
     </el-form-item>
     <el-form-item label="主题" class="filter-item">
      <el-select class="item-content" v-model="formModel.themeId" placeholder="" clearable size="small" style="">
       <el-option v-for="(item, idx) of filteredThemeList" :key="idx" :value="item.id" :label="item.themeName" />
      </el-select>
     </el-form-item>
     <el-form-item label="开始时间" class="filter-item">
      <el-date-picker class="item-content" type="date" clearable size="small" style="" placeholder="" v-model="formModel.startTime" value-format="yyyy-MM-dd HH:mm:ss" />
     </el-form-item>
     <el-form-item label="结束时间" class="filter-item">
      <el-date-picker class="item-content" type="date" clearable size="small" style="" placeholder="" v-model="formModel.endTime" value-format="yyyy-MM-dd HH:mm:ss" />
     </el-form-item>
    </el-form>

    <div>
     <el-button size="small" class="filter-item" type="primary" icon="el-icon-search" @click="onClick_search($event)">查询 </el-button>
     <el-button size="small" class="filter-item" type="primary" icon="el-icon-download" @click="onClick_exportExcel($event)">导出Excel</el-button>
    </div>
   </div>
  </div>

  <el-table :data="list" class="data-table" stripe height="1000">
   <el-table-column label="主题名称" prop="_themeName" align="center" />
   <el-table-column label="总排场" prop="total" align="center" />
   <el-table-column label="销售场次" prop="reserved" align="center" />
   <el-table-column label="通过场次" prop="completedPassed" align="center" />
   <el-table-column label="上座率" prop="_soldRate" align="center" />
   <el-table-column label="通关率" prop="_passedRate" align="center" />
  </el-table>

  <pagination v-show="true" :total="listSize" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
 </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { getBusinesses, getShops, getResults } from '@/api/shop'
import { deleteTheme, getTheme, getThemeList, saveOrUpdateTheme, uploadThemeImage, getPlaybacks } from '@/api/theme/theme'
import { getPayChannelList, getPayChannel, saveOrUpdatePayChannel, deletePayChannel } from '@/api/paychannel'

export default {
 components: {
  Pagination,
 },
 data() {
  let today = new Date(new Date().format('yyyy-MM-dd'))
  let tommorrow = new Date(new Date().format('yyyy-MM-dd'))
  tommorrow.setDate(tommorrow.getDate() + 1)

  return {
   listExcel: null,

   shopList: null,
   themeList: null,
   payChannelList: null,
   formModel: {
    storeId: null,
    themeId: null,
    startTime: today.format('yyyy-MM-dd HH:mm:ss'),
    endTime: tommorrow.format('yyyy-MM-dd HH:mm:ss'),
   },
   // +++++++++++++++++++++++++++++++++++++++
   list: null,
   listSize: 0,
   listLoading: true,
   listQuery: {
    page: 1,
    limit: 10,
   },
  }
 },
 computed: {
  filteredThemeList() {
   let clone_themeList = _.cloneDeep(this.themeList)
   if (_.isInteger(this.formModel.storeId) && this.formModel.storeId > 0) {
    return clone_themeList.filter((ele_theme, idx, arr) => {
     return ele_theme.storeId === this.formModel.storeId
    })
   } else {
    return clone_themeList
   }
  },
 },
 beforeCreate() {
  Promise.all([getShops(), getThemeList(), getPayChannelList()]).then(result => {
   this.shopList = result[0].data.data
   this.themeList = result[1].data
   this.payChannelList = result[2].data

   this.getList()
  })
 },

 methods: {
  onClick_exportExcel() {
   if (_.isArray(this.listExcel) && !_.isEmpty(this.listExcel)) {
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    let columns = []
    _.entries(this.listExcel[0]).filter((ele, idx, arr) => {
     switch (ele[0]) {
      case '_themeName':
       columns.push({
        prop: ele[0],
        label: '主题名称',
       })
       return true

      case 'total':
       columns.push({
        prop: ele[0],
        label: '总排场',
       })
       return true

      case 'reserved':
       columns.push({
        prop: ele[0],
        label: '销售场次',
       })
       return true

      case 'completedPassed':
       columns.push({
        prop: ele[0],
        label: '通过场次',
       })
       return true

      case '_soldRate':
       columns.push({
        prop: ele[0],
        label: '上座率',
       })
       return true

      case '_passedRate':
       columns.push({
        prop: ele[0],
        label: '通关率',
       })
       return true

      default:
       return false
     }
    })
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    this.$export.excel({ columns: columns, data: this.listExcel }).then(() => {})
   } else {
    this.$message.error('没有数据可导出')
   }
  },
  onClick_search() {
   this.listQuery.storeId = this.formModel.storeId
   this.listQuery.themeId = this.formModel.themeId
   this.listQuery.startTime = this.formModel.startTime
   this.listQuery.endTime = this.formModel.endTime

   this.getList()
  },
  getList() {
   this.listLoading = true

   let param = {
    pageSize: this.listQuery.limit,
    pageNum: this.listQuery.page,
    isPage: true,

    storeId: this.listQuery.storeId,
    themeId: this.listQuery.themeId,
    startTime: this.listQuery.startTime,
    endTime: this.listQuery.endTime,
   }

   getResults(param)
    .then(response => {
     response.data.rows.forEach((ele, idx, arr) => {
      let foundEle = this.themeList.find((ele_theme, idx, arr) => {
       return ele_theme.id === ele.theme_id
      })
      if (!_.isUndefined(foundEle)) {
       // 找到了!
       ele._themeName = foundEle.themeName
      }

      ele._soldRate = Number((100 * ele.reserved) / ele.total).toFixed(2)
      if (ele._soldRate == 'NaN') {
       ele._soldRate = '0%'
      } else {
       ele._soldRate += '%'
      }

      ele._passedRate = Number((100 * ele.completedPassed) / ele.reserved).toFixed(2)
      if (ele._passedRate == 'NaN') {
       ele._passedRate = '0%'
      } else {
       ele._passedRate += '%'
      }
     })

     this.list = response.data.rows
     this.listSize = response.data.total
    })
    .then(response => {
     this.getExcelList()
    })
  },
  getExcelList() {
   let param = {
    // pageSize: this.listQuery.limit,
    // pageNum: this.listQuery.page,
    isPage: false,

    storeId: this.listQuery.storeId,
    themeId: this.listQuery.themeId,
    startTime: this.listQuery.startTime,
    endTime: this.listQuery.endTime,
   }

   getResults(param).then(response => {
    response.data.forEach((ele, idx, arr) => {
     let foundEle = this.themeList.find((ele_theme, idx, arr) => {
      return ele_theme.id === ele.theme_id
     })
     if (!_.isUndefined(foundEle)) {
      // 找到了!
      ele._themeName = foundEle.themeName
     }

     ele._soldRate = Number((100 * ele.reserved) / ele.total).toFixed(2)
     if (ele._soldRate == 'NaN') {
      ele._soldRate = '0%'
     } else {
      ele._soldRate += '%'
     }

     ele._passedRate = Number((100 * ele.completedPassed) / ele.reserved).toFixed(2)
     if (ele._passedRate == 'NaN') {
      ele._passedRate = '0%'
     } else {
      ele._passedRate += '%'
     }
    })

    this.listExcel = response.data
    // this.listSize = response.data.length
    this.listLoading = false
   })
  },

  handleFilter() {},
 },
}
</script>
<style lang="scss" scoped>
#yogo-passrate {
 .data-table {
  // 60px headbar
  // 200px search block
  // 100px pagination block
  max-height: calc(100vh - 60px - 200px - 100px);
 }
}
</style>
