import request from '@/utils/request'

// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
export function fetchList() {
 return request.get('/games')
}

export function create(data) {
 return request.post('/games', data)
}

export function update(data) {
 return request.put('/games/' + data.id, data)
}

export function delete_(data) {
 return request.delete('/games/' + data.id)
}

// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
export function getTypes(params) {
 return request({
  url: '/api/TopicTypes',
  method: 'get',
  params,
 })
 // return request.get('/types')
}

export function createType(data) {
 return request({
  url: '/api/TopicType',
  method: 'post',
  data,
 })
 // return request.post('/types', data)
}

export function updateType(data) {
 return request({
  url: '/api/TopicType',
  method: 'post',
  data,
 })
}

export function deleteType(data) {
 return request({
  url: '/api/TopicType/' + data.id,
  method: 'delete',
  data,
 })
}

// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
export function getStyles(params) {
 return request({
  url: '/api/GameStyles',
  method: 'get',
  params,
 })
}

export function createStyle(data) {
 return request({
  url: '/api/GameStyle',
  method: 'post',
  data,
 })
}

export function updateStyle(data) {
 return request({
  url: '/api/GameStyle',
  method: 'post',
  data,
 })
}

export function deleteStyle(id) {
 return request({
  url: '/api/GameStyle/' + id,
  method: 'delete',
 })
}
