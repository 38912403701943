<template>
 <div id="yogo-informationPlayback" class="eltables" v-loading.fullscreen.lock="listLoading" element-loading-background="rgba(0, 0, 0, 0.8)">
  <div class="toolbar-container duku">
   <div>
    <span class="yogo-title toolbar-title">主题回放</span>
   </div>

   <div class="toolbar-box">
    <el-form label-position="top">
     <el-form-item label="门店" class="filter-item">
      <el-select class="item-content" v-model="formModel.storeId" placeholder="" clearable size="small" style="" @change="formModel.themeId = null">
       <el-option v-for="(item, idx) of shopList" :key="idx" :value="item.id" :label="item.name" />
      </el-select>
     </el-form-item>
     <el-form-item label="主题" class="filter-item">
      <el-select class="item-content" v-model="formModel.themeId" placeholder="" clearable size="small" style="">
       <el-option v-for="(item, idx) of filteredThemeList" :key="idx" :value="item.id" :label="item.themeName" />
      </el-select>
     </el-form-item>

     <el-form-item label="开始时间" class="filter-item">
      <el-date-picker class="item-content" type="date" clearable size="small" style="" placeholder="" v-model="formModel.startTime" value-format="yyyy-MM-dd HH:mm:ss" />
     </el-form-item>
     <el-form-item label="结束时间" class="filter-item">
      <el-date-picker class="item-content" type="date" clearable size="small" style="" placeholder="" v-model="formModel.endTime" value-format="yyyy-MM-dd HH:mm:ss" />
     </el-form-item>
    </el-form>

    <div>
     <el-button size="small" class="filter-item" type="primary" icon="el-icon-search" @click="onClick_search($event)">查询</el-button>
     <el-button size="small" class="filter-item" type="primary" icon="el-icon-download" @click="onClick_exportExcel($event)">导出Excel</el-button>
    </div>
   </div>
  </div>

  <el-table :data="list" class="data-table" stripe height="1000">
   <el-table-column label="主题照片" align="center">
    <template slot-scope="scope">
     <img :src="combineImgUrl(scope.row.themePicUrl)" style="height:50px" />
    </template>
   </el-table-column>

   <el-table-column label="主题名称" prop="themeName" align="center" />
   <el-table-column label="游戏时长" prop="duration" align="center" />

   <el-table-column label="主题档期" align="center">
    <template slot-scope="scope">
     <el-tooltip class="tooltip" placement="right" effect="dark">
      <div>{{ scope.row.schedules.length + '场' }}</div>
      <div slot="content">
       <div v-for="(item, idx) in scope.row.schedules" :key="idx">
        {{ '场次' + (idx + 1) + ': ' + item.startTime + '-' + item.endTime }}
       </div>
      </div>
     </el-tooltip>
    </template>
   </el-table-column>

   <el-table-column label="忙时单价" prop="busyPrice" align="center" />
   <el-table-column label="闲时单价" prop="idlePrice" align="center" />
   <el-table-column label="玩家人数" prop="maxPeople" align="center" />

   <el-table-column prop="actions" label="操作" width="120" align="left">
    <template slot-scope="{ row }">
     <el-button type="primary" size="small" class="el-icon-tickets" circle @click="handleDetails(row)"></el-button>
    </template>
   </el-table-column>
  </el-table>

  <pagination v-show="true" :total="listSize" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

  <detailDialog ref="detailDialog" />
 </div>
</template>

<script>
import detailDialog from '@/views/xxcx/themePlaybackDetail'
import Pagination from '@/components/Pagination'

import { getBusinesses, getShops, getResults } from '@/api/shop'
import { deleteTheme, getTheme, getThemeList, saveOrUpdateTheme, uploadThemeImage, getPlaybacks } from '@/api/theme/theme'
import { getPayChannelList, getPayChannel, saveOrUpdatePayChannel, deletePayChannel } from '@/api/paychannel'
import { getThemeSessionById, getThemeSessionList, saveOrUpdateThemeSession } from '@/api/theme/themeSession'

export default {
 components: {
  detailDialog,
  Pagination,
 },
 data() {
  let today = new Date(new Date().format('yyyy-MM-dd'))
  let tommorrow = new Date(new Date().format('yyyy-MM-dd'))
  tommorrow.setDate(tommorrow.getDate() + 1)

  return {
   listExcel: null,

   shopList: null,
   themeList: null,
   payChannelList: null,
   formModel: {
    storeId: null,
    themeId: null,
    startTime: today.format('yyyy-MM-dd'),
    endTime: tommorrow.format('yyyy-MM-dd'),
   },
   // +++++++++++++++++++++++++++++++++++++++
   list: null,
   listSize: 0,
   listLoading: true,
   listQuery: {
    page: 1,
    limit: 10,
   },
  }
 },
 computed: {
  filteredThemeList() {
   if (!_.isEmpty(this.themeList)) {
    let clone_themeList = _.cloneDeep(this.themeList)
    if (_.isInteger(this.formModel.storeId) && this.formModel.storeId > 0) {
     return clone_themeList.filter((ele_theme, idx, arr) => {
      return ele_theme.storeId === this.formModel.storeId
     })
    } else {
     return clone_themeList
    }
   } else {
    return null
   }
  },
 },
 beforeCreate() {
  Promise.all([getShops(), getThemeList(), getPayChannelList()]).then(result => {
   this.shopList = result[0].data.data
   this.themeList = result[1].data
   this.payChannelList = result[2].data
  })
 },
 created() {
  this.getList()
 },
 methods: {
  combineImgUrl(value) {
   let url = '' + process.env.VUE_APP_BASE_API + '/' + value
   return url
  },
  onClick_exportExcel() {
   if (_.isArray(this.listExcel) && !_.isEmpty(this.listExcel)) {
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    let columns = []
    _.entries(this.listExcel[0]).filter((ele, idx, arr) => {
     switch (ele[0]) {
      case 'id':
       columns.push({
        prop: ele[0],
        label: '主题id',
       })
       return true

      case 'themeName':
       columns.push({
        prop: ele[0],
        label: '主题名称',
       })
       return true

      case 'duration':
       columns.push({
        prop: ele[0],
        label: '游戏时长',
       })
       return true

      case '_schedules':
       columns.push({
        prop: ele[0],
        label: '主题档期',
       })
       return true

      case 'busyPrice':
       columns.push({
        prop: ele[0],
        label: '忙时单价',
       })
       return true

      case 'idlePrice':
       columns.push({
        prop: ele[0],
        label: '闲时单价',
       })
       return true

      case 'maxPeople':
       columns.push({
        prop: ele[0],
        label: '玩家人数',
       })
       return true

      default:
       return false
     }
    })
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    this.$export.excel({ columns: columns, data: this.listExcel }).then(() => {})
   } else {
    this.$message.error('没有数据可导出')
   }
  },
  onClick_search() {
   this.listQuery.storeId = this.formModel.storeId
   this.listQuery.themeId = this.formModel.themeId
   this.listQuery.startTime = this.formModel.startTime
   this.listQuery.endTime = this.formModel.endTime
   this.getList()
  },
  getList() {
   this.listLoading = true

   let param = {
    pageSize: this.listQuery.limit,
    pageNum: this.listQuery.page,
    isPage: true,

    storeId: this.listQuery.storeId,
    id: this.listQuery.themeId,
    // themeId: this.listQuery.themeId,
    startTime: this.listQuery.startTime,
    endTime: this.listQuery.endTime,
   }
   getThemeList(param)
    .then(response => {
     response.data.rows.forEach((eleTheme, idx, arr) => {
      // 将过期的排场过滤掉
      eleTheme.schedules = eleTheme.schedules.filter((eleSchedule, idx, arr) => {
       return _.isNil(eleSchedule.expireDate) || _.isEmpty(eleSchedule.expireDate)
      })

      // eleTheme._actual = Number(eleTheme._money - eleTheme._poundage).toFixed(2)
     })

     this.list = response.data.rows
     this.listSize = response.data.total
    })
    .then(response => {
     this.getExcelList()
    })
  },
  getExcelList() {
   let param = {
    // pageSize: this.listQuery.limit,
    // pageNum: this.listQuery.page,
    isPage: false,

    storeId: this.listQuery.storeId,
    // themeId: this.listQuery.themeId,
    id: this.listQuery.themeId,
    startTime: this.listQuery.startTime,
    endTime: this.listQuery.endTime,
   }
   this.listExcel = null
   getThemeList(param).then(response => {
    response.data.forEach((eleTheme, idx, arr) => {
     eleTheme._schedules = eleTheme.schedules
      .filter((eleSchedule, idx, arr) => {
       return _.isNil(eleSchedule.expireDate) || _.isEmpty(eleSchedule.expireDate)
      })
      .reduce(function(accumulator, currentValue, currentIndex, array) {
       return accumulator + currentValue.startTime + '-' + currentValue.endTime + ',  '
      }, '')

     // eleTheme._actual = Number(eleTheme._money - eleTheme._poundage).toFixed(2)
    })

    this.listExcel = response.data
    this.listLoading = false
   })
  },
  handleFilter() {},
  handleDetails(item) {
   let param = {
    // pageSize: this.listQuery.limit,
    // pageNum: this.listQuery.page,
    isPage: false,

    id: item.id,
    session: '',

    startDate: this.listQuery.startTime,
    endDate: this.listQuery.endTime,
   }

   getPlaybacks(param).then(response => {
    let list = []
    if (_.isArray(response.data) && !_.isEmpty(response.data)) {
     list = response.data.filter((ele_, idx, arr) => {
      if (_.isNumber(ele_.sessionStatus) && ele_.sessionStatus > 0) {
       return true
      } else {
       return false
      }
     })
    }

    this.$refs['detailDialog'].showDialog('主题详情', list)
   })
  },
 },
}
</script>

<style lang="less" scoped>
.topic-image {
 width: 71/100rem;
 height: 1rem;
}
</style>

<style lang="scss" scoped>
#yogo-informationPlayback {
 .data-table {
  // 60px headbar
  // 200px search block
  // 100px pagination block
  max-height: calc(100vh - 60px - 200px - 100px);
 }
}
</style>
