<template>
 <div class="dialogContent">
  <el-dialog :close-on-click-modal="false" :title="tit" :visible.sync="dialogVisible" @close="closeDialog" class="dialog-wrap" width="4.85rem">
   <el-form size="small">
    <el-row :gutter="14">
     <el-col :span="12">
      <el-form-item label="风格名称">
       <el-input placeholder="请输入风格名称" type="text" v-model="currObj.gameStyle" />
      </el-form-item>
     </el-col>
    </el-row>
   </el-form>
   <div class="row rowbuttons">
    <el-button @click="handleConfirm" type="primary">确 定</el-button>
    <el-button @click="closeDialog">取 消</el-button>
   </div>
   <span class="dialog-footer" slot="footer"></span>
  </el-dialog>
 </div>
</template>

<script>
export default {
 data() {
  return {
   tit: '',
   dialogVisible: false,
   currObj: {},
   callback: null,
  }
 },
 methods: {
  closeDialog() {
   this.dialogVisible = false
   this.callback = null
  },
  cancelDialog() {
   this.dialogVisible = false
   this.callback = null
  },
  showDialog(title = '新增游戏风格', data, callback) {
   this.tit = title
   this.dialogVisible = true
   if (data) {
    this.currObj = data
   } else {
    this.currObj = {}
   }
   this.callback = callback
  },
  handleConfirm() {
   if (this.callback) {
    this.callback(this.currObj)
    this.closeDialog()
   }
  },
 },
}
</script>

<style lang="less" scoped>
@import url('../../statics/css/editDialog.css');
</style>
