import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
NProgress.configure({ showSpinner: false }) // NProgress Configuration

/**
 * 这里导入组件
 */

//登录页面
import loginPage from '@/views/login.vue'
// 运营管理 （密室主题运营、第三方通知、其他收入）
// 首页
import homePage from '@/views/homePage.vue'
// 密室主题运营
import theme from '@/views/yygl/theme.vue'
// 第三方通知
import notice from '@/views/yygl/notice.vue'
// 其他收入
import extra from '@/views/yygl/extra.vue'

// 信息查询（主题回放、操作记录）
// 主题回放
import themePlayback from '@/views/xxcx/themePlayback.vue'
// 操作记录
import operaterecord from '@/views/xxcx/operateRecord.vue'

//经营管理（密室经营状况、其他经营状况、上座率和通关率、店面支出、会员记录）
// 密室经营状况
import roomBusiness from '@/views/jygl/roomBusiness.vue'
// 其他经营状况
import otherBusiness from '@/views/jygl/otherBusiness.vue'
// 通关率上座率
import passrate from '@/views/jygl/passRate.vue'
// 店面支出
import payment from '@/views/jygl/payment.vue'
// 会员记录
import member from '@/views/jygl/member.vue'

// 店面管理 （出错记录、引导人员统计、公司管理、门面管理、人员管理）
// 出错记录
import errLog from '@/views/dmgl/errLog'
// 引导人员统计
import guide from '@/views/dmgl/guide'
// 门店管理
import shopManagement from '@/views/dmgl/shopManagement'
// 人员管理
import staffManagement from '@/views/dmgl/staffManagement'
// 公司管理
import compManagement from '@/views/dmgl/compManagement'

//后台管理（主题信息管理、主题类型设置、游戏风格设置、系统条目设置、付款方式设置、门店状态）
// 主题信息管理
import topicInfo from '@/views/htgl/topicInfo'
// 主题类型设置
import topicType from '@/views/htgl/topicType'
// 游戏风格设置
import gameStyle from '@/views/htgl/gameStyle'
// 系统条目设置
import systemEntry from '@/views/htgl/systemEntry'
// 付款方式设置
import payMethod from '@/views/htgl/payMethod'
// 门店状态
import storeStatus from '@/views/htgl/storeStatus'

// 系统管理（个人面板、角色管理）
// 个人面板
import personalPanel from '@/views/xtgl/personalPanel'
// 角色管理
import roleManager from '@/views/xtgl/roleManager'

//创建路由对象
//创建路由对象

// axios.defaults.baseURL = ''
// axios.defaults.withCredentials = true
// Vue.prototype.$axios = axios
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => { if (typeof err !== 'undefined') console.log(err) })
// }

// 密室主题运营 duku版
import themeduku from '@/views/yygl/themeduku.vue'

Vue.use(VueRouter)

let constantRoutes = [
 //  {
 //   path: '/',
 //   component: homePage,
 //   redirect: '/yygl/theme'
 //  },
 {
  path: '/login',
  name: '登录页',
  component: loginPage,
 },
 {
  path: '/yygl',
  name: '运营管理',
  component: homePage,
  children: [
   //  {
   //   path: '/yygl/theme',
   //   component: themeduku,
   //   name: '密室主题运营'
   //  },
   {
    path: '/yygl/theme',
    component: themeduku,
    name: '密室主题运营',
   },
  ],
 },
 //  {
 //   path: '/htgl',
 //   hidden: false,
 //   component: homePage,
 //   name: '后台管理',
 //   children: [
 //    {
 //     name: '主题信息设置',
 //     path: '/htgl/topicInfo',
 //     component: topicInfo
 //    },
 //    {
 //     name: '主题类型设置',
 //     path: '/htgl/topicType',
 //     component: topicType
 //    },
 //    {
 //     name: '游戏风格设置',
 //     path: '/htgl/gameStyle',
 //     component: gameStyle
 //    },
 //    {
 //     name: '系统条目设置',
 //     path: '/htgl/systemEntry',
 //     component: systemEntry
 //    },
 //    {
 //     name: '付款方式设置',
 //     path: '/htgl/payMethod',
 //     component: payMethod
 //    },
 //    {
 //     name: '门店状态设置',
 //     path: '/htgl/storeStatus',
 //     component: storeStatus
 //    }
 //   ]
 //  }
]

const createRouter = () =>
 new VueRouter({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
 })

const router = createRouter()

// const anyRouter =  [{
//     path: '/',
//     redirect: '/login'
//   },
//   {
//     path: '/login',
//     component: loginPage
//   },
//   //
//   {
//     path: '/yygl',
//     name: '运营管理',
//     component: homePage,
//     children: [
//       {
//         path: '/yygl/theme',
//         component: theme,
//         name: '密室主题运营'
//       },
//       {
//         path: '/yygl/notice',
//         component: notice,
//         name: '第三方通知'
//       },
//       {
//         path: '/yygl/extra',
//         name: '其它收入',
//         component: extra
//       }
//     ]
//   },
//   {
//     path: '/xxcx',
//     component: homePage,
//     hidden: true,
//     name: '信息查询',
//     children: [
//       {
//         path: '/xxcx/operateRecord',
//         name: '操作记录',
//         component: operaterecord
//       },
//       {
//         path: '/xxcx/themePlayback',
//         name: '主题回放',
//         component: themePlayback
//       }
//     ]
//   },
//   {
//     path: '/jjgl',
//     hidden: true,
//     component: homePage,
//     name: '经营管理',
//     children: [
//       {
//         name: '密室经营状况',
//         path: '/jjgl/roomBusiness',
//         component: roomBusiness
//       }, {
//         path: '/jjgl/otherBusiness',
//         name: '其它经营状况',
//         component: otherBusiness
//       }, {
//         path: '/jjgl/passRate',
//         name: '上座率和通关率',
//         component: passrate
//       }, {
//         path: '/jjgl/member',
//         component: member,
//         name: '会员记录'
//       },
//       {
//         path: '/jjgl/payment',
//         name: '店面支出',
//         component: payment
//       },
//     ]
//   },
//   {
//     path: '/dmgl',
//     hidden: true,
//     component: homePage,
//     name: '门面管理',
//     children: [
//       {
//         path: '/dmgl/errLog',
//         name: '出错记录',
//         component: errLog
//       }, {
//         name: '引导人员统计',
//         path: '/dmgl/guide',
//         component: guide
//       }, {
//         name: '门店管理',
//         path: '/dmgl/shopManagement',
//         component: shopManagement
//       }, {
//         name: '人员管理',
//         path: '/dmgl/staffManagement',
//         component: staffManagement
//       }, {
//         name: '公司管理',
//         path: '/dmgl/compManagement',
//         component: compManagement
//       },
//     ]
//   },
//   {
//     path: '/htgl',
//     hidden: true,
//     component: homePage,
//     name: '后台管理',
//     children: [
//       {
//         name: '主题信息设置',
//         path: '/htgl/topicInfo',
//         component: topicInfo
//       },
//       {
//         name: '主题类型设置',
//         path: '/htgl/topicType',
//         component: topicType
//       },
//       {
//         name: '游戏风格设置',
//         path: '/htgl/gameStyle',
//         component: gameStyle
//       },
//       {
//         name: '系统条目设置',
//         path: '/htgl/systemEntry',
//         component: systemEntry
//       },
//       {
//         name: '付款方式设置',
//         path: '/htgl/payMethod',
//         component: payMethod
//       }
//       ,
//       {
//         name: '门店状态设置',
//         path: '/htgl/storeStatus',
//         component: storeStatus
//       }
//     ]
//   }
//     ,
//   {
//     path: '/xtgl',
//     hidden: true,
//     component: homePage,
//     name: '系统管理',
//     children: [
//       {
//         name: '个人面板',
//         path: '/xtgl/personalPanel',
//         component: personalPanel
//       },
//       {
//         name: '角色管理',
//         path: '/xtgl/roleManager',
//         component: roleManager
//       },
//     ]
//   }
//   ]

const whiteList = ['/login']

router.afterEach(() => {
 // finish progress bar
 NProgress.done()
})

// 导航守卫，渲染动态路由
router.beforeEach(async (to, from, next) => {
 // console.log(to, from, next)
 // start progress bar
 NProgress.start()
 const hasToken = store.getters.token

 if (hasToken) {
  if (to.path === '/') {
   // if is logged in, redirect to the home page
   next({ path: '/yygl/theme' })
   NProgress.done()
  } else {
   const hasAsyncRoute = store.getters.has_async_route
   if (hasAsyncRoute) {
    next()
   } else {
    try {
     resetRouter()
     const user_routes = await store.dispatch('user_detail/get_user_routes', store.getters.username)
     const async_routes = filterAsyncRouter(user_routes)

     router.addRoutes(async_routes)

     await store.dispatch('user_detail/update_user_routes', user_routes)
     await store.dispatch('user_detail/change_has_async_route', true)
     next({ ...to, replace: true })
     NProgress.done()
    } catch (error) {
     await store.dispatch('user_detail/resetToken')
     Message.error(error || 'Has Error')
     next(`/login?redirect=${to.path}`)
     NProgress.done()
    }
   }
  }
 } else {
  if (whiteList.indexOf(to.path) !== -1) {
   // in the free login whitelist, go directly
   next()
   NProgress.done()
  } else {
   // other pages that do not have permission to access are redirected to the login page.
   next(`/login?redirect=${to.path}`)
   NProgress.done()
  }
 }
})

// 导航守卫，渲染动态路由
// router.beforeEach(async (to, from, next) => {
//   // router.beforeEach2(async (to, from, next) => {
//   if (to.path === '/htgl/topicInfo') {
//     next()
//     NProgress.done()
//   } else if (to.path === '/yygl/themeduku') {
//     next()
//     NProgress.done()
//   } else {
//     next({ path: '/yygl/themeduku' })
//   }
// })

export function filterAsyncRouter(routes) {
 return routes.filter(route => {
  let component = route.component
  if (component) {
   switch (route.component) {
    // case 'MenuView':
    //   route.component = MenuView
    //   break
    // case 'PageView':
    //   route.component = PageView
    //   break
    // case 'EmptyPageView':
    //   route.component = EmptyPageView
    //   break
    case 'homePage':
     route.component = homePage
     break
    default: {
     route.component = view(component)
    }
   }
   if (route.children && route.children.length) {
    route.children = filterAsyncRouter(route.children)
   }
   return true
  }
 })
}

function view(path) {
 return function(resolve) {
  import(`@/views${path}.vue`).then(mod => {
   resolve(mod)
  })
 }
}

export function resetRouter() {
 const newRouter = createRouter()
 router.matcher = newRouter.matcher // reset router
}

// //导航守卫
// router.beforeEach((to, from, next) => {
//     next()
// })
//导入路由
export default router
