<template>
<div id="app">
 <router-view></router-view>
</div>
</template>

<script>
export default {
 name: "Yogo",
 components: {}
};
</script>

<style scoped>
@import "./statics/font/SourceHanSansCN-Regular.css";
@import "./statics/css/base.css";
</style><style lang="less">
.el-date-picker {
 .popper__arrow {
  // left: 230px !important;
 }
}
</style>
