import request from '@/utils/request'
export function getShops(query) {
 // return request.get('/api/stores')
 return request({
  url: '/api/stores',
  method: 'get',
  params: query,
 })
}
export function createShop(data) {
 // return request.post('/api/store', data)
 return request({
  url: '/api/store',
  method: 'post',
  data,
 })
}
export function updateShop(data) {
 // return request.put('/api/store', data)
 return request({
  url: '/api/store',
  method: 'post',
  data,
 })
}
export function deleteShop(data) {
 return request({
  url: '/api/store/' + data.id,
  method: 'delete',
  data,
 })
}
// +++++++++++++++++++++++++++++++++++++++
export function getBusinesses(params) {
 return request({
  url: '/api/store/getBussniss',
  method: 'get',
  params,
 })
}
export function getResults(params) {
 return request({
  url: '/api/store/getResults',
  method: 'get',
  params,
 })
}
export function getGuideStatistics(params) {
 return request({
  url: '/api/store/getGuides',
  method: 'get',
  params,
 })
}
// +++++++++++++++++++++++++++++++++++++++
export function getOtherIncomes() {
 return request.get('/store/otherIncomes')
}
export function createOtherIncome() {
 return request.post('/store/otherIncomes')
}
export function updateOtherIncome() {
 return request.post('/store/otherIncomes')
}
export function deleteOtherIncome() {
 return request.delete('/store/otherIncomes')
}

// OtherTrade 其他经营收入
export function getOtherTrades(params) {
 return request({
  url: '/api/store/OtherTrades',
  method: 'get',
  params,
 })
}

export function createOtherTrade(data) {
 return request({
  url: '/api/store/OtherTrade',
  method: 'post',
  data,
 })
}

export function deleteOtherTrade(data) {
 return request({
  url: '/api/store/OtherTrade/' + data.id,
  method: 'delete',
  data,
 })
}

// StorePayOut 店面支出
export function getStorePayOuts(params) {
 return request({
  url: '/api/store/StorePayOuts',
  method: 'get',
  params,
 })
}

export function createStorePayOut(data) {
 return request({
  url: '/api/store/StorePayOut',
  method: 'post',
  data,
 })
}

export function deleteStorePayOut(data) {
 return request({
  url: '/api/store/StorePayOut/' + data.id,
  method: 'delete',
 })
}
// MistakeRecord 出错记录
export function getMistakeRecords(params) {
 return request({
  url: '/api/store/MistakeRecords',
  method: 'get',
  params,
 })
}

export function createMistakeRecord(data) {
 return request({
  url: '/api/store/MistakeRecord',
  method: 'post',
  data,
 })
}

export function deleteMistakeRecord(data) {
 return request({
  url: '/api/store/MistakeRecord/' + data.id,
  method: 'delete',
 })
}

export function getPayments(query) {
 return request({
  url: '/api/store/payments',
  method: 'get',
  params: query,
 })
}
export function createPayment(data) {
 return request({
  url: '/api/store/payments',
  method: 'post',
  data,
 })
}
export function updatePayment(data) {
 return request({
  url: '/api/store/payments',
  method: 'post',
  data,
 })
}
export function deletePayment(data) {
 return request({
  url: '/api/store/payments/' + data.id,
  method: 'delete',
 })
}
