<template>
 <div class="dialogContent">
  <el-dialog :close-on-click-modal="false" :title="tit" :visible.sync="dialogVisible" @close="closeDialog" class="dialog-wrap" width="6.71rem">
   <el-form size="small" :inline="true">
    <el-row :gutter="14">
     <el-col :span="12">
      <el-form-item label="门店">
       <el-select placeholder="请选择" style="width: 100%" v-model="currObj.storeId" value-key="id">
        <el-option :key="item.id" :label="item.name" :value="item.id" v-for="item in stores"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="12">
      <el-form-item label="付款方式">
       <el-input placeholder="请输入付款方式" type="text" v-model="currObj.channelName" />
      </el-form-item>
     </el-col>
     <el-col :span="12">
      <el-form-item label="开通日期">
       <el-date-picker placeholder="请选择开通日期" style="width: 100%" type="date" v-model="currObj.startTime" />
      </el-form-item>
     </el-col>
     <el-col :span="12">
      <el-form-item label="失效日期">
       <el-date-picker placeholder="请选择失效日期" style="width: 100%" type="date" v-model="currObj.expiredTime" />
      </el-form-item>
     </el-col>
     <el-col :span="12" id="rates">
      <el-form-item label="费率">
       <el-input placeholder="请输入费率" type="text" v-model.float="rates_100times" />
      </el-form-item>
      <div class="symbol">%</div>
     </el-col>
     <el-col :span="12">
      <el-form-item label="预收方式">
       <el-switch style="display: block;" v-model="currObj.preIncomeStatus" active-color="#13ce66" inactive-color="#b1b1b1" inactive-text="非预收" active-text="预收" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
     </el-col>
    </el-row>
   </el-form>

   <div class="row rowbuttons">
    <el-button @click="handleConfirm" type="primary">确 定</el-button>
    <el-button @click="closeDialog">取 消</el-button>
   </div>
  </el-dialog>
 </div>
</template>

<script>
import { getShops } from '@/api/shop'
export default {
 data() {
  return {
   tit: '',
   dialogVisible: false,
   currObj: {},
   callback: null,
   stores: [],

   rates_100times: null,
  }
 },

 computed: {},
 methods: {
  closeDialog() {
   this.dialogVisible = false
   this.callback = null
  },
  cancelDialog() {
   this.dialogVisible = false
   this.callback = null
  },
  showDialog(title = '新增付款方式', data, callback) {
   this.tit = title
   this.dialogVisible = true
   getShops().then(response => {
    this.stores = response.data.data
   })
   if (data) {
    this.currObj = data
    this.rates_100times = this.currObj.rates * 100
   } else {
    this.currObj = {}
   }
   this.callback = callback
  },
  handleConfirm() {
   if (this.callback) {
    this.currObj.rates = this.rates_100times / 100
    this.callback(this.currObj)
    this.closeDialog()
   }
  },
 },
}
</script>

<style lang="less">
@import url('../../statics/css/editDialog.css');

#rates {
 display: flex;
 flex-direction: row;
 align-items: center;

 position: static;

 .el-form-item {
  width: 40%;
 }

 .symbol {
  position: relative;
  top: 7px;
  left: -5px;
 }
}
</style>
