<template>
 <div id="yogo-CompManagerment" class="eltables">
  <div class="toolbar-container">
   <span class="yogo-title toolbar-title">公司管理</span>
   <el-button type="primary" class="myicon iconadd" @click="handleAdd"><i></i>新增公司</el-button>
   <el-button type="primary" class="myicon iconadd"><i></i>导出Excel</el-button>
  </div>

  <el-table :data="list" class="data-table" stripe>
   <el-table-column prop="name" label="公司名称" align="center" />
   <el-table-column prop="manager" label="负责人" align="center" />
   <el-table-column prop="contacts[0]" label="联系方式1" align="center" />
   <el-table-column prop="contacts[1]" label="联系方式2" align="center" />
   <el-table-column prop="begin_at" label="开通日期" align="center">
    <template slot-scope="{ row }">
     {{ formatTime(row.begin_at, '{y}-{m}-{d}') }}
    </template>
   </el-table-column>
   <el-table-column prop="end_at" label="失效日期" align="center">
    <template slot-scope="{ row }">
     {{ formatTime(row.end_at, '{y}-{m}-{d}') }}
    </template>
   </el-table-column>
   <el-table-column prop="actions" label="操作" width="120" align="left">
    <template slot-scope="{ row }">
     <el-button type="primary" size="small" class="el-icon-edit" circle @click="handleEdit(row)"></el-button>
     <el-button type="danger" size="small" class="el-icon-delete" circle @click="handleDelete(row)"></el-button>
    </template>
   </el-table-column>
  </el-table>

  <pagination v-show="true" :total="listSize" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

  <delDialog ref="DelDialogForm" :confirm="doDelete" />
  <editDialog ref="EditDialogForm" />
 </div>
</template>

<script>
import delDialog from '@/views/template/delDialog'
import editDialog from '@/views/dmgl/compManagementEdit'
import Pagination from '@/components/Pagination'
import { parseTime } from '@/utils'
import { getCompanies, create, update, deleteCompany } from '@/api/company'

export default {
 components: {
  delDialog,
  editDialog,
  Pagination
 },
 data() {
  return {
   list: null,
   listSize: 0,
   listLoading: true,
   listQuery: {
    page: 1,
    limit: 10,
    importance: undefined,
    title: undefined,
    type: undefined,
    status: undefined,
    sort: '+id'
   },
   selectedItem: null
  }
 },
 created() {
  this.getList()
 },
 methods: {
  formatTime(v, fmt) {
   return parseTime(v, fmt)
  },
  getList() {
   this.listLoading = true
   getCompanies().then(response => {
    this.list = response.data.rows
    this.listSize = response.data.total
   })
   this.listLoading = false
  },
  handleAdd() {
   this.$refs['EditDialogForm'].showDialog('新增公司', null, this.doCreate)
  },
  handleEdit(item) {
   this.selectedItem = item
   const target = JSON.parse(JSON.stringify(item))
   this.$refs['EditDialogForm'].showDialog('修改公司', target, this.doUpdate)
  },
  handleDelete(item) {
   this.selectedItem = item
   this.$refs['DelDialogForm'].showDialog('删除公司', '是否删除当前公司', item, this.doDelete)
  },
  doCreate(data) {
   create(data).then(response => {
    this.list.push(data)
   })
  },
  doUpdate(data) {
   update(data).then(response => {
    this.selectedItem.name = data.name
    this.selectedItem.manager = data.manager
    this.selectedItem.contacts[0] = data.contacts[0]
    this.selectedItem.contacts[1] = data.contacts[1]
    this.selectedItem.begin_at = data.begin_at
    this.selectedItem.end_at = data.end_at
    this.selectedItem.register_type = data.register_type
    this.selectedItem.register_info = data.register_info
   })
  },
  doDelete() {
   deleteCompany(this.selectedItem).then(response => {
    this.list.splice(this.list.indexOf(this.selectedItem), 1)
   })
  }
 }
}
</script>

<style lang="less">
@import url(../../statics/css/eltable.css);
</style>
