import axios from 'axios'
import { Notification } from 'element-ui'
import store from '@/store'
import moment from 'moment'

moment.locale('zh-cn')

// 统一配置
// 创建axios实例
const service = axios.create({
 baseURL: process.env.VUE_APP_BASE_API,
 responseType: 'json',
 validateStatus(status) {
  // 200 外的状态码都认定为失败
  return status === 200
 },
})

// 拦截请求
// service.interceptors.request.use((config) => {
//   // let expireTime = store.state.account.expireTime
//   // let now = moment().format('YYYYMMDDHHmmss')
//   // // 让token早10秒种过期，提升“请重新登录”弹窗体验
//   // if (now - expireTime >= -10) {
//   //   MessageBox.error({
//   //     title: '登录已过期',
//   //     content: '很抱歉，登录已过期，请重新登录',
//   //     okText: '重新登录',
//   //     mask: false,
//   //     onOk: () => {
//   //       return new Promise((resolve, reject) => {
//   //         db.clear()
//   //         location.reload()
//   //       }).catch(function (reason) {
//   //         console.log('catch:', reason)
//   //       })
//   //     }
//   //   })
//   // }
//   // 有 token就带上
//   if (store.state.account.token) {
//     config.headers.Authorization = 'Bearer ' + (store.state.account.token).toString()
//   }
//   return config
// }, (error) => {
//   return Promise.reject(error)
// })

// // request拦截器
service.interceptors.request.use(
 config => {
  if (store.getters.token) {
   config.headers['Authorization'] = 'Bearer ' + store.getters.token // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  // if (getTenant()) {
  //   config.headers['TENANT_ID'] = getTenant() // 租户ID
  // }
  return config
 },
 error => {
  // eslint-disable-next-line no-console
  Promise.reject(error)
 },
)

// 拦截响应
service.interceptors.response.use(
 config => {
  return config
 },
 error => {
  if (error.response) {
   let errorMessage = error.response.data === null ? '系统内部异常，请联系网站管理员' : error.response.data.msg
   switch (error.response.status) {
    case 404:
     Notification.error({
      title: '系统提示',
      message: '很抱歉，资源未找到',
      duration: 2500,
     })
     break
    case 403:
    case 401:
     Notification.error({
      title: '系统提示',
      message: '很抱歉，您无法访问该资源，可能是因为没有相应权限或者登录已失效',
      duration: 2500,
     })
     break
    default:
     Notification.error({
      title: '系统提示',
      message: errorMessage,
      duration: 2500,
     })
     break
   }
  } else {
   const errorMsg = error.response.data.msg
   if (errorMsg !== undefined) {
    Notification.error({
     title: errorMsg,
     duration: 2500,
    })
   }
  }
  return Promise.reject(error)
 },
)

//response 拦截器
// service.interceptors.response.use(
//   response => {
//     // eslint-disable-next-line no-console
//     console.log(response)
//     const code = response.data.code
//     if (code === 401) {
//       MessageBox.confirm(
//         '登录状态已过期，您可以继续留在该页面，或者重新登录',
//         '系统提示',
//         {
//           confirmButtonText: '重新登录',
//           cancelButtonText: '取消',
//           type: 'warning'
//         }
//       ).then(() => {
//         store.dispatch('LogOut').then(() => {
//           location.reload() // 为了重新实例化vue-router对象 避免bug
//         })
//       })
//     } else if (code !== 200) {
//       Notification.error({
//         title: response.data.msg
//       })
//       return Promise.reject('error')
//     } else {
//       return response
//     }
//   },
//   error => {
//     let code = 0
//     try {
//       code = error.response.status
//     } catch (e) {
//       if (error.toString().indexOf('Error: timeout') !== -1) {
//         Notification.error({
//           title: '网络请求超时',
//           duration: 2500
//         })
//         return Promise.reject(error)
//       }
//       if (error.toString().indexOf('Error: Network Error') !== -1) {
//         Notification.error({
//           title: '网络请求错误',
//           duration: 2500
//         })
//         return Promise.reject(error)
//       }
//     }
//     if (code === 504) {
//       Notification.error({
//         title: '服务器异常',
//         duration: 2500
//       })
//       return Promise.reject(error)
//     }
//     if (code === 401) {
//       MessageBox.confirm(
//         '登录状态已过期，您可以继续留在该页面，或者重新登录',
//         '系统提示',
//         {
//           confirmButtonText: '重新登录',
//           cancelButtonText: '取消',
//           type: 'warning'
//         }
//       ).then(() => {
//         store.dispatch('LogOut').then(() => {
//           location.reload() // 为了重新实例化vue-router对象 避免bug
//         })
//       })
//     } else if (code === 403) {
//       router.push({ path: '/401' })
//     } else {
//       const errorMsg = error.response.data.message
//       if (errorMsg !== undefined) {
//         Notification.error({
//           title: errorMsg,
//           duration: 2500
//         })
//       }
//     }
//     return Promise.reject(error)
//   })

// const request = {
//   post (url, params) {
//     return service.post(url, params, {
//       transformRequest: [(params) => {
//         let result = ''
//         Object.keys(params).forEach((key) => {
//           if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
//             result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
//           }
//         })
//         return result
//       }],
//       headers: {
//         'Content-Type': 'application/x-www-form-urlencoded'
//       }
//     })
//   },
//   put (url, params) {
//     return service.put(url, params, {
//       transformRequest: [(params) => {
//         let result = ''
//         Object.keys(params).forEach((key) => {
//           if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
//             result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
//           }
//         })
//         return result
//       }],
//       headers: {
//         'Content-Type': 'application/x-www-form-urlencoded'
//       }
//     })
//   },
//   get (url, params) {
//     let _params
//     if (Object.is(params, undefined)) {
//       _params = ''
//     } else {
//       _params = '?'
//       for (let key in params) {
//         if (params.hasOwnProperty(key) && params[key] !== null) {
//           _params += `${key}=${params[key]}&`
//         }
//       }
//     }
//     return service.get(`${url}${_params}`)
//   },
//   delete (url, params) {
//     let _params
//     if (Object.is(params, undefined)) {
//       _params = ''
//     } else {
//       _params = '?'
//       for (let key in params) {
//         if (params.hasOwnProperty(key) && params[key] !== null) {
//           _params += `${key}=${params[key]}&`
//         }
//       }
//     }
//     return service.delete(`${url}${_params}`)
//   }
// }
//
// export default request

export default service
