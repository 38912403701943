<template>
  <div id="yogo-payment"> 
    <table-template 
       ref="tabletemplate"
      :dynamicTableTilteArr="tableTilteArr"
      :dynamicTableContentArr="tableContentArr"
      :dynamicTableComponents="tableComponents"
      :dynamicTableTopBtns="tableTopBtns"
      :dynamicTableLowBtns="tableLowBtns"
      :dynamicFormFields="formFields"
      :dynamicRowform="true"
      dynamicTableTit='会员记录' 
       />   
      <!--  @changeCode='changeCode' -->
      <!-- @deleteData='deleteItem'  --> 
      <!-- ref="memberRecharge"  -->
    <memberAdd  ref="addDialogForm" />


  </div>
</template>
<script>
import memberAdd from '@/views/jygl/memberAdd'
import tableTemplate from "@/views/template/onetable";
export default {
  components:{ 
    tableTemplate,
    memberAdd
  },
  data() {
    return {  
      formFields:{
        mengdian:'',
        byuser:'',
        begin:'',
        end:''
      },
      tableTopBtns:[
          {compKey:'compKey1',labelName:"充值",type:'primary',className:'iconcz',clickEvent:()=>this.chongzhi()}
      ],
      tableLowBtns:[
           {compKey:'compKey1',labelName:"查 询",type:'primary',className:'btncz',iconClass:'el-icon-search',clickEvent:()=>this.query()}, 
           {compKey:'compKey2',labelName:"导出Excel",type:'primary',className:'btnexport',iconClass:'el-icon-download',clickEvent:()=>null}
      ],
      tableComponents: [
        {CompArr: [{code: "YOGO优果真人密室逃脱(江汉路店)",codeField: "mengdian", component: () => import("@/views/template/commonInput"),labelName: "门店"},
                   {code: "默认值",codeField: "byuser",component: () => import("@/views/template/commonInput"),labelName: "充值人员"},
         {code: "",codeField: "begin",component: () => import("@/views/template/datePicker"),labelName: "开始时间"},
                   {code: "",codeField: "end",component: () => import("@/views/template/datePicker"),labelName: "结束时间"}]},
      ],
      tableTilteArr: [
        {title: "会员名称",prop: "store", className: ""},
        {title: "手机号码", prop: "content", className: ""},
        {title: "会员生日",prop: "price",className: "" }, 
        {title: "会员卡号",prop: "payer",className: "" }, 
        {title: "充值金额",prop: "remark",className: "" }, 
        {title: "付款方式",prop: "recorded",className: "" }, 
        {title: "门店",prop: "recorded",className: ""},
        {title: "充值时间",prop: "recorded",className: ""},
        {title: "充值人员",prop: "recorded",className: ""},
        {title: "备注",prop: "recorded",className: ""}
      ],
      tableContentArr: [
         {
          uname: "张三",
          phone:"13430003000",
          times: "2018-08-23 ",
          number:"KB339928290",
          price: 500,
          pay:"支付宝",
          store:"YOGO优果真人密室逃脱(江汉路店)",
          paytimes: "2018-08-23 ",
          byuser: "王小石",
          desc: "-"
        },
        {
          uname: "李四",
          phone:"13430003000",
          times: "2018-08-23 ",
          number:"KB339928290",
          price: 500,
          pay:"支付宝",
          store:"YOGO优果真人密室逃脱(江汉路店)",
          paytimes: "2018-08-23 ",
          byuser: "王小石",
          desc: "-"
        },
        {
          uname: "王五",
          phone:"13430003000",
          times: "2018-08-23 ",
          number:"KB339928290",
          price: 500,
          pay:"支付宝",
          store:"YOGO优果真人密室逃脱(江汉路店)",
          paytimes: "2018-08-23 ",
          byuser: "王小石",
          desc: "-"
        },
        {
          uname: "赵六",
          phone:"13430003000",
          times: "2018-08-23 10:01:44",
          number:"KB339928290",
          price: 500,
          pay:"支付宝",
          store:"YOGO优果真人密室逃脱(江汉路店)",
          paytimes: "2018-08-23 ",
          byuser: "王小石",
          desc: "-"
        },
        {
          uname: "孙琦",
          phone:"13430003000",
          times: "2018-08-23 ",
          number:"KB339928290",
          price: 500,
          pay:"支付宝",
          store:"YOGO优果真人密室逃脱(江汉路店)",
          paytimes: "2018-08-23 ",
          byuser: "王小石",
          desc: "-"
        },
        {
          uname: "会员名称",
          phone:"13430003000",
          times: "2018-08-23 ",
          number:"KB339928290",
          price: 500,
          pay:"支付宝",
          store:"YOGO优果真人密室逃脱(江汉路店)",
          paytimes: "2018-08-23 ",
          byuser: "王小石",
          desc: "-"
        },
      ]
    }
  }, 
  methods:{  
    chongzhi(){ 
       
      this.$refs['addDialogForm'].showDialog(); 
    }, 
    query(){
       
    },
    edit(item){
      cosnole.log("edit:",item)
    },
    del(item){
       cosnole.log("delete:",item)
    }
  }
};
</script>
<style scoped> 
</style>
