import { login, logout, get_user_routes } from '@/api/user'
import db from '@/utils/localstorage'
import { resetRouter } from '@/router'

const state = {
 demodata: db.get('DEMO_DATA'),

 token: db.get('TOKEN'),
 username: db.get('USERNAME'),
 roles: db.get('ROLES'),
 user_routes: db.get('USER_ROUTES'),
 user: db.get('USER'),
 has_async_route: false
}

const mutations = {
 SET_DEMO_DATA(state, val) {
  db.save('DEMO_DATA', val)
  state.demodata = val
 },

 SET_TOKEN: (state, token) => {
  db.save('TOKEN', token)
  state.token = token
 },
 SET_USERNAME: (state, username) => {
  db.save('USERNAME', username)
  state.username = username
 },
 SET_ROLES: (state, roles) => {
  db.save('ROLES', roles)
  state.roles = roles
 },
 SET_USER_ROUTES: (state, user_routes) => {
  db.save('USER_ROUTES', user_routes)
  state.user_routes = user_routes
 },
 SET_USER: (state, user) => {
  db.save('USER', user)
  state.user = user
 },
 SET_HAS_ASYNC_ROUTE: (state, change_val) => {
  state.has_async_route = change_val
 }
}

const actions = {
 set_demo_data({ commit }, val) {
  commit('SET_DEMO_DATA', val)
 },

 change_has_async_route({ commit }, val) {
  commit('SET_HAS_ASYNC_ROUTE', val)
 },
 // user login
 login({ commit }, userInfo) {
  const { username, password, code } = userInfo

  return new Promise((resolve, reject) => {
   login({ username: username.trim(), password: password, code: code })
    .then(response => {
     const { data } = response.data
     commit('SET_TOKEN', data.token)
     commit('SET_USERNAME', data.user.username)
     commit('SET_ROLES', data.roles)
     commit('SET_USER_ROUTES', data.user_routes)
     commit('SET_USER', data.user)
     resolve(data)
    })
    .catch(error => {
     reject(error)
    })
  })
 },

 // // user logout
 logout({ commit, state, dispatch }) {
  return new Promise((resolve, reject) => {
   logout(state.token)
    .then(() => {
     commit('SET_TOKEN', null)
     commit('SET_ROLES', null)
     commit('SET_USERNAME', null)
     commit('SET_USER_ROUTES', null)
     commit('SET_USER', null)
     commit('SET_HAS_ASYNC_ROUTE', false)

     resetRouter()
     // reset visited views and cached views
     // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
     // dispatch('tagsView/delAllViews', null, { root: true })
     resolve()
    })
    .catch(error => {
     reject(error)
    })
   commit('SET_TOKEN', null)
   commit('SET_ROLES', null)
   commit('SET_USERNAME', null)
   commit('SET_USER_ROUTES', null)
   commit('SET_USER', null)
   commit('SET_HAS_ASYNC_ROUTE', false)
  })
 },

 update_user_routes({ commit }, routes) {
  commit('SET_USER_ROUTES', routes)
 },

 get_user_routes({ commit, state }, username) {
  return new Promise((resolve, reject) => {
   get_user_routes(state.username)
    .then(response => {
     const { data } = response
     if (!data) {
      reject('not data')
     }
     commit('SET_USER_ROUTES', data)
     resolve(data)
    })
    .catch(error => {
     reject(error)
    })
  })
 },
 // get user info
 // get_user_routes({ commit, state }) {
 //   return new Promise((resolve, reject) => {
 //     getInfo(state.token).then(response => {
 //       const { data } = response

 //       if (!data) {
 //         reject('Verification failed, please Login again.')
 //       }

 //       const { roles, name, avatar, introduction } = data

 //       // roles must be a non-empty array
 //       if (!roles || roles.length <= 0) {
 //         reject('getInfo: roles must be a non-null array!')
 //       }

 //       commit('SET_ROLES', roles)
 //       commit('SET_NAME', name)
 //       commit('SET_AVATAR', avatar)
 //       commit('SET_INTRODUCTION', introduction)
 //       resolve(data)
 //     }).catch(error => {
 //       reject(error)
 //     })
 //   })
 // },

 // // remove token
 resetToken({ commit }) {
  return new Promise(resolve => {
   commit('SET_TOKEN', null)
   commit('SET_USERNAME', null)
   commit('SET_ROLES', null)
   commit('SET_USER_ROUTES', null)
   commit('SET_USER', null)
   resolve()
  })
 }

 // // dynamically modify permissions
 // changeRoles({ commit, dispatch }, role) {
 //   return new Promise(async resolve => {
 //     const token = role + '-token'

 //     commit('SET_TOKEN', token)
 //     setToken(token)

 //     const { roles } = await dispatch('getInfo')

 //     resetRouter()

 //     // generate accessible routes map based on roles
 //     const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })

 //     // dynamically add accessible routes
 //     router.addRoutes(accessRoutes)

 //     // reset visited views and cached views
 //     dispatch('tagsView/delAllViews', null, { root: true })

 //     resolve()
 //   })
 // }
}

export default {
 namespaced: true,
 state,
 mutations,
 actions
}
