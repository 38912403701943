import request from '@/utils/request'

// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
export function getThemeSessionById(id) {
 return request({
  url: '/api/themeSession/get/' + id,
  method: 'get',
 })
}

export function getScheduleById(id) {
 return request({
  url: '/api/themeSchedule/get/' + id,
  method: 'get',
 })
}

export function getThemeSessionList(params) {
 return request({
  url: '/api/themeSession/list1',
  method: 'get',
  params,
 })
}
export function saveOrUpdateThemeSession(data) {
 for (const key in data) {
  key.startsWith('_') ? delete data[key] : ''
 }

 return request({
  url: '/api/themeSession/saveOrUpdate',
  method: 'post',
  data,
 })
}
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
