import request from '@/utils/request'

// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
export function getThemeSessionGiftById(id) {
 return request({
  url: '/api/themeSessionGift/get/' + id,
  method: 'get',
 })
}

export function getThemeSessionGiftList(params) {
 return request({
  url: '/api/themeSessionGift/list',
  method: 'get',
  params,
 })
}
export function saveOrUpdateThemeSessionGift(data) {
 return request({
  url: '/api/themeSessionGift/saveOrUpdate',
  method: 'post',
  data,
 })
}

export function deleteThemeSessionGiftById(id) {
 return request({
  url: '/api/themeSessionGift/delete/' + id,
  method: 'delete',
 })
}

// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
