import request from '@/utils/request'

export function getTopicTypeList(params) {
 return request({
  url: '/api/TopicTypes',
  method: 'get',
  params,
 })
}

export function saveOrUpdateTopicType(data) {
 return request({
  url: '/api/TopicType',
  method: 'post',
  data,
 })
}

export function deleteTopicType(id) {
 return request({
  url: '/api/TopicType/' + id,
  method: 'delete',
 })
}
