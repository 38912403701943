const getters = {
 demodata: state => state.user_detail.demodata,
 user: state => state.user_detail.user,
 roles: state => state.user_detail.roles,
 token: state => state.user_detail.token,
 user_routes: state => state.user_detail.user_routes,
 username: state => state.user_detail.username,
 has_async_route: state => state.user_detail.has_async_route,
}
export default getters
