<template>
 <div class="dialogContent">
  <el-dialog :close-on-click-modal="false" :title="tit" :visible.sync="dialogVisible" @close="closeDialog" class="dialog-wrap" width="4.85rem" @opened="onOpenedDialog">
   <el-form size="small">
    <el-row :gutter="14">
     <el-col :span="12">
      <el-form-item label="主题下线时间">
       <el-date-picker @change="" ref="datePicker" class="p-right" format="yyyy-MM-dd" placeholder="请选择" type="date" v-model="currObj.offlineDate" value-format="yyyy-MM-dd" :picker-options="pickerOptions" :clearable="false" />
      </el-form-item>
     </el-col>
    </el-row>
   </el-form>
   <div class="row rowbuttons">
    <el-button @click="handleConfirm" type="warning">确 定</el-button>
    <el-button @click="closeDialog" type="info">取 消</el-button>
   </div>
  </el-dialog>
 </div>
</template>

<script>
import { deleteTheme, getTheme, getThemeList, saveOrUpdateTheme, uploadThemeImage, canOffline, setThemeOffline } from '@/api/theme/theme'

var _this

export default {
 data() {
  return {
   tit: '',
   dialogVisible: false,
   currObj: {},
   callback: null,

   pickerOptions: {
    disabledDate(date_time) {
     if (!_.isEmpty(_this.currObj) && !_.isEmpty(_this.currObj._latestReservedDay)) {
      let date_minDateToOffline = _this.calc_minDateToOffline(_this.currObj._latestReservedDay)

      if (date_time > date_minDateToOffline) {
       return false
      } else {
       return true
      }
     } else {
      return false
     }
    },
   },
  }
 },
 mounted() {},
 methods: {
  onOpenedDialog() {
   this.$refs['datePicker'].focus()
  },

  calc_minDateToOffline(latestReservedDay) {
   let dateYMD_today = new Date(new Date().format('yyyy-MM-dd'))
   let strYMD_today = dateYMD_today.format('yyyy-MM-dd')

   let dateYMD_yesterday = new Date()
   dateYMD_yesterday.setDate(dateYMD_yesterday.getDate() - 1)
   let strYMD_yesterday = dateYMD_yesterday.format('yyyy-MM-dd')

   let dateYMD_latestReservedDay = new Date(new Date().format(latestReservedDay))
   let strYMD_latestReservedDay = dateYMD_latestReservedDay.format('yyyy-MM-dd')

   if (dateYMD_yesterday > dateYMD_latestReservedDay) {
    return dateYMD_yesterday
   } else {
    return dateYMD_latestReservedDay
   }
  },

  onChange_datePicker(value) {
   if (!_.isEmpty(value)) {
    canOffline(this.currObj.id, {
     offlineDate: value,
    }).then(response => {
     if (_.isNumber(response.data.data) && response.data.data > 0) {
      this.$message.error(`此主题在${value}之后已有${response.data.data}个预定，请先联系相关客户进行退订`)
      this.currObj.offlineDate = null
     } else {
      // this.callback(this.currObj)
     }
    })
   } else {
   }
  },
  closeDialog() {
   this.dialogVisible = false
   this.callback = null
  },
  cancelDialog() {
   this.dialogVisible = false
   this.callback = null
  },
  showDialog(title = '主题下线', data, callback) {
   this.tit = title
   this.dialogVisible = true
   if (data) {
    // this.currObj = data
    this.currObj = _.cloneDeep(data)
   } else {
    this.currObj = {}
   }
   this.callback = callback
  },
  handleConfirm() {
   if (this.callback) {
    this.callback(this.currObj)
    this.closeDialog()
   }
  },
 },
 mounted() {
  _this = this
 },
}
</script>

<style lang="less">
@import url('../../statics/css/editDialog.css');
</style>
