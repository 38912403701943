<template>
 <el-container direction="horizontal" class="dk-time-picker">
  <el-select v-model="valueComp" :placeholder="placeholder" @change="onChange_elSelect" :clearable="clearable" :disabled="disabled">
   <el-option v-for="(item, idx) of options" :key="idx" :value="item.value" :label="item.label" />
  </el-select>
 </el-container>
</template>

<script>
export default {
 name: 'DkTimePicker',
 components: {},
 props: {
  value: {
   type: String,
   required: false,
   default: '',
  },
  clearable: {
   type: Boolean,
   required: false,
   default: false,
  },
  disabled: {
   type: Boolean,
   required: false,
   default: false,
  },
  pickerOptions: {
   type: Object,
   required: true,
   default() {
    return {
     start: '00:00',
     step: '00:00',
     end: '00:00',
    }
   },
  },
  placeholder: {
   type: String,
   required: false,
   default: '请选择',
  },
 },
 data() {
  return {
   options: [],
  }
 },
 computed: {
  valueComp: {
   get() {
    return this.value
   },
   set(val) {
    this.$emit('input', val)
   },
  },
 },
 watch: {
  pickerOptions: {
   handler: function(newVal, oldVal) {
    this.calc_options()
   },
   deep: true,
  },
 },
 mounted() {
  this.calc_options()
 },
 methods: {
  calc_options() {
   let startArr = this.pickerOptions.start.split(':')
   let startMinute = parseInt(startArr[0]) * 60 + parseInt(startArr[1])

   let stepArr = this.pickerOptions.step.split(':')
   let stepMinute = parseInt(stepArr[0]) * 60 + parseInt(stepArr[1])

   let endArr = this.pickerOptions.end.split(':')
   let endMinute =
    parseInt(endArr[0]) < parseInt(startArr[0])
     ? (parseInt(endArr[0]) + 24) * 60 + parseInt(endArr[1])
     : parseInt(endArr[0]) * 60 + parseInt(endArr[1])

   let durationMinute = endMinute - startMinute

   let times = Math.ceil(durationMinute / stepMinute)

   let retArr = []
   let timeStr = null
   let isTurnClock = false
   let timeStrTurnClock = null
   for (let index = 0; index < times; index++) {
    let timeMinute = startMinute + stepMinute * index
    timeStr = ('' + Math.floor(timeMinute / 60)).padStart(2, '0') + ':' + ('' + (timeMinute % 60)).padStart(2, '0')

    if (timeMinute >= 24 * 60) {
     isTurnClock = true
     let timeMinuteTurnClock = timeMinute - 24 * 60
     timeStrTurnClock =
      '次日 ' + ('' + Math.floor(timeMinuteTurnClock / 60)).padStart(2, '0') + ':' + ('' + (timeMinuteTurnClock % 60)).padStart(2, '0')
    }

    retArr.push({
     label: isTurnClock ? timeStrTurnClock : timeStr,
     value: timeStr,
    })
   }

   this.options.splice(0)
   retArr.forEach((ele, idx, arr) => {
    this.options.push(ele)
   })
  },

  onChange_elSelect(val) {
   this.$emit('change', val)
  },
 },
}
</script>

<style lang="scss" scoped>
.dk-time-picker {
 flex-wrap: wrap;
}

.select-button {
 padding: 6px 9px;
 height: 26px;
 margin-top: 4px;
}
</style>
