<template>
  <div class="themegift">
    <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" @closed="closeDialog" center class="giftContentList gift" title="游戏赠品">
      <div class="giftImg">
        <el-image :src="combineImgUrl" alt class="giftPic" fit="cover" />
      </div>

      <div class="isOrNotgift">密室通关成功!</div>

      <template v-if="!_.isEmpty(scene) && !_.isEmpty(scene.themeSession) && !_.isEmpty(scene.themeSession.gifts)">
        <div :key="index" class="giftContent" v-for="(itemGift, index) in scene.themeSession.gifts">
          <div class="item">
            <el-row align="middle" class="row-title" justify="start" style="width:100%;" type="flex">赠品{{ index + 1 }}</el-row>

            <el-row align="top" class="row-content" justify="start" style="width:100%;" type="flex">
              <el-form ref="elFormGift" :inline="true" :model="itemGift" :rules="elFormGiftRules" label-width="100px">
                <!-- <el-form :inline="true" :model="itemGift" label-width="100px" ref="elFormGift"> -->
                <el-col :offset="0" :pull="0" :push="0" :span="10" class="col-" style="height:100%; display:flex; flex-direction:row; justify-content:flex-start; align-items:center;">
                  <el-form-item label prop="giftId">
                    <el-select v-model="itemGift.giftId" placeholder="请选择赠品">
                      <el-option v-for="(itemEntry, idx) in allEntry" :key="idx" :label="itemEntry.name" :value="itemEntry.id" />
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :offset="0" :pull="0" :push="0" :span="10" class="col-" style="height:100%; display:flex; flex-direction:row; justify-content:flex-start; align-items:center;">
                  <el-form-item label prop="num">
                    <el-input placeholder="请输入赠品数量" style :maxlength="3" v-model.number="itemGift.num"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :offset="0" :pull="0" :push="0" :span="4" class="col-" style="height:100%; display:flex; flex-direction:row; justify-content:flex-start; align-items:flex-start;">
                  <el-button @click="reduceArr(index)" icon="el-icon-delete" type="danger">删除</el-button>
                </el-col>
              </el-form>
            </el-row>
          </div>
        </div>
      </template>

      <div class="addGift">
        <el-row align="middle" class="row-" justify="center" style="width:100%;" type="flex">
          <p @click="addArr">+添加赠品</p>
        </el-row>
      </div>

      <div class="helpMan">
        <el-row align="middle" class="row-title" justify="start" style="width:100%;" type="flex">引导人员</el-row>

        <el-row align="middle" class="row-content" justify="start" style="width:100%;" type="flex">
          <el-col :offset="0" :pull="0" :push="0" :span="24" class="col-" style="height:100%; display:flex; flex-direction:row; justify-content:flex-start; align-items:center;">
            <el-form :inline="true" :model="elFormGuideModel" :rules="elFormGuideRules" label-width="100px" ref="elFormGuide">
              <el-form-item label prop="userIdArr">
                <el-select placeholder="请选择" multiple v-model="elFormGuideModel.userIdArr" :clearable="true">
                  <el-option :key="staff.userId" :label="staff.username" :value="staff.userId" v-for="(staff, index) in allStaff"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <!-- ++++++++++++++++++++++++++++++++++++++++++++++++++++ -->
        <el-row align="middle" class="row-title" justify="start" style="width:100%;" type="flex">通关情况</el-row>

        <el-row align="middle" class="row-content" justify="start" style="width:100%;" type="flex">
          <el-col :offset="0" :pull="0" :push="0" :span="24" class="col-" style="height:100%; display:flex; flex-direction:row; justify-content:flex-start; align-items:center;">
            <el-form :inline="true" :model="elFormGuideModel" :rules="elFormGuideRules" label-width="100px" ref="elFormGuide">
              <el-form-item label>
                <el-switch style="display: block" v-if="!_.isEmpty(scene) && !_.isEmpty(scene.themeSession)" v-model="scene.themeSession.sessionStatus" :active-value="3" :inactive-value="2" active-color="#13ce66" inactive-color="#ff4949" active-text="已通关" inactive-text="未通关"> </el-switch>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>

      <span class="dialog-footer" slot="footer">
        <el-button @click="onClick_confirm" type="primary">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <delDialog ref="delDialog" />
  </div>
</template>

<script>
import delDialog from '@/views/template/delDialog'
import { getSytemEntryList, createEntry, updateEntry, deleteEntry } from '@/api/systementry'

import { getThemeSessionById, getThemeSessionList, saveOrUpdateThemeSession } from '@/api/theme/themeSession'

import { getThemeSessionGiftById, getThemeSessionGiftList, saveOrUpdateThemeSessionGift, deleteThemeSessionGiftById } from '@/api/theme/themeSessionGift'

import { getStaffUserList, create, update, delete_ } from '@/api/user'

var _this

export default {
  components: {
    delDialog,
  },
  computed: {
    combineImgUrl() {
      if (this.themePicUrl !== '') {
        let url = '' + process.env.VUE_APP_BASE_API + '/' + this.themePicUrl
        return url
      } else {
        return ''
      }
    },
  },
  data() {
    return {
      tttttttttt: null,

      // +++++++++++++++++++++++++++++++++++++++
      elFormGuideModel: {
        username: null,
        userId: null,
        userIdArr: [],
      },

      scene: null,
      sceneSrc: null,
      theme: null,
      themePicUrl: '',
      selectedShopId: null,

      allStaff: null,
      allEntry: null,

      elFormGiftRules: {
        giftId: [
          {
            required: true,
            message: '请输入',
            trigger: ['blur', 'change'],
          },
        ],
        num: [
          {
            validator: (rule, value, callback) => {
              const reg = /^[0-9]*$/
              if (reg.test(value)) {
                callback()
              } else {
                callback(new Error())
              }
            },
            required: true,
            message: '请输入正整数',
            trigger: ['blur', 'change'],
          },
        ],
      },
      elFormGuideRules: {
        userId: [
          {
            required: true,
            message: '请选择',
            trigger: ['blur', 'change'],
          },
        ],
        userIdArr: [
          {
            required: true,
            message: '请选择',
            trigger: ['blur', 'change'],
          },
        ],
        // username: [
        //  {
        //   required: true,
        //   message: '请选择',
        //   trigger: ['blur', 'change'],
        //  },
        // ],
      },

      dialogVisible: false,
    }
  },
  mounted() {
    _this = this
  },
  methods: {
    showDialog(title = '游戏赠品', scene, theme, date, selectedShopId) {
      this.theme = theme
      this.selectedShopId = selectedShopId
      this.sceneSrc = scene

      this.scene = []
      this.scene = _.cloneDeep(scene)

      this.themePicUrl = theme.themePicUrl

      getStaffUserList().then(response => {
        _this.allStaff = response.data.data.filter((eleStaff, idx, arr) => {
          return eleStaff.stores.some((eleStore, idx, arr) => {
            return eleStore.id === _this.selectedShopId
          })
        })
      })

      getSytemEntryList({
        isPage: false,
      }).then(response => {
        _this.allEntry = response.data
          .filter((ele_, idx, arr) => {
            return ele_.type === 0
          })
          .filter((ele_, idx, arr) => {
            return ele_.storeId === _this.theme.storeId
          })
      })

      if (_.isNil(this.scene.themeSession.gifts) || !_.isArray(this.scene.themeSession.gifts) || _.isEmpty(this.scene.themeSession.gifts)) {
        this.scene.themeSession.gifts = []
        // this.scene.themeSession.gifts.push({
        //  gift: null,
        //  // "id": 0,
        //  // "note": "string",
        //  num: null,
        //  themeSessionid: this.scene.themeSession.id,
        // })
      }

      if (_.isString(this.scene.themeSession.guidePerson) && !_.isEmpty(this.scene.themeSession.guidePerson)) {
        this.elFormGuideModel.userIdArr = this.scene.themeSession.guidePerson.split(',').map((ele_, idx, arr) => {
          return parseInt(ele_)
        })
      } else {
        this.elFormGuideModel.userIdArr = []
      }

      this.dialogVisible = true
    },
    onClick_confirm() {
      _this.$refs['elFormGuide'].validate(valid => {
        if (!valid) {
          this.$message.error('请选择引导人员')
          return
        } else if (_.isNil(this.scene.themeSession.gifts) || !_.isArray(this.scene.themeSession.gifts) || _.isEmpty(this.scene.themeSession.gifts)) {
          // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
          _this.scene.themeSession.guideTime = new Date().format('yyyy-MM-dd HH:mm:ss')
          _this.scene.themeSession.guidePerson = _this.elFormGuideModel.userIdArr.join(',')
          _this.scene.themeSession.guideName = _this.elFormGuideModel.userIdArr
            .map((ele_userId, idx, arr) => {
              let foundEle = _this.allStaff.find((ele, idx, arr) => {
                return ele.userId === ele_userId
              })
              if (!_.isUndefined(foundEle)) {
                return foundEle.username
              } else {
                return 'unnamed'
              }
            })
            .join(',')

          saveOrUpdateThemeSession(_this.scene.themeSession).then(response => {
            // sync
            _.assign(this.sceneSrc.themeSession, response.data.data)

            this.$message.success('引导人员信息变更成功')
            this.dialogVisible = false
          })
        } else if (_.isArray(this.scene.themeSession.gifts) && !_.isEmpty(this.scene.themeSession.gifts)) {
          // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
          let allValidateRst = true
          _this.$refs['elFormGift'].forEach(element => {
            element.validate(valid => {
              if (!valid) {
                allValidateRst = false
              }
            })
          })

          if (allValidateRst) {
            saveOrUpdateThemeSession(_this.scene.themeSession).then(response => {
              _this.sceneSrc.themeSession.sessionStatus = _this.scene.themeSession.sessionStatus
            })

            _this.scene.themeSession.gifts.forEach((ele_gift, idx, arr) => {
              ele_gift.guidePerson = _this.elFormGuideModel.userIdArr.join(',')

              ele_gift.guideName = _this.elFormGuideModel.userIdArr
                .map((ele_userId, idx, arr) => {
                  let foundEle = _this.allStaff.find((ele_staff, idx, arr) => {
                    return ele_staff.userId === ele_userId
                  })
                  if (!_.isUndefined(foundEle)) {
                    return foundEle.username
                  } else {
                    return 'unnamed'
                  }
                })
                .join(',')

              saveOrUpdateThemeSessionGift(ele_gift).then(response => {
                // sync
                let giftRt = response.data.data
                _this.sceneSrc.themeSession.guideName = giftRt.guideName
                _this.sceneSrc.themeSession.guidePerson = giftRt.guidePerson

                let foundIdx = _this.sceneSrc.themeSession.gifts.findIndex((ele_, idx, arr) => {
                  return ele_.id === giftRt.id
                })
                if (foundIdx !== -1) {
                  // 找到了!
                  _this.sceneSrc.themeSession.gifts[foundIdx] = giftRt
                } else {
                  _this.sceneSrc.themeSession.gifts.push(giftRt)
                }
              })
            })

            this.$message.success('赠品信息变更成功')
            this.dialogVisible = false
          } else {
            this.$message.error('赠品信息不完整或不正确')
            return
          }
        }
      })
    },
    closeDialog() {
      this.scene = null
      // this.allStaff = null
      // this.allEntry = null

      this.dialogVisible = false
    },
    // 增加赠品
    addArr() {
      if (_.isArray(_this.$refs['elFormGift']) && !_.isEmpty(_this.$refs['elFormGift'])) {
        let allValidateRst = true
        _this.$refs['elFormGift'].forEach(element => {
          element.validate(valid => {
            if (!valid) {
              allValidateRst = false
            }
          })
        })

        if (allValidateRst) {
          this.scene.themeSession.gifts.push({
            gift: null,
            // "id": 0,
            // "note": "string",
            num: 1,
            themeSessionid: this.scene.themeSession.id,
          })
        } else {
          this.$message.error('有赠品的参数填写不完整,无法新增')
        }
      } else {
        this.scene.themeSession.gifts.push({
          gift: null,
          // "id": 0,
          // "note": "string",
          num: 1,
          themeSessionid: this.scene.themeSession.id,
        })
      }
    },
    // 删除赠品
    reduceArr(index) {
      let toDelGift = this.scene.themeSession.gifts[index]
      if ((!_.isNil(toDelGift.id) && _.isNumber(toDelGift.id)) || toDelGift.id > 0) {
        function _deleteThemeSessionGiftById(id) {
          deleteThemeSessionGiftById(id).then(response => {
            _this.scene.themeSession.gifts.splice(index, 1)

            // sync
            _this.sceneSrc.themeSession.gifts.splice(index, 1)

            this.$message.success('删除赠品成功')
          })
        }
        _this.$refs['delDialog'].showDialog('删除赠品', '当前赠品已保存在服务器, 是否删除', toDelGift.id, _deleteThemeSessionGiftById)
      } else {
        this.scene.themeSession.gifts.splice(index, 1)
      }
    },
  },
}
</script>

<style lang="less">
.el-form {
  width: 100%;

  .el-form-item {
    width: 100%;

    .el-form-item__content {
      width: 100%;
    }
  }
}

.giftContentList {
  .el-dialog {
    width: 7.38rem;

    .el-dialog__header {
      width: 7.38rem;
      background: url('../../statics/images/other/标题栏BG@2x.png') no-repeat;
      background-size: cover;
      // background-position-x: -0.5rem;
      height: 0.4rem;
      position: relative;
      padding: 0.1rem 0.11rem 0.09rem;

      .el-dialog__title {
        width: 0.72rem;
        height: 0.18rem;
        white-space: nowrap;
        font-size: 0.18rem;
        position: absolute;
        left: 0rem;
        padding-left: 0.41rem;
        color: white;
      }

      .el-dialog__title::before {
        content: '';
        display: block;
        width: 0.23rem;
        height: 0.21rem;
        background: url('../../statics/images/other/yogo@2x.png') center center;
        background-size: cover;
        position: absolute;
        left: 0.11rem;
      }

      .el-dialog__headerbtn {
        width: 0.29rem;
        height: 0.29rem;
        background-color: #fff;
        top: 0.05rem;
        right: -0.47rem;
        border-radius: 0.15rem;

        i::before {
          font-size: 0.2rem;
          position: absolute;
          top: 55%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    // 滚动条的轨道（里面装有Thumb）
    .el-dialog__body::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #fff;
    }

    // 滚动条整体部分
    .el-dialog__body::-webkit-scrollbar {
      width: 6px;
      background-color: #fff;
    }

    // 滚动条里面的小方块
    .el-dialog__body::-webkit-scrollbar-thumb {
      background-color: #d2d2d2;
    }

    .el-dialog__body,
    .el-dialog__footer {
      width: 7.38rem;
      height: auto;
      background-color: #fff;
    }
  }

  &.gift {
    .el-dialog__body {
      overflow-x: hidden;
      overflow-y: scroll;
      height: 6.03rem;
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .giftImg {
        width: 1.8rem;
        height: 1.8rem;
        margin-top: 0.1rem;
        border-radius: 0.9rem;

        .giftPic {
          width: 1.81rem;
          height: 1.81rem;
        }
      }

      .isOrNotgift {
        color: #000;
        font-size: 30px;
        margin: 0 0 10px 0;
      }

      .row-title {
        width: 100%;
        font-size: 20px;
        margin: 5px;
      }

      .giftContent {
        // margin-top: 0.3rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: #00000010;

        width: 100%;

        > ul {
          display: flex;
          align-items: flex-end;

          .giftName,
          .giftNum {
            width: 2.8rem;
            margin-top: 0.3rem;

            > p {
              color: #000;
              font-size: 0.18rem;
              line-height: 0.14rem;
              margin-bottom: 0.19rem;
            }

            > form {
              width: 100%;

              > input {
                width: 100%;
                height: 0.36rem;
                background: rgba(255, 255, 255, 1);
                border: 1px solid rgba(177, 177, 177, 1);
                border-radius: 0.06rem;
                padding: 0.1rem;
                font-size: 0.14rem;
              }
            }
          }

          .giftName {
            margin-right: 0.31rem;
          }

          .delGift {
            width: 0.36rem;
            height: 0.36rem;
            background-color: #e66969;
            border-radius: 0.06rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 0.3rem;

            > img {
              width: 0.16rem;
              height: 0.18rem;
            }
          }
        }

        .item {
          width: 100%;

          .row-content {
            .el-col {
              padding: 0 20px;
            }
          }
        }
      }

      .addGift {
        width: 100%;
        margin-top: 10px;

        p {
          width: 200px;
          height: 0.4rem;
          border: 1px solid rgba(32, 141, 239, 1);
          border-radius: 0.06rem;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.18rem;
          font-weight: 400;
          color: rgba(32, 141, 239, 1);
        }
      }

      .helpMan {
        width: 100%;

        .row-content {
          .el-col {
            padding: 0 20px;
          }
        }

        .el-form {
          width: 100%;

          .el-form-item {
            width: 100%;

            .el-select {
              width: 50%;
            }
          }
        }

        > p:nth-of-type(1) {
          font-size: 0.18rem;
          font-weight: 400;
          color: rgba(0, 0, 0, 1);
        }

        > form {
          border: solid 11px red;
          width: 2.8rem;
          margin-top: 0.19rem;

          > input {
            width: 100%;
            height: 0.36rem;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(177, 177, 177, 1);
            border-radius: 0.06rem;
            padding: 0.1rem;
            font-size: 0.14rem;
          }
        }

        > p:nth-of-type(2) {
          width: 0.36rem;
          height: 0.36rem;
          background-color: #d2d2d2 !important;
          border: 1px solid rgba(210, 210, 210, 1);
          border-radius: 0px 6px 6px 0px;
          position: absolute;
          left: 2.44rem;
          top: 57%;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;

          > img {
            width: 0.16rem;
            height: 0.1rem;
          }
        }
      }
    }

    .el-dialog__footer {
      padding-bottom: 0.4rem;
      padding-top: 0;
      font-size: 14px;

      .el-button--primary {
        width: 0.8rem;
        height: 0.3rem;
        background: #ea4335;
        border: none;
        border-radius: 0.06rem;
        padding: 0;
      }

      .el-button--default {
        width: 0.8rem;
        height: 0.3rem;
        border-radius: 0.06rem;
        padding: 0;
      }
    }
  }
}
</style>
