<template>
 <div class="login">
  &nbsp;
  <div class="lbox flex">
   <div class="left-box">
    <h3>YOGO优果</h3>
    <h2>实景娱乐管理平台</h2>
   </div>
   <div class="right-box">
    <el-form class="form" ref="loginForm" :model="loginForm">
     <div class="username">
      <el-input prefix-icon="el-icon-user" v-model="loginForm.username" placeholder="用户名" @keyup.enter.native="handleLogin"></el-input>
      <!-- <i class="isok"></i> -->
     </div>
     <div class="password">
      <el-input prefix-icon="el-icon-lock" v-model="loginForm.password" show-password placeholder="请输入密码" @keyup.enter.native="handleLogin"></el-input>
      <!-- <i class="iserror"></i> -->
     </div>
     <!-- <div   class="yanbox" >
      <el-input
       prefix-icon="el-icon-postcard"
       v-model="loginForm.code"
       class="yan"
       @keyup.enter.native="handleLogin"
      ></el-input>
      <img class="yanimg" :src="src" alt @click="refreshCaptcha" />
     </div> -->

     <el-button type="primary" round @click="handleLogin">登录</el-button>
    </el-form>
   </div>
  </div>

  <div class="dk-container remark">
   <p>主办单位：森果信息科技（武汉）有限公司</p>
   <p>
    备案/许可证编号：
    <el-link href="http://www.beian.miit.gov.cn" target="_blank" type="primary">
     鄂ICP备18022432号
    </el-link>
   </p>
  </div>
 </div>
</template>

<script>
/*global process*/

export default {
 data() {
  return {
   loginForm: {
    username: '',
    password: '',
    code: '',
    token: '',
    t: '',
   },
   src: '',
   otherQuery: {},
   loading: false,
  }
 },
 watch: {
  $route: {
   handler: function(route) {
    this.redirect = route.query && route.query.redirect
   },
   immediate: true,
  },
 },
 created() {
  this.refreshCaptcha()
 },
 methods: {
  refreshCaptcha: function() {
   this.loginForm.code = ''
   this.loginForm.t = new Date().getTime()
   // this.loginForm.src = process.env.BASE_API + '/captcha.jpg?t=' + this.loginForm.t
   this.src = process.env.VUE_APP_BASE_API + `/api/getGifCode?t=` + new Date()
  },

  handleLogin() {
   this.$refs.loginForm.validate(valid => {
    if (valid) {
     this.loading = true
     this.$store
      .dispatch('user_detail/login', this.loginForm)
      .then(() => {
       this.$router.push({
        path: this.redirect || '/',
       })
       this.loading = false
      })
      .catch(() => {
       this.loading = false
      })
    } else {
     return false
    }
   })
  },

  getOtherQuery(query) {
   return Object.keys(query).reduce((acc, cur) => {
    if (cur !== 'redirect') {
     acc[cur] = query[cur]
    }
    return acc
   }, {})
  },
 },
}
</script>

<style scoped>
@import '../statics/css/login.css';
</style>

<style lang="scss" scoped>
.login {
 position: relative;

 .lbox {
 }

 .remark {
  position: absolute;
  bottom: 0px;

  @include setWidthHeight((100%), (100px));
  @include setFlexAlign((column), (nowrap), (center), (center));
  color: rgba(220, 220, 220, 0.61);
 }
}
</style>
