var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"eltables",attrs:{"id":"yogo-shopManagement"}},[_c('div',{staticClass:"toolbar-container"},[_c('span',{staticClass:"yogo-title toolbar-title"},[_vm._v("门店管理")]),_c('el-button',{staticClass:"myicon iconadd",attrs:{"type":"primary"},on:{"click":_vm.handleAdd}},[_c('i'),_vm._v("新增门店 ")]),_c('el-button',{staticClass:"myicon iconadd",attrs:{"type":"primary"}},[_c('i'),_vm._v("导出Excel ")])],1),_c('el-table',{staticClass:"data-table",attrs:{"data":_vm.list,"stripe":""}},[_c('el-table-column',{attrs:{"prop":"name","label":"门店名称","align":"center"}}),_c('el-table-column',{attrs:{"prop":"company.name","label":"所属公司","align":"center"}}),_c('el-table-column',{attrs:{"prop":"manager","label":"负责人","align":"center"}}),_c('el-table-column',{attrs:{"prop":"contacts","label":"联系方式1","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.contacts.length > 0 ? row.contacts[0] : ''))]}}])}),_c('el-table-column',{attrs:{"prop":"contacts","label":"联系方式2","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.contacts.length > 1 ? row.contacts[1] : ''))]}}])}),_c('el-table-column',{attrs:{"prop":"begin_at","label":"开通日期","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.formatTime(row.begin_at, '{y}-{m}-{d}')))]}}])}),_c('el-table-column',{attrs:{"prop":"end_at","label":"失效日期","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.formatTime(row.end_at, '{y}-{m}-{d}')))]}}])}),_c('el-table-column',{attrs:{"prop":"actions","label":"操作","width":"120","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{staticClass:"el-icon-edit",attrs:{"type":"primary","size":"small","circle":""},on:{"click":function($event){return _vm.handleEdit(row)}}}),_c('el-button',{staticClass:"el-icon-delete",attrs:{"type":"danger","size":"small","circle":""},on:{"click":function($event){return _vm.handleDelete(row)}}})]}}])})],1),_c('pagination',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],attrs:{"total":_vm.listSize,"page":_vm.listQuery.page,"limit":_vm.listQuery.limit},on:{"update:page":function($event){return _vm.$set(_vm.listQuery, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.listQuery, "limit", $event)},"pagination":_vm.getShopList}}),_c('delDialog',{ref:"DelDialogForm",attrs:{"confirm":_vm.doDelete}}),_c('editDialog',{ref:"EditDialogForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }