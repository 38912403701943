<template>
 <div class="dialogContent">
  <el-dialog :close-on-click-modal="false" :title="tit" :visible.sync="dialogVisible" @close="closeDialog" class="dialog-wrap" width="6.85rem">
   <el-form size="small">
    <el-row :gutter="14">
     <el-col :span="12">
      <el-form-item label="用户名" required> <el-input placeholder="请输入姓名" type="text" v-model="currObj.username" /> </el-form-item>
     </el-col>
     <el-col :span="12">
      <el-form-item label="密码">
       <el-input placeholder="请输入密码" type="text" v-model="currObj.password" show-password />
      </el-form-item>
     </el-col>
    </el-row>
    <el-row :gutter="14">
     <el-col :span="12">
      <el-form-item label="性别" required>
       <el-radio-group style="width: 100%" v-model="currObj.ssex">
        <el-radio label="1">男</el-radio>
        <el-radio label="0">女</el-radio>
       </el-radio-group>
      </el-form-item>
     </el-col>
    </el-row>
    <el-row :gutter="14">
     <el-col :span="12">
      <el-form-item label="年龄" required> <el-input placeholder="请输入年龄" type="text" v-model="currObj.age"/></el-form-item>
     </el-col>
     <el-col :span="12">
      <el-form-item label="电话" required> <el-input placeholder="请输入电话" type="text" v-model="currObj.mobile" /> </el-form-item>
     </el-col>
     <el-col :span="12">
      <el-form-item label="邮箱" required> <el-input placeholder="请输入邮箱" type="text" v-model="currObj.email"/></el-form-item>
     </el-col>
     <el-col :span="12">
      <el-form-item label="QQ" required> <el-input placeholder="请输入QQ" type="text" v-model="currObj.qq"/></el-form-item>
     </el-col>
    </el-row>
    <div class="diaWrapper">
     <div class="foldWrap">
      <el-form-item label="新建类型">
       <el-radio-group v-model="currType">
        <el-radio label="com" :disabled="!isAdmin">公司管理员</el-radio>
        <el-radio label="nor">一般员工</el-radio>
       </el-radio-group>
      </el-form-item>
      <el-row :gutter="14" v-show="currType === 'com'">
       <el-col :span="12">
        <!-- todo 919 -->
        <el-form-item label="公司" required>
         <el-select style="width: 100%" v-model="currObj.deptId" value-key="id" placeholder="请选择">
          <el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.id"></el-option>
         </el-select>
        </el-form-item>
       </el-col>
      </el-row>
      <el-row :gutter="14" v-show="currType === 'nor'">
       <el-col :span="12">
        <el-form-item label="门店" required>
         <el-select style="width: 100%" v-model="currObj.storeId" multiple value-key="id" placeholder="请选择">
          <el-option v-for="item in shops" :key="item.id" :label="item.name" :value="item.id"></el-option>
         </el-select>
        </el-form-item>
       </el-col>
       <el-col :span="12">
        <el-form-item label="角色" required>
         <el-select placeholder="请选择角色" style="width: 100%" multiple v-model="currObj.roleId">
          <template v-for="item in roles">
           <template v-if="item.roleId !== 1">
            <el-option :key="item.roleId" :label="item.roleName" :value="item.roleId"></el-option>
           </template>
          </template>
         </el-select>
        </el-form-item>
       </el-col>
      </el-row>
     </div>
    </div>
   </el-form>
   <div class="row rowbuttons">
    <el-button @click="handleConfirm" type="primary">确 定</el-button>
    <el-button @click="closeDialog">取 消</el-button>
   </div>
   <span class="dialog-footer" slot="footer"></span>
  </el-dialog>
 </div>
</template>

<script>
import { getRoleList } from '@/api/roles'
import { getShops } from '@/api/shop'
import { getCompanies } from '@/api/company'

export default {
 data() {
  return {
   tit: '',
   dialogVisible: false,
   currObj: {},
   callback: null,
   roles: [],
   shops: [],
   companyList: [],
   currType: 'com',
   isAdmin: false,
  }
 },
 created() {
  this.getRoleList()
  this.getShopList()
  this.getCompanyList()
  this.roles = this.$store.getters.roles
  if (this.roles.indexOf('管理员') != -1) this.isAdmin = true
  else this.isAdmin = false
 },
 methods: {
  // changeType() {
  //  if (this.currType == 'com') {
  //   this.currType == 'nor'
  //  } else {
  //   this.currType = 'com'
  //  }
  // },

  getCompanyList() {
   this.listLoading = true
   getCompanies().then(response => {
    this.companyList = response.data.rows
    this.listSize = response.data.total
   })
   this.listLoading = false
  },
  getShopList() {
   this.listLoading = true
   getShops().then(response => {
    this.shops = response.data.data
   })
   // this.list = pageData;
   // this.listSize = this.list.length;
   this.listLoading = false
  },
  getRoleList() {
   getRoleList().then(response => {
    this.roles = response.data.rows
   })
  },
  closeDialog() {
   this.dialogVisible = false
   this.callback = null
  },
  cancelDialog() {
   this.dialogVisible = false
   this.callback = null
  },
  showDialog(title = '新增人员', data, callback) {
   console.log('[dmgl/staffManagementEdit.vue FUNCTION] [157:32:25] ▉ data', data);
   this.tit = title
   this.dialogVisible = true
   if (data) {
    this.currObj = data
   } else {
    this.currObj = {}
   }
   this.callback = callback
  },
  handleConfirm() {
   let checkArray = ['username', 'ssex', 'age', 'mobile', 'email', 'qq']
   if (this.currType === 'com') {
    checkArray.push('deptId')
   } else {
    checkArray.push('storeId')
    checkArray.push('roleId')
   }
   if (!this.$paramsValidate(this.currObj, checkArray)) return
   else if (this.callback) {
    this.callback(this.currObj)
    this.closeDialog()
   }
  },
 },
}
</script>
<style lang="less" scoped>
@import url('../../statics/css/editDialog.css');
</style>
