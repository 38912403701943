<template>
 <div id="personalPanel">
  <div class="title">
   个人面板
  </div>
  <!-- <el-button type="primary" class="btncz" @click="openDialogFn"><i class="el-icon-sold-out"></i>充 值</el-button> -->
  <div class="container">
   <div class="userPortrait">
    <img src="../../statics/images/xtgl/userPortrait.png" alt="" />
    <button type="button" class="uploadBtn"></button>
   </div>
   <ul class="userInfo">
    <li>
     <div class="infoTitle">
      用户名
     </div>
     <p class="infoDetail">{{ user.username }}</p>
    </li>
    <li>
     <div class="infoTitle">
      手机
     </div>
     <p class="infoDetail">{{ user.mobile }}</p>
     <el-button type="primary" @click="handleUpdatePhone(user)">
      <img src="../../statics/images/xtgl/phone.png" alt="" />
      绑定手机
     </el-button>
    </li>
    <li>
     <div class="infoTitle">
      邮箱
     </div>
     <p class="infoDetail">{{ user.email }}</p>
     <el-button type="primary" @click="handleUpdateEmail(user)">
      <img class="emailLogo" src="../../statics/images/xtgl/email.png" alt="" />
      修改邮箱
     </el-button>
    </li>
    <li>
     <div class="infoTitle">
      账号密码
     </div>
     <p class="infoDetail">********</p>
     <el-button type="primary" @click="handleUpdatePassword(user)">
      <img class="keyboardLogo" src="../../statics/images/xtgl/keyboard.png" alt="" />
      修改密码
     </el-button>
    </li>
   </ul>
  </div>
  <modifyphone ref="modifyPhone"></modifyphone>
  <modifyemail ref="modifyEmail"></modifyemail>
  <modifypwd ref="modifyPwd"></modifypwd>
 </div>
</template>
<script>
import { get_user_detail, updateEmail, updatePhone, updatePassword } from '@/api/user'
import store from '@/store'

import modifyphone from '@/views/xtgl/modifyPhone.vue'
import modifyemail from '@/views/xtgl/modifyEmail.vue'
import modifypwd from '@/views/xtgl/modifyPwd.vue'
export default {
 components: {
  modifyphone,
  modifyemail,
  modifypwd
 },
 data() {
  return {
   memberVisible: false,
   user: null
  }
 },
 beforeCreate() {
  this.user = store.state.user_detail.user
 },
 created() {
  this.user = store.state.user_detail.user
  this.getUserDetail()
 },
 methods: {
  getUserDetail() {
   get_user_detail(this.user.userId).then(response => {
    console.log(response)
    this.user = response.data.data
    store.state.user_detail.user = this.user
   })
  },

  doUpdateEmail(data) {
   if (this.judgeEmail(data.email)) {
    updateEmail(this.user.userId, data).then(response => {
     console.log(response)
     this.getUserDetail()
    })
   } else {
    alert('电子邮件格式错误')
    this.getUserDetail()
   }
  },

  doUpdatePhone(data) {
   if (this.judgePhoneNo(data.mobile)) {
    updatePhone(this.user.userId, data).then(response => {
     console.log(response)
     this.getUserDetail()
    })
   } else {
    alert('手机号码格式错误')
    this.getUserDetail()
   }
  },
  doUpdatePassword(data, b_reset) {
   if (b_reset) {
    data.password = '123456'
   }

   if (data.password != null && data.password != '') {
    updatePassword(this.user.userId, data).then(response => {
     console.log(response)
     this.$store
      .dispatch('user_detail/logout')
      .then(() => {
       this.$router.push(`/login`)
       this.loading = false
      })
      .catch(() => {
       this.loading = false
      })
    })
   }

   this.getUserDetail()
  },

  handleUpdatePhone(data) {
   this.$refs['modifyPhone'].isShowDia(true, data, this.doUpdatePhone)
  },

  handleUpdateEmail(data) {
   this.$refs['modifyEmail'].isShowDia(true, data, this.doUpdateEmail)
  },

  handleUpdatePassword(data) {
   this.$refs['modifyPwd'].isShowDia(true, data, this.doUpdatePassword)
  },

  //手机号码格式判断
  judgePhoneNo(phoneNo) {
   var reg = /^1[3-9][0-9]\d{8}$/
   return reg.test(phoneNo)
  },
  //电子邮箱格式判断
  judgeEmail(email) {
   var myreg = /^([a-zA-Z0-9]+[_|\_|\.|\-]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[-|_|\_|\.|\-]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/
   return myreg.test(email)
  }

  // judgeInsured(data) {
  //  if (data.password == '') {
  //   alert('密码不能为空')
  //   return true
  //  }
  //  if (data.mobile == '') {
  //   alert('手机号码不能为空')
  //   return true
  //  } else if (!this.judgePhoneNo(data.mobile)) {
  //   alert('手机号码格式错误')
  //   return true
  //  }
  //  if (data.email != '' && !this.judgeEmail(data.email)) {
  //   alert('电子邮箱格式错误')
  //   return true
  //  }
  // }
 }
}
</script>
<style scoped>
@import '../../statics/css/xtgl/personalPanel.css';
</style>
