import { render, staticRenderFns } from "./topicInfo.vue?vue&type=template&id=189bc946&scoped=true&"
import script from "./topicInfo.vue?vue&type=script&lang=js&"
export * from "./topicInfo.vue?vue&type=script&lang=js&"
import style0 from "./topicInfo.vue?vue&type=style&index=0&id=189bc946&lang=less&scoped=true&"
import style1 from "./topicInfo.vue?vue&type=style&index=1&lang=less&"
import style2 from "./topicInfo.vue?vue&type=style&index=2&id=189bc946&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "189bc946",
  null
  
)

export default component.exports