<template>
 <div id="themeing">
  <!-- 进行中弹窗 -->

  <template v-if="!_.isNil(scene) && !_.isNil(scene._percentage) && !_.isNil(theme)">
   <el-dialog :close-on-click-modal="false" :visible.sync="ingFlag" center class="contentList doIng" title="结束密室主题">
    <div class="doIngAround">
     <el-progress :percentage="!_.isNil(scene) && !_.isNil(scene._percentage) ? scene._percentage : 0" :show-text="false" type="circle"></el-progress>

     <div class="outAround">
      <div class="innerAround">
       <!-- <p>剩余时间</p>
              <p>00:12:11</p>-->
       <p>{{ calc_leftTime(scene) }}</p>
       <p>{{ theme.themeName }}</p>
       <!-- <p>当前耗时</p> -->
       <!-- <p>00:57:21</p> -->
       <p>{{ calc_usingTime(scene) }}</p>
      </div>
     </div>
    </div>

    <span class="dialog-footer" slot="footer">
     <el-button @click="overIngFlag = true" class="confime" type="primary">
      <!-- <div class="startImg">
       <img :src="combineImgUrl" alt class="startPic" />
            </div>-->
      <span>结 束</span>
     </el-button>
    </span>
   </el-dialog>

   <!-- 进行中结束弹窗 -->
   <el-dialog :close-on-click-modal="false" :visible.sync="overIngFlag" center class="contentList start" title="结束密室主题">
    <div class="startImg">
     <el-image :src="combineImgUrl" alt class="startPic" fit="cover" />
    </div>
    <div class="isOrNotstart">是否结束当前密室主题</div>
    <span class="dialog-footer" slot="footer">
     <!-- <el-button type="primary" @click="overIngFlag = false,ingFlag=false">确 定</el-button> -->
     <el-button @click="onClick_confirm(true)" type="success" size="mini">确定(已通关)</el-button>
     <el-button @click="onClick_confirm(false)" type="warning" size="mini">确定(未通关)</el-button>
     <el-button @click="overIngFlag = false" type="info" size="mini">取消</el-button>
    </span>
   </el-dialog>
  </template>
 </div>
</template>

<script>
import { getThemeSessionById, getThemeSessionList, saveOrUpdateThemeSession } from '@/api/theme/themeSession'

export default {
 data() {
  return {
   themePicUrl: '',

   theme: null,
   scene: null,
   sceneSrc: null,

   dialogVisible: false,
   // 进行中弹窗
   ingFlag: false,
   // 进行中结束弹窗
   overIngFlag: false,
  }
 },
 computed: {
  combineImgUrl() {
   if (this.themePicUrl !== '') {
    let url = '' + process.env.VUE_APP_BASE_API + '/' + this.themePicUrl
    return url
   } else {
    return ''
   }
  },
 },
 methods: {
  calc_leftTime(scene) {
   // return percentage === 100 ? '满' : `${percentage}%`
   // return '进行中 ' + percentage
   // return percentage === 100 ? '已结束' : `${percentage}%`
   // return ''

   if (_.isNil(scene.themeSession) || _.isNil(scene.themeSession.openTime) || !_.isString(scene.themeSession.openTime)) {
    return '未开始'
   }

   let strArray_sceneStartTime = scene.startTime.split(':')
   let num_sceneStartTime = parseInt(strArray_sceneStartTime[0]) * 60 * 60 + parseInt(strArray_sceneStartTime[1]) * 60
   let strArray_sceneEndTime = scene.endTime.split(':')
   let num_sceneEndTime = parseInt(strArray_sceneEndTime[0]) * 60 * 60 + parseInt(strArray_sceneEndTime[1]) * 60
   let duration_sec = num_sceneEndTime - num_sceneStartTime
   let duration_min = (num_sceneEndTime - num_sceneStartTime) / 60

   let openTime_sec = new Date(scene.themeSession.openTime).getTime() / 1000
   let usingTime_sec = Math.ceil(new Date().getTime() / 1000 - openTime_sec)
   let usingTime_min = Math.ceil(new Date().getTime() / (1000 * 60) - openTime_sec / 60)
   let percentage = (usingTime_sec / duration_sec) * 100

   // return '已开场' + usingTime_sec + '秒'

   let leftTime_min = duration_min - usingTime_min
   if (leftTime_min < 0) {
    return '已超时' + -leftTime_min + '分钟'
   } else {
    return '剩余' + leftTime_min + '分钟'
   }
  },
  calc_usingTime(scene) {
   // return percentage === 100 ? '满' : `${percentage}%`
   // return '进行中 ' + percentage
   // return percentage === 100 ? '已结束' : `${percentage}%`
   // return ''

   if (_.isNil(scene.themeSession) || _.isNil(scene.themeSession.openTime) || !_.isString(scene.themeSession.openTime)) {
    return '未开始'
   }

   let strArray_sceneStartTime = scene.startTime.split(':')
   let num_sceneStartTime = parseInt(strArray_sceneStartTime[0]) * 60 * 60 + parseInt(strArray_sceneStartTime[1]) * 60
   let strArray_sceneEndTime = scene.endTime.split(':')
   let num_sceneEndTime = parseInt(strArray_sceneEndTime[0]) * 60 * 60 + parseInt(strArray_sceneEndTime[1]) * 60
   let duration_sec = num_sceneEndTime - num_sceneStartTime

   let openTime_sec = new Date(scene.themeSession.openTime).getTime() / 1000
   let usingTime_sec = Math.ceil(new Date().getTime() / 1000 - openTime_sec)
   let usingTime_min = Math.ceil(new Date().getTime() / (1000 * 60) - openTime_sec / 60)
   let percentage = (usingTime_sec / duration_sec) * 100

   // return '已开场' + usingTime_sec + '秒'
   return '已开场' + usingTime_min + '分钟'
  },

  onClick_confirm(isPassed) {
   this.overIngFlag = false
   this.ingFlag = false
   // 当前场次的运行状态
   // 0.未开场
   // 1.运行中
   // 2.已结束未通关
   // 3.已结束已通过

   if (isPassed) {
    this.scene.themeSession.sessionStatus = 3
   } else {
    this.scene.themeSession.sessionStatus = 2
   }
   this.scene.themeSession.closeTime = new Date().format('yyyy-MM-dd HH:mm:ss')

   saveOrUpdateThemeSession(this.scene.themeSession).then(response => {
    // sync
    _.assign(this.sceneSrc.themeSession, response.data.data)

    this.$message.success('场次状态变更成功')
   })
  },

  closeDialog() {
   this.ingFlag = false
  },
  cancelDialog() {
   this.ingFlag = false
  },
  showDialog(title = '结束密室主题', scene, theme, date) {
   this.theme = theme
   this.themePicUrl = theme.themePicUrl
   this.sceneSrc = scene
   this.scene = _.cloneDeep(scene)
   this.ingFlag = true
  },
 },
}
</script>

<style lang="less">
#themeing {
 .contentList {
  .el-dialog {
   width: 4.56rem;

   .el-dialog__header {
    width: 4.56rem;
    background: url('../../statics/images/other/标题栏BG@2x.png') no-repeat;
    background-size: cover;
    background-position-x: -0.5rem;
    height: 0.4rem;
    position: relative;
    padding: 0.1rem 0.11rem 0.09rem;

    .el-dialog__title {
     width: 0.72rem;
     height: 0.18rem;
     white-space: nowrap;
     font-size: 0.18rem;
     position: absolute;
     left: 0rem;
     padding-left: 0.41rem;
     color: white;
    }

    .el-dialog__title::before {
     content: '';
     display: block;
     width: 0.23rem;
     height: 0.21rem;
     background: url('../../statics/images/other/yogo@2x.png') center center;
     background-size: cover;
     position: absolute;
     left: 0.11rem;
    }

    .el-dialog__headerbtn {
     width: 0.29rem;
     height: 0.29rem;
     background-color: #fff;
     top: 0.05rem;
     right: -0.47rem;
     border-radius: 0.15rem;

     i::before {
      font-size: 0.2rem;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
     }
    }
   }

   .el-dialog__body,
   .el-dialog__footer {
    width: 4.56rem;
    height: c auto;
    background-color: #fff;

    .el-button {
     // border: solid 1px red;
     // height: 40px;
    }
   }
  }

  &.start {
   .el-dialog__body {
    padding: 0;
    padding-top: 0.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .startImg {
     width: 1.8rem;
     height: 1.8rem;
     margin-top: 0.1rem;
     border-radius: 0.9rem;

     .startPic {
      width: 1.8rem;
      height: 1.8rem;
     }
    }

    .isOrNotstart {
     color: #000;
     font-size: 0.16rem;
     margin-bottom: 0.24rem;
     margin-top: 0.25rem;
    }
   }

   .el-dialog__footer {
    padding-bottom: 0.4rem;
    padding-top: 0;
    font-size: 14px;

    .el-button--primary {
     width: 0.8rem;
     height: 0.3rem;
     background: #ea4335;
     border: none;
     border-radius: 0.06rem;
     padding: 0;
    }

    .el-button--default {
     width: 0.8rem;
     height: 0.3rem;
     border-radius: 0.06rem;
     padding: 0;
    }
   }
  }

  &.doIng {
   .el-dialog__body {
    padding: 0;
    padding-top: 0.19rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .el-progress-circle {
     width: 2.7rem !important;
     height: 2.7rem !important;

     .el-progress-circle__track,
     .el-progress-circle__path {
      stroke-width: 0.02rem;
     }
    }

    .outAround {
     width: 2.2rem;
     height: 2.2rem;
     border: 1px solid #cfe7ff;
     border-radius: 50%;
     position: absolute;
     top: 0.45rem;
     left: 50%;
     transform: translateX(-50%);
    }

    .innerAround {
     width: 2.1rem;
     height: 2.1rem;
     border: 1px solid #cfe7ff;
     border-radius: 50%;
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     display: flex;
     align-items: center;
     flex-direction: column;

     p {
      padding: 0;
     }

     p:nth-of-type(1) {
      height: 0.13rem;
      line-height: 0.13rem;
      font-size: 0.14rem;
      color: #101529;
      margin-top: 0.3rem;
     }

     p:nth-of-type(2) {
      height: 0.21rem;
      line-height: 0.21rem;
      font-size: 0.26rem;
      color: #ff8712;
      font-weight: bold;
      margin-top: 0.08rem;
     }

     p:nth-of-type(3) {
      height: 0.22rem;
      line-height: 0.22rem;
      font-size: 0.22rem;
      color: #208def;
      font-weight: bold;
      margin-top: 0.21rem;
     }

     p:nth-of-type(4) {
      height: 0.12rem;
      line-height: 0.12rem;
      font-size: 0.12rem;
      color: #101529;
      margin-top: 0.29rem;
     }

     p:nth-of-type(5) {
      height: 0.15rem;
      line-height: 0.15rem;
      font-size: 0.18rem;
      color: #b1b1b1;
      margin-top: 0.1rem;
     }
    }
   }

   .el-dialog__footer {
    padding-bottom: 0.4rem;
    padding-top: 0;
    font-size: 14px;

    .el-button--primary {
     width: 0.8rem;
     height: 0.3rem;
     background: #ea4335;
     border: none;
     border-radius: 0.06rem;
     padding: 0;
    }

    .el-button--default {
     width: 0.8rem;
     height: 0.3rem;
     border-radius: 0.06rem;
     padding: 0;
    }
   }
  }
 }
}

.startImg {
 width: 1.8rem;
 height: 1.8rem;
 margin-top: 0.1rem;
 border-radius: 0.9rem;

 .startPic {
  width: 1.8rem;
  height: 1.8rem;
 }
}
</style>
