<template>
 <!-- 删除收入 -->
 <div id="deldialogContent">
  <el-dialog :close-on-click-modal="false" :title="tit" :visible.sync="dialogVisible" center width="3.2rem">
   <p class="tooltitle">{{ content }}</p>
   <div class="delbuttons">
    <el-button @click="closeDialog" class="buttons redbtn">确 定</el-button>
    <el-button @click="cancelDialog" class="buttons">取 消</el-button>
   </div>
  </el-dialog>
 </div>
</template>

<script>
export default {
 props: {
  confirm: {
   required: false,
   type: Function,
   default: null,
  },
  cancel: {
   required: false,
   type: Function,
   default: null,
  },
 },
 data() {
  return {
   tit: '',
   content: '',
   dialogVisible: this.inDialogVisible,
   delTgt: null,
   callback: null,
  }
 },
 methods: {
  closeDialog() {
   if (this.callback) {
    this.callback(this.delTgt)
   }
   this.dialogVisible = false
  },
  cancelDialog() {
   if (this.cancel) {
    this.cancel()
   }
   this.dialogVisible = false
  },
  showDialog(title = '删除', content = '', data = {}, callback) {
   this.delTgt = data
   this.tit = title
   this.content = content
   this.dialogVisible = true
   this.callback = callback
  },
 },
}
</script>

<style lang="less">
/* //删除 */
#deldialogContent {
 .el-dialog__header {
  background: url('../../statics/images/other/标题栏BG@2x.png') no-repeat;
  background-size: 3.2rem 0.4rem;
  width: 3.2rem;
  height: 0.4rem;
  position: relative;
  padding: 0;

  .el-dialog__title {
   // font-size: 1.5em;
   font-size: 0.18rem;
   line-height: 0.4rem;
   float: left;
   margin-left: 0.4rem;
   color: white;
   font-weight: normal;
  }

  .el-dialog__headerbtn {
   width: 0.29rem;
   height: 0.29rem;
   background-color: #fff;
   top: 0.06rem;
   right: -0.48rem;
   border-radius: 50%;
   box-sizing: border-box;

   i::before {
    font-size: 28px;
    // line-height: 25px;
   }
  }

  &::before {
   content: '';
   display: block;
   width: 0.24rem;
   height: 0.21rem;
   background: url('../../statics/images/other/yogo@2x.png') no-repeat center;
   background-size: 0.24rem 0.21rem;
   margin: 0.1rem;
   position: absolute;
  }
 }

 .el-dialog__body {
  padding: 30px;
  width: 100%;
  font-size: 0.16rem;
  overflow: hidden;

  .delbuttons {
   text-align: center;
  }

  .buttons {
   width: 0.8rem;
   height: 0.3rem;
   padding: 0;
  }

  .redbtn {
   background: rgba(234, 67, 53, 1);
   color: #fff;
   border: rgba(234, 67, 53, 1) solid 1px;
  }

  .tooltitle {
   text-align: center;
   padding-bottom: 20px;
  }
 }
}
</style>
