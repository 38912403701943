<template>
 <div id="topicinfoedit" v-loading.fullscreen.lock="listLoading" element-loading-background="rgba(0, 0, 0, 0.8)">
  <el-dialog class="subscribeContentList subscribe" center :lock-scroll="false" :close-on-click-modal="false" :visible.sync="dialogVisible" :title="title" @close="onDialog_close" @closed="onDialog_closed" @opened="onOpened_dialog">
   <el-collapse v-model="elCollapse_activeName">
    <el-collapse-item class="theme-info" name="themeInfo">
     <template slot="title">
      <div class="icon"></div>
      主题信息
     </template>

     <el-container class="content" direction="vertical">
      <el-form ref="elFormBasicInfo" :inline="true" :inline-message="true" :model="elFormThemeInfoModel" :rules="elFormBasicInfoRules" :show-message="true" label-position="top" label-width="80px">
       <el-form-item label="主题名称" prop="themeName">
        <el-input v-model="elFormThemeInfoModel.themeName"></el-input>
       </el-form-item>
       <el-form-item label="门店" prop="storeId">
        <el-select placeholder="请选择" v-model="elFormThemeInfoModel.storeId">
         <el-option :key="shop.id" :label="shop.name" :value="shop.id" v-for="shop in storeList"></el-option>
        </el-select>
       </el-form-item>
       <el-form-item label="难度系数" prop="hardNum">
        <el-rate @change="onChangeRate" v-model="elFormThemeInfoModel.hardNum"></el-rate>
       </el-form-item>
       <el-form-item label="游戏风格" prop="gameStyleId">
        <el-select placeholder="请选择" v-model="elFormThemeInfoModel.gameStyleId">
         <el-option :key="idx" :label="gameStyle.gameStyle" :value="gameStyle.id" v-for="(gameStyle, idx) in gameStyleList"></el-option>
        </el-select>
       </el-form-item>
       <el-form-item label="主题类型" prop="topicTypeId">
        <el-select placeholder="请选择" v-model="elFormThemeInfoModel.topicTypeId">
         <el-option :key="idx" :label="topicType.topicType" :value="topicType.id" v-for="(topicType, idx) in topicTypeList"></el-option>
        </el-select>
       </el-form-item>
       <el-form-item label="开场最低人数" prop="minPeople">
        <el-input placeholder="请输入" :maxlength="3" v-model.number="elFormThemeInfoModel.minPeople"></el-input>
       </el-form-item>
       <el-form-item label="包场人数" prop="lockPeople">
        <el-input placeholder="请输入" :maxlength="3" v-model.number="elFormThemeInfoModel.lockPeople"></el-input>
       </el-form-item>
       <el-form-item label="最多参与人数" prop="maxPeople">
        <el-input placeholder="请输入" :maxlength="3" v-model.number="elFormThemeInfoModel.maxPeople"></el-input>
       </el-form-item>
       <el-form-item label="主题介绍" prop="themeDes">
        <el-input :rows="8" placeholder="请输入内容" type="textarea" v-model="elFormThemeInfoModel.themeDes"></el-input>
       </el-form-item>
       <el-form-item label="主题图片" prop="themePicUrl">
        <el-upload :auto-upload="false" :before-upload="onBeforeUpload" :on-change="onChangeUpload" :show-file-list="false" accept=".jpg, .jpeg, .png, .PNG, .JPG, .JPEG" action class="avatar-uploader" ref="upload">
         <el-image fit="cover" :src="imgSrcFileUrl" class="avatar" v-if="imgSrcFileUrl" />
         <el-image fit="cover" :src="combineImgUrl(elFormThemeInfoModel.themePicUrl)" class="avatar" v-else-if="elFormThemeInfoModel.themePicUrl" />
         <i class="el-icon-plus avatar-uploader-icon" v-else></i>
        </el-upload>
       </el-form-item>
      </el-form>
     </el-container>
    </el-collapse-item>

    <el-collapse-item class="schedules-info" name="sceneInfo">
     <template slot="title">
      <div class="icon"></div>
      场次设置
     </template>
     <!-- ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ -->
     <!-- 新增主题 -->
     <el-container class="content" direction="vertical" v-if="title === '新增主题'">
      <el-form :disabled="false" :inline="true" :inline-message="true" :model="elFormThemeInfoModel" :rules="elFormSchedulesRules" :show-message="true" label-position="top" label-width="80px" ref="elFormSchedules">
       <!-- 生效日期 -->
       <el-form-item label="生效日期" prop="effectiveRange">
        <el-container class="wrapper" direction="horizontal">
         <el-date-picker disabled value-format="yyyy-MM-dd" v-model="elFormThemeInfoModel.effectiveDate" align="right" type="date" placeholder="选择日期" :picker-options="pickerOptions"> </el-date-picker>
         <!-- <p>至</p> -->
         <!-- <el-date-picker disabled  v-model="value2" align="right" type="date" placeholder=""> </el-date-picker> -->
         <!-- <el-date-picker disabled align="right" type="date" placeholder=""></el-date-picker> -->
        </el-container>
       </el-form-item>

       <!-- 营业时段 -->
       <el-form-item label="营业时段" prop="workTime">
        <el-container class="wrapper" direction="horizontal">
         <dk-time-picker
          :clearable="false"
          :picker-options="{
           start: '07:00',
           step: '00:10',
           end: '15:10',
          }"
          placeholder="起始时间"
          v-model="elFormThemeInfoModel.openTime"
          class="dk-time-picker"
         ></dk-time-picker>
         <p>至</p>
         <dk-time-picker
          class="dk-time-picker"
          :disabled="elFormThemeInfoModel.openTime === null"
          :picker-options="{
           start: '22:00',
           step: '00:05',
           end: '05:00',
          }"
          placeholder="结束时间"
          v-model="elFormThemeInfoModel.closeTime"
         ></dk-time-picker>
        </el-container>
       </el-form-item>

       <!-- 主题时长 -->
       <el-form-item label="主题时长" prop="duration" class="duration">
        <el-container class="wrapper" direction="horizontal">
         <el-input placeholder="主题时长(单位:分钟)" :maxlength="3" v-model.number="elFormThemeInfoModel.duration"></el-input>
        </el-container>
       </el-form-item>

       <!-- 间隔时长 -->
       <el-form-item label="间隔时长" class="interval">
        <el-container class="wrapper" direction="horizontal">
         <el-input placeholder="间隔时长(单位:分钟)" :maxlength="3" v-model.number="elFormThemeInfoModel._interval"></el-input>
        </el-container>
       </el-form-item>

       <!-- 场次时段 -->
       <el-form-item label="场次时段" prop="schedules">
        <el-container class="wrapper" direction="vertical" v-show="elFormThemeInfoModel.openTime !== null && elFormThemeInfoModel.closeTime !== null">
         <el-container
          :key="idx"
          :style="{
           zIndex: 1000 - idx,
          }"
          class="time-picker"
          direction="horizontal"
          v-for="(scene, idx) in elFormThemeInfoModel.schedules"
         >
          <dk-time-picker
           class="dk-time-picker"
           :disabled="calc_disabledStartTime(idx, elFormThemeInfoModel.schedules)"
           :picker-options="{
            start: calc_rangeStart(idx, elFormThemeInfoModel.schedules),
            step: '00:05',
            end: calc_rangeEnd(idx, elFormThemeInfoModel.schedules),
           }"
           placeholder="选择时间"
           v-model="scene.startTime"
           @change="onChange_dkTimePicker_startTime($event, scene)"
          ></dk-time-picker>
          <p>至</p>
          <dk-time-picker
           class="dk-time-picker"
           :disabled="calc_disabledEndTime(idx, elFormThemeInfoModel.schedules)"
           :picker-options="{
            start: !_.isEmpty(scene.startTime) ? scene.startTime : elFormThemeInfoModel.openTime,
            step: '00:05',
            end: calc_rangeEnd(idx, elFormThemeInfoModel.schedules),
           }"
           placeholder="选择时间"
           v-model="scene.endTime"
          ></dk-time-picker>

          <el-button @click="onClick_deleteScene(scene, idx)" circle icon="el-icon-delete" type="danger"></el-button>
         </el-container>
         <el-button @click="onClick_addScene" class="add-sence" icon="el-icon-plus" plain style="width:150px" type="primary">添加场次</el-button>
        </el-container>
       </el-form-item>
      </el-form>
     </el-container>
     <!-- ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ -->
     <!-- 修改主题 -->
     <el-tabs v-model="editableTabsValue" type="border-card" v-else-if="title === '修改主题'" @tab-click="onTabClick">
      <el-tab-pane :lazy="false" v-for="(item_scheduleCollection, idx) in elFormThemeInfoModel._sortSchedules" :key="idx" :name="'' + idx" v-if="(!item_scheduleCollection.isOld && !isShow_addScheduleButton) || item_scheduleCollection.isOld">
       <span slot="label"
        ><i class="el-icon-date"></i>
        {{ idx === elFormThemeInfoModel._sortSchedules.length - 1 ? '新的场次计划' : '场次计划' + (idx + 1) }}
       </span>
       <el-container class="content" direction="vertical">
        <el-form :disabled="item_scheduleCollection.isOld" :inline="true" :inline-message="true" :model="elFormThemeInfoModel" :rules="elFormSchedulesRules" :show-message="true" label-position="top" label-width="80px" ref="elFormSchedules">
         <!-- 生效日期范围 -->
         <el-form-item label="生效日期范围" prop="effectiveRange">
          <el-container class="wrapper" direction="horizontal">
           <el-date-picker disabled value-format="yyyy-MM-dd" v-model="item_scheduleCollection.effectiveDate" align="right" type="date" :picker-options="pickerOptions"> </el-date-picker>
           <p>至</p>
           <el-date-picker v-model="item_scheduleCollection.schedules[0].expireDate" align="right" type="date" placeholder="" disabled> </el-date-picker>
          </el-container>
         </el-form-item>

         <!-- 营业时段 -->
         <el-form-item label="营业时段" prop="workTime">
          <el-container class="wrapper" direction="horizontal">
           <dk-time-picker
            :clearable="false"
            :picker-options="{
             start: '07:00',
             step: '00:10',
             end: '15:10',
            }"
            placeholder="起始时间"
            v-model="elFormThemeInfoModel.openTime"
            class="dk-time-picker"
           ></dk-time-picker>
           <p>至</p>
           <dk-time-picker
            class="dk-time-picker"
            :disabled="elFormThemeInfoModel.openTime === null"
            :picker-options="{
             start: '22:00',
             step: '00:05',
             end: '05:00',
            }"
            placeholder="结束时间"
            v-model="elFormThemeInfoModel.closeTime"
           ></dk-time-picker>
          </el-container>
         </el-form-item>

         <!-- 时长 -->
         <el-form-item label="主题时长" prop="duration" class="duration">
          <el-container class="wrapper" direction="horizontal">
           <el-input placeholder="主题时长(单位:分钟)" :maxlength="3" v-model.number="elFormThemeInfoModel.duration"></el-input>
          </el-container>
         </el-form-item>

         <!-- 间隔时长 -->
         <el-form-item label="间隔时长" class="interval">
          <el-container class="wrapper" direction="horizontal">
           <el-input placeholder="间隔时长(单位:分钟)" :maxlength="3" v-model.number="elFormThemeInfoModel._interval"></el-input>
          </el-container>
         </el-form-item>

         <!-- 场次时段 -->
         <el-form-item label="场次时段" prop="schedules">
          <el-container class="wrapper" direction="vertical" v-show="elFormThemeInfoModel.openTime !== null && elFormThemeInfoModel.closeTime !== null">
           <el-container
            :key="idx"
            :style="{
             zIndex: 1000 - idx,
            }"
            class="time-picker"
            direction="horizontal"
            v-for="(scene, idx) in item_scheduleCollection.schedules"
           >
            <dk-time-picker
             class="dk-time-picker"
             :disabled="calc_disabledStartTime(idx, item_scheduleCollection.schedules)"
             :picker-options="{
              start: calc_rangeStart(idx, item_scheduleCollection.schedules),
              step: '00:05',
              end: calc_rangeEnd(idx, item_scheduleCollection.schedules),
             }"
             placeholder="选择时间"
             v-model="scene.startTime"
             @change="onChange_dkTimePicker_startTime($event, scene)"
            ></dk-time-picker>
            <p>至</p>
            <dk-time-picker
             class="dk-time-picker"
             :disabled="calc_disabledEndTime(idx, item_scheduleCollection.schedules)"
             :picker-options="{
              start: !_.isEmpty(scene.startTime) ? scene.startTime : elFormThemeInfoModel.openTime,
              step: '00:05',
              end: calc_rangeEnd(idx, item_scheduleCollection.schedules),
             }"
             placeholder="选择时间"
             v-model="scene.endTime"
            ></dk-time-picker>

            <el-button @click="onClick_deleteScene(scene, idx)" circle icon="el-icon-delete" type="danger"></el-button>
           </el-container>
           <el-button @click="onClick_addScene" class="add-sence" icon="el-icon-plus" plain style="width:150px" type="primary">添加场次</el-button>
          </el-container>
         </el-form-item>
        </el-form>
       </el-container>
      </el-tab-pane>

      <el-tab-pane v-if="isShow_addScheduleButton" name="add_button">
       <div slot="label" :name="'add_button'">+</div>
      </el-tab-pane>
     </el-tabs>
     <!-- ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ -->
    </el-collapse-item>

    <el-collapse-item class="ticket-info" name="ticketInfo">
     <template slot="title">
      <div class="icon"></div>
      票价设置
     </template>

     <el-container class="content" direction="vertical">
      <el-form :inline="true" :model="elFormThemeInfoModel" :rules="elFormPricesRules" :show-message="true" class="demo-form-inline" label-position="top" ref="elFormPrices">
       <el-form-item label="忙时" prop="busyPrice">
        <el-input placeholder="票价" v-model="elFormThemeInfoModel.busyPrice"></el-input>
       </el-form-item>
       <el-form-item label="闲时" prop="idlePrice">
        <el-input placeholder="票价" v-model="elFormThemeInfoModel.idlePrice"></el-input>
       </el-form-item>
      </el-form>
     </el-container>
    </el-collapse-item>
   </el-collapse>
   <span class="dialog-footer" slot="footer">
    <el-button @click="handleConfirm()" type="success">确 定</el-button>
    <el-button @click="dialogVisible = false" type="info">取 消</el-button>
   </span>
  </el-dialog>
 </div>
</template>

<script>
import { getShops, createShop, updateShop, deleteShop } from '@/api/shop'
import { fetchList, create, update, delete_ } from '@/api/game'
import { getStyles, createStyle, updateStyle, deleteStyle } from '@/api/game'
import { getTypes, createType, updateType, deleteType } from '@/api/game'
import { getLatestReservedDay, deleteTheme, getTheme, getThemeList, saveOrUpdateTheme, uploadThemeImage } from '@/api/theme/theme'
import { getPayChannelList, getPayChannel, saveOrUpdatePayChannel, deletePayChannel } from '@/api/paychannel'
import DkTimePicker from '@/components/DkTimePicker'

var _this

function _calc_timeRange(duration, interval, endTime, closeTime) {
 if (!_.isString(endTime) || _.isEmpty(endTime)) {
  return {
   startTime: undefined,
   endTime: undefined,
  }
 }

 let min_endTime = endTime.split(':').reduce(function(accumulator, currentValue, currentIndex, array) {
  return accumulator + parseInt(currentValue) * Math.pow(60, array.length - 1 - currentIndex)
 }, 0)

 let min_closeTime = closeTime.split(':').reduce(function(accumulator, currentValue, currentIndex, array) {
  return accumulator + parseInt(currentValue) * Math.pow(60, array.length - 1 - currentIndex)
 }, 0)

 if (min_endTime + interval > min_closeTime) {
  _this.$message.warning('场次时间不能超过营业时间')
  return {
   startTime: undefined,
   endTime: undefined,
  }
 }

 let str_lastEndTime = endTime
 let min_lastEndTime = parseInt(str_lastEndTime.split(':')[0]) * 60 + parseInt(str_lastEndTime.split(':')[1])

 if (!_.isNumber(duration) && _.isNumber(interval)) {
  let min_toAddStartTime = min_lastEndTime + parseInt(interval)
  let str_toAddStartTime = ('' + parseInt(min_toAddStartTime / 60)).padStart(2, '0') + ':' + ('' + parseInt(min_toAddStartTime % 60)).padStart(2, '0')

  return {
   startTime: str_toAddStartTime,
   endTime: undefined,
  }
 } else if (_.isNumber(duration) && _.isNumber(interval)) {
  let min_toAddStartTime = min_lastEndTime + parseInt(interval)
  let min_toAddEndTime = min_toAddStartTime + parseInt(duration)

  let str_toAddStartTime = ('' + parseInt(min_toAddStartTime / 60)).padStart(2, '0') + ':' + ('' + parseInt(min_toAddStartTime % 60)).padStart(2, '0')
  let str_toAddEndTime = ('' + parseInt(min_toAddEndTime / 60)).padStart(2, '0') + ':' + ('' + parseInt(min_toAddEndTime % 60)).padStart(2, '0')

  if (min_toAddEndTime > min_closeTime) {
   _this.$message.warning('场次时间不能超过营业时间')
   return {
    startTime: str_toAddStartTime,
    endTime: undefined,
   }
  } else {
   return {
    startTime: str_toAddStartTime,
    endTime: str_toAddEndTime,
   }
  }
 } else {
  return {
   startTime: undefined,
   endTime: undefined,
  }
 }
}

export default {
 components: {
  DkTimePicker,
 },

 data() {
  var checkSceneTime = (rule, value, callback) => {
   if (this.elFormThemeInfoModel.schedules.length > 0) {
    let findRst = this.elFormThemeInfoModel.schedules.find(ele => {
     return ele.startTime === null || ele.endTime === null
    })

    if (findRst) {
     return callback(new Error('【场次起止时间】填写不完整!'))
    } else {
     callback()
    }
   } else {
    callback()
   }
  }
  var check_schedules = (rule, value, callback) => {
   if (this.elFormThemeInfoModel.schedules.length > 0) {
    let findRst = this.elFormThemeInfoModel.schedules.find(ele => {
     return _.isEmpty(ele.startTime) || _.isEmpty(ele.endTime)
    })

    if (findRst) {
     return callback(new Error('场次计划表填写不完整!'))
    } else {
     callback()
    }
   } else {
    callback()
   }
   // } else if (_this.title === '修改主题') {
   // }
  }
  var checkEffectiveRange = (rule, value, callback) => {
   if (!_.isEmpty(this.elFormThemeInfoModel._sortSchedules)) {
    let isSome = this.elFormThemeInfoModel._sortSchedules.some((ele_, idx, arr) => {
     if (_.isEmpty(ele_.effectiveDate)) {
      return true
     }
    })
    if (isSome) {
     return callback(new Error('参数不完整!'))
    } else {
     callback()
    }
   } else {
    if (_.isEmpty(this.elFormThemeInfoModel.effectiveDate)) {
     return callback(new Error('参数不完整!'))
    } else {
     callback()
    }
   }
  }
  var checkWorkTime = (rule, value, callback) => {
   if (this.elFormThemeInfoModel.openTime === null || this.elFormThemeInfoModel.closeTime === null) {
    return callback(new Error('参数不完整!'))
   } else {
    callback()
   }
  }
  var check_hardNum = (rule, value, callback) => {
   if (_.isNil(this.elFormThemeInfoModel.hardNum) || this.elFormThemeInfoModel.hardNum === 0) {
    return callback(new Error('最少选一颗星'))
   } else {
    callback()
   }
  }
  var check_common = (rule, value, callback) => {
   if (_.isArray(value) || _.isString(value) || _.isObject(value)) {
    // 检查 value 是否为一个空对象，集合，映射或者set
    if (_.isEmpty(value)) {
     callback(new Error('不能为空'))
    } else {
     callback()
    }
   } else if (!_.isNil(value)) {
    callback()
   } else {
    callback(new Error('请输入'))
   }
  }
  var checkNum = (rule, value, callback) => {
   if (value === null || value === undefined) {
    return callback(new Error('请输入数字'))
   }
   if (!Number.isInteger(value)) {
    callback(new Error('请输入数字值'))
   } else {
    if (value < 1) {
     callback(new Error('必须大于0'))
    } else {
     callback()
    }
   }
  }
  var check_themePicUrl = (rule, value, callback) => {
   if (!_.isNil(this.imgSrcFileRaw) || !_.isNil(this.elFormThemeInfoModel.themePicUrl)) {
    callback()
   } else {
    return callback(new Error('请选择图片'))
   }
  }
  var check_busyPrice = (rule, value, callback) => {
   if (_.isNil(value)) {
    return callback(new Error('请输入价格'))
   }

   let price = Number.parseFloat(value)

   if (_.isNaN(price)) {
    callback(new Error('请输入数字值'))
   } else {
    this.elFormThemeInfoModel.busyPrice = price
    callback()
   }
  }
  var check_idlePrice = (rule, value, callback) => {
   if (_.isNil(value)) {
    return callback(new Error('请输入价格'))
   }

   let price = Number.parseFloat(value)

   if (_.isNaN(price)) {
    callback(new Error('请输入数字值'))
   } else {
    this.elFormThemeInfoModel.idlePrice = price
    callback()
   }
  }
  var check_duration = (rule, value, callback) => {
   if (_.isNil(value)) {
    return callback(new Error('请输入主题时长'))
   }

   let duration = Number.parseFloat(value)

   if (_.isNaN(duration)) {
    callback(new Error('请输入数字值'))
   } else {
    this.elFormThemeInfoModel.duration = duration
    callback()
   }
  }
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  return {
   // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
   listLoading: false,

   latestReservedDay: '',

   isShow_addScheduleButton: true,
   editableTabsValue: '0',

   title: '',
   pickerOptions: {
    disabledDate(time) {
     let now = new Date()
     let timeZeroClock = new Date(time.format('yyyy-MM-dd'))
     let todayZeroClock = new Date(now.format('yyyy-MM-dd'))

     if (_this.title === '新增主题') {
      return timeZeroClock < todayZeroClock
     } else if (_this.title === '修改主题') {
      let latestReservedDayZeroClock = new Date(_this.latestReservedDay)
      return timeZeroClock < latestReservedDayZeroClock
     } else {
      return true
     }
    },
    shortcuts: [
     {
      text: '今天',
      onClick(picker) {
       picker.$emit('pick', new Date())
      },
     },
     {
      text: '一周后',
      onClick(picker) {
       const date = new Date()
       date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
       picker.$emit('pick', date)
      },
     },
     {
      text: '一月后',
      onClick(picker) {
       const date = new Date()
       date.setTime(date.getTime() + 3600 * 1000 * 24 * 30)
       picker.$emit('pick', date)
      },
     },
    ],
   },

   // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

   //img在新增主题时不需要传过去， 所有单独拎出来
   imgSrcFileUrl: null, // File
   imgSrcFileRaw: null, // File

   elFormThemeInfoModel: {},

   elFormThemeInfoModel_backup: {
    effectiveDate: new Date().format('yyyy-MM-dd'), // string

    themeName: '', // string
    hardNum: null, // int, 1-5
    gameStyleId: null, // int
    topicTypeId: null, // int
    minPeople: null, // int
    storeId: null, // int
    lockPeople: null, // int
    maxPeople: null, // int
    themeDes: null, // string
    busyPrice: null, // float
    idlePrice: null, // float

    themePicUrl: null, // File

    openTime: '8:00', // string
    closeTime: '23:00', // string

    duration: 60, // int
    _interval: 30, // int

    schedules: [
     {
      startTime: '08:30', // string
      endTime: '9:30', // string
     },
    ],
   },

   elFormBasicInfoRules: {
    themeName: [
     {
      validator: check_common,
      required: true,
      trigger: ['blur', 'change'],
     },
    ],
    hardNum: [
     {
      required: true,
      validator: check_hardNum,
      trigger: ['blur', 'change'],
     },
    ],
    topicTypeId: [
     {
      validator: check_common,
      required: true,
      trigger: ['blur', 'change'],
     },
    ],
    gameStyleId: [
     {
      validator: check_common,
      required: true,
      trigger: ['blur', 'change'],
     },
    ],
    minPeople: [
     {
      required: true,
      validator: checkNum,
      trigger: ['blur', 'change'],
     },
    ],
    lockPeople: [
     {
      required: true,
      validator: checkNum,
      trigger: ['blur', 'change'],
     },
    ],
    maxPeople: [
     {
      required: true,
      validator: checkNum,
      trigger: ['blur', 'change'],
     },
    ],
    storeId: [
     {
      validator: check_common,
      required: true,
      trigger: ['blur', 'change'],
     },
    ],
    themePicUrl: [
     {
      validator: check_themePicUrl,
      required: true,
      trigger: ['blur', 'change'],
     },
    ],
    themeDes: [
     {
      validator: check_common,
      required: true,
      trigger: ['blur', 'change'],
     },
    ],
    duration: [
     {
      required: true,
      validator: check_duration,
      trigger: ['blur'],
     },
    ],
    schedules: [
     {
      required: true,
      validator: check_schedules,
      trigger: ['blur', 'change'],
     },
    ],
   },
   elFormSchedulesRules: {
    effectiveRange: [
     {
      required: true,
      validator: checkEffectiveRange,
      trigger: ['blur', 'change'],
     },
    ],
    workTime: [
     {
      required: true,
      validator: checkWorkTime,
      trigger: ['blur', 'change'],
     },
    ],
    duration: [
     {
      required: true,
      validator: checkNum,
      trigger: ['blur', 'change'],
     },
    ],
    schedules: [
     {
      required: true,
      validator: check_schedules,
      trigger: ['blur', 'change'],
     },
    ],
   },
   elFormPricesRules: {
    busyPrice: [
     {
      required: true,
      validator: check_busyPrice,
      trigger: ['blur'],
     },
    ],
    idlePrice: [
     {
      required: true,
      validator: check_idlePrice,
      trigger: ['blur'],
     },
    ],
   },
   // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

   storeList: [],

   gameStyleList: [],
   topicTypeList: [],
   payChannelList: [],

   gameTypeList: [
    {
     id: 1,
     label: '冒险',
    },
    {
     id: 2,
     label: '逻辑',
    },
    {
     id: 3,
     label: '剧情',
    },
   ],

   gameStyleList: [
    {
     id: 1,
     label: '恐怖',
    },
    {
     id: 2,
     label: '唯美',
    },
    {
     id: 3,
     label: '爱情',
    },
   ],

   //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

   formInline: {
    user: '',
    region: '',
   },

   startTime: '',
   endTime: '',

   temp: null,

   // list
   listQuery: {
    pageNum: 1,
    limit: 10,
    importance: undefined,
    title: undefined,
    type: undefined,
    status: undefined,
    sort: '+id',
   },

   orderArr: [],
   // elCollapse_activeName: ['themeInfo'],
   elCollapse_activeName: ['themeInfo', 'sceneInfo', 'ticketInfo'],

   dialogVisible: false,
  }
 },

 mounted() {
  _this = this

  Promise.all([getShops(), getStyles(), getTypes(), getPayChannelList()]).then(result => {
   _this.storeList = result[0].data.data
   _this.gameStyleList = result[1].data
   _this.topicTypeList = result[2].data
   _this.payChannelList = result[3].data
  })
 },

 computed: {
  calc_disabledStartTime: function() {
   return function(idx, schedules) {
    let findRst = schedules.find((eleScene, eleIdx) => {
     if (eleIdx === idx) {
      return false
     } else {
      return _.isEmpty(eleScene.startTime) || _.isEmpty(eleScene.endTime)
     }
    })

    if (_.isUndefined(findRst)) {
     return false
    } else {
     return true
    }
   }
  },
  calc_disabledEndTime: function() {
   return function(idx, schedules) {
    let findRst = schedules.find((eleScene, eleIdx) => {
     if (eleIdx === idx) {
      if (_.isEmpty(eleScene.startTime)) {
       return true
      } else {
       return false
      }
     } else {
      return _.isEmpty(eleScene.startTime) || _.isEmpty(eleScene.endTime)
     }
    })

    if (_.isUndefined(findRst)) {
     return false
    } else {
     return true
    }
   }
  },
 },

 methods: {
  onClick_addSchedule($event, sortSchedules) {},
  onChange_dkTimePicker_startTime(...args) {
   if (_.isNumber(this.elFormThemeInfoModel.duration)) {
    let str_startTime = args[1].startTime
    let min_startTime = parseInt(str_startTime.split(':')[0]) * 60 + parseInt(str_startTime.split(':')[1])
    let min_endTime = min_startTime + parseInt(this.elFormThemeInfoModel.duration)
    let str_endTime = ('' + parseInt(min_endTime / 60)).padStart(2, '0') + ':' + ('' + parseInt(min_endTime % 60)).padStart(2, '0')
    args[1].endTime = str_endTime
   }
  },
  onChange_datePicker(...args) {
   this.$set(this.elFormThemeInfoModel, 'effectiveDateNew', args[1])
  },
  onTabClick(...args) {
   if (args[0].paneName === 'add_button') {
    this.editableTabsValue = '' + (this.elFormThemeInfoModel._sortSchedules.length - 1)
    this.isShow_addScheduleButton = false
   }
  },
  onChange_elTimeSelect(...args) {},
  combineImgUrl(value) {
   let url = '' + process.env.VUE_APP_BASE_API + '/' + value

   return url
  },
  showDialog(title = '', data, callback) {
   this.title = title

   if (!_.isNil(data)) {
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // edit
    let copy_elFormThemeInfoModel = _.cloneDeep(data)

    const set_effectiveDate = new Set()
    copy_elFormThemeInfoModel.schedules = copy_elFormThemeInfoModel.schedules.filter((ele_, idx, arr) => {
     if (!_.isEmpty(ele_.expireDate)) {
      let dateZeroClock_effectiveDate = new Date(new Date(ele_.effectiveDate).format('yyyy-MM-dd'))
      let dateZeroClock_expireDate = new Date(new Date(ele_.expireDate).format('yyyy-MM-dd'))
      let dateZeroClock_today = new Date(new Date().format('yyyy-MM-dd'))

      return dateZeroClock_expireDate > dateZeroClock_effectiveDate && dateZeroClock_expireDate > dateZeroClock_today
     } else {
      return true
     }
    })

    copy_elFormThemeInfoModel.schedules.forEach((ele_, idx, arr) => {
     if (!_.isEmpty(ele_.effectiveDate)) {
      set_effectiveDate.add(ele_.effectiveDate)
     }
    })

    if (!_.isEmpty(set_effectiveDate)) {
     copy_elFormThemeInfoModel._sortSchedules = []
     set_effectiveDate.forEach((ele_effectiveDate, idx, set) => {
      let obj = {
       isOld: true,
       effectiveDate: ele_effectiveDate,
       schedules: [],
      }
      obj.schedules = copy_elFormThemeInfoModel.schedules.filter((ele_schedule, idx, arr) => {
       return ele_schedule.effectiveDate === ele_effectiveDate
      })

      copy_elFormThemeInfoModel._sortSchedules.push(obj)
     })

     copy_elFormThemeInfoModel._sortSchedules.sort((first, second) => {
      return new Date(first.effectiveDate) - new Date(second.effectiveDate)
     })

     let date = new Date(copy_elFormThemeInfoModel._latestReservedDay)
     date.setDate(date.getDate() + 1)
     copy_elFormThemeInfoModel._sortSchedules.push({
      isOld: false,
      effectiveDate: date.format('yyyy-MM-dd'),
      schedules: [
       {
        startTime: '08:00',
        endTime: '09:00',
       },
      ],
     })
    } else {
     alert('Wrong case!')
    }

    copy_elFormThemeInfoModel._interval = 30
    this.elFormThemeInfoModel = copy_elFormThemeInfoModel
    this.editableTabsValue = '0'
   } else {
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // create
    this.elFormThemeInfoModel = _.cloneDeep(this.elFormThemeInfoModel_backup)
   }

   this.imgSrcFileUrl = null
   this.imgSrcFileRaw = null
   this.callback = callback
   this.dialogVisible = true
  },
  handleConfirm() {
   if (this.title === '修改主题' && !this.isShow_addScheduleButton) {
    this.elFormThemeInfoModel.effectiveDate = this.elFormThemeInfoModel._sortSchedules[this.elFormThemeInfoModel._sortSchedules.length - 1].effectiveDate
    this.elFormThemeInfoModel.schedules = this.elFormThemeInfoModel._sortSchedules[this.elFormThemeInfoModel._sortSchedules.length - 1].schedules
   } else if (this.title === '修改主题' && this.isShow_addScheduleButton) {
    !_.isUndefined(this.elFormThemeInfoModel.effectiveDate) ? delete this.elFormThemeInfoModel.effectiveDate : ''
    !_.isUndefined(this.elFormThemeInfoModel.schedules) ? delete this.elFormThemeInfoModel.schedules : ''
   }

   let allValidateRst = true
   this.$refs['elFormBasicInfo'].validate(valid => {
    if (valid) {
    } else {
     allValidateRst = false
    }
   })

   if (_.isArray(this.$refs['elFormSchedules']) && !_.isEmpty(this.$refs['elFormSchedules']) && !this.isShow_addScheduleButton) {
    this.$refs['elFormSchedules'][this.$refs['elFormSchedules'].length - 1].validate(valid => {
     if (valid) {
     } else {
      allValidateRst = false
     }
    })
   } else if (!_.isArray(this.$refs['elFormSchedules'])) {
    this.$refs['elFormSchedules'].validate(valid => {
     if (valid) {
     } else {
      allValidateRst = false
     }
    })
   }

   this.$refs['elFormPrices'].validate(valid => {
    if (valid) {
    } else {
     allValidateRst = false
    }
   })

   if (allValidateRst) {
    // let param = _.cloneDeep(this.elFormThemeInfoModel)

    for (const key in this.elFormThemeInfoModel) {
     key.startsWith('_') ? delete this.elFormThemeInfoModel[key] : ''
    }

    this.callback(this.elFormThemeInfoModel, this.imgSrcFileRaw)
    this.dialogVisible = false
   } else {
    this.$message.error('参数填写不完整或不正确!')
   }
  },
  onChangeRate(rate) {
   this.$refs['elFormBasicInfo'].validate()
  },
  resetForm(formName) {
   this.$refs[formName].resetFields()
  },
  onChangeStartTime(startTime, scene) {
   // if sceneTime.startTime >  sceneTime.endTime
   // clear endTime

   if (!_.isNil(scene) && !_.isNil(scene.endTime) && !_.isNil(startTime)) {
    let startTimeSplitArr = startTime.split(':')
    let startTimeMin = parseInt(startTimeSplitArr[0]) * 60 + parseInt(startTimeSplitArr[1])

    let endTimeSplitArr = scene.endTime.split(':')
    let endTimeMin = parseInt(endTimeSplitArr[0]) * 60 + parseInt(endTimeSplitArr[1])

    if (startTimeMin > endTimeMin) {
     scene.endTime = null
    }
   }
  },
  calc_rangeStart(idx, schedules) {
   let ret

   if (idx === 0) {
    ret = this.elFormThemeInfoModel.openTime
   } else {
    ret = !_.isEmpty(schedules[idx - 1].endTime) ? schedules[idx - 1].endTime : this.elFormThemeInfoModel.openTime
   }

   return ret
  },
  calc_rangeEnd(idx, schedules) {
   let ret

   if (idx === schedules.length - 1) {
    ret = this.elFormThemeInfoModel.closeTime
   } else {
    ret = !_.isEmpty(schedules[idx + 1].startTime) ? schedules[idx + 1].startTime : this.elFormThemeInfoModel.closeTime
   }

   return ret
  },
  onBeforeUpload(file) {
   const isLt = file.size / 1024 / 1024 < 6

   if (!isLt) {
    this.$message.error('上传头像图片大小不能超过 6MB!')
   }
   return isLt
  },
  onChangeUpload(file, fileList) {
   const isLt = file.size / 1024 / 1024 < 6
   if (!isLt) {
    this.$message.error('上传头像图片大小不能超过 6MB!')
    return
   }

   this.imgSrcFileUrl = URL.createObjectURL(file.raw)
   this.imgSrcFileRaw = file.raw

   this.$refs['elFormBasicInfo'].validate()
  },
  onClick_deleteScene: function(sceneTime, idx) {
   if (this.title === '新增主题') {
    if (this.elFormThemeInfoModel.schedules.length < 2) {
     this.$message.error('最后一个时段不可删除!')
    } else {
     this.elFormThemeInfoModel.schedules.splice(idx, 1)
    }
   } else if (this.title === '修改主题') {
    if (this.elFormThemeInfoModel._sortSchedules[this.elFormThemeInfoModel._sortSchedules.length - 1].schedules.length < 2) {
     this.$message.error('最后一个时段不可删除!')
    } else {
     this.elFormThemeInfoModel._sortSchedules[this.elFormThemeInfoModel._sortSchedules.length - 1].schedules.splice(idx, 1)
    }
   }

   if (_.isArray(this.$refs['elFormSchedules']) && !_.isEmpty(this.$refs['elFormSchedules']) && !this.isShow_addScheduleButton) {
    this.$refs['elFormSchedules'][this.$refs['elFormSchedules'].length - 1].validate()
   } else if (!_.isArray(this.$refs['elFormSchedules'])) {
    this.$refs['elFormSchedules'].validate()
   }
  },
  onClick_addScene: function() {
   if (this.title === '新增主题') {
    let foundEle = this.elFormThemeInfoModel.schedules.find((ele_, idx, arr) => {
     return _.isEmpty(ele_.startTime) || _.isEmpty(ele_.endTime)
    })
    if (!_.isUndefined(foundEle)) {
     // 找到了!
     this.$message.error('请先将已有的时段填写完整!')
    } else {
     let range = _calc_timeRange(this.elFormThemeInfoModel.duration, this.elFormThemeInfoModel._interval, this.elFormThemeInfoModel.schedules[this.elFormThemeInfoModel.schedules.length - 1].endTime, this.elFormThemeInfoModel.closeTime)

     this.elFormThemeInfoModel.schedules.push({
      startTime: range.startTime,
      endTime: range.endTime,
     })
    }
   } else if (this.title === '修改主题') {
    let foundEle = this.elFormThemeInfoModel._sortSchedules[this.elFormThemeInfoModel._sortSchedules.length - 1].schedules.find((ele_, idx, arr) => {
     return _.isEmpty(ele_.startTime) || _.isEmpty(ele_.endTime)
    })
    if (!_.isUndefined(foundEle)) {
     // 找到了!
     this.$message.error('请先将已有的时段填写完整!')
    } else {
     let range = _calc_timeRange(this.elFormThemeInfoModel.duration, this.elFormThemeInfoModel._interval, this.elFormThemeInfoModel._sortSchedules[this.elFormThemeInfoModel._sortSchedules.length - 1].schedules[this.elFormThemeInfoModel._sortSchedules[this.elFormThemeInfoModel._sortSchedules.length - 1].schedules.length - 1].endTime, this.elFormThemeInfoModel.closeTime)

     this.elFormThemeInfoModel._sortSchedules[this.elFormThemeInfoModel._sortSchedules.length - 1].schedules.push({
      startTime: range.startTime,
      endTime: range.endTime,
     })
    }
   }

   let allPageTags = document.getElementsByTagName('div')
   for (let i = 0; i < allPageTags.length; i++) {
    if (allPageTags[i].className == 'el-dialog__body') {
     allPageTags[i].scrollTo({
      top: 2000,
     })
    }
   }
  },

  onOpened_dialog() {
   let allPageTags = document.getElementsByTagName('div')
   for (let i = 0; i < allPageTags.length; i++) {
    if (allPageTags[i].className == 'el-dialog__body') {
     allPageTags[i].scrollTo({
      top: 0,
     })
    }
   }
  },
  onDialog_close() {
   let allPageTags = document.getElementsByTagName('div')
   for (let i = 0; i < allPageTags.length; i++) {
    if (allPageTags[i].className == 'el-dialog__body') {
     allPageTags[i].scrollTo({
      top: 0,
     })
    }
   }
  },
  onDialog_closed() {
   this.$refs['elFormBasicInfo'].clearValidate()

   if (_.isArray(this.$refs['elFormSchedules']) && !_.isEmpty(this.$refs['elFormSchedules']) && !this.isShow_addScheduleButton) {
    // this.$refs['elFormSchedules'].forEach(element => {
    //  element.clearValidate()
    // })

    this.$refs['elFormSchedules'][this.$refs['elFormSchedules'].length - 1].clearValidate()
   } else if (!_.isArray(this.$refs['elFormSchedules'])) {
    this.$refs['elFormSchedules'].clearValidate()
   }

   this.$refs['elFormPrices'].clearValidate()

   this.elFormThemeInfoModel = {}
   this.imgSrcFileUrl = null
   this.imgSrcFileRaw = null
   this.callback = null
   this.dialogVisible = null
   this.title = ''
   this.isShow_addScheduleButton = true
  },
 },
}
</script>

<style lang="less">
#topicinfoedit {
 .subscribeContentList {
  .el-dialog {
   // border: solid 1px red;
   width: 8.56rem;

   .el-dialog__header {
    width: 8.56rem;
    background: url('../../statics/images/other/标题栏BG@2x.png') no-repeat;
    background-size: cover;
    // background-position-x: -0.5rem;
    height: 0.4rem;
    position: relative;
    padding: 0.1rem 0.11rem 0.09rem;

    .el-dialog__title {
     width: 0.72rem;
     height: 0.18rem;
     white-space: nowrap;
     font-size: 0.18rem;
     position: absolute;
     left: 0rem;
     padding-left: 0.41rem;
     color: white;
    }

    .el-dialog__title::before {
     content: '';
     display: block;
     width: 0.23rem;
     height: 0.21rem;
     background: url('../../statics/images/other/yogo@2x.png') center center;
     background-size: cover;
     position: absolute;
     left: 0.11rem;
    }

    .el-dialog__headerbtn {
     width: 0.29rem;
     height: 0.29rem;
     background-color: #fff;
     top: 0.05rem;
     right: -0.47rem;
     border-radius: 0.15rem;

     i::before {
      font-size: 0.2rem;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
     }
    }
   }

   // 滚动条的轨道（里面装有Thumb）
   .el-dialog__body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
   }

   // 滚动条整体部分
   .el-dialog__body::-webkit-scrollbar {
    width: 6px;
    background-color: #fff;
   }

   // 滚动条里面的小方块
   .el-dialog__body::-webkit-scrollbar-thumb {
    background-color: #d2d2d2;
   }

   .el-dialog__body,
   .el-dialog__footer {
    // border: solid 1px red;
    width: 8.56rem;
    height: auto;
    background-color: #fff;
   }

   .el-collapse {
    border: solid 0px blue;
    width: 7.76rem;

    // width: 90%;
    // min-width: 90%;
    // max-width: 90%;
    .el-collapse-item {
     margin-bottom: 40px;
     border: solid 1px rgb(241, 241, 241);
     border-radius: 6px;
     padding: 0 30px;
     background-color: #f1f1f1;

     .el-collapse-item__header {
      background-color: rgba(255, 0, 0, 0);

      .icon {
       height: 30%;
       border: 2px solid #208def;
       margin-right: 10px;
      }
     }

     .el-form {
      position: relative;
      background-color: #f1f1f1;

      .el-form-item {
       background-color: #f1f1f1;
       width: 40%;
       margin-right: 0;
       padding: 0 0px;

       .el-form-item__content {
        background-color: #f1f1f1;
        width: 70%;

        .el-select {
         //  border: solid 1px orange;
         width: 100%;
        }
       }
      }

      .el-form-item.num {
       width: 15%;
      }

      .customNum {
       //  border: solid 1px red;
       //  position: relative;
       //  width: 0.36rem;
       //  height: 0.36rem;
       p {
        // border: solid 1px red;
        position: absolute;
        top: 0;
        left: 40px;
        line-height: 12px;
        height: 0.27rem;
        font-size: 0.96rem;
        color: rgba(193, 193, 193, 1);
       }
      }
     }
    }

    .el-collapse-item__header {
     font-size: 0.22rem;
     line-height: 0.22rem;
     height: 84px;
     border: solid 0px rgb(241, 241, 241);
    }

    .el-collapse-item__content {
     border: solid 0px rgb(241, 241, 241);
     background-color: #f1f1f1;
    }

    .el-collapse-item__wrap {
     border: solid 0px red;
     height: fit-content;
     //  width: 7.16rem;
     //  height: 2.36rem;
     //  background: rgba(235, 235, 235, 1);
     //  border-radius: 0.06rem;
     //  padding: 0.3rem;
     //  margin-top: 0.3rem;
    }

    // .el-collapse-item__header.is-active {
    //  //  height: 84px;
    //  font-size: 0.22rem;
    //  line-height: 0.22rem;
    //  height: 84px;
    //  margin-bottom: 0px;
    //  border-top: solid 1px rgb(241, 241, 241);
    // }
   }
  }

  &.subscribe {
   // border: solid 1px red;
   .el-dialog__body {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 6rem;
    padding: 0;
    padding-top: 0.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .customInfo,
    .orderForm,
    .payMoney {
     width: 7.76rem;
     padding: 0.3rem;
     border: 1px solid #f1f1f1;
     border-radius: 0.06rem;
     margin-bottom: 0.4rem;

     .customTitle,
     .orderFormTitle,
     .payMoneyTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.22rem;
      line-height: 0.22rem;
      color: #000;

      p:nth-of-type(1) {
       display: flex;
       align-items: center;
       justify-content: center;

       img {
        width: 0.04rem;
        height: 0.22rem;
        margin-right: 0.09rem;
       }
      }
     }
    }

    .customInfo {
     .contentInfo {
      ul {
       width: 7.16rem;
       height: 2.36rem;
       background: rgba(235, 235, 235, 1);
       border-radius: 0.06rem;
       padding: 0.3rem;
       margin-top: 0.3rem;

       li {
        margin-bottom: 0.3rem;
        display: flex;

        input {
         width: 2.8rem;
         height: 0.36rem;
         background: rgba(255, 255, 255, 1);
         border: 1px solid rgba(210, 210, 210, 1);
         border-radius: 0.06rem;
         padding-left: 0.09rem;
         font-size: 0.14rem;
         margin-right: 0.3rem;
        }

        p {
         line-height: 0.18rem;
         height: 0.18rem;
         font-size: 0.18rem;
         color: rgba(0, 0, 0, 1);
         margin-bottom: 0.19rem;
        }

        .activeLine {
         border: 1px solid rgba(32, 141, 239, 1);
        }
       }

       li:nth-of-type(1) {
        align-items: flex-end;

        .delCustom {
         width: 0.36rem;
         height: 0.36rem;
         background: rgba(230, 105, 105, 1);
         border-radius: 0.06rem;
         position: relative;

         img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
         }
        }
       }

       li:nth-of-type(2) {
        align-items: flex-end;

        .guessAge {
         position: relative;

         .downIcon {
          position: absolute;
          top: 51%;
          right: 0.3rem;
          width: 0.36rem;
          height: 0.36rem;
          background: rgba(210, 210, 210, 1);
          border: 1px solid rgba(210, 210, 210, 1);
          border-radius: 0px 0.06rem 0.06rem 0px;

          img {
           width: 0.16rem;
           height: 0.1rem;
           position: absolute;
           top: 50%;
           left: 50%;
           transform: translate(-50%, -50%);
          }
         }
        }

        .customNum {
         position: relative;
         width: 0.36rem;
         height: 0.36rem;

         p {
          position: absolute;
          top: 0;
          right: 0;
          line-height: 0.36rem;
          height: 0.27rem;
          font-size: 0.36rem;
          color: rgba(193, 193, 193, 1);
         }
        }
       }
      }

      .addCustom {
       width: 100%;
       margin-top: 0.3rem;

       > p {
        width: 1.18rem;
        height: 0.4rem;
        border: 1px solid rgba(32, 141, 239, 1);
        border-radius: 0.06rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.18rem;
        font-weight: 400;
        color: rgba(32, 141, 239, 1);
       }
      }
     }
    }

    .orderForm {
     .orderFormContent {
      padding: 0.3rem 0.3rem 0;

      ul {
       display: flex;

       li {
        margin-right: 0.3rem;

        p {
         padding: 0;
         line-height: 0.18rem;
         height: 0.18rem;
         font-size: 0.18rem;
         color: rgba(0, 0, 0, 1);
         margin-bottom: 0.19rem;
        }

        input {
         width: 2.8rem;
         height: 0.36rem;
         background: rgba(255, 255, 255, 1);
         border: 1px solid rgba(210, 210, 210, 1);
         border-radius: 0.06rem;
         padding-left: 0.09rem;
         font-size: 0.14rem;
        }

        .isOrNotTeam {
         position: relative;

         .downIcon {
          position: absolute;
          top: 51%;
          right: 0;
          width: 0.36rem;
          height: 0.36rem;
          background: rgba(210, 210, 210, 1);
          border: 1px solid rgba(210, 210, 210, 1);
          border-radius: 0px 0.06rem 0.06rem 0px;

          img {
           width: 0.16rem;
           height: 0.1rem;
           position: absolute;
           top: 50%;
           left: 50%;
           transform: translate(-50%, -50%);
          }
         }
        }
       }
      }

      .payWay {
       display: flex;

       > div {
        width: 2.8rem;
        margin-right: 0.3rem;
        margin-top: 0.3rem;

        > p {
         padding: 0;
         height: 0.17rem;
         line-height: 0.17rem;
         font-size: 0.18rem;
         color: rgba(0, 0, 0, 1);
         margin-bottom: 0.2rem;
        }

        .payWayList {
         width: 2.8rem;
         display: flex;
         height: 0.36rem;
         // justify-content: center;
         align-items: center;
         margin-bottom: 0.2rem;

         img {
          margin-top: -0.04rem;
          margin-right: 0.06rem;
         }
        }

        input {
         width: 2.8rem;
         height: 0.36rem;
         border: 1px solid rgba(210, 210, 210, 1);
         border-radius: 0.06rem;
         margin-bottom: 0.2rem;
         padding-left: 0.1rem;
         font-size: 0.14rem;
        }

        form:nth-of-type(9) {
         input {
          margin-bottom: 0rem;
         }
        }

        .payWayList:nth-of-type(9) {
         margin-bottom: 0rem;
        }
       }
      }
     }
    }

    .payMoney {
     .payMoneyList {
      padding: 0.3rem 0.3rem 0;

      ul li {
       display: flex;
       margin-bottom: 0.29rem;

       p {
        padding: 0;
        line-height: 0.16rem;
        height: 0.17rem;
        font-size: 0.16rem;
        font-weight: normal;
        color: rgba(129, 129, 129, 1);
        margin-right: 0.2rem;
       }

       p:nth-of-type(2) {
        margin-right: 0.15rem;
       }

       p:nth-of-type(3) {
        margin-right: 0rem;
       }
      }

      li:nth-of-type(4) {
       margin-bottom: 0rem;

       .total {
        span:nth-of-type(1) {
         height: 0.16rem;
         font-size: 0.21rem;
         font-weight: 400;
         color: rgba(242, 94, 35, 1);
        }

        span:nth-of-type(2) {
         height: 0.28rem;
         font-size: 0.36rem;
         font-weight: bold;
         color: rgba(242, 94, 35, 1);
        }
       }
      }
     }
    }
   }

   .el-dialog__footer {
    padding-bottom: 0.4rem;
    padding-top: 0;
    font-size: 14px;

    .el-button {
     width: 1rem;
     height: 0.5rem;
     margin: 20px;
    }

    // .el-button--primary {
    //   width: 0.8rem;
    //   height: 0.4rem;
    //   //   // // background: #ea4335;
    //   //   // border: none;
    //   //   // border-radius: 0.06rem;
    //   //   // padding: 0;
    // }
    // .el-button--default {
    //   width: 0.8rem;
    //   height: 0.4rem;
    //   //   border-radius: 0.06rem;
    //   //   padding: 0;
    // }
   }
  }
 }

 .theme-info {
  .el-form {
   .el-form-item {
    width: 50%;
    min-width: 50%;
    max-width: 50%;

    .el-form-item__label {
     font-size: 1.2em;
     padding: 0px;
    }

    .el-rate {
     //  border: solid 1px blue;
     height: 40px;
     line-height: 40px;
     font-size: 35px;
    }
   }
  }
 }

 .schedules-info {
  .el-form {
   .el-form-item {
    // border: solid 1px blue;
    width: 100%;
    min-width: 100%;
    max-width: 100%;

    .el-form-item__label {
     font-size: 1.2em;
    }

    .el-form-item__content {
     width: 100%;
     min-width: 100%;
     max-width: 100%;

     .wrapper {
      width: 100%;
      min-width: 100%;
      max-width: 100%;

      .time-picker {
       justify-content: flex-start;
       align-items: center;
      }

      .dk-time-picker {
       width: 40%;
       min-width: 40%;
       max-width: 40%;
       margin-right: 20px;

       .el-input {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
       }
      }

      .el-date-editor {
       width: 40%;
       min-width: 40%;
       max-width: 40%;
       margin: 10px;
      }

      p {
       margin: 10px;
      }

      .el-input {
       width: 40%;
       min-width: 40%;
       max-width: 40%;
       margin: 10px;
      }

      .el-button.add-sence {
       // border: solid 1px red;
       align-self: flex-start;
       margin-left: 10px;
      }
     }
    }
   }
   .el-form-item.duration,
   .el-form-item.interval {
    // border: solid 1px red;
    width: 50%;
    min-width: 50%;
    max-width: 50%;

    .el-form-item__content {
     width: 100%;
     min-width: 100%;
     max-width: 100%;

     .el-input {
      width: 80%;
      min-width: 80%;
      max-width: 80%;
      margin: 10px;
      margin-right: 0;
     }
    }
   }

   // .el-form-item.duration {
   //  // border: solid 1px blue !important;
   //  border: solid 1px blue;
   //  width: 40%;
   //  min-width: 40%;
   //  max-width: 40%;

   //  .el-input {
   //   border: solid 1px red !important;
   //   width: 100%;
   //   min-width: 100%;
   //   max-width: 100%;

   //   .el-input__inner {
   //    width: 100%;
   //    min-width: 100%;
   //    max-width: 100%;
   //   }
   //  }
   // }
  }
 }

 .ticket-info {
  // border: solid 1px red;
  .content {
   //  border: solid 1px yellow;
   .el-form {
    // border: solid 1px green;
    .el-form-item {
     //  border: solid 1px blue;
     width: 50%;
     min-width: 50%;
     max-width: 50%;

     .el-form-item__label {
      font-size: 1.2em;
     }
    }
   }
  }
 }
}</style
><style>
.avatar-uploader .el-upload {
 border: 1px dashed #d9d9d9;
 border-radius: 6px;
 cursor: pointer;
 position: relative;
 overflow: hidden;
}

.avatar-uploader .el-upload:hover {
 border-color: #409eff;
}

.avatar-uploader .avatar-uploader-icon {
 font-size: 58px;
 color: #8c939d;
 width: 178px;
 height: 178px;
 line-height: 178px;
 text-align: center;
}

.avatar-uploader .avatar {
 width: 178px;
 height: 178px;
 display: block;
}

.avatar-uploader .el-icon-plus::before {
 line-height: 178px;
 text-align: center;
}
</style>
