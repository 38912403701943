import Vue from 'vue'
import Vuex from 'vuex'
import user_detail from '@/store/modules/user_detail'
import getters from '@/store/getters'
import excel from '@d2-projects/vue-table-export'

Vue.use(Vuex)
Vue.use(excel)

const store = new Vuex.Store({
 modules: {
  user_detail,
 },
 getters,
})

export default store
