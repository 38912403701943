<template>
 <div id="yogo-errLog" class="eltables">
  <div class="toolbar-container duku">
   <div>
    <span class="yogo-title toolbar-title">出错记录</span>
    <el-button type="primary" class="myicon iconadd" @click="handleAdd"><i></i>新增出错记录</el-button>
   </div>
   <div class="toolbar-box">
    <el-form label-position="top">
     <el-form-item label="门店" class="filter-item">
      <el-select class="item-content" v-model="formModel.storeId" placeholder="" clearable size="small" style="" @change="formModel.mistakePeopleId = null">
       <el-option v-for="(item, idx) of shopList" :key="idx" :value="item.id" :label="item.name" />
      </el-select>
     </el-form-item>
     <el-form-item label="出错人员" class="filter-item">
      <el-select class="item-content" v-model="formModel.mistakePeopleId" placeholder="" clearable size="small" style="">
       <el-option v-for="(item, idx) of filteredStaff" :key="idx" :value="item.userId" :label="item.username" />
      </el-select>
     </el-form-item>
     <el-form-item label="开始时间" class="filter-item">
      <el-date-picker class="item-content" type="date" clearable size="small" style="" placeholder="" v-model="formModel.startTime" value-format="yyyy-MM-dd HH:mm:ss" />
     </el-form-item>
     <el-form-item label="结束时间" class="filter-item">
      <el-date-picker class="item-content" type="date" clearable size="small" style="" placeholder="" v-model="formModel.endTime" value-format="yyyy-MM-dd HH:mm:ss" />
     </el-form-item>
    </el-form>
    <div>
     <el-button size="small" class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter($event)">查询 </el-button>
     <el-button size="small" class="filter-item" type="primary" icon="el-icon-download" @click="onClick_exportExcel($event)">导出Excel</el-button>
    </div>
   </div>
  </div>
  <el-table :data="list" class="data-table" stripe>
   <el-table-column label="出错门店" prop="storeName" align="center" />
   <el-table-column label="出错人员" prop="mistakePeopleName" align="center" />
   <el-table-column label="处罚内容" prop="systemEntryName" align="center" />
   <el-table-column label="处罚金额" prop="punishMoney" align="center">
    <template slot-scope="{ row }">
     ¥ {{ Number(row.punishMoney / 100.0).toFixed(2) }}
    </template>
   </el-table-column>
   <el-table-column label="出错时间" prop="mistakeTime" align="center" />
   <el-table-column label="备注" prop="description" align="center" />
   <el-table-column prop="actions" label="操作" width="120" align="left">
    <template slot-scope="{ row }">
     <el-button type="primary" size="small" class="el-icon-edit" circle @click="handleEdit(row)"></el-button>
     <el-button type="danger" size="small" class="el-icon-delete" circle @click="handleDelete(row)"></el-button>
    </template>
   </el-table-column>
  </el-table>
  <pagination v-show="true" :total="listSize" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

  <delDialog ref="DelDialogForm" />
  <editDialog ref="EditDialogForm" />
 </div>
</template>
<script>
import delDialog from '@/views/template/delDialog'
import editDialog from '@/views/dmgl/errLogEdit'
import Pagination from '@/components/Pagination'
import { getShops, getMistakeRecords, createMistakeRecord, deleteMistakeRecord } from '@/api/shop'
import { getSytemEntryList } from '@/api/systementry'
import { getUserList, getStaffUserList } from '@/api/user'
/* global _ */
export default {
 components: {
  editDialog,
  delDialog,
  Pagination
 },
 data() {
  return {
   list: null,
   listSize: 0,
   listLoading: true,
   listQuery: {
    page: 1,
    limit: 10,
    importance: undefined,
    title: undefined,
    type: undefined,
    status: undefined,
    sort: '+id'
   },
   formModel: {
    storeId: null,
    themeId: null,
    payChannelId: null,
    startTime: null,
    endTime: null
   },
   shopList: null,
   user: null,
   allUser: null,
   allEntry: null,
   allStaff: null
  }
 },
 computed: {
  filteredStaff() {
   if (!_.isEmpty(this.allStaff)) {
    let clone_allStaff = _.cloneDeep(this.allStaff)
    if (_.isInteger(this.formModel.storeId) && this.formModel.storeId > 0) {
     return clone_allStaff.filter(eleStaff => {
      return eleStaff.stores.some(eleStore => {
       return eleStore.id === this.formModel.storeId
      })
     })
    } else {
     return clone_allStaff
    }
   } else {
    return null
   }
  }
 },

 beforeCreate() {
  let param = {
   isPage: false,
   type: 3
  }
  Promise.all([getShops(), getStaffUserList(), getSytemEntryList(param)]).then(result => {
   this.shopList = result[0].data.data
   this.allStaff = result[1].data.data
   this.allEntry = result[2].data
   this.getList()
  })
 },
 created() {
  this.user = this.$store.getters.user
  this.getList()
 },
 methods: {
  getList() {
   this.listLoading = true
   let param = {
    pageSize: this.listQuery.limit,
    pageNum: this.listQuery.page,
    isPage: true,

    storeId: this.listQuery.storeId,
    mistakePeopleId: this.listQuery.mistakePeopleId,
    startTime: this.listQuery.startTime,
    endTime: this.listQuery.endTime
   }
   getMistakeRecords(param).then(response => {
    if (response.data.rows) {
     this.list = response.data.rows
     if (response.data && response.data.total) {
      this.listSize = response.data.total
     } else {
      this.listSize = this.list.length
     }
    }
   })
   this.listLoading = false
  },
  handleFilter() {
   this.listQuery.storeId = this.formModel.storeId
   this.listQuery.mistakePeopleId = this.formModel.mistakePeopleId
   this.listQuery.startTime = this.formModel.startTime
   this.listQuery.endTime = this.formModel.endTime

   this.getList()
  },
  handleAdd() {
   this.$refs['EditDialogForm'].showDialog('新增错误记录', null, this.doCreate)
  },
  handleEdit(item) {
   this.selectedItem = item
   const target = JSON.parse(JSON.stringify(item))
   target.punishMoney = target.punishMoney / 100.0
   this.$refs['EditDialogForm'].showDialog('修改错误记录', target, this.doUpdate)
  },
  handleDelete(item) {
   this.selectedItem = item
   this.$refs['DelDialogForm'].showDialog('删除错误记录', '是否删除当前错误记录', item, this.doDelete)
  },
  doCreate(data) {
   data.createPeople = this.user.userId
   data.punishMoney = data.punishMoney * 100.0
   createMistakeRecord(data).then(response => {
    console.log(response)
    this.getList()
   })
  },
  doUpdate(data) {
   // TODO 金额 12.0 格式化为 1200
   data.createPeople = this.user.userId
   data.punishMoney = data.punishMoney * 100.0
   createMistakeRecord(data).then(response => {
    // TODO
    console.log(response)
    this.getList()
   })
  },
  doDelete() {
   deleteMistakeRecord(this.selectedItem).then(response => {
    console.log(response)
    this.list.splice(this.list.indexOf(this.selectedItem), 1)
   })
  }
 }
}
</script>
<style scoped></style>
