<template>
<div id="yogo-onetable">
 <div class="yogo-title">
  {{tableTit}}
  <el-button v-for="item in tableTopBtns" :key="item.compKey" :type="item.type" class="myicon" :class="item.className" @click="item.clickEvent">
   <i></i>
   {{item.labelName}}
  </el-button>
 </div>
 <div class="yogo-search" :class="{'bottom0':rowform}" v-if="tableComponents!=null">
  <form action :class="{'formflex':rowform}">
   <div class="row" v-for="(item,index) in tableComponents" :key="index">
    <component v-for="(comp,cindex) in item.CompArr" :key="cindex" :is="comp.component" :codeField="comp.codeField" @changeCode="getComponentCode" :labelName="comp.labelName" :code="comp.code"></component>
   </div>
   <div class="row rowbutton" :class="{'onerow':rowform}">
    <div class="lastcolumn">
     <p class="yogo-input-name">&nbsp;</p>
     <!-- <input type="text" /> -->
     <!-- <i class="btnicon btnimgdown"></i> -->
     <el-button v-for="item in tableLowBtns" :key="item.compKey" :type="item.type" :class="item.className" @click="item.clickEvent">
      <i :class="item.iconClass"></i>
      {{item.labelName}}
     </el-button>
    </div>
   </div>
  </form>
  <div class="row rowbutton">
   <!-- 密室经营状况，在查询处 有显示总金额 -->
   <slot name="slotTotal"></slot>
  </div>
 </div>
 <div class="yogo-table" id="template_table">
  <div class="yogo-table-th">
   <p v-for="(item, index) in tableTilteArr" :key="index" :class="item.className">{{item.title}}</p>
  </div>
  <div class="yogo-table-tr" v-for="(item, index) in tableContentArr" :key="index">
   <p v-for="(value, key, cindex) in item" :key="cindex" v-show="key!='id'">
    <span class="tdcontent" v-if="(!value.hasedit)&&(!value.hasup)&&(!value.hastopicimg)&&(!value.topicimg)" @mouseenter="handleIn($event,item)" @mouseleave="handleOut($event,item)">{{fmtData(value,key)}}</span>
    <!-- 编辑按钮、删除按钮 -->
    <el-button class="optionbtn btnedit" v-if="value.hasedit" type="text" @click="editData(item)"></el-button>
    <el-button class="optionbtn btndel" v-if="value.hasdelete" type="text" @click="deleteData(item)"></el-button>
    <el-button class="optionbtn btnup" v-if="value.hasup" type="text"></el-button>
    <el-button class="optionbtn btndown" v-if="value.hasdown" type="text"></el-button>
    <el-button class="optionbtn btntopic" v-if="value.hastopicimg" type="text" @click="openDetail(item)"></el-button>
    <!-- 主题照片 -->
    <img class="topicPic" v-if="value.topicimg" :src="value.topicimg" alt />
    <!-- 评分插件 -->
    <el-rate v-if="value.starnum" v-model="value.starnum" disabled style="font-size:.14rem"></el-rate>
   </p>
  </div>
 </div>

 <div class="yogo-pages">
  <div class="yogo-pages-left">
   <span>共1页&nbsp; 每页&nbsp;</span>
   <div class="pagesel">
    <span>10</span>
    <i class="btnicon btnimgpage"></i>
   </div>
   <span>&nbsp;条&nbsp; 共6条</span>
  </div>
  <div class="yogo-pages-right">
   <el-pagination background layout="prev, pager, next" :total="1"></el-pagination>
  </div>
 </div>
</div>
</template>

<script>
import {
 oneTableTemplateMixins
} from '@/views/mixins'
export default {
 mixins: [oneTableTemplateMixins],
 name: 'template_table',
 methods: {
  fmtData(val, column) {
   //操作，主题照片，难度评分插件
   if (column == 'options' || column == 'images' || column == 'diff') {
    //  ;
   } else {
    return val
   }
  },
  editData(value) {
   this.$emit('edit', value)
  },
  deleteData(value) {
   this.$emit('del', value)
  },
  openDetail(value) {
    
   this.$emit('detail', value)
  },
  //更新table数据
  updateTableContentArr(val) {
    
   this.tableContentArr = val
  },
  updateTableTilteArr(val) {
   this.tableTilteArr = val
  },
  handleIn: function (e, content) {
   this.$emit('handleIn', e, content)
  },
  handleOut: function (e, content) {
   this.$emit('handleOut', e, content)
  },
 },
}
</script>

<style lang="less">
#yogo-onetable {
 padding: 0 0.15rem 0.15rem 0.15rem;

 button {
  font-size: 1.333em;
 }

 .yogo-title {
  color: white;
  height: 0.5rem;
  line-height: 0.5rem;
  font-size: 1.3em;

  // 头部查询按钮，新增主题类型按钮
  .myicon {
   height: 0.3rem;
   line-height: 0.3rem;
   float: right;
   margin-top: 17px;
   margin-left: 20px;
   font-size: 0.12rem;
   padding: 0 0.1rem;
   border-radius: 0.06rem;

   i {
    padding: 0 5px;
   }
  }

  .iconadd {
   i::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0.15rem;
    height: 0.18rem;
    background: url('../../statics/images/iconaddtopictype.png') no-repeat;
    background-size: 0.15rem 0.18rem;
   }
  }

  .iconcz {
   i::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0.16rem;
    height: 0.16rem;
    background: url('../../statics/images/iconcz.png') no-repeat;
    background-size: 0.16rem 0.16rem;
   }
  }
 }

 .yogo-search {
  background: rgba(28, 32, 52, 1);
  border-radius: 0.1rem;
  padding: 30px;
  box-sizing: border-box;

  .formflex {
   display: flex;
   flex-wrap: wrap;
  }

  .row {
   position: relative;
   display: flex;
   color: rgba(255, 255, 255, 1);
   margin-bottom: 15px;

   .btncz {
    font-size: 0.12rem;
    width: 0.8rem;
    // padding: 0 .2rem;
    height: 0.3rem;
    background: rgba(32, 141, 239, 1);
    border-radius: 0.06rem;
    padding: 0 0.1rem;
   }

   .btnexport {
    font-size: 0.12rem;
    width: 1rem;
    padding: 0 0.2rem;
    height: 0.3rem;
    background: rgba(32, 141, 239, 1);
    border-radius: 0.06rem;
    padding: 0 0.1rem;
   }

   &:last-child {
    margin-bottom: 0;
   }

   div.incomeMoney {
    position: absolute;
    right: 0;
    bottom: 0;
    color: #b1b1b1;
    font-size: 16/140rem;
    display: flex;
    align-items: flex-end;

    .allMoney {
     margin-right: 18/140rem;
    }
   }
  }

  .onerow {

   // padding:55px 0;
   .lastcolumn {

    //  width: 2.1rem;
    .yogo-input-name {
     // font-size: 1.5em;
     // padding-bottom: 0.2rem;
     font-size: 0.14rem;
     padding-bottom: 0.1rem;
    }
   }
  }

  .column {
   position: relative;
   width: 2.1rem;
   margin-right: 15px;

   input {
    width: 2.1rem;
    font-size: 0.11rem;
    color: #b1b1b1;
    background-color: #282e43;
    border: 1px solid #ee9911;
    border-radius: 0.06rem;
    height: 0.3rem;
    text-indent: 4px;
    padding-left: 0;
   }

   .el-date-editor.el-input {
    width: 160px;
   }

   input::-webkit-input-placeholder {
    color: #b1b1b1;
   }

   .el-date-editor {
    span {
     display: none;
    }
   }

   .yogo-input-name {
    // font-size: 1em;
    font-size: 0.14rem;
    // padding-bottom: 0.2rem;
    // font-size: 1.167em !important;
    padding-bottom: 0.1rem;
   }

   .btnicon {
    position: absolute;
    right: 0px;
    height: 0.3rem;
    width: 0.3rem;
    border-left: 1px solid #ee9911;
    background: url(../../statics/images/icondown.png) center center no-repeat;
   }

   .el-date-editor.el-input {
    width: 100%;
   }

   .btnimgdown {
    background-image: url(../../statics/images/icondown.png);
    background-size: 0.12rem 0.08rem;
   }

   .btnimgdate {
    background-image: url(../../statics/images/icondate.png);
    background-size: 0.16rem 0.15rem;
   }
  }

  .rowbutton {
   margin-bottom: 0rem;
  }
 }

 .bottom0 {
  padding-bottom: 0;
 }

 .yogo-table {
  margin-top: 20px;

  .yogo-table-th {
   display: flex;
   background-color: #1c2034;
   height: 0.35rem;
   line-height: 0.35rem;
   // font-size: 1.167em !important;
   font-size: 0.14rem;
   justify-content: space-around;
   border-top-left-radius: 0.1rem;
   border-top-right-radius: 0.1rem;

   p {
    color: #fff;
    width: 1.5rem;
    text-align: center;

    &.maxSize {
     width: 500px;
    }

    &.colorred {
     color: red;
    }

    &.smwidth {
     width: 8%;
    }
   }
  }

  .yogo-table-tr {
   display: flex;
   align-items: center;
   background-color: #1c2034;
   justify-content: space-around;
   font-size: 0.12rem;
   box-sizing: border-box;
   // padding: 10px;

   &:nth-of-type(2n) {
    background-color: #282e43;
   }

   &:last-of-type {
    color: red;
    border-bottom-left-radius: 0.1rem;
    border-bottom-right-radius: 0.1rem;
   }

   p {
    color: #b1b1b1;
    padding: 12px 0;
    text-align: center;
    box-sizing: border-box;
    // height: 110/140rem;
    width: 14.2%;
    display: flex;
    align-items: center;
    justify-content: center;

    .tdcontent {
     width: 100%;
     height: 100%;
     display: inline-block;
    }

    &.smwidth {
     width: 8%;
    }

    .optionbtn {
     width: 0.3rem;
     height: 0.3rem;
     background: rgba(32, 141, 239, 1) url('../../statics/images/other/修改@2x.png') no-repeat center center;
     background-size: 0.14rem 0.14rem;
     border-radius: 50%;
    }

    .btnedit {
     background-image: url('../../statics/images/other/修改@2x.png');
    }

    .btndel {
     background-color: rgba(230, 105, 105, 1);
     background-image: url('../../statics/images/other/删除@2x.png');
     margin-left: 0.1rem;
    }

    .btnup {
     background-image: url('../../statics/images/other/updown.png');
     margin-left: 0.1rem;
    }

    .btndown {
     background-image: url('../../statics/images/other/updown.png');
     transform: rotate(180deg);
     margin-left: 0.1rem;
    }

    .btntopic {
     background-image: url('../../statics/images/other/icontopic.png');
     margin-left: 0.1rem;
    }

    .topicPic {
     width: 71/100rem;
     height: 1rem;
    }

    .el-rate__icon {
     margin-right: 0;
    }
   }
  }
 }

 .yogo-pages {
  margin-top: 30px;
  display: flex;
  color: #fff;
  justify-content: space-between;
  font-size: 0.11rem;
  margin-bottom: 0.6rem;

  .yogo-pages-left {
   display: flex;
   align-items: center;

   .pagesel {
    position: relative;
    background-color: #282e43;
    width: 0.66rem;
    height: 0.3rem;
    line-height: 0.3rem;
    padding-left: 0.1rem;
    border-radius: 0.06rem;
    border: 1px solid #ee9911;
    margin: 0 10px;

    .btnicon {
     position: absolute;
     right: 0px;
     height: 0.29rem;
     width: 0.3rem;
     border-left: 1px solid #ee9911;
     background: url(../../statics/images/icondown.png) center center no-repeat;
    }

    .btnimgpage {
     background-image: url(../../statics/images/icondown.png);
     background-size: 0.1rem 0.08rem;
    }
   }
  }

  .yogo-pages-right {

   .el-pagination.is-background .btn-prev,
   .el-pagination.is-background .btn-next {
    background-color: #38405c;
   }

   .el-icon-arrow-left:before {
    content: '\E792';
   }

   .el-icon-arrow-right:before {
    content: '\E791';
   }

   .btn-prev,
   .btn-next {
    height: 0.3rem;
    width: 0.3rem;
    border-radius: 0.06rem;
    border: 1px solid #ee9911;

    .el-icon {
     font-size: 0.2rem;
    }
   }

   .el-pager li {
    text-align: center;
    height: 0.3rem;
    width: 0.3rem;
    line-height: 0.3rem;
    // padding: 0 .17rem;
    font-size: 0.11rem;
    border-radius: 0.06rem;
   }
  }
 }
}
</style>
