<template>
 <div class="playbackdialogContent eltables">
  <el-dialog :close-on-click-modal="false" :title="tit" :visible.sync="dialogVisible" @close="closeDialog" class="dialog-wrap" width="8.87rem">
   <!-- <div class="dialog-table">
    <div class="dialog-table-th">
     <span :key="index" v-for="(item, index) in tableTilteArr">{{ item.title }}</span>
    </div>
    <div :key="index" class="dialog-table-td" v-for="(item, index) in tableContentArr">
     <span>{{ item.intimes }}</span>
     <span>{{ item.outtimes }}</span>
     <span>{{ item.summinutes | fmtMins }}</span>
     <span>{{ item.ispass | fmtPass }}</span>
    </div>
   </div> -->

   <el-table :data="list" class="data-table" stripe height="1000">
    <el-table-column label="游戏进场时间" prop="openTime" align="center" />
    <el-table-column label="游戏出场时间" prop="closeTime" align="center" />
    <el-table-column label="用时" align="center">
     <template slot-scope="scope">
      {{ calc_duration(scope.row) }}
     </template>
    </el-table-column>
    <el-table-column label="是否通关" align="center">
     <template slot-scope="scope">
      {{ scope.row.sessionStatus == 3 ? '是' : '否' }}
     </template>
    </el-table-column>

    <!-- <el-table-column label="主题档期" align="center">
     <template slot-scope="scope">
      <el-tooltip class="tooltip" placement="right" effect="dark">
       <div>{{ scope.row.schedules.length + '场' }}</div>
       <div slot="content">
        <div v-for="(item, idx) in scope.row.schedules" :key="idx">
         {{ '场次' + (idx + 1) + ': ' + item.startTime + '-' + item.endTime }}
        </div>
       </div>
      </el-tooltip>
     </template>
    </el-table-column>

    <el-table-column label="玩家人数" prop="maxPeople" align="center" />

    <el-table-column prop="actions" label="操作" width="120" align="left">
     <template slot-scope="{ row }">
      <el-button type="primary" size="small" class="el-icon-tickets" circle @click="handleDetails(row)"></el-button>
     </template>
    </el-table-column> -->
   </el-table>

   <div class="btns">
    <el-button @click="closeDialog" class="redbtn" type="primary" size="mini">确&nbsp;&nbsp;&nbsp;定</el-button>
   </div>
  </el-dialog>
 </div>
</template>

<script>
export default {
 data() {
  return {
   list: [],
   tit: '',
   dialogVisible: false,
   tableTilteArr: [
    {
     title: '游戏进场时间',
    },
    {
     title: '游戏出场时间',
    },
    {
     title: '用时',
    },
    {
     title: '是否通关',
    },
   ],
   tableContentArr: [
    {
     intimes: '2018-08-23 10:01:44',
     outtimes: '2018-08-23 10:55:44',
     summinutes: 60,
     ispass: true,
    },
    {
     intimes: '2018-08-23 10:01:44',
     outtimes: '2018-08-23 10:55:44',
     summinutes: 60,
     ispass: true,
    },
    {
     intimes: '2018-08-23 10:01:44',
     outtimes: '2018-08-23 10:55:44',
     summinutes: 60,
     ispass: true,
    },
    {
     intimes: '2018-08-23 10:01:44',
     outtimes: '2018-08-23 10:55:44',
     summinutes: 60,
     ispass: false,
    },
   ],
  }
 },
 filters: {
  fmtMins(val) {
   return val + '分钟'
  },
  fmtPass(ispass) {
   if (ispass) {
    return '是'
   }
   return '否'
  },
 },
 methods: {
  calc_duration(row) {
   if (_.isString(row.openTime) && !_.isEmpty(row.openTime) && _.isString(row.closeTime) && !_.isEmpty(row.closeTime)) {
    let duration_msec = new Date(row.closeTime).getTime() - new Date(row.openTime).getTime()
    return Math.ceil(duration_msec / 1000 / 60) + '分钟'
   } else {
    return ''
   }
  },

  closeDialog() {
   this.dialogVisible = false
  },
  showDialog(title = '主题详情', data = []) {
   this.list = data
   this.tit = title
   this.dialogVisible = true

   // this.tableContentArr = data; [待数据补全]
  },
 },
}
</script>

<style lang="less">
.dialogContent {
 border-radius: 5px;
 box-sizing: border-box;

 .buttons {
  text-align: center;
  margin-bottom: 0;
  width: 1.36rem;
  margin: 0.35rem auto;
  box-sizing: border-box;
 }
}
</style>

<style lang="less">
.playbackdialogContent {
 .el-dialog__header {
  background: url('../../statics/images/other/标题栏BG@2x.png') no-repeat;
  background-size: 8.87rem 0.4rem;
  width: 8.87rem;
  height: 0.4rem;
  position: relative;
  padding: 0;

  .el-dialog__title {
   font-size: 1.5em;
   line-height: 0.4rem;
   float: left;
   margin-left: 0.4rem;
   color: white;
   font-weight: normal;
  }

  &::before {
   content: '';
   display: block;
   width: 0.24rem;
   height: 0.21rem;
   background: url('../../statics/images/other/yogo@2x.png') no-repeat center;
   background-size: 0.24rem 0.21rem;
   margin: 0.1rem;
   position: absolute;
  }

  .el-dialog__headerbtn {
   width: 0.29rem;
   height: 0.29rem;
   background-color: #fff;
   top: 0.06rem;
   right: -0.48rem;
   border-radius: 50%;

   i::before {
    font-size: 30px;
    line-height: 25px;
   }
  }
 }

 .el-dialog__body {
  padding: 0.4rem 0.4rem 0 0.4rem;
  height: 5.26rem;
  width: 8.87rem;
  box-sizing: border-box;
  text-align: center;

  .dialog-table {
   // margin-top: 20px;
   border: 1px solid rgba(177, 177, 177, 1);
   border-radius: 6px;
   .dialog-table-th {
    display: flex;
    background-color: #f3f3f3;
    height: 0.57rem;
    line-height: 0.57rem;
    font-size: 1.1em;
    justify-content: space-around;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    span {
     color: #414141;
     width: 1.5rem;
     text-align: center;
    }
   }

   .dialog-table-td {
    display: flex;
    align-items: center;
    background-color: rgba(243, 243, 243, 1);
    justify-content: space-around;
    font-size: 1.143em;
    height: 0.78rem;

    span {
     color: #b1b1b1;
     width: 1.5rem;
     padding: 0.3rem 0;
     text-align: center;
     box-sizing: border-box;
    }
   }

   .dialog-table-td:nth-of-type(2n) {
    background-color: rgba(255, 255, 255, 1);
   }

   .dialog-table-td:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
   }
  }

  .redbtn {
   // background: red;
   color: #fff;
   margin: 20px 0 0 0;
   border: none;
   width: 1.4rem;
   height: 0.4rem;
   border-radius: 6px;
  }
 }
}
</style>

<style lang="scss">
.playbackdialogContent {
 .el-dialog__body {
  /deep/ .data-table {
   // 60px headbar
   // 233px search block
   // 100px pagination block
   // max-height: calc(100vh - 60px - 233px - 100px);
   max-height: 80%;
  }
 }
}
</style>
