<template>
 <div id="yogo-topictype" class="eltables" v-loading.fullscreen.lock="listLoading" element-loading-background="rgba(0, 0, 0, 0.8)">
  <div class="toolbar-container">
   <span class="yogo-title toolbar-title">主题类型管理</span>
   <el-button type="primary" class="myicon iconadd" @click="handleAdd"> <i></i>新增类型 </el-button>
   <el-button type="primary" class="myicon iconadd" @click="onClick_exportExcel($event)"> <i></i>导出Excel </el-button>
  </div>

  <el-table :data="list" class="data-table" stripe>
   <el-table-column prop="topicType" label="主题类型名称" align="center" />
   <!-- <el-table-column prop="begin_at" label="生效时间" align="center">
        <template slot-scope="{row}">
          {{ formatTime(row.begin_at, '{y}-{m}-{d}') }}
        </template>
      </el-table-column>
      <el-table-column prop="end_at" label="失效时间" align="center">
        <template slot-scope="{row}">
          {{ formatTime(row.end_at, '{y}-{m}-{d}') }}
        </template>
      </el-table-column> -->
   <el-table-column prop="actions" label="操作" width="120" align="left">
    <template slot-scope="{ row }">
     <el-button type="primary" size="small" class="el-icon-edit" circle @click="handleEdit(row)"></el-button>
     <el-button type="danger" size="small" class="el-icon-delete" circle @click="handleDelete(row)"></el-button>
    </template>
   </el-table-column>
  </el-table>

  <pagination v-show="true" :total="listSize" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

  <delDialog ref="delDialog" :confirm="doDelete" />
  <editDialog ref="editDialog" />
 </div>
</template>

<script>
import delDialog from '@/views//template/delDialog'
import editDialog from '@/views/htgl/topicTypeEdit'
import Pagination from '@/components/Pagination'
import { parseTime } from '@/utils'
import { getTopicTypeList, saveOrUpdateTopicType, deleteTopicType } from '@/api/topictype'

const pageData = [
 // {
 //   "id": 1,
 //   "name": "水帘洞",
 //   // "begin_at": 1571900646,
 //   // "end_at": 1571900646,
 //   // "store": {
 //   //   "id": 1,
 //   //   "name": "花果山店"
 //   // }
 // },
 // {
 //   "id": 2,
 //   "name": "小树林",
 //   // "begin_at": 1571900646,
 //   // "end_at": 1571900646,
 //   // "store": {
 //   //   "id": 1,
 //   //   "name": "花果山店"
 //   // }
 // }
]

export default {
 components: {
  delDialog,
  editDialog,
  Pagination,
 },
 data() {
  return {
   listExcel: null,

   list: null,
   listSize: 0,
   listLoading: true,
   listQuery: {
    page: 1,
    limit: 10,
    importance: undefined,
    title: undefined,
    type: undefined,
    status: undefined,
    sort: '+id',
   },
   selectedItem: null,
  }
 },
 created() {
  this.getList()
 },
 methods: {
  onClick_exportExcel() {
   if (_.isArray(this.listExcel) && !_.isEmpty(this.listExcel)) {
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    let columns = []
    _.entries(this.listExcel[0]).filter((ele, idx, arr) => {
     switch (ele[0]) {
      case 'topicType':
       columns.push({
        prop: ele[0],
        label: '主题类型名称',
       })
       return true

      default:
       return false
     }
    })
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    this.$export.excel({ columns: columns, data: this.listExcel }).then(() => {})
   } else {
    this.$message.error('没有数据可导出')
   }
  },

  formatTime(v, fmt) {
   return parseTime(v, fmt)
  },
  getList() {
   this.listLoading = true

   let param = {
    pageSize: this.listQuery.limit,
    pageNum: this.listQuery.page,
    isPage: true,
   }
   getTopicTypeList(param)
    .then(response => {
     this.list = response.data.rows
     this.listSize = response.data.total
    })
    .then(response => {
     this.getExcelList()
    })
  },
  getExcelList() {
   let param = {
    // pageSize: this.listQuery.limit,
    // pageNum: this.listQuery.page,
    isPage: false,
   }
   getTopicTypeList(param).then(response => {
    this.listExcel = response.data
    this.listLoading = false
   })
  },

  handleAdd() {
   this.$refs['editDialog'].showDialog('新增类型', null, this.doCreate)
  },
  handleEdit(item) {
   const target = {
    id: item.id,
    topicType: item.topicType,
   }
   this.selectedItem = item
   this.$refs['editDialog'].showDialog('修改类型', target, this.doUpdate)
  },
  handleDelete(item) {
   this.selectedItem = item
   this.$refs['delDialog'].showDialog('删除类型', '是否删除当前类型', item, this.doDelete)
  },
  doCreate(data) {
   saveOrUpdateTopicType(data).then(response => {
    this.getList()
   })
  },
  doUpdate(data) {
   saveOrUpdateTopicType(data).then(response => {
    this.getList()
   })
  },
  doDelete(delTgt) {
   deleteTopicType(delTgt.id).then(response => {
    this.getList()
   })
  },
 },
}
</script>

<style lang="less">
@import url(../../statics/css/eltable.css);
</style>
