<template>
 <div class="dialogContent">
  <el-dialog :close-on-click-modal="false" :title="tit" :visible.sync="dialogVisible" @close="closeDialog" class="dialog-wrap" width="4.85rem">
   <el-form size="small">
    <el-row :gutter="14">
     <el-col :span="24">
      <el-form-item label="所属公司">
       <el-select @change="currentSel" placeholder="请选择所属公司" style="width: 100%" v-model="currObj.company" value-key="id">
        <el-option :key="item.id" :label="item.name" :value="item" v-for="item in companies"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="12">
      <el-form-item label="门店名称">
       <el-input placeholder="请输入门店名称" type="text" v-model="currObj.name" />
      </el-form-item>
     </el-col>
     <el-col :span="12">
      <el-form-item label="负责人">
       <el-input placeholder="请输入负责人" type="text" v-model="currObj.manager" />
      </el-form-item>
     </el-col>
     <el-col :span="12">
      <el-form-item label="联系方式1">
       <el-input placeholder="请输入联系方式1" type="text" v-model="currObj.contacts[0]" />
      </el-form-item>
     </el-col>
     <el-col :span="12">
      <el-form-item label="联系方式2">
       <el-input placeholder="请输入联系方式2" type="text" v-model="currObj.contacts[1]" />
      </el-form-item>
     </el-col>
     <template v-if="isAdmin">
      <el-col :span="12">
       <el-form-item label="开通日期">
        <el-date-picker format="yyyy-MM-dd" placeholder="请选择开通日期" style="width: 100%" v-model="currObj.begin_at" value-format="yyyy-MM-dd HH:mm:ss" />
       </el-form-item>
      </el-col>
      <el-col :span="12">
       <el-form-item label="失效日期">
        <el-date-picker format="yyyy-MM-dd" placeholder="请选择失效日期" style="width: 100%" v-model="currObj.end_at" value-format="yyyy-MM-dd HH:mm:ss" />
       </el-form-item>
      </el-col>
     </template>
     <el-col :span="24">
      <el-form-item label="门店地址">
       <el-input placeholder="请输入门店地址" type="text" v-model="currObj.address" />
      </el-form-item>
     </el-col>
     <el-col :span="24">
      <el-form-item label="门店描述">
       <el-input :rows="2" placeholder="请输入描述信息" type="textarea" v-model="currObj.description" />
      </el-form-item>
     </el-col>
     <el-col :span="24">
      <template v-if="isAdmin">
       <el-form-item label="登记类型">
        <el-radio-group v-model="currObj.register_type">
         <el-radio label="0">免费</el-radio>
         <el-radio label="3">3个月</el-radio>
         <el-radio label="12">1年</el-radio>
         <el-radio label="36">3年</el-radio>
        </el-radio-group>
       </el-form-item>
      </template>
     </el-col>
    </el-row>
   </el-form>
   <div class="row rowbuttons">
    <el-button @click="handleConfirm" type="primary">确 定</el-button>
    <el-button @click="closeDialog">取 消</el-button>
   </div>
   <span class="dialog-footer" slot="footer"></span>
  </el-dialog>
 </div>
</template>

<script>
import { getCompanies } from '@/api/company'
export default {
 data() {
  return {
   tit: '',
   dialogVisible: false,
   currObj: {
    company: {},
    contacts: ['', '']
   },
   callback: null,
   companies: [],
   company: {},
   roles: [],
   isAdmin: false
  }
 },
 created() {
  this.roles = this.$store.getters.roles
  if (this.roles.indexOf('管理员') != -1) this.isAdmin = true
  else this.isAdmin = false
  console.log(this.isAdmin)
 },
 methods: {
  currentSel(company) {
   this.currObj.company = {}
   this.currObj.company.id = company.id
   this.currObj.company.name = company.name
  },
  closeDialog() {
   this.dialogVisible = false
   this.callback = null
  },
  cancelDialog() {
   this.dialogVisible = false
   this.callback = null
  },
  showDialog(title = '新增门店', data, callback) {
   this.tit = title
   this.dialogVisible = true
   getCompanies().then(response => {
    this.companies = response.data.rows
   })
   if (data) {
    this.currObj = data
   }
   this.callback = callback
  },
  handleConfirm() {
   if (this.callback) {
    this.callback(this.currObj)
    this.closeDialog()
   }
  }
 }
}
</script>
<style lang="less" scoped>
@import url('../../statics/css/editDialog.css');
</style>
