<template>
 <div id="yogo-gamestyle" class="eltables" v-loading.fullscreen.lock="listLoading" element-loading-background="rgba(0, 0, 0, 0.8)">
  <div class="toolbar-container">
   <span class="yogo-title toolbar-title">游戏风格管理</span>
   <el-button type="primary" class="myicon iconadd" @click="handleAdd"> <i></i>新增风格 </el-button>
   <el-button type="primary" class="myicon iconadd" @click="onClick_exportExcel($event)"> <i></i>导出Excel </el-button>
  </div>

  <el-table :data="list" class="data-table" stripe>
   <el-table-column prop="gameStyle" label="游戏风格名称" align="center" />
   <el-table-column prop="actions" label="操作" width="120" align="center">
    <template slot-scope="{ row }">
     <el-button type="primary" size="small" class="el-icon-edit" circle @click="handleEdit(row)"></el-button>
     <el-button type="danger" size="small" class="el-icon-delete" circle @click="handleDelete(row)"></el-button>
    </template>
   </el-table-column>
  </el-table>

  <pagination v-show="true" :total="listSize" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

  <delDialog ref="delDialog" :confirm="doDelete" />
  <editDialog ref="editDialog" />
 </div>
</template>

<script>
import delDialog from '@/views/template/delDialog'
import editDialog from '@/views/htgl/gameStyleEdit'
import Pagination from '@/components/Pagination'
import { parseTime } from '@/utils'
import { getStyles, createStyle, updateStyle, deleteStyle } from '@/api/game'

export default {
 components: {
  delDialog,
  editDialog,
  Pagination,
 },
 data() {
  return {
   listExcel: null,

   list: null,
   listSize: 0,
   listLoading: true,
   listQuery: {
    page: 1,
    limit: 10,
    importance: undefined,
    title: undefined,
    type: undefined,
    status: undefined,
    sort: '+id',
   },
   selectedItem: null,
  }
 },
 created() {
  this.getList()
 },
 methods: {
  onClick_exportExcel() {
   if (_.isArray(this.listExcel) && !_.isEmpty(this.listExcel)) {
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    let columns = []
    _.entries(this.listExcel[0]).filter((ele, idx, arr) => {
     switch (ele[0]) {
      case 'gameStyle':
       columns.push({
        prop: ele[0],
        label: '游戏风格名称',
       })
       return true

      default:
       return false
     }
    })
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    this.$export.excel({ columns: columns, data: this.listExcel }).then(() => {})
   } else {
    this.$message.error('没有数据可导出')
   }
  },

  formatTime(v, fmt) {
   return parseTime(v, fmt)
  },
  getList() {
   this.listLoading = true

   let param = {
    pageSize: this.listQuery.limit,
    pageNum: this.listQuery.page,
    isPage: true,
   }
   getStyles(param)
    .then(response => {
     this.list = response.data.rows
     this.listSize = response.data.total
    })
    .then(response => {
     this.getExcelList()
    })
  },
  getExcelList() {
   let param = {
    // pageSize: this.listQuery.limit,
    // pageNum: this.listQuery.page,
    isPage: false,
   }
   this.listExcel = null
   getStyles(param).then(response => {
    this.listExcel = response.data
    this.listLoading = false
   })
  },
  handleAdd() {
   this.$refs['editDialog'].showDialog('新增风格', null, this.doCreate)
  },
  handleEdit(item) {
   const target = {
    id: item.id,
    gameStyle: item.gameStyle,
   }
   this.selectedItem = item
   this.$refs['editDialog'].showDialog('修改风格', target, this.doUpdate)
  },
  handleDelete(item) {
   this.selectedItem = item
   this.$refs['delDialog'].showDialog('删除风格', '是否删除当前风格', item, this.doDelete)
  },
  doCreate(data) {
   createStyle(data).then(response => {
    this.getList()
   })
  },
  doUpdate(data) {
   updateStyle(data).then(response => {
    this.getList()
   })
  },
  doDelete(delTgt) {
   deleteStyle(delTgt.id).then(response => {
    this.getList()
   })
  },
 },
}
</script>

<style lang="less">
@import url(../../statics/css/eltable.css);
</style>
