import request from '@/utils/request'

// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
export function getThemeStatisticsById(id) {
 return request({
  url: '/api/themeStatistics/get/' + id,
  method: 'get',
 })
}

export function getThemeStatisticsList(params) {
 return request({
  url: '/api/themeStatistics/list',
  method: 'get',
  params,
 })
}

export function getThemeStatisticsSearch(params) {
 return request({
  url: '/api/themeStatistics/Statistic',
  method: 'get',
  params,
 })
}

export function saveOrUpdateThemeStatistics(data) {
 return request({
  url: '/api/themeStatistics/saveOrUpdate',
  method: 'post',
  data,
 })
}

export function deleteThemeStatisticsById(id) {
 return request({
  url: '/api/themeStatistics/delete/' + id,
  method: 'delete',
 })
}

// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
