<template>
 <div id="yogo-staffManagement" class="eltables" v-loading.fullscreen.lock="listLoading" element-loading-background="rgba(0, 0, 0, 0.8)">
  <div class="toolbar-container">
   <span class="yogo-title toolbar-title">人员管理</span>
   <el-button type="primary" class="myicon iconadd" @click="handleAdd"> <i></i>新增人员 </el-button>
   <el-button type="primary" class="myicon iconadd"> <i></i>导出Excel </el-button>
  </div>
  <el-table :data="list" class="data-table" stripe>
   <el-table-column prop="username" label="姓名" align="center" />
   <el-table-column prop="ssex" label="性别" align="center">
    <template slot-scope="{ row }">
     {{ row.ssex == '1' ? '男' : '女' }}
    </template>
   </el-table-column>
   <el-table-column prop="age" label="年龄" align="center" />
   <el-table-column prop="mobile" label="电话" align="center" />
   <el-table-column prop="email" label="邮箱" align="center" />
   <el-table-column prop="qq" label="QQ" align="center" />
   <el-table-column prop="wechat" label="微信" align="center" />
   <el-table-column prop="stores" label="门店" align="center">
    <template slot-scope="{ row }">
     <li v-for="storeOne in row.stores" :key="storeOne.id">
      {{ storeOne.name }}
     </li>
    </template>
   </el-table-column>
   <el-table-column prop="roles" label="角色" align="center">
    <template slot-scope="{ row }">
     <li v-for="roleOne in row.roles" :key="roleOne.id">
      {{ roleOne.name }}
     </li>
    </template>
   </el-table-column>

   <el-table-column prop="actions" label="操作" width="120" align="left">
    <template slot-scope="{ row }">
     <el-button type="primary" size="small" class="el-icon-edit" circle @click="handleEdit(row)"></el-button>
     <el-button type="danger" size="small" class="el-icon-delete" circle @click="handleDelete(row)"></el-button>
    </template>
   </el-table-column>
  </el-table>
  <pagination v-show="true" :total="listSize" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

  <delDialog ref="DelDialogForm" />
  <editDialog ref="EditDialogForm" />
 </div>
</template>

<script>
import { Notification } from 'element-ui'
import delDialog from '@/views/template/delDialog'
import editDialog from '@/views/dmgl/staffManagementEdit'
import Pagination from '@/components/Pagination'
import { getStaffUserList, createUser, updateUser, deleteUser, deleteUserById } from '@/api/user'

const pageData = [
 {
  id: 1,
  username: 'sunwukong',
  name: '孙悟空',
  gender: 'male',
  age: 3,
  mobile: '13410001234',
  email: '',
  qq: '',
  wechat: '',
  role: {
   id: 1,
   name: '管理员',
  },
  store: {
   id: 1,
   name: '花果山店',
  },
 },
]

export default {
 components: {
  delDialog,
  editDialog,
  Pagination,
 },
 data() {
  return {
   roles: null,
   isAdmin: false,

   // +++++++++++++++++++++++++++++++++++++++
   list: null,
   listSize: 0,
   listLoading: true,
   listQuery: {
    page: 1,
    limit: 10,
    importance: undefined,
    title: undefined,
    type: undefined,
    status: undefined,
    sort: '+id',
   },
   selectedItem: null,
  }
 },
 created() {
  this.getList()

  this.roles = this.$store.getters.roles
  if (this.roles.indexOf('管理员') != -1) this.isAdmin = true
  else this.isAdmin = false
 },
 methods: {
  getList() {
   this.listLoading = true
   let param = {
    pageSize: this.listQuery.limit,
    pageNum: this.listQuery.page,
    isPage: true,
   }
   getStaffUserList(param).then(response => {
    this.list = response.data.rows
    this.listSize = response.data.total
    this.listLoading = false
   })
  },
  handleAdd() {
   this.$refs['EditDialogForm'].showDialog('新增人员', null, this.doCreate)
  },
  handleEdit(item) {
   let isCompanyAdmin_me = this.roles.some((eleRole, idx, arr) => {
    return eleRole == '公司管理员'
   })

   let isCompanyAdmin_toEdit = item.roles.some((eleRole, idx, arr) => {
    return eleRole.name == '公司管理员'
   })

   if (isCompanyAdmin_me && isCompanyAdmin_toEdit) {
    this.$message.error('公司管理员不能编辑其他公司管理员')
    return
   }
   // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
   const target = {
    userId: item.userId,
    username: item.username,
    password: item.password,
    ssex: item.ssex,
    age: item.age,
    mobile: item.mobile,
    email: item.email,
    qq: item.qq,
    wechat: item.wechat,
   }
   if (item.roles) {
    let role_list = new Array()
    item.roles.forEach(role_item => {
     role_list.push(role_item.id)
    })
    target.roleId = role_list
   }
   if (item.stores) {
    let store_list = new Array()
    item.stores.forEach(store_item => {
     store_list.push(store_item.id)
    })
    target.storeId = store_list
   }
   this.$refs['EditDialogForm'].showDialog('修改人员', target, this.doUpdate)
  },
  handleDelete(item) {
   this.selectedItem = item

   let isCompanyAdmin_me = this.roles.some((eleRole, idx, arr) => {
    return eleRole == '公司管理员'
   })

   let isCompanyAdmin_toDel = item.roles.some((eleRole, idx, arr) => {
    return eleRole.name == '公司管理员'
   })

   if (isCompanyAdmin_me && isCompanyAdmin_toDel) {
    this.$message.error('公司管理员不能删除其他公司管理员')
    return
   }

   this.$refs['DelDialogForm'].showDialog('删除人员', '是否删除当前人员', item, this.doDelete)
  },
  doCreate(data) {
   if (typeof data.roleId == 'undefined') {
    data.roleId = []
   }
   data.roleId = data.roleId.toString()
   if (typeof data.storeId == 'undefined') {
    data.storeId = []
   }
   data.storeId = data.storeId.toString()
   console.log(data)
   createUser(data).then(response => {
    // TODO append new record to list
    console.log(response)
    if (response.data.code != 200) {
     Notification.error({
      title: response.data.msg + ' ' + response.data.data,
      duration: 3500,
     })
    }
    this.getList()
   })
  },
  doUpdate(data) {
   data.roleId = data.roleId.toString()
   data.storeId = data.storeId.toString()
   console.log(data)
   updateUser(data).then(response => {
    // TODO update record
    this.getList()
   })
  },
  doDelete(data) {
   deleteUserById(data.userId).then(response => {
    // TODO
    console.log(response)
    if (response.data.code != 200) {
     Notification.error({
      title: response.data.msg + ' ' + response.data.data,
      duration: 3500,
     })
    }
    this.getList()
   })
  },
 },
}
</script>

<style lang="less">
@import url(../../statics/css/eltable.css);
</style>
