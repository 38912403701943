<template>
 <div id="yogo-payMethod" class="eltables" v-loading.fullscreen.lock="listLoading" element-loading-background="rgba(0, 0, 0, 0.8)">
  <div class="toolbar-container duku">
   <div>
    <span class="yogo-title toolbar-title">付款方式管理</span>
    <el-button size="small" class="filter-item add" type="primary" icon="el-icon-plus" @click="handleAdd">新增付款方式</el-button>
   </div>

   <div class="toolbar-box">
    <el-form label-position="top">
     <el-form-item label="门店" class="filter-item">
      <el-select class="item-content" v-model="formModel.storeId" placeholder="" clearable size="small" style="" @change="formModel.themeId = null">
       <el-option v-for="(item, idx) of shopList" :key="idx" :value="item.id" :label="item.name" />
      </el-select>
     </el-form-item>
     <el-form-item label="开始时间" class="filter-item">
      <el-date-picker class="item-content" type="date" clearable size="small" style="" placeholder="" v-model="formModel.startTime" value-format="yyyy-MM-dd HH:mm:ss" />
     </el-form-item>
     <el-form-item label="结束时间" class="filter-item">
      <el-date-picker class="item-content" type="date" clearable size="small" style="" placeholder="" v-model="formModel.endTime" value-format="yyyy-MM-dd HH:mm:ss" />
     </el-form-item>
    </el-form>

    <div class="buttons-div">
     <el-button size="small" class="filter-item" type="primary" icon="el-icon-search" @click="onClick_search($event)">查询</el-button>
     <el-button size="small" class="filter-item" type="primary" icon="el-icon-download" @click="onClick_exportExcel($event)">导出Excel</el-button>
    </div>
   </div>
  </div>

  <el-table :data="list" class="data-table" stripe height="1000">
   <el-table-column prop="channelName" label="付款方式" align="center" />
   <el-table-column prop="rates" label="费率" align="center">
    <template slot-scope="{ row }"
     >{{ Number(row.rates * 100).toFixed(1) }}%
    </template>
   </el-table-column>
   <el-table-column prop="_storeName" label="所属门店" align="center" />

   <el-table-column prop="preIncomeStatus" label="预收方式" align="center">
    <template slot-scope="{ row }">
     {{ _.isNil(row.preIncomeStatus) ? '-' : row.preIncomeStatus == 1 ? '预收' : '非预收' }}
    </template>
   </el-table-column>

   <el-table-column prop="startTime" label="生效时间" align="center" />
   <el-table-column prop="expiredTime" label="失效时间" align="center" />
   <el-table-column prop="actions" label="操作" width="120" align="left">
    <template slot-scope="{ row }">
     <el-button type="primary" size="small" class="el-icon-edit" circle @click="handleEdit(row)"></el-button>
     <el-button type="danger" size="small" class="el-icon-delete" circle @click="handleDelete(row)"></el-button>
    </template>
   </el-table-column>
  </el-table>

  <pagination v-show="true" :total="listSize" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

  <delDialog ref="delDialog" />
  <editDialog ref="editDialog" />
 </div>
</template>

<script>
import delDialog from '@/views/template/delDialog'
import editDialog from '@/views/htgl/payMethodEdit'
import Pagination from '@/components/Pagination'
import { parseTime } from '@/utils'
import { getPayChannelList, getPayChannel, saveOrUpdatePayChannel, deletePayChannel } from '@/api/paychannel'
import { getBusinesses, getShops, getResults } from '@/api/shop'

export default {
 components: {
  delDialog,
  editDialog,
  Pagination,
 },
 data() {
  return {
   listExcel: null,
   shopList: null,

   formModel: {
    storeId: null,
    themeId: null,
    payChannelId: null,
    startTime: null,
    endTime: null,
   },

   list: null,
   listSize: 0,
   listLoading: true,
   listQuery: {
    page: 1,
    limit: 10,
    importance: undefined,
    title: undefined,
    type: undefined,
    status: undefined,
    sort: '+id',
   },
  }
 },

 beforeCreate() {
  Promise.all([getShops()]).then(result => {
   this.shopList = result[0].data.data
   this.getList()
  })
 },

 methods: {
  onClick_exportExcel() {
   if (_.isArray(this.listExcel) && !_.isEmpty(this.listExcel)) {
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    let columns = []
    _.entries(this.listExcel[0]).filter((ele, idx, arr) => {
     switch (ele[0]) {
      case 'channelName':
       columns.push({
        prop: ele[0],
        label: '付款方式',
       })
       return true

      case '_storeName':
       columns.push({
        prop: ele[0],
        label: '所属门店',
       })
       return true

      case 'rates':
       columns.push({
        prop: ele[0],
        label: '费率',
       })
       return true

      case 'startTime':
       columns.push({
        prop: ele[0],
        label: '生效时间',
       })
       return true

      case 'expiredTime':
       columns.push({
        prop: ele[0],
        label: '失效时间',
       })
       return true

      default:
       return false
     }
    })
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    this.$export.excel({ columns: columns, data: this.listExcel }).then(() => {})
   } else {
    this.$message.error('没有数据可导出')
   }
  },

  formatTime(v, fmt) {
   return parseTime(v, fmt)
  },
  onClick_search() {
   this.listQuery.storeId = this.formModel.storeId
   this.listQuery.startTime = this.formModel.startTime
   this.listQuery.endTime = this.formModel.endTime

   this.getList()
  },
  getList() {
   this.listLoading = true

   let param = {
    pageSize: this.listQuery.limit,
    pageNum: this.listQuery.page,
    isPage: true,

    storeId: this.listQuery.storeId,
    startTime: this.listQuery.startTime,
    endTime: this.listQuery.endTime,
   }
   getPayChannelList(param)
    .then(response => {
     response.data.rows.forEach((ele, idx, arr) => {
      ele.startTime = new Date(ele.startTime) == 'Invalid Date' ? null : new Date(ele.startTime).format('yyyy-MM-dd')
      ele.expiredTime = new Date(ele.expiredTime) == 'Invalid Date' ? null : new Date(ele.expiredTime).format('yyyy-MM-dd')

      let foundEle = this.shopList.find((ele_store, idx, arr) => {
       return ele_store.id === ele.storeId
      })
      if (!_.isUndefined(foundEle)) {
       // 找到了!
       ele._storeName = foundEle.name
      }
     })

     this.list = response.data.rows
     this.listSize = response.data.total
    })
    .then(response => {
     this.getExcelList()
    })
  },
  getExcelList() {
   let param = {
    // pageSize: this.listQuery.limit,
    // pageNum: this.listQuery.page,
    isPage: false,
   }
   this.listExcel = null
   getPayChannelList(param).then(response => {
    response.data.forEach((ele, idx, arr) => {
     ele.startTime = new Date(ele.startTime) == 'Invalid Date' ? null : new Date(ele.startTime).format('yyyy-MM-dd')
     ele.expiredTime = new Date(ele.expiredTime) == 'Invalid Date' ? null : new Date(ele.expiredTime).format('yyyy-MM-dd')

     let foundEle = this.shopList.find((ele_store, idx, arr) => {
      return ele_store.id === ele.storeId
     })
     if (!_.isUndefined(foundEle)) {
      // 找到了!
      ele._storeName = foundEle.name
     }
    })

    this.listExcel = response.data
    this.listLoading = false
   })
  },
  handleAdd() {
   this.$refs['editDialog'].showDialog('新增付款方式', null, this.doCreate)
  },
  handleEdit(item) {
   const target = {
    storeId: item.storeId,
    id: item.id,
    channelName: item.channelName,
    preIncomeStatus: item.preIncomeStatus,
    rates: item.rates,
    startTime: !_.isEmpty(item.startTime) ? this.formatTime(item.startTime, '{y}-{m}-{d}') : null,
    expiredTime: !_.isEmpty(item.expiredTime) ? this.formatTime(item.expiredTime, '{y}-{m}-{d}') : null,
   }
   this.$refs['editDialog'].showDialog('修改付款方式', target, this.doUpdate)
  },
  handleDelete(item) {
   this.$refs['delDialog'].showDialog('删除付款方式', '是否删除当前付款方式', item, this.doDelete)
  },
  doCreate(data) {
   data.startTime = data.startTime ? new Date(data.startTime).format('yyyy-MM-dd') : undefined
   data.expiredTime = data.expiredTime ? new Date(data.expiredTime).format('yyyy-MM-dd') : undefined
   saveOrUpdatePayChannel(data).then(response => {
    this.getList()
   })
  },
  doUpdate(data) {
   data.startTime = data.startTime ? new Date(data.startTime).format('yyyy-MM-dd') : undefined
   data.expiredTime = data.expiredTime ? new Date(data.expiredTime).format('yyyy-MM-dd') : undefined
   saveOrUpdatePayChannel(data).then(response => {
    this.getList()
   })
  },
  doDelete(delTgt) {
   deletePayChannel(delTgt.id).then(response => {
    this.getList()
   })
  },
 },
}
</script>

<style lang="less">
@import url(../../statics/css/eltable.css);
</style>

<style lang="scss" scoped>
#yogo-payMethod {
 .data-table {
  // 60px headbar
  // 233px search block
  // 100px pagination block
  max-height: calc(100vh - 60px - 233px - 100px);
 }

 .el-button.add {
  float: right;
  position: relative;
  top: 10px;
  right: 10px;
 }
}
</style>
