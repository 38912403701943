<template>
 <div class="dialogContent">
  <el-dialog :close-on-click-modal="false" :title="tit" :visible.sync="dialogVisible" class="dialog-wrap" width="4.85rem">
   <el-form size="small">
    <el-row :gutter="14">
     <el-col :span="24">
      <el-form-item label="门店">
       <el-select placeholder="请选择" style="width: 100%" v-model="currObj.storeId">
        <el-option :key="index" :label="item.name" :value="item.id" v-for="(item, index) in stores"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="12">
      <el-form-item label="条目类型">
       <el-select placeholder="请选择" style="width: 100%" v-model="currObj.type">
        <el-option :key="index" :label="item.desc" :value="item.type" v-for="(item, index) in entryType"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="12">
      <el-form-item label="条目名称">
       <el-input placeholder="请输入条目名称" type="text" v-model="currObj.name" />
      </el-form-item>
     </el-col>
     <el-col :span="24">
      <el-form-item label="条目金额 单位(元)">
       <el-input placeholder="请输入条目金额 单位(元)" type="text" v-model="currObj.price" />
      </el-form-item>
     </el-col>
     <el-col :span="12">
      <el-form-item label="开通日期">
       <el-date-picker placeholder="请选择开通日期" style="width: 100%" type="date" v-model="currObj.start_time" />
      </el-form-item>
     </el-col>
     <el-col :span="12">
      <el-form-item label="失效日期">
       <el-date-picker placeholder="请选择失效日期" style="width: 100%" type="date" v-model="currObj.expired_time" />
      </el-form-item>
     </el-col>
    </el-row>
   </el-form>
   <div class="row rowbuttons">
    <el-button @click="handleConfirm" type="primary">确 定</el-button>
    <el-button @click="closeDialog">取 消</el-button>
   </div>
  </el-dialog>
 </div>
</template>

<script>
import { getShops } from '@/api/shop'
export default {
 data() {
  return {
   tit: '',
   dialogVisible: false,
   currObj: {},
   callback: null,
   stores: [],
   fenM100: 0,
   entryType: [
    {
     type: 0,
     desc: '赠品',
    },
    {
     type: 1,
     desc: '出错惩罚',
    },
    {
     type: 2,
     desc: '其他收入',
    },
    {
     type: 3,
     desc: '支出',
    },
   ],
  }
 },

 computed: {},
 methods: {
  closeDialog() {
   this.dialogVisible = false
   this.callback = null
  },
  cancelDialog() {
   this.dialogVisible = false
   this.callback = null
  },
  showDialog(title = '新增条目', data, callback) {
   this.tit = title
   this.dialogVisible = true
   getShops().then(response => {
    this.stores = response.data.data
   })
   if (data) {
    this.currObj = data
   } else {
    this.currObj = {}
   }
   this.callback = callback
  },
  handleConfirm() {
   if (this.callback) {
    this.callback(this.currObj)
    this.closeDialog()
   }
  },
 },
}
</script>

<style lang="less">
@import url('../../statics/css/editDialog.css');
</style>
