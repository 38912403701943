<template>
 <div class="yogotheme clearfix">
  <div class="themeheader">
   <h1>密室主题运营</h1>
   <div class="right">
    <input type="text" value="YOGO优果真人密室逃脱(江汉路店)" />
    <i class="btnicon btnimgdown"></i>
   </div>
  </div>
  <!-- 日期部分 -->
  <div class="themedate">
   <div class="themedate_l">
    <img alt src="../../statics/images/日期翻页箭头左@2x.png" />
   </div>
   <div class="themedate_m">
    <div class="mdate">
     <span>07-14(星期日)</span>
    </div>
    <div class="mdate">
     <span>07-15(星期一)</span>
    </div>
    <div class="mdate">
     <span>07-16(星期二)</span>
    </div>
    <div class="mdate active">
     <span>07-17(星期三)</span>
    </div>
    <div class="mdate">
     <span>07-18(星期四)</span>
    </div>
    <div class="mdate">
     <span>07-19(星期五)</span>
    </div>
    <div class="mdate">
     <span>07-20(星期六)</span>
    </div>
   </div>
   <div class="themedate_r">
    <img alt src="../../statics/images/日期翻页箭头右@2x.png" />
   </div>
   <div class="themedate_date">
    <img alt src="../../statics/images/日期ICON.png" />
    <span>日期</span>
   </div>
  </div>

  <div class="themecontainer">
   <div class="tb1">&nbsp;</div>
   <div class="tb2">
    <ul class="themelist">
     <li :key="index" v-for="(item,index) in themelist">
      <p class="title">{{item.title}}</p>
      <img :src="item.imgSrc" />
     </li>
    </ul>
   </div>
   <div class="tb3">
    <div class="timeslist">
     <p>08:010</p>
     <p>08:30</p>
     <p>09:00</p>
     <p>09:30</p>
     <p>10:00</p>
     <p>10:30</p>
     <p>11:00</p>
     <p>11:30</p>
     <p>12:00</p>
     <p>12:30</p>
     <p>13:00</p>
     <p>13:30</p>
     <p>14:00</p>
     <p>14:30</p>
     <p>15:00</p>
     <p>15:30</p>
     <p>16:00</p>
     <p>16:30</p>
     <p>17:00</p>
     <p>17:30</p>
     <p>18:00</p>
     <p>18:30</p>
     <p>19:00</p>
     <p>19:30</p>
     <p>20:00</p>
     <p>20:30</p>
     <p>21:00</p>
     <p>21:30</p>
     <p>22:00</p>
     <p>22:30</p>
     <p>23:00</p>
     <p>23:30</p>
    </div>
   </div>
   <div class="tb4">
    <ul class="themelist">
     <li :key="index" v-for="(item,index) in themelist">
      <div :key="cindex" :style="'top:'+citem.top*61+'px'" class="themeitem" v-for="(citem,cindex) in item.plans">
       <p class="sale">已售 {{citem.sold}}/{{citem.allt}}</p>
       <p class="tip">{{citem.together?"可拼团":"不可拼团"}}</p>
       <div class="progress">
        <!-- :style="'width:'+getStartTime(citem.starttime,citem.endtime)[0]" -->
        <div class="inner">
         <span></span>
        </div>
        <div class="showtxt">{{citem.status}}</div>
       </div>
       <p v-show="citem.status =='已结束'">
        <button class="btn btnover">结 束</button>
        <button @click="openDialog('gift')" class="btn btngive">赠 品</button>
       </p>
       <p v-show="citem.status =='进行中'">
        <button @click="openDialog('ing')" class="btn btning" id="success">进行中</button>
       </p>
       <p v-show="citem.status =='未开场'">
        <button @click="openDialog('reserve')" class="btn btnreserve">预 定</button>
        <button @click="openDialog('start')" class="btn btnbegin">开 始</button>
       </p>
      </div>
     </li>
    </ul>
   </div>
  </div>

  <themegift ref="themeGift"></themegift>
  <themeing ref="themeIng"></themeing>
  <themereserve ref="themeReserve"></themereserve>
  <themestart ref="themeStart"></themestart>
 </div>
</template>

<script>
let ds = new Date()
let de = new Date(ds.getFullYear(), ds.getMonth(), ds.getDate(), ds.getHours() + 1, ds.getMinutes(), ds.getSeconds())

import themegift from './themegift.vue'
import themeing from './themeing.vue'
import themereserve from './themereserve.vue'
import themestart from './themestart.vue'
export default {
 data() {
  return {
   themelist: [
    {
     title: '记忆碎片PLUS',
     imgSrc: require('../../statics/images/nav/1@2x.png'),
     //（8点-8点）* 60分 + 30分 = 30 / 30 = 1
     plans: [
      {
       allt: 10,
       sold: 4,
       starttime: ds,
       endtime: de,
       top: ((ds.getHours() - 8) * 60 + ds.getMinutes()) / 30,
       together: false,
       status: '进行中',
       showValue: '已开场 00:42:00',
      },
      {
       allt: 10,
       sold: 5,
       starttime: '2019-12-29 10:00',
       endtime: '2019-12-29 11:00',
       top: 5,
       together: true,
       status: '已结束',
       showValue: '已结束',
      },
     ],
    },
    {
     title: '记忆碎片PLUS',
     imgSrc: require('../../statics/images/nav/1@2x.png'),
     plans: [
      {
       allt: 10,
       sold: 2,
       starttime: '2019-10-29 11:00',
       endtime: '2019-10-29 12:00',
       top: 7,
       together: false,
       status: '未开场',
       showValue: '未开场',
      },
      {
       allt: 10,
       sold: 7,
       starttime: '2019-10-29 13:00',
       endtime: '2019-10-29 14:00',
       top: 11,
       together: true,
       status: '进行中',
       showValue: '已开场 00:42:00',
      },
     ],
    },
    {
     title: '记忆碎片PLUS',
     imgSrc: require('../../statics/images/nav/1@2x.png'),
     plans: [
      {
       allt: 10,
       sold: 10,
       starttime: '2019-10-29 11:30',
       endtime: '2019-10-29 12:30',
       top: 7,
       together: true,
       status: '未开场',
       showValue: '未开场',
      },
      {
       allt: 10,
       sold: 10,
       starttime: '2019-10-29 14:00',
       endtime: '2019-10-29 15:00',
       top: 12,
       together: true,
       status: '已结束',
       showValue: '已结束',
      },
     ],
    },
    {
     title: '记忆碎片PLUS',
     imgSrc: require('../../statics/images/nav/1@2x.png'),
     plans: [
      {
       allt: 10,
       sold: 10,
       starttime: '2019-10-29 10:00',
       endtime: '2019-10-29 11:00',
       top: 5,
       together: true,
       status: '未开场',
       showValue: '未开场',
      },
      {
       allt: 10,
       sold: 10,
       starttime: '2019-10-29 16:00',
       endtime: '2019-10-29 17:00',
       top: 16,
       together: true,
       status: '已结束',
       showValue: '已结束',
      },
     ],
    },
    {
     title: '记忆碎片PLUS',
     imgSrc: require('../../statics/images/nav/1@2x.png'),
     plans: [
      {
       allt: 10,
       sold: 10,
       starttime: '2019-10-29 08:00',
       endtime: '2019-10-30 09:00',
       top: 0,
       together: true,
       status: '未开场',
       showValue: '未开场',
      },
     ],
    },
    {
     title: '记忆碎片PLUS',
     imgSrc: require('../../statics/images/nav/1@2x.png'),
     //（8点-8点）* 60分 + 30分 = 30 / 30 = 1
     plans: [
      {
       allt: 10,
       sold: 10,
       starttime: '2019-10-29 08:30',
       endtime: '2019-10-29 09:30',
       top: 1,
       together: true,
       status: '已结束',
       showValue: '已结束',
      },
      {
       allt: 10,
       sold: 5,
       starttime: '2019-10-29 10:00',
       endtime: '2019-10-29 11:00',
       top: 5,
       together: true,
       status: '未开场',
       showValue: '未开场',
      },
     ],
    },
    {
     title: '记忆碎片PLUS',
     imgSrc: require('../../statics/images/nav/1@2x.png'),
     plans: [
      {
       allt: 10,
       sold: 10,
       starttime: '2019-10-29 15:00',
       endtime: '2019-10-29 16:00',
       top: 13,
       together: true,
       status: '已结束',
       showValue: '已结束',
      },
      {
       allt: 10,
       sold: 10,
       starttime: '2019-10-29 18:00',
       endtime: '2019-10-29 19:00',
       top: 21,
       together: true,
       status: '未开场',
       showValue: '未开场',
      },
     ],
    },
    {
     title: '记忆碎片PLUS',
     imgSrc: require('../../statics/images/nav/1@2x.png'),
     plans: [
      {
       allt: 10,
       sold: 10,
       starttime: '2019-10-29 11:30',
       endtime: '2019-10-29 12:30',
       top: 7,
       together: true,
       status: '未开场',
       showValue: '未开场',
      },
      {
       allt: 10,
       sold: 10,
       starttime: '2019-10-29 14:00',
       endtime: '2019-10-29 15:00',
       top: 12,
       together: true,
       status: '进行中',
       showValue: '已开场 00:42:00',
      },
     ],
    },
    {
     title: '记忆碎片PLUS',
     imgSrc: require('../../statics/images/nav/1@2x.png'),
     plans: [
      {
       allt: 10,
       sold: 10,
       starttime: '2019-10-29 10:00',
       endtime: '2019-10-29 11:00',
       top: 5,
       together: true,
       status: '未开场',
       showValue: '未开场',
      },
      {
       allt: 10,
       sold: 10,
       starttime: '2019-10-29 16:00',
       endtime: '2019-10-29 17:00',
       top: 16,
       together: true,
       status: '进行中',
       showValue: '已开场 00:42:00',
      },
     ],
    },
    {
     title: '记忆碎片PLUS',
     imgSrc: require('../../statics/images/nav/1@2x.png'),
     plans: [
      {
       allt: 10,
       sold: 10,
       starttime: '2019-10-29 08:00',
       endtime: '2019-10-29 09:00',
       top: 0,
       together: true,
       status: '未开场',
       showValue: '未开场',
      },
     ],
    },
    {
     title: '记忆碎片PLUS',
     imgSrc: require('../../statics/images/nav/1@2x.png'),
     plans: [
      {
       allt: 10,
       sold: 10,
       starttime: '2019-10-29 10:00',
       endtime: '2019-10-29 11:00',
       top: 5,
       together: true,
       status: '未开场',
       showValue: '未开场',
      },
      {
       allt: 10,
       sold: 10,
       starttime: '2019-10-29 16:00',
       endtime: '2019-10-29 17:00',
       top: 16,
       together: true,
       status: '未开场',
       showValue: '未开场',
      },
     ],
    },
   ],
   // 赠品弹窗
   giftFlag: false,
  }
 },
 components: {
  themegift,
  themeing,
  themereserve,
  themestart,
 },
 computed: {},
 methods: {
  calTime(startTime, endTime) {
   startTime = startTime.toString()
   endTime = endTime.toString()
   if (!startTime) return
   var param = {}
   var status = status || 'h'
   var beginTime = Date.parse(startTime.replace(/-/g, '/'))
   var startTime = new Date(beginTime)
   var nowTime = endTime == null ? new Date() : new Date(endTime)

   /*****相差秒数*****/
   var subTime = Math.floor((nowTime.getTime() - startTime.getTime()) / 1000)
   param.s = subTime

   /*****相差分钟*****/
   var cal = Math.floor(subTime / 60)
   param.m = cal

   var minute = (cal % 60 < 10 ? '0' + (cal % 60) : +cal % 60) + ''
   var leftTime

   if (cal > 60) {
    leftTime = Math.floor(cal / 60 < 10 ? '0' + cal / 60 : cal / 60 + '')
   } else {
    leftTime = '00'
   }

   param.time = leftTime + ':' + minute.charAt(0) + minute.charAt(1)
   return param
  },
  getStartTime(startt, endt) {
   startt = Date.parse(startt)
   endt = Date.parse(endt)
   let currDate = ds

   //2019-10-29 18:00 < 2019-10-30 09:00   && 2019-10-29 19:00 < 2019-10-30 09:00
   //开始时间<=当前时间   && 结束时间<=当前时间  表示已结束
   if (startt <= currDate && endt <= currDate) {
    return ['0%', '已结束']
   }
   //2019-10-31 00:45  > 2019-10-31 00:30 && 2019-10-31 01:30< 2019-10-31 00:30
   // 开始时间 >当前时间  && 结束时间>当前时间   表示进行中
   else if (startt >= currDate && endt > currDate) {
    let count = this.calTime(startt, currDate)
    let sum = this.calTime(startt, endt)
    let percentage = count / sum
    return [percentage + '%', '已开场' + count]
   }
   // 2019-11-01 19:00> 2019-10-30 09:00
   // 结束时间 >= 当前时间
   else if (endt > currDate) {
    return ['0%', '未开场']
   }
  },
  openDialog(type) {
   if (type == 'gift') {
    this.$refs['themeGift'].showDialog(true)
   } else if (type == 'ing') {
    this.$refs['themeIng'].showDialog(true)
   } else if (type == 'reserve') {
    this.$refs['themeReserve'].showDialog(true)
   } else if (type == 'start') {
    this.$refs['themeStart'].showDialog(true)
   }
  },
 },
}
</script>

<style lang="less">
body {
 padding: 0 !important;
}

// 统一设置滚动条样式
.themecontainer::-webkit-scrollbar-track {
 -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
 background-color: #1c2135;
}

// 滚动条整体部分
.themecontainer::-webkit-scrollbar {
 width: 15px;
 // background-color:red;// #1c2135
}

// 滚动条里面的小方块
.themecontainer::-webkit-scrollbar-thumb {
 background-color: #222840; //#1c2135;
}
</style><style lang="less" scoped>
.clearfix:after {
 content: '';
 display: block;
 clear: both;
}

.yogotheme {
 padding: 23px 19px;
 background-color: #101529;

 //头部
 .themeheader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.2rem;

  h1 {
   // font-size: .24rem;
   font-size: 0.15rem;
   color: #fff;
  }

  .right {
   position: relative;
   width: 2.1rem;

   input {
    width: 2.1rem;
    font-size: 0.11rem;
    color: #b1b1b1;
    background-color: #282e43;
    border: 1px solid #ee9911;
    border-radius: 0.06rem;
    height: 0.3rem;
    text-indent: 4px;
    padding-left: 0;
   }

   .btnicon {
    position: absolute;
    right: 0px;
    height: 0.29rem;
    width: 0.3rem;
    border-left: 1px solid #ee9911;
    background: url(../../statics/images/icondown.png) center center no-repeat;
   }

   .btnimgdown {
    background-image: url(../../statics/images/icondown.png);
    background-size: 0.12rem 0.08rem;
   }
  }
 }

 //日期部分
 .themedate {
  height: 0.7rem;
  border-radius: 0.06rem;
  margin-bottom: 0.2rem;
  background-color: #1c2034;
  display: flex;
  cursor: pointer;

  .themedate_l,
  .themedate_r {
   width: 8%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   border-right: 1px solid rgba(52, 67, 94, 1);
   font-size: 22.6px;
   color: #acadad;
  }

  .themedate_m {
   flex: 1;
   display: flex;
   color: #b1b1b1;
   font-size: 16px;

   .mdate {
    flex: 1;
    border-right: 1px solid #2b364e;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.active {
     background-color: #55eedd;
     color: #1c2034;
    }
   }
  }

  .themedate_date {
   height: 100%;
   width: 8.7%;
   font-size: 16px;
   color: #8b8c91;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   img {
    width: 19px;
   }
  }
 }

 //主题内容
 .themecontainer {
  // border: 1px solid #cdd;
  width: 100%;
  height: 8.4rem;
  overflow: scroll;
  position: relative;
  float: left;
  top: 0;
  left: 0;
  margin-bottom: 10px;

  .tb1 {
   border: solid 1px rgb(213, 226, 21);
   background-color: #101529;
   position: fixed;
   z-index: 10001;
   width: 110px;
   height: 200px;
  }

  .tb2 {
   border: solid 1px red;
   background-color: #101529;
   position: sticky;
   top: 0px;
   margin-left: 110px;
   padding: 20px;
   z-index: 1000;
   width: 100%;
  }

  .tb3 {
   border: solid 1px red;
   background-color: #101529;
   left: 0px;
   height: 1960px;
   float: left;
   position: sticky;
   z-index: 1000;
   width: 110px;

   .timeslist {
    padding: 0 20px;

    > p {
     margin-bottom: 49px;
     font-size: 14px;
     font-weight: 400;
     color: rgba(255, 255, 255, 1);
     height: 12px;
     vertical-align: top;

     &:nth-last-of-type(1) {
      margin-bottom: 0;
     }
    }
   }
  }

  .tb4 {
   left: 110px;
   width: 100%;
   height: 1960px;
   position: absolute;
   background: mistyrose;
   padding: 20px;
   background: url('../../statics/images/timeline.png') repeat;

   .themeitem {
    position: absolute;
    width: 120px;
    background: rgba(45, 51, 74, 1);
    box-shadow: 1px 1px 4px 0px rgba(4, 0, 0, 0.16);
    border-radius: 0.06rem;
    padding: 9px 0 9px 0;
    text-align: center;
    font-size: 14px;
    margin-bottom: 10px;

    .sale {
     color: rgba(34, 147, 118, 1);
    }

    .tip {
     color: rgba(119, 136, 170, 1);
    }

    p {
     margin-bottom: 9px;
    }

    .progress {
     width: 101px;
     height: 16px;
     box-shadow: 1px 1px 1px 0 #060614;
     border-radius: 7px;
     background: #1f2438;
     margin: 0 auto;
     margin-bottom: 9px;
     overflow: hidden;
     position: relative;

     .inner {
      //  border-radius: 7px;
      //  width: 20%;
      height: 16px;
      // background: #fff;
      position: relative;

      span {
       border-radius: 50%;
       height: 16px;
       width: 16px;
       position: absolute;
       left: 0;
       display: inline-block;
       background: #fff;
      }
     }

     .showtxt {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 9;
      top: 50%;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      opacity: 0.7;
     }
    }

    .btn {
     width: 46px;
     height: 24px;
     background: rgba(136, 136, 136, 1);
     border-radius: 4px;
     color: #fff;
     font-size: 12px;
     font-weight: 400;
     margin: 5px 5px 0 5px;

     // &.btnover {
     //     background: rgba(136, 136, 136, 1);
     // }
     &.btngive {
      background: rgba(238, 102, 34, 1);
     }

     &.btning {
      width: 70px;
      height: 24px;
      background: rgba(34, 172, 56, 1);
      border-radius: 4px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
     }

     &.btnreserve {
      width: 46px;
      height: 24px;
      background: rgba(85, 102, 170, 1);
      border-radius: 4px;
     }

     &.btnbegin {
      width: 46px;
      height: 24px;
      background: rgba(255, 68, 68, 1);
      border-radius: 4px;
     }
    }
   }
  }

  //tb2 和 tb4 公用
  .themelist {
   display: flex;
   background-color: #101529;

   // overflow: hidden;
   li {
    width: 120px;
    font-size: 14px;
    margin-right: 20px;

    .title {
     border-top-left-radius: 0.06rem;
     border-top-right-radius: 0.06rem;
     height: 0.4rem;
     font-size: 0.12rem;
     display: flex;
     justify-content: center;
     align-items: center;
     background-color: #364064;
    }

    img {
     width: 120px;
    }
   }
  }
 }
}
</style>
