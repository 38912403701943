<template>
 <div id="yogo-home">
  <div class="yogo-header">
   <div class="yogo-header-l">
    <img alt class="yogologo" src="../statics/images/yogo_logo.png" />
    实景娱乐管理平台
   </div>
   <div class="yogo-header-m">
    <div class="yogo-header-m-l">
     <!-- <el-breadcrumb separator-class="el-icon-arrow-right"> -->
     <el-breadcrumb separator=" ">
      <!-- <el-breadcrumb-item :to="{ path: '/' }" class="navfont">{{ navRoute.pRouteName }}</el-breadcrumb-item> -->
      <el-breadcrumb-item :to="{ path: '/yygl/theme' }">
       <span style="font-weight:normal; cursor:pointer; color:black">
        运营首页
       </span>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/htgl/topicInfo' }">
       <span style="font-weight:normal; cursor:pointer; color:black">
        主题管理
       </span>
      </el-breadcrumb-item>
      <!-- <!-- <el-breadcrumb-item :to="{ path: '/xxcx/themePlayback' }">主题回放</el-breadcrumb-item> -->
      <el-breadcrumb-item :to="{ path: '/jygl/roomBusiness' }">
       <span style="font-weight:normal; cursor:pointer; color:black">
        经营状况
       </span>
      </el-breadcrumb-item>
     </el-breadcrumb>
     <span class="navfont">{{ navRoute.cRouteName }}</span>
    </div>
    <div class="yogo-header-m-r">
     <div class="yogo-user-l">
      <div class="user">
       <img alt class="mine" src="../statics/images/我的@2x.png" />
       <span style="email">{{ user.username }}</span>
       <img alt src="../statics/images/头像下拉ICON@2x.png" />
       <!-- 移入的子页面 -->
       <div class="plain1 plain">
        <div class="plain-top">
         <p class="email">{{ user.username }}</p>
         <span @click="handleLogout" class="admin">{{ roles[0] }}</span>
        </div>
        <div class="plain-bottom">
         <img alt src="../statics/images/退出登录@2x.png" />
         <span @click="handleLogout">退出登陆</span>
        </div>
       </div>
      </div>
      <div class="notic">
       <i class="num1">99</i>
       <img alt src="../statics/images/通知信息@2x.png" />
      </div>
      <div class="now">
       <i class="num1 selected">!</i>
       <img alt src="../statics/images/到期@2x.png" />
       <div class="plain1 plain">
        <div class="plain-top">
         <p class="subtitle">所属门店</p>
         <p class="admin">YOGO优果真人密室逃脱(江汉路店)</p>
        </div>
        <div class="plain-bottom">
         <div class="plain-bottom-left">
          <p class="subtitle">门店到期时间</p>
          <p class="expiration-time">2019-08-05</p>
         </div>
         <!-- 充值点击 -->
         <el-button type="text">
          <div @click="recharge = true" class="plain-bottom-right">
           <i>充 &nbsp;值</i>
          </div>
         </el-button>
        </div>
       </div>
       <!-- 充值弹窗 -->
       <el-dialog :close-on-click-modal="false" :visible.sync="recharge" center class="congzhi" title="续费充值">
        <span class="title">门店续费</span>
        <div class="cTimes">
         <div class="oneYear colorActive">
          <div class="topRight">
           <p></p>
          </div>
          <p>一年期</p>
          <p><span>5000</span>元</p>
         </div>
         <div class="threeYear">
          <div class="topRight">
           <p></p>
          </div>
          <p>三年期</p>
          <p><span>10800</span>元</p>
         </div>
        </div>
        <div class="payWay">支付方式</div>
        <div class="payBox">
         <p>
          <input checked name="pay" type="radio" />
          <span>微信支付</span>
         </p>
         <p>
          <input name="pay" type="radio" />
          <span>支付宝支付</span>
         </p>
         <p>
          <input name="pay" type="radio" />
          <span>银行卡支付</span>
         </p>
        </div>
        <!-- handelProtocol -->
        <div @click="protocol = true" class="agreement">《YOGO优果VIP网络协议》</div>
        <span class="dialog-footer" slot="footer">
         <el-button @click="chongzhiConfim" class="confime" type="primary">确 定</el-button>
         <el-button @click="recharge = false">取 消</el-button>
        </span>
       </el-dialog>
       <!-- 网络协议弹窗 -->
       <el-dialog :close-on-click-modal="false" :visible.sync="protocol" center class="congzhi protocol" title="YOGO优果VIP网络协议">
        <div class="content">
         版本生效日期：2018年12月28日
         <br />尊敬的用户： <br />欢迎您使用YOGO优果收费服务。YOGO优果提供的网络服务采用收费与免费结合的方式。您须先成为YOGO优果普通注册用户之后，才能继续进行收费服务的购买服务。通过购买程序支付费用后，您就可以轻松享受YOGO优果VIP会员服务。在开始购买程序之前，请您首先同意并接受以下服务协议条款。
         <br />【注意】YOGO优果提醒您，请仔细阅读以下全部内容，特别以黑体加粗或其他合理方式提示您注意，并请您重点阅读的条款。未成年人应在法定监护人陪同下审阅和履行，未成年人行使和履行本协议项下的权利和义务视为已获得法定监护人的认可。如果您不同意本协议的任一或全部条款内容，请不要以确认的形式（包括但不限于：点击、进入购买程序等）进行下一步操作或使用本协议项下服务。当您确认接受本协议或开始使用本协议项下YOGO优果服务时，即表示您与YOGO优果（平台运营主体：YOGO优果信息技术（北京）有限公司，以下简称“YOGO优果”或“服务主体”】已达成协议关系，并自愿接受并遵守YOGO优果提供服务涉及的包括本协议在内的所有服务规范及相关子协议、管理规范，包括但不限于YOGO优果不时发布并修订的关于YOGO优果VIP会员的守则、活动规则、使用规则、公告、提示、通知及在线FAQ等内容。
         YOGO优果有权就本协议及相关子协议、管理规范、权益说明进行更新，更新会通过系统提示、信息推送和／或您在YOGO优果留下的联系方式通知和提醒您注意，更新后依法生效。您的权利以及义务的表述、服务内容及收费标准，均以最新的服务协议为准。如您继续使用服务，即意味着您同意接受我方更新后的服务协议。如您不同意变更后的服务协议，请您停止使用本产品和服务。
        </div>
       </el-dialog>
       <!-- 扫码弹窗 -->
       <el-dialog :close-on-click-modal="false" :visible.sync="dCode" center class="congzhi dCodeClass" title="续费充值">
        <div class="saomaTitle">扫码支付</div>
        <div class="dCodeImg">
         <img alt class="codePic" src="../statics/images/图层635拷贝 5@2x.png" />
        </div>
        <div class="whichDcode">使用微信扫描二维码进行支付</div>
        <span class="dialog-footer" slot="footer">
         <el-button @click="dCode = false" class="confime" type="primary">上一步</el-button>
        </span>
       </el-dialog>
      </div>
     </div>
    </div>
   </div>
  </div>
  <div class="yogo-wrap">
   <div class="yogo-l-tarbar">
    <el-row class="tac">
     <el-col>
      <!-- :default-openeds="['1','2','3','4','5','6']" -->
      <el-menu @close="handleClose" @open="handleOpen" @select="openPage" active-text-color="#FFFFFF" background-color="#1c2135" class="el-menu-vertical-demo" default-active="1" text-color="#555E86">
       <el-submenu :index="item.name" :key="item.name" v-for="item in submenuList">
        <template slot="title">
         <span>
          <i :class="item.icon" class="titicon"></i>
          {{ item.name }}
         </span>
        </template>
        <router-link :key="citem.name" :to="citem.path" v-for="citem in item.children">
         <el-menu-item :index="citem.name">
          <i :class="citem.icon"></i>
          {{ citem.name }}
         </el-menu-item>
        </router-link>
       </el-submenu>
      </el-menu>
     </el-col>
    </el-row>
   </div>
   <div class="yogo-r-main">
    <router-view></router-view>
   </div>
  </div>
 </div>
</template>
<script>
import demoData from './yygl/themedukudata2.js'

export default {
 data() {
  return {
   navRoute: { pRouteName: '', cRouteName: '' },
   defaultActArr: new Set(['运营管理']),
   // 充值弹窗
   recharge: false,
   // 网络协议弹窗
   protocol: false,
   // 扫码弹窗
   dCode: false,
   submenuList: [],
   user: null,
   roles: [],
  }
 },
 created() {
  this.user = this.$store.getters.user
  this.roles = this.$store.getters.roles
 },

 mounted: function() {
  this.submenuList = this.$store.getters.user_routes

  this.$store
   .dispatch('user_detail/set_demo_data', demoData)
   .then(() => {})
   .catch(() => {})
 },

 methods: {
  handleLogout() {
   this.$store
    .dispatch('user_detail/logout')
    .then(() => {
     this.$router.push('/login')
     this.loading = false
    })
    .catch(() => {
     this.loading = false
    })
  },

  handleOpen(key, keyPath) {
   //  ;
  },
  handleClose(key, keyPath) {
   //  ;
  },
  // // 网络协议点击
  // handelProtocol() {
  //   this.protocol = true;
  //   this.recharge = false;
  // },
  // // watch: {
  // //     $route:function(newVal,oldVal){
  // //
  // //       }

  // //  },
  // // @select="openPage"
  openPage(key, keypath, temp, test) {
   //   alert(db)
   //     this.submenuList = this.$db.get('USER_ROUTER')
   //     this.defaultActArr.add(temp.$route.matched[0].name)
   //     this.navRoute={pRouteName:keypath[0],cRouteName:keypath[1]}
  }, // 充值确定
  chongzhiConfim() {
   ;(this.dCode = true), (this.L0recharge = false)
  },
 },
}
</script>
<style scoped>
@import url('../statics/css/homePage.css');
</style>

<style lang="scss" scoped>
.yogo-header-m-l {
 .el-breadcrumb {
  font-size: 16px;
 }
}
</style>
