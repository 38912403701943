<template>
 <div id="yogo-roomBusiness" class="eltables" v-loading.fullscreen.lock="listLoading" element-loading-background="rgba(0, 0, 0, 0.8)">
  <div class="toolbar-container duku">
   <div>
    <span class="yogo-title toolbar-title">密室经营状况</span>
   </div>
   <div class="toolbar-box">
    <el-form label-position="top">
     <el-form-item label="门店" class="filter-item">
      <el-select class="item-content" v-model="formModel.storeId" placeholder="" clearable size="small" style="" @change="formModel.themeId = null">
       <el-option v-for="(item, idx) of shopList" :key="idx" :value="item.id" :label="item.name" />
      </el-select>
     </el-form-item>
     <el-form-item label="主题" class="filter-item">
      <el-select class="item-content" v-model="formModel.themeId" placeholder="" clearable size="small" style="">
       <el-option v-for="(item, idx) of filteredThemeList" :key="idx" :value="item.id" :label="item.themeName" />
      </el-select>
     </el-form-item>
     <el-form-item label="付款方式" class="filter-item">
      <el-select class="item-content" v-model="formModel.payChannelId" placeholder="" clearable size="small" style="">
       <el-option v-for="(item, idx) of payChannelList" :key="idx" :value="item.id" :label="item.channelName" />
      </el-select>
     </el-form-item>
     <el-form-item label="开始时间" class="filter-item">
      <el-date-picker class="item-content" type="date" clearable size="small" style="" placeholder="" v-model="formModel.startTime" value-format="yyyy-MM-dd HH:mm:ss" />
     </el-form-item>
     <el-form-item label="结束时间" class="filter-item">
      <el-date-picker class="item-content" type="date" clearable size="small" style="" placeholder="" v-model="formModel.endTime" value-format="yyyy-MM-dd HH:mm:ss" />
     </el-form-item>
    </el-form>

    <div>
     <el-button size="small" class="filter-item" type="primary" icon="el-icon-search" @click="onClick_search($event)">查询 </el-button>
     <el-button size="small" class="filter-item" type="primary" icon="el-icon-download" @click="onClick_exportExcel($event)">导出Excel</el-button>
    </div>

    <p style="margin-top: 5px; text-align: right; color: white">
     收入总金额：¥ {{ Number(totalIncome).toFixed(2) }}， 实收总金额：¥
     {{ Number(totalActual).toFixed(2) }}
    </p>
   </div>
  </div>

  <el-table :data="list" class="data-table" stripe height="1000">
   <el-table-column label="所属门店" prop="_storeName" align="center" />
   <el-table-column label="主题名称" prop="_themeName" align="center" />
   <el-table-column label="入账/出账时间" prop="inAccountTime" align="center" />
   <el-table-column label="排场开始时间" prop="_scheduleStartTime" align="center" />
   <el-table-column label="支付方式" prop="_channelName" align="center" />
   <el-table-column label="收入金额" prop="_money" align="center" />
   <el-table-column label="手续费" prop="_poundage" align="center" />
   <el-table-column label="实收金额" prop="_actual" align="center" />
  </el-table>

  <pagination class="pagination" v-show="true" :total="listSize" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
 </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { getBusinesses, getShops, getResults } from '@/api/shop'
import { deleteTheme, getTheme, getThemeList, saveOrUpdateTheme, uploadThemeImage, getPlaybacks } from '@/api/theme/theme'
import { getPayChannelList, getPayChannel, saveOrUpdatePayChannel, deletePayChannel } from '@/api/paychannel'

export default {
 components: {
  Pagination,
 },
 data() {
  let today = new Date(new Date().format('yyyy-MM-dd'))
  let tommorrow = new Date(new Date().format('yyyy-MM-dd'))
  tommorrow.setDate(tommorrow.getDate() + 1)

  return {
   listExcel: null,

   shopList: null,
   themeList: null,
   payChannelList: null,
   formModel: {
    storeId: null,
    themeId: null,
    payChannelId: null,
    startTime: today.format('yyyy-MM-dd HH:mm:ss'),
    endTime: tommorrow.format('yyyy-MM-dd HH:mm:ss'),
   },

   // +++++++++++++++++++++++++++++++++++++++
   totalIncome: 0,
   totalActual: 0,
   list: null,
   listSize: 0,
   listLoading: true,
   listQuery: {
    page: 1,
    limit: 10,
   },
  }
 },
 computed: {
  filteredThemeList() {
   if (!_.isEmpty(this.themeList)) {
    let clone_themeList = _.cloneDeep(this.themeList)
    if (_.isInteger(this.formModel.storeId) && this.formModel.storeId > 0) {
     return clone_themeList.filter((ele_theme, idx, arr) => {
      return ele_theme.storeId === this.formModel.storeId
     })
    } else {
     return clone_themeList
    }
   } else {
    return null
   }
  },
 },
 beforeCreate() {
  Promise.all([getShops(), getThemeList(), getPayChannelList()]).then(result => {
   this.shopList = result[0].data.data
   this.themeList = result[1].data
   this.payChannelList = result[2].data

   this.getList()
  })
 },
 methods: {
  onClick_exportExcel() {
   if (_.isArray(this.listExcel) && !_.isEmpty(this.listExcel)) {
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    let columns = []
    _.entries(this.listExcel[0]).filter((ele, idx, arr) => {
     switch (ele[0]) {
      case '_storeName':
       columns.push({
        prop: ele[0],
        label: '所属门店',
       })
       return true

      case '_themeName':
       columns.push({
        prop: ele[0],
        label: '主题名称',
       })
       return true

      case 'inAccountTime':
       columns.push({
        prop: ele[0],
        label: '入账/出账时间',
       })
       return true

      case '_scheduleStartTime':
       columns.push({
        prop: ele[0],
        label: '排场时间',
       })
       return true

      case '_channelName':
       columns.push({
        prop: ele[0],
        label: '支付方式',
       })
       return true

      case '_money':
       columns.push({
        prop: ele[0],
        label: '收入金额',
       })
       return true

      case '_poundage':
       columns.push({
        prop: ele[0],
        label: '手续费',
       })
       return true

      case '_actual':
       columns.push({
        prop: ele[0],
        label: '实收金额',
       })
       return true

      default:
       return false
     }
    })
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    this.$export
     .excel({
      columns: columns,
      data: this.listExcel,
     })
     .then(() => {})
   } else {
    this.$message.error('没有数据可导出')
   }
  },
  onClick_search() {
   this.listQuery.storeId = this.formModel.storeId
   this.listQuery.themeId = this.formModel.themeId
   this.listQuery.payChannelId = this.formModel.payChannelId
   this.listQuery.startTime = this.formModel.startTime
   this.listQuery.endTime = this.formModel.endTime

   this.getList()
  },
  getList() {
   this.listLoading = true

   let param = {
    pageSize: this.listQuery.limit,
    pageNum: this.listQuery.page,
    isPage: true,

    storeId: this.listQuery.storeId,
    themeId: this.listQuery.themeId,
    payChannelId: this.listQuery.payChannelId,
    startTime: this.listQuery.startTime,
    endTime: this.listQuery.endTime,

    timeKey: 'inAccountTime', // /inAccountTimesessionStartDay
    orderSql: 'inAccountTime desc', //asc/desc
   }

   if (_.isNil(param.startTime)) {
    param.startTime = new Date('2020-01-01 00:00:00').format('yyyy-MM-dd HH:mm:ss')
   }
   if (_.isNil(param.endTime)) {
    param.endTime = new Date('2030-01-01 00:00:00').format('yyyy-MM-dd HH:mm:ss')
   }

   getBusinesses(param)
    .then(response => {
     response.data.rows.forEach((eleBussiness, idx, arr) => {
      // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
      {
       let foundStore = this.shopList.find((eleStore, idx, arr) => {
        return eleStore.id === eleBussiness.storeId
       })
       if (!_.isUndefined(foundStore)) {
        // 找到了!
        eleBussiness._storeName = foundStore.name
       } else {
        eleBussiness._storeName = '-'
       }
      }

      // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
      {
       eleBussiness._scheduleStartTime = eleBussiness.sessionStartDay + ' ' + eleBussiness.scheduleStartTime + ':00'
      }

      // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
      {
       let foundEle = this.themeList.find((ele_theme, idx, arr) => {
        return ele_theme.id == eleBussiness.themeId
       })
       if (!_.isUndefined(foundEle)) {
        // 找到了!
        eleBussiness._themeName = foundEle.themeName
       }
      }

      // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
      {
       // +++++++++++++++++++++++++++++++++++++++
       let str_payChannelId = eleBussiness.payChannelId + ''
       let arr_payChannelId = str_payChannelId
        .split(',')
        .filter((ele, idx, arr) => {
         return !_.isEmpty(ele)
        })
        .map((ele, idx, arr) => {
         return parseInt(ele)
        })
       // +++++++++++++++++++++++++++++++++++++++
       let str_money = eleBussiness.money + ''
       let arr_money = str_money
        .split(',')
        .filter((ele, idx, arr) => {
         return !_.isEmpty(ele)
        })
        .map((ele, idx, arr) => {
         return parseFloat(ele)
        })
       // +++++++++++++++++++++++++++++++++++++++
       let str_payChannelRate = eleBussiness.payChannelRate + ''
       let arr_payChannelRate = str_payChannelRate
        .split(',')
        .filter((ele, idx, arr) => {
         return !_.isEmpty(ele)
        })
        .map((ele, idx, arr) => {
         return parseFloat(ele)
        })
       // +++++++++++++++++++++++++++++++++++++++

       eleBussiness._channelName = ''
       eleBussiness._poundage = 0

       arr_payChannelId.forEach((eleStr_payChannelId, idx, arr) => {
        let found_payChannel = this.payChannelList.find((ele_payChannel, idx, arr) => {
         return ele_payChannel.id == eleStr_payChannelId
        })

        if (!_.isUndefined(found_payChannel)) {
         // 找到了!
         eleBussiness._channelName += found_payChannel.channelName
        } else {
         eleBussiness._channelName += '-'
        }
        eleBussiness._channelName += ','

        if (!_.isNil(arr_payChannelRate[idx]) && !_.isNaN(arr_payChannelRate[idx])) {
         eleBussiness._poundage += arr_money[idx] * arr_payChannelRate[idx]
        }
       })

       eleBussiness._channelName = eleBussiness._channelName.slice(0, eleBussiness._channelName.length - 1)
       eleBussiness._money = arr_money
        .reduce(function(accumulator, currentValue, currentIndex, array) {
         return accumulator + currentValue
        }, 0)
        .toFixed(2)
       eleBussiness._poundage = eleBussiness._poundage.toFixed(2)
       eleBussiness._actual = (eleBussiness._money - eleBussiness._poundage).toFixed(2)

       if (eleBussiness.inAccountStatus == 2 && _.isNil(eleBussiness.nextId)) {
        eleBussiness._money = (-eleBussiness._money).toFixed(2)
        eleBussiness._poundage = (-eleBussiness._poundage).toFixed(2)
        eleBussiness._actual = (-eleBussiness._actual).toFixed(2)
       }
      }
     })

     this.list = response.data.rows
     this.listSize = response.data.total
    })
    .then(response => {
     this.getExcelList()
    })
  },
  getExcelList() {
   let param = {
    // pageSize: this.listQuery.limit,
    // pageNum: this.listQuery.page,
    isPage: false,

    storeId: this.listQuery.storeId,
    themeId: this.listQuery.themeId,
    payChannelId: this.listQuery.payChannelId,
    startTime: this.listQuery.startTime,
    endTime: this.listQuery.endTime,

    timeKey: 'inAccountTime', // /inAccountTimesessionStartDay
    orderSql: 'inAccountTime desc', //asc/desc
   }

   if (_.isNil(param.startTime)) {
    param.startTime = new Date('2020-01-01 00:00:00').format('yyyy-MM-dd HH:mm:ss')
   }
   if (_.isNil(param.endTime)) {
    param.endTime = new Date('2030-01-01 00:00:00').format('yyyy-MM-dd HH:mm:ss')
   }

   this.listExcel = null
   getBusinesses(param).then(response => {
    response.data.forEach((eleBussiness, idx, arr) => {
     // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
     {
      let foundStore = this.shopList.find((eleStore, idx, arr) => {
       return eleStore.id === eleBussiness.storeId
      })
      if (!_.isUndefined(foundStore)) {
       // 找到了!
       eleBussiness._storeName = foundStore.name
      } else {
       eleBussiness._storeName = '-'
      }
     }

     // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
     {
      eleBussiness._scheduleStartTime = eleBussiness.sessionStartDay + ' ' + eleBussiness.scheduleStartTime + ':00'
     }

     // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
     {
      let foundEle = this.themeList.find((ele_theme, idx, arr) => {
       return ele_theme.id === eleBussiness.themeId
      })
      if (!_.isUndefined(foundEle)) {
       // 找到了!
       eleBussiness._themeName = foundEle.themeName
      }
     }

     // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
     {
      // +++++++++++++++++++++++++++++++++++++++
      let str_payChannelId = eleBussiness.payChannelId + ''
      let arr_payChannelId = str_payChannelId
       .split(',')
       .filter((ele, idx, arr) => {
        return !_.isEmpty(ele)
       })
       .map((ele, idx, arr) => {
        return parseInt(ele)
       })
      // +++++++++++++++++++++++++++++++++++++++
      let str_money = eleBussiness.money + ''
      let arr_money = str_money
       .split(',')
       .filter((ele, idx, arr) => {
        return !_.isEmpty(ele)
       })
       .map((ele, idx, arr) => {
        return parseFloat(ele)
       })
      // +++++++++++++++++++++++++++++++++++++++
      let str_payChannelRate = eleBussiness.payChannelRate + ''
      let arr_payChannelRate = str_payChannelRate
       .split(',')
       .filter((ele, idx, arr) => {
        return !_.isEmpty(ele)
       })
       .map((ele, idx, arr) => {
        return parseFloat(ele)
       })
      // +++++++++++++++++++++++++++++++++++++++

      eleBussiness._channelName = ''
      eleBussiness._poundage = 0

      arr_payChannelId.forEach((eleStr_payChannelId, idx, arr) => {
       let found_payChannel = this.payChannelList.find((ele_payChannel, idx, arr) => {
        return ele_payChannel.id == eleStr_payChannelId
       })

       if (!_.isUndefined(found_payChannel)) {
        // 找到了!
        eleBussiness._channelName += found_payChannel.channelName
       } else {
        eleBussiness._channelName += '-'
       }
       eleBussiness._channelName += ','

       if (!_.isNil(arr_payChannelRate[idx]) && !_.isNaN(arr_payChannelRate[idx])) {
        eleBussiness._poundage += arr_money[idx] * arr_payChannelRate[idx]
       }
      })

      eleBussiness._channelName = eleBussiness._channelName.slice(0, eleBussiness._channelName.length - 1)
      eleBussiness._money = arr_money
       .reduce(function(accumulator, currentValue, currentIndex, array) {
        return accumulator + currentValue
       }, 0)
       .toFixed(2)
      eleBussiness._poundage = eleBussiness._poundage.toFixed(2)
      eleBussiness._actual = (eleBussiness._money - eleBussiness._poundage).toFixed(2)

      if (eleBussiness.inAccountStatus == 2 && _.isNil(eleBussiness.nextId)) {
       eleBussiness._money = -eleBussiness._money
       eleBussiness._poundage = -eleBussiness._poundage
       eleBussiness._actual = -eleBussiness._actual
      }
     }
    })

    this.listExcel = response.data
    this.listLoading = false

    this.totalIncome = this.listExcel.reduce(function(accumulator, currentValue, currentIndex, array) {
     return Number(accumulator) + Number(currentValue._money)
    }, 0)

    this.totalActual = this.listExcel.reduce(function(accumulator, currentValue, currentIndex, array) {
     return Number(accumulator) + Number(currentValue._actual)
    }, 0)
   })
  },
  query() {
   this.$refs.multipleTable.query()
  },
 },
}
</script>

<style lang="scss" scoped>
#yogo-roomBusiness {
 .data-table {
  // 60px headbar
  // 233px search block
  // 100px pagination block
  max-height: calc(100vh - 60px - 233px - 100px);
 }
}
</style>
