// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../statics/images/other/标题栏BG@2x.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../statics/images/other/yogo@2x.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "#deldialogContent .el-dialog__header{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:3.2rem .4rem;width:3.2rem;height:.4rem;position:relative;padding:0}#deldialogContent .el-dialog__header .el-dialog__title{font-size:.18rem;line-height:.4rem;float:left;margin-left:.4rem;color:#fff;font-weight:400}#deldialogContent .el-dialog__header .el-dialog__headerbtn{width:.29rem;height:.29rem;background-color:#fff;top:.06rem;right:-.48rem;border-radius:50%;-webkit-box-sizing:border-box;box-sizing:border-box}#deldialogContent .el-dialog__header .el-dialog__headerbtn i:before{font-size:28px}#deldialogContent .el-dialog__header:before{content:\"\";display:block;width:.24rem;height:.21rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 50%;background-size:.24rem .21rem;margin:.1rem;position:absolute}#deldialogContent .el-dialog__body{padding:30px;width:100%;font-size:.16rem;overflow:hidden}#deldialogContent .el-dialog__body .delbuttons{text-align:center}#deldialogContent .el-dialog__body .buttons{width:.8rem;height:.3rem;padding:0}#deldialogContent .el-dialog__body .redbtn{background:#ea4335;color:#fff;border:1px solid #ea4335}#deldialogContent .el-dialog__body .tooltitle{text-align:center;padding-bottom:20px}", ""]);
// Exports
module.exports = exports;
