<template>
 <div id="yogo-extra" class="eltables">
  <div class="toolbar-container duku">
   <div>
    <span class="yogo-title toolbar-title">其他收入</span>
    <el-button type="primary" class="myicon iconadd" @click="handleAdd"><i></i>新增收入</el-button>
   </div>
   <!-- <el-button type="primary" class="myicon iconadd"><i></i>导出Excel</el-button> -->

   <div class="toolbar-box">
    <el-form label-position="top">
     <el-form-item label="门店" class="filter-item">
      <el-select class="item-content" v-model="formModel.storeId" placeholder="门店" clearable size="small" style="">
       <el-option v-for="(item, idx) of shopList" :key="idx" :value="item.id" :label="item.name" />
      </el-select>
     </el-form-item>
     <el-form-item label="开始时间" class="filter-item">
      <el-date-picker class="item-content" type="date" clearable size="small" v-model="formModel.startTime" placeholder="开始时间" value-format="yyyy-MM-dd HH:mm:ss" />
     </el-form-item>
     <el-form-item label="结束时间" class="filter-item">
      <el-date-picker class="item-content" type="date" clearable size="small" v-model="formModel.endTime" placeholder="结束时间" value-format="yyyy-MM-dd HH:mm:ss" />
     </el-form-item>
    </el-form>
    <div>
     <el-button size="small" class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">查询 </el-button>
     <el-button size="small" class="filter-item" type="primary" icon="el-icon-download">导出Excel</el-button>
    </div>
    <p style="margin-top: 5px; text-align: right; color: white">收入总金额：¥ {{ Number(totalUsed).toFixed(2) }}</p>
   </div>
  </div>

  <el-table :data="list" class="data-table" stripe height="1000">
   <el-table-column prop="happenTime" label="时间" align="center" />
   <el-table-column prop="systemTypeName" label="收入内容" align="center" />
   <el-table-column prop="payChannelName" label="付款方式" align="center" />
   <el-table-column prop="amount" label="订单金额(￥)" align="center">
    <template slot-scope="{ row }">
     ¥ {{ Number(row.amount / 100.0).toFixed(2) }}
    </template>
   </el-table-column>
   <el-table-column prop="note" label="备注" align="center" />
   <el-table-column prop="actions" label="操作" width="120" align="left">
    <template slot-scope="{ row }">
     <el-button type="primary" size="small" class="el-icon-edit" circle @click="handleEdit(row)"></el-button>
     <el-button type="danger" size="small" class="el-icon-delete" circle @click="handleDelete(row)"></el-button>
    </template>
   </el-table-column>
  </el-table>

  <pagination v-show="true" :total="listSize" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

  <extraEdit ref="EditDialogForm" />
  <delDialog ref="DelDialogForm" />
 </div>
</template>

<script>
import delDialog from '@/views/template/delDialog'
import extraEdit from '@/views/yygl/extraEdit'
import Pagination from '@/components/Pagination'
import { getOtherTrades, createOtherTrade, updateOtherIncome, deleteOtherTrade, getShops } from '@/api/shop'

const pageData = [
 {
  id: 1,
  time: '2019-08-23',
  item: '订票',
  pay_method: '优果君微信',
  amount: 100.0,
  remarks: '',
  store: {
   id: 1,
   name: '花果山店'
  }
 }
]

export default {
 components: {
  extraEdit,
  delDialog,
  Pagination
 },
 data() {
  return {
   totalUsed: 0,
   list: null,
   listSize: 0,
   listLoading: true,
   listQuery: {
    page: 1,
    limit: 10,
    importance: undefined,
    title: undefined,
    type: undefined,
    status: undefined,
    sort: '+id'
   },
   selectedItem: null,
   formModel: {
    storeId: null,
    themeId: null,
    payChannelId: null,
    startTime: null,
    endTime: null
   }
  }
 },
 beforeCreate() {
  Promise.all([getShops()]).then(result => {
   this.shopList = result[0].data.data
   this.getList()
  })
 },
 created() {
  this.getList()
 },
 methods: {
  handleFilter() {
   this.listQuery.storeId = this.formModel.storeId
   this.listQuery.themeId = this.formModel.themeId
   this.listQuery.payChannelId = this.formModel.payChannelId
   this.listQuery.startTime = this.formModel.startTime
   this.listQuery.endTime = this.formModel.endTime

   this.getList()
  },
  getList() {
   this.listLoading = true
   let param = {
    pageSize: this.listQuery.limit,
    pageNum: this.listQuery.page,
    isPage: true,

    storeId: this.listQuery.storeId,
    themeId: this.listQuery.themeId,
    payChannelId: this.listQuery.payChannelId,
    startTime: this.listQuery.startTime,
    endTime: this.listQuery.endTime
   }
   getOtherTrades(param).then(response => {
    this.list = response.data.rows
    this.listSize = response.data.total
    this.totalUsed = response.data.totalAmount
   })

   this.listLoading = false
  },
  handleAdd() {
   this.$refs['EditDialogForm'].showDialog('新增收入', null, this.doCreate)
  },
  handleEdit(item) {
   const target = {
    id: item.id,
    systemTypeId: item.systemTypeId,
    storeId: item.storeId,
    payChannelId: item.payChannelId,
    amount: (item.amount / 100.0).toFixed(2),
    note: item.note
   }
   this.$refs['EditDialogForm'].showDialog('修改收入', target, this.doUpdate)
  },
  handleDelete(item) {
   this.selectedItem = item
   this.$refs['DelDialogForm'].showDialog('删除收入', '是否删除当前收入', item, this.doDelete)
  },
  doCreate(data) {
   data.happenTime = data.happenTime ? new Date(data.happenTime).format('yyyy-MM-dd') : new Date().format('yyyy-MM-dd')
   data.amount = data.amount * 100
   createOtherTrade(data).then(response => {
    // TODO append new record to list
    console.log(response)
    this.getList()
   })
  },
  doUpdate(data) {
   data.amount = data.amount * 100
   createOtherTrade(data).then(response => {
    // TODO update record
    console.log(response)
    this.getList()
   })
  },
  doDelete() {
   deleteOtherTrade(this.selectedItem).then(response => {
    // TODO
    console.log(response)
    this.getList()
   })
  }
 }
}
</script>
<style scoped></style>

<style lang="scss" scoped>
@mixin setWidthHeight($width, $height) {
 width: $width;
 min-width: $width;
 max-width: $width;

 height: $height;
 min-height: $height;
 max-height: $height;
}
@mixin setWidth($width) {
 width: $width;
 min-width: $width;
 max-width: $width;
}
@mixin setHeight($height) {
 height: $height;
 min-height: $height;
 max-height: $height;
}
@mixin setAlign($jc, $ai) {
 justify-content: $jc;
 align-items: $ai;
}
@mixin setFlex($direction, $wrap, $jc, $ai) {
 display: flex !important;
 flex-direction: $direction;

 // flex-wrap: nowrap|wrap|wrap-reverse|initial|inherit;
 flex-wrap: $wrap;

 justify-content: $jc;
 align-items: $ai;
}
//-----------------------------------------------example
//justify-content: flex-start|flex-end|center|space-between|space-around|initial|inherit;
//align-items: stretch|center|flex-start|flex-end|baseline|initial|inherit;
// @include setWidth(100px);
// @include setHeight(100px);
// @include setWidthHeight(100%, calc(100% - #{$cardHeaderHight}));
// @include setAlign(center, center);
// @include setFlex(row/column, nowrap, center, center);
//-----------------------------------------------example

#yogo-extra {
 .data-table {
  // 60px headbar
  // 233px search block
  // 100px pagination block
  max-height: calc(100vh - 60px - 233px - 100px);
 }
}
</style>
