<template>
 <div class="dialogContent">
  <el-dialog :close-on-click-modal="false" :title="tit" :visible.sync="dialogVisible" @close="closeDialog" class="dialog-wrap" width="8.71rem">
   <form action>
    <div class="row">
     <div class="column">
      <p class="yogo-input-name">角色名称 <i class="theme"></i></p>
      <input placeholder="请选择" type="text" v-model="currObj.roleName" />
     </div>
     <div class="column">
      <p class="yogo-input-name">角色描述</p>
      <input placeholder="请选择" type="text" v-model="currObj.remark" />
     </div>
    </div>
    <div class="row">
     <div class="column roleBox">
      <p class="yogo-input-name">权限管理</p>
      <div class="roleContent">
       <el-table
        ref="roleTable"
        :data="roleMenu"
        style="width: 100%;margin-bottom: 20px;"
        row-key="path"
        border
        :tree-props="{ children: 'children', hasChildren: 'hasChildrena' }"
        @selection-change="handleSelectionChange"
       >
        <el-table-column prop="text" label="名称"> </el-table-column>
        <!-- <el-table-column prop="icon" label="图标" width="80px">
         <template slot-scope="{ row }">
          <i :class="row.icon" />
         </template>
        </el-table-column> -->
        <el-table-column label="授权" width="100px" type="selection"> </el-table-column>
       </el-table>
      </div>
     </div>
    </div>
    <div class="row rowbuttons" style="margin-bottom:0">
     <el-button @click="handleConfirm" type="primary">确 定</el-button>
     <el-button @click="closeDialog">取 消</el-button>
    </div>
   </form>
  </el-dialog>
 </div>
</template>

<script>
import { getMenuTree } from '@/api/menu'
import { getRoleMenuIds } from '@/api/roles'
export default {
 data() {
  return {
   tit: '',
   dialogVisible: false,
   currObj: {},
   defaultProps: {
    children: 'children',
    label: 'name',
   },
   roleMenu: [
    // {
    //  id: '0',
    //  text: 'root',
    //  key: '0',
    //  path: '/',
    //  parentId: '',
    //  children: [],
    // },
   ],
  }
 },
 // 组件的名称
 name: 'modifyRole',
 created() {
  this.getMenu()
 },

 methods: {
  toggleSelection(rows) {
   if (rows) {
    rows.forEach(row => {
     this.$refs.roleTable.toggleRowSelection(row)
    })
   } else {
    this.$refs.roleTable.clearSelection()
   }
  },

  handleSelectionChange(val) {
   this.multipleSelection = []
   for (let i = 0; i < val.length; i++) {
    if (this.multipleSelection.indexOf(val[i].id) === -1) {
     this.multipleSelection.push(val[i].id)
    }
   }
  },

  getMenu() {
   getMenuTree().then(response => {
    this.roleMenu = response.data.rows.children
    console.log(this.roleMenu)
   })
  },

  handleConfirm() {
   if (this.callback) {
    this.currObj.menuId = this.multipleSelection.toString()
    this.callback(this.currObj)
    this.closeDialog()
   }
  },

  closeDialog() {
   this.dialogVisible = false
  },

  cancelDialog() {
   this.dialogVisible = false
  },

  showDialog(title, data = {}, callback = null) {
   this.tit = title
   this.dialogVisible = true
   if (this.$refs.roleTable) this.$refs.roleTable.clearSelection()
   if (data) {
    getRoleMenuIds(data.roleId).then(response => {
     this.currObj = data
     this.currObj.menuId = response.data
     this.setTableCheck()
    })
   } else {
    this.currObj = {}
   }
   this.callback = callback
  },

  setTableCheck() {
   let table = this.roleMenu // 从后台获取到的数据
   table.forEach(item => {
    if (this.currObj.menuId.indexOf(item.id) >= 0) {
     this.$refs.roleTable && this.$refs.roleTable.toggleRowSelection(item, true)
    } else {
     this.$refs.roleTable && this.$refs.roleTable.toggleRowSelection(item, false)
    }
    if (item.children) {
     item.children.forEach(childItem => {
      if (this.currObj.menuId.indexOf(childItem.id) >= 0) {
       this.$refs.roleTable && this.$refs.roleTable.toggleRowSelection(childItem, true)
      } else {
       this.$refs.roleTable && this.$refs.roleTable.toggleRowSelection(childItem, false)
      }
     })
    }
   })
  },
 },
}
</script>
<style lang="less" scoped>
.dialogContent {
 border-radius: 5px;
 box-sizing: border-box;
 overflow-x: hidden;

 .row {
  display: flex;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 0.1rem;
 }

 .column {
  position: relative;
  // width: 2.8rem;
  margin-right: 0.3rem;

  input::-webkit-input-placeholder {
   color: #b1b1b1;
  }

  .yogo-input-name {
   color: #000000;
   font-size: 1em;
   padding-bottom: 0.2rem;
  }

  .btnicon {
   position: absolute;
   right: 0px;
   height: 0.36rem;
   width: 0.36rem;
   background: #d2d2d2 url(../../statics/images/icondown2.png) center center no-repeat;
   border-radius: 0 5px 5px 0;
  }

  .btnimgdown {
   background-image: url(../../statics/images/icondown2.png);
   background-size: 0.12rem 0.08rem;
  }

  .btnimgdate {
   background-image: url(../../statics/images/icondate2.png);
   background-size: 0.16rem 0.15rem;
  }
 }

 .columndesc {
  textarea {
   // font-size: 1.23em;
   color: #b1b1b1;
   border: 1px solid #d2d2d2;
   border-radius: 5px;
   width: 5.91rem;
   height: 1.2rem;
   text-indent: 8px;
  }
 }

 .rowbuttons {
  text-align: center;
  width: 3.22rem;
  margin: 0 auto;
  margin-bottom: 0;
 }
}

.dialogContent {
 .el-dialog__header {
  background: url('../../statics/images/other/标题栏BG@2x.png');
  background-size: 7.71rem 0.4rem;
  width: 8.2rem;
  height: 0.4rem;
  position: relative;
  padding: 0;

  .el-dialog__title {
   font-size: 1.5em;
   line-height: 0.4rem;
   float: left;
   margin-left: 0.4rem;
   color: white;
   font-weight: normal;
  }

  &::before {
   content: '';
   display: block;
   width: 0.24rem;
   height: 0.21rem;
   background: url('../../statics/images/other/yogo@2x.png') no-repeat center;
   background-size: 0.24rem 0.21rem;
   margin: 0.1rem;
   position: absolute;
  }

  .el-dialog__headerbtn {
   width: 0.29rem;
   height: 0.29rem;
   background-color: #fff;
   top: 0.06rem;
   right: -0.48rem;
   border-radius: 50%;

   i::before {
    font-size: 30px;
    line-height: 25px;
   }
  }
 }

 .custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
 }
 .el-dialog__body {
  padding: 0.4rem;
  // height: 5.2rem;
  // overflow-y: auto;

  .el-button--default {
   width: 1.4rem;
   height: 0.4rem;
   border-radius: 6px;
  }

  .el-button--primary {
   width: 1.4rem;
   height: 0.4rem;
   background: rgba(234, 67, 53, 1);
   border: none;
   border-radius: 6px;
  }
 }

 .el-dialog__footer {
  text-align: center;
  height: 40px;
  background: rgba(243, 243, 243, 1);
 }

 .roleBox {
  // width: 5.9rem;
  .roleContent {
   // overflow-x: hidden;
   width: 7.9rem;
   // height: 1770/100rem;
   background: rgba(255, 255, 255, 1);
   border: 1px solid rgba(210, 210, 210, 1);
   border-radius: 3px;

   .content {
    height: auto;
    padding: 0.1rem;
    // margin-top: 15/100rem;
    // margin-left: 20/100rem;
    box-sizing: border-box;

    .content_title {
     .titIcon {
      display: inline-block;
      height: 0.22rem;
      width: 0.04rem;
      vertical-align: middle;
      margin-right: 5px;
      margin-bottom: 2px;
      background: url(../../statics/images/矩形1拷贝9@2x.png);
     }

     // line-height: 1.53em;
     // font-size:22/100em;
     // font-family: Source Han Sans CN;
     // font-weight: 400;
     // color: rgba(0, 0, 0, 1);
     width: 110/100rem;
     height: 30/100rem;
     font-size: 22/100rem;
     font-family: Source Han Sans CN;
     font-weight: 400;
     color: rgba(0, 0, 0, 1);
    }

    .content_info {
     color: #000000;

     dl {
      vertical-align: top;
      width: 168/100rem;
      display: inline-block;
      margin-top: 0.1rem;
      margin-left: 0.15rem;

      dt {
       font-size: 16/100rem;
      }

      dd {
       span {
        font-size: 16/100rem;
        color: #818181;
       }

       margin-top: 0.05rem;
       margin-left: 0.15rem;
      }
     }
    }
   }
  }
 }

 //   图标内减模式
 .iconPub {
  display: inline-block;
  height: 0.14rem;
  width: 0.16rem;
  vertical-align: middle;
  margin-right: 8px;
  margin-bottom: 2px;
 }

 .theme {
  .iconPub;
  background: url(../../statics/images/xtgl/密室icon拷贝@2x.png) no-repeat center;
 }

 .notice {
  .iconPub;
  background: url(../../statics/images/xtgl/第三方通知ICON拷贝@2x.png) no-repeat center;
 }

 .extra {
  .iconPub;
  background: url(../../statics/images/xtgl/其他收入拷贝@2x.png) no-repeat center;
 }

 .themePlayback {
  .iconPub;
  background: url(../../statics/images/xtgl/主题回放拷贝@2x.png) no-repeat center;
 }

 .operaterecord {
  .iconPub;
  background: url(../../statics/images/xtgl/操作记录拷贝@2x.png) no-repeat center;
 }

 .roombusiness {
  .iconPub;
  background: url(../../statics/images/xtgl/密室经济状况拷贝@2x.png) no-repeat center;
 }

 .otherbusiness {
  .iconPub;
  background: url(../../statics/images/xtgl/其他经济状况拷贝@2x.png) no-repeat center;
 }

 .passrate {
  .iconPub;
  background: url(../../statics/images/xtgl/上座率和通关率拷贝@2x.png) no-repeat center;
 }

 .payment {
  .iconPub;
  background: url(../../statics/images/xtgl/店面支出拷贝@2x.png) no-repeat center;
 }

 .member {
  .iconPub;
  background: url(../../statics/images/xtgl/会员记录拷贝@2x.png) no-repeat center;
 }

 .errLog {
  .iconPub;
  background: url(../../statics/images/xtgl/出错记录拷贝@2x.png) no-repeat center;
 }

 .guide {
  .iconPub;
  background: url(../../statics/images/xtgl/引导人员统计拷贝@2x.png) no-repeat center;
 }

 .CompManagement {
  .iconPub;
  background: url(../../statics/images/xtgl/公司管理拷贝@2x.png) no-repeat center;
 }

 .shopManagement {
  .iconPub;
  background: url(../../statics/images/xtgl/门面管理@2x.png) no-repeat center;
 }

 .staffManagement {
  .iconPub;
  background: url(../../statics/images/xtgl/人员管理拷贝@2x.png) no-repeat center;
 }

 .topicInfo {
  .iconPub;
  background: url(../../statics/images/xtgl/主题信息管理拷贝@2x.png) no-repeat center;
 }

 .topicType {
  .iconPub;
  background: url(../../statics/images/xtgl/主题类型拷贝@2x.png) no-repeat center;
 }

 .gameStyle {
  .iconPub;
  background: url(../../statics/images/xtgl/游戏风格设置拷贝@2x.png) no-repeat center;
 }

 .systemEntry {
  .iconPub;
  background: url(../../statics/images/xtgl/系统条目设置拷贝@2x.png) no-repeat center;
 }

 .payMethod {
  .iconPub;
  background: url(../../statics/images/xtgl/付款方式设置拷贝@2x.png) no-repeat center;
 }

 .shopStatu {
  .iconPub;
  background: url(../../statics/images/xtgl/门店状态设置拷贝@2x.png) no-repeat center;
 }

 .personalPanel {
  .iconPub;
  background: url(../../statics/images/xtgl/个人面板拷贝@2x.png) no-repeat center;
 }

 .roleManager {
  .iconPub;
  background: url(../../statics/images/xtgl/角色管理@2x.png) no-repeat center;
 }
}
</style>
