import Vue from 'vue'
import Yogo from '@/YOGO'
//Vue.config.productionTip = false
import router from '@/router'
import ElementUI from 'element-ui'

import store from '@/store'
import request from '@/utils/request'
import db from '@/utils/localstorage'
import 'element-ui/lib/theme-chalk/index.css'
import { Notification } from 'element-ui'
// import 'default-passive-events'

import '@/statics/css/index.less' // global css
import 'default-passive-events'

import './icons'

Vue.use(ElementUI, db, {
 install(Vue) {
  Vue.prototype.$db = db
 },
})

Vue.prototype.$post = request.post
Vue.prototype.$get = request.get
Vue.prototype.$put = request.put
Vue.prototype.$delete = request.delete
Vue.prototype._ = require('lodash')
/**
 * 请求参数验证
 * params 的所有属性值都不能为空
 * 参数验证
 * if(!this.paramsValidate(params)) return;
 */
Vue.prototype.$paramsValidate = function(params, keys) {
 let flag = true

 for (var key in keys) {
  if (!params[keys[key]] || params[keys[key]] == '') {
   Notification.error({
    title: '请完善数据!',
    message: keys[key] + '数据缺失',
    duration: 2500,
   })
   return false // 终止程序
  }
 }

 return flag
}

window._ = require('lodash')

Date.prototype.format = function(fmt) {
 var o = {
  'M+': this.getMonth() + 1, // 月份
  'd+': this.getDate(), // 日
  'H+': this.getHours(), // 小时
  'm+': this.getMinutes(), // 分
  's+': this.getSeconds(), // 秒
  'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
  S: this.getMilliseconds(), // 毫秒
 }
 if (/(y+)/.test(fmt)) {
  fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
 }
 for (var k in o) {
  if (new RegExp('(' + k + ')').test(fmt)) {
   fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
  }
 }
 return fmt
}

new Vue({
 el: '#app',
 router,
 store,
 render: h => h(Yogo),
})
