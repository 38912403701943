let demoData = {
  ageRangeArr: [
    {
      id: 0,
      range: '14岁以下',
    },
    {
      id: 1,
      range: '14-18岁',
    },
    {
      id: 2,
      range: '18-30岁',
    },
    {
      id: 3,
      range: '30岁以上',
    },
    {
      id: 4,
      range: '未知',
    },
  ],
  shop: {
    id: 134,
    name: '江汉路店',
    workTimeStart: '8:00',
    workTimeEnd: '24:00',
  },
  themeArr: [
    {
      id: 134,

      //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
      name: '局',
      difficulty: null, // int, 1-5
      gameTypeId: null, // int
      gameStyleId: null, // int
      minPeopleNumber: null, // int
      entireBookPeopleNumber: null, // int
      suggestPeopleNumber: null, // int
      shopId: null, // int
      introduce: null, // string
      ticketPriceBusy: null, // float
      ticketPriceIdle: null, // float
      imgSrc: require('../../statics/images/nav/1@2x.png'),
      imgSrcFile: null, // File
      workTime: {
        startTime: null, // string
        endTime: null, // string
      },
      //--------------------------------------------------------------------------------------------------------------------------------------------------------------------

      sceneArr: [
        {
          id: 1,

          //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
          startTime: '10:00',
          endTime: '11:00',
          //--------------------------------------------------------------------------------------------------------------------------------------------------------------------

          startTimeLastMin: 120, // startTime - workTimeStart
          duration: 60,
          hasGuider: false,
          status: '未开场', // '未开场' '进行中' '已结束'

          orderArr: [
            {
              id: 1,

              //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
              reservePeopleName: '刘德华',
              reservePeoplePhone: '13418987657',
              ageRangeId: 1,
              numberOfPeople: 3,
              payMethodId: 27, // 27-38
              payMoney: 896.0,
              //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
            },
          ],
        },
      ],
    },
  ],
}

export default demoData

/*
date+shop -> theme -> scene -> order


/////////////////////////////////////////////////////
// order
//+++++++++++++++++++++++++++++++++++++++++++++++++++

//通过某一天的日期及门店来获取那一天的所有主题信息
getThemesByDateShop(date, shopId)

//预定,相当于新增订单
//orderInfo: 基本的订单信息,可参看预定界面
//belongSceneId: 所属的场次id.
reserveOrder(orderInfo, belongSceneId)

//退订,相当于删除订单
//orderInfoId: 订单id
unreserveOrder(orderInfoId)

//修改订单信息
//orderInfoId: 订单id
//newOrderInfo: 修改后的订单信息
editOrder(orderInfoId, newOrderInfo)

//+++++++++++++++++++++++++++++++++++++++++++++++++++
// order
/////////////////////////////////////////////////////

/////////////////////////////////////////////////////
// theme && scene
//+++++++++++++++++++++++++++++++++++++++++++++++++++

//通过某一天的日期及门店来获取那一天的所有主题信息
getThemesByDateShop(date, shopId)

//预定,相当于新增订单
//orderInfo: 基本的订单信息,可参看预定界面
//belongSceneId: 所属的场次id.
reserveOrder(orderInfo, belongSceneId)

//退订,相当于删除订单
//orderInfoId: 订单id
unreserveOrder(orderInfoId)

//修改订单信息
//orderInfoId: 订单id
//newOrderInfo: 修改后的订单信息
editOrder(orderInfoId, newOrderInfo)

//+++++++++++++++++++++++++++++++++++++++++++++++++++
// theme && scene
/////////////////////////////////////////////////////









*/
