<template>
 <div id="themestart">
  <!-- 开始弹窗 -->
  <el-dialog :close-on-click-modal="false" :visible.sync="startFlag" center class="contentList start" title="开启密室主题">
   <div class="startImg">
    <el-image :src="combineImgUrl" alt class="startPic" fit="cover" />
   </div>
   <div class="isOrNotstart">是否开启当前密室主题</div>
   <span class="dialog-footer" slot="footer">
    <el-button @click="onClick_confirm" type="primary">确 定</el-button>
    <el-button @click="startFlag = false">取 消</el-button>
   </span>
  </el-dialog>
  <!-- 结束弹窗 -->
  <el-dialog :close-on-click-modal="false" :visible.sync="endFlag" center class="contentList start" title="是否通关">
   <div class="startImg">
    <img :src="combineImgUrl" alt class="startPic" />
   </div>
   <div class="isOrNotstart">当前密室是否通关</div>
   <span class="dialog-footer" slot="footer">
    <el-button @click="endFlag = false" type="primary">成 功</el-button>
    <el-button @click="endFlag = false" class="fail">惜 败</el-button>
   </span>
  </el-dialog>
 </div>
</template>

<script>
import { getThemeSessionById, getThemeSessionList, saveOrUpdateThemeSession } from '@/api/theme/themeSession'

export default {
 data() {
  return {
   callback: null,

   scene: null,
   sceneSrc: null,

   dialogVisible: false,
   // 开始弹窗
   startFlag: false,
   // 结束弹窗
   endFlag: false,

   themePicUrl: '',
  }
 },
 computed: {
  combineImgUrl() {
   if (this.themePicUrl !== '') {
    let url = '' + process.env.VUE_APP_BASE_API + '/' + this.themePicUrl
    return url
   } else {
    return ''
   }
  },
 },

 methods: {
  onClick_confirm() {
   this.startFlag = false

   // 当前场次的运行状态
   // 0.未开场
   // 1.运行中
   // 2.已结束未通关
   // 3.已结束已通过
   this.scene.themeSession.sessionStatus = 1
   this.scene.themeSession.openTime = new Date().format('yyyy-MM-dd HH:mm:ss')

   this.scene.themeSession.statistics.forEach((eleOrder, idx, arr) => {
    if (eleOrder.unreservedStatus != 1) {
     eleOrder.costs.forEach((elePayEntry, idx, arr) => {
      if (elePayEntry.inAccountStatus == 0) {
       elePayEntry.inAccountStatus = 1
       elePayEntry.inAccountTime = new Date().format('yyyy-MM-dd HH:mm:ss')
      }
     })
    }
   })

   saveOrUpdateThemeSession(this.scene.themeSession).then(response => {
    // sync
    _.assign(this.sceneSrc.themeSession, response.data.data)

    this.$message.success('场次状态变更成功')
    this.callback()
   })
  },
  closeDialog() {
   this.startFlag = false
  },
  cancelDialog() {
   this.startFlag = false
  },
  showDialog(title = '开始密室主题', scene, theme, date, callback) {
   this.sceneSrc = scene
   this.scene = _.cloneDeep(scene)
   this.themePicUrl = theme.themePicUrl
   this.callback = callback
   this.startFlag = true
  },
 },
}
</script>

<style lang="less">
#themestart {
 .contentList {
  .el-dialog {
   width: 4.56rem;

   .el-dialog__header {
    width: 4.56rem;
    background: url('../../statics/images/other/标题栏BG@2x.png') no-repeat;
    background-size: cover;
    background-position-x: -0.5rem;
    height: 0.4rem;
    position: relative;
    padding: 0.1rem 0.11rem 0.09rem;

    .el-dialog__title {
     width: 0.72rem;
     height: 0.18rem;
     white-space: nowrap;
     font-size: 0.18rem;
     position: absolute;
     left: 0rem;
     padding-left: 0.41rem;
     color: white;
    }

    .el-dialog__title::before {
     content: '';
     display: block;
     width: 0.23rem;
     height: 0.21rem;
     background: url('../../statics/images/other/yogo@2x.png') center center;
     background-size: cover;
     position: absolute;
     left: 0.11rem;
    }

    .el-dialog__headerbtn {
     width: 0.29rem;
     height: 0.29rem;
     background-color: #fff;
     top: 0.05rem;
     right: -0.47rem;
     border-radius: 0.15rem;

     i::before {
      font-size: 0.2rem;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
     }
    }
   }

   .el-dialog__body,
   .el-dialog__footer {
    width: 4.56rem;
    height: auto;
    background-color: #fff;
   }
  }

  &.start {
   .el-dialog__body {
    padding: 0;
    padding-top: 0.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .startImg {
     width: 1.8rem;
     height: 1.8rem;
     margin-top: 0.1rem;
     border-radius: 0.9rem;

     .startPic {
      width: 1.8rem;
      height: 1.8rem;
     }
    }

    .isOrNotstart {
     color: #000;
     font-size: 0.16rem;
     margin-bottom: 0.24rem;
     margin-top: 0.25rem;
    }
   }

   .el-dialog__footer {
    padding-bottom: 0.4rem;
    padding-top: 0;
    font-size: 14px;

    .el-button--primary {
     width: 0.8rem;
     height: 0.3rem;
     background: #ea4335;
     border: none;
     border-radius: 0.06rem;
     padding: 0;
    }

    .el-button--default {
     width: 0.8rem;
     height: 0.3rem;
     border-radius: 0.06rem;
     padding: 0;
    }
   }
  }
 }
}
</style>
