<template>
  <div id="yogo-storeStatus">
    <table-template
      ref="multipleTable"
      :dynamicTableTilteArr="tableTilteArr"
      :dynamicTableContentArr="tableContentArr"
      :dynamicTableComponents="tableComponents"
      :dynamicTableTopBtns="tableTopBtns"
      :dynamicTableLowBtns="tableLowBtns"
      :dynamicFormFields="formFields"
      dynamicTableTit="门店状态"
      :dynamicRowform="true"
      @edit="edit"
      @del="del"
    />
    <delDialog ref="DelDialogForm" />
    <editDialog ref="EditDialogForm" />
  </div>
</template>

<script>
import tableTemplate from '@/views/template/onetable'
import delDialog from '@/views/template/delDialog'
import editDialog from '@/views/htgl/storeStatusEdit'
export default {
  components: {
    delDialog,
    tableTemplate,
    editDialog,
  },
  data() {
    return {
      formFields: {
        mengdian: '',
        type: '',
      },
      tableTopBtns: [
        {
          compKey: 'compKey1',
          labelName: '新增门店状态',
          type: 'primary',
          className: 'iconadd',
          clickEvent: () => this.openAdd(),
        },
      ],
      tableLowBtns: [
        {
          compKey: 'compKey1',
          labelName: '查 询',
          type: 'primary',
          className: 'btncz',
          iconClass: 'el-icon-search',
          clickEvent: () => null,
        },
        {
          compKey: 'compKey2',
          labelName: '导出Excel',
          type: 'primary',
          className: 'btnexport',
          iconClass: 'el-icon-download',
          clickEvent: () => null,
        },
      ],
      tableComponents: [
        {
          CompArr: [
            {
              code: 'YOGO优果真人密室逃脱(江汉路店)',
              codeField: 'mengdian',
              component: () => import('@/views/template/commonInput'),
              labelName: '门店',
            },
            {
              code: '请选择',
              codeField: 'type',
              component: () => import('@/views/template/commonInput'),
              labelName: '门店状态',
            },
          ],
        },
      ],
      tableTilteArr: [
        {
          title: '门店状态',
          prop: 'status',
          className: '',
        },
        {
          title: '门店费用(￥)',
          prop: 'price',
          className: '',
        },
        {
          title: '生效时间',
          prop: 'startdate',
          className: '',
        },
        {
          title: '失效时间',
          prop: 'enddate',
          className: '',
        },
        {
          title: '操作',
          prop: true,
          className: '',
        },
      ],
      tableContentArr: [
        {
          status: '免费',
          price: 10,
          startdate: '2018-03-23',
          enddate: '2018-08-23',
          options: {
            hasedit: true,
            hasdelete: true,
          },
        },
        {
          status: '三年期',
          price: 10,
          startdate: '2018-03-23',
          enddate: '2018-08-23',
          options: {
            hasedit: true,
            hasdelete: true,
          },
        },
        {
          status: '一年期',
          price: 10,
          startdate: '2018-03-23',
          enddate: '2018-08-23',
          options: {
            hasedit: true,
            hasdelete: true,
          },
        },
        {
          status: '免费',
          price: 10,
          startdate: '2018-03-23',
          enddate: '2018-08-23',
          options: {
            hasedit: true,
            hasdelete: true,
          },
        },
      ],
    }
  },
  methods: {
    openAdd() {
       
      this.$refs['EditDialogForm'].showDialog()
    },
    edit(item) {
       
      this.$refs['EditDialogForm'].showDialog('修改门店状态', item)
    },
    del(item) {
       
      this.$refs['DelDialogForm'].showDialog(
        '删除门店状态',
        '是否删除当前门店状态',
        item,
      )
    },
  },
}
</script>

<style scoped>
</style>
