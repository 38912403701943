<template>
 <div id="yogo-otherBusiness" class="eltables">
  <div class="toolbar-container duku">
   <div>
    <span class="yogo-title toolbar-title">其他经营状况(收入)</span>
   </div>
   <div class="toolbar-box">
    <el-form label-position="top">
     <el-form-item label="门店" class="filter-item">
      <el-select class="item-content" v-model="formModel.storeId" placeholder="" clearable size="small" style="">
       <el-option v-for="(item, idx) of shopList" :key="idx" :value="item.id" :label="item.name" />
      </el-select>
     </el-form-item>

     <!-- 
     <el-form-item label="主题" class="filter-item">
      <el-select class="item-content" v-model="formModel.themeId" placeholder="" clearable size="small" style="">
       <el-option v-for="(item, idx) of themeList" :key="idx" :value="item.id" :label="item.themeName" />
      </el-select>
     </el-form-item> 
     -->

     <el-form-item label="条目名称" class="filter-item">
      <el-input class="item-content" size="small" v-model="formModel.entryName" placeholder="请输入"></el-input>
     </el-form-item>

     <el-form-item label="付款方式" class="filter-item">
      <el-select class="item-content" v-model="formModel.payChannelId" placeholder="" clearable size="small" style="">
       <el-option v-for="(item, idx) of payChannelList" :key="idx" :value="item.id" :label="item.channelName" />
      </el-select>
     </el-form-item>
     <el-form-item label="开始时间" class="filter-item">
      <el-date-picker
       class="item-content"
       type="date"
       clearable
       size="small"
       style=""
       placeholder=""
       v-model="formModel.startTime"
       value-format="yyyy-MM-dd HH:mm:ss"
      />
     </el-form-item>
     <el-form-item label="结束时间" class="filter-item">
      <el-date-picker
       class="item-content"
       type="date"
       clearable
       size="small"
       style=""
       placeholder=""
       v-model="formModel.endTime"
       value-format="yyyy-MM-dd HH:mm:ss"
      />
     </el-form-item>
    </el-form>

    <div>
     <el-button size="small" class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">查询</el-button>
     <el-button size="small" class="filter-item" type="primary" icon="el-icon-download" @click="onClick_exportExcel($event)">导出Excel</el-button>
    </div>

    <p style="margin-top: 5px; text-align: right; color: white">
     收入总金额：¥ {{ totalIncome.toFixed(2) }}， 实收总金额：¥ {{ totalActual.toFixed(2) }}
    </p>
   </div>
  </div>

  <el-table :data="list" class="data-table" stripe height="1000">
   <el-table-column prop="systemTypeName" label="条目名称" align="center" />
   <el-table-column prop="happenTime" label="支付时间" align="center" />
   <el-table-column prop="payChannelName" label="支付方式" align="center" />
   <el-table-column prop="amount" label="收入金额" align="center">
    <template slot-scope="{ row }"> ¥ {{ Number(row.amount / 100.0).toFixed(2) }} </template>
   </el-table-column>
   <el-table-column prop="charge" label="手续费" align="center">
    <template slot-scope="{ row }"> ¥ {{ Number(row.charge / 100.0).toFixed(2) }} </template>
   </el-table-column>
   <el-table-column label="实收金额" align="center">
    <template slot-scope="{ row }"> ¥ {{ Number((row.amount - row.charge) / 100.0).toFixed(2) }} </template>
   </el-table-column>
  </el-table>

  <pagination v-show="true" :total="listSize" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
 </div>
</template>

<script>
/* global _ */
import Pagination from '@/components/Pagination'
import { getOtherTrades, getShops } from '@/api/shop'
import { getThemeList } from '@/api/theme/theme'
import { getPayChannelList } from '@/api/paychannel'

const pageData = [
 {
  id: 1,
  name: '条目名称1',
  pay_time: '2019-10-10 10:10:01',
  pay_method: '支付宝',
  income: 1800,
  fee: 70,
  store: {
   id: 1,
   name: '花果山店',
  },
 },
]

export default {
 components: {
  Pagination,
 },
 data() {
  return {
   shopList: null,
   themeList: null,
   payChannelList: null,
   // +++++++++++++++++++++++++++++++++++++++

   totalIncome: 0,
   totalActual: 0,
   list: null,
   listSize: 0,
   listLoading: true,
   listQuery: {
    page: 1,
    limit: 10,
    importance: undefined,
    store: undefined,
    sort: '+id',
   },
   formModel: {
    storeId: null,
    themeId: null,
    payChannelId: null,
    startTime: null,
    endTime: null,
    entryName: null,
   },
   selectedItem: null,
  }
 },
 beforeCreate() {
  Promise.all([getShops(), getThemeList(), getPayChannelList()]).then(result => {
   this.shopList = result[0].data.data
   this.themeList = result[1].data
   this.payChannelList = result[2].data
  })
 },
 created() {
  this.getList()
 },
 methods: {
  onClick_exportExcel() {
   if (_.isArray(this.list) && !_.isEmpty(this.list)) {
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    this.list.forEach((ele, idx, arr) => {
     console.log(idx, arr)
     ele._storeName = ele.storeName
     ele._amount = (ele.amount / 100.0).toFixed(2)
     ele._actual = Number((ele.amount - ele.charge) / 100.0).toFixed(2)
    })
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    let columns = []
    _.entries(this.list[0]).filter((ele, idx, arr) => {
     console.log(idx, arr)
     switch (ele[0]) {
      case 'systemTypeName':
       columns.push({
        prop: ele[0],
        label: '条目名称',
       })
       return true

      case 'happenTime':
       columns.push({
        prop: ele[0],
        label: '支付时间',
       })
       return true

      case 'payChannelName':
       columns.push({
        prop: ele[0],
        label: '支付方式',
       })
       return true

      case '_amount':
       columns.push({
        prop: ele[0],
        label: '收入金额',
       })
       return true

      case '_actual':
       columns.push({
        prop: ele[0],
        label: '实收金额',
       })
       return true

      default:
       return false
     }
    })
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    this.$export.excel({ columns: columns, data: this.list }).then(() => {})
   } else {
    this.$message.error('没有数据可导出')
   }
  },

  getList() {
   this.listLoading = true

   let param = {
    pageSize: this.listQuery.limit,
    pageNum: this.listQuery.page,
    isPage: true,

    storeId: this.listQuery.storeId,
    themeId: this.listQuery.themeId,
    payChannelId: this.listQuery.payChannelId,
    startTime: this.listQuery.startTime,
    endTime: this.listQuery.endTime,
    systemTypeName: this.listQuery.systemTypeName,
   }

   getOtherTrades(param).then(response => {
    this.list = response.data.rows
    this.listSize = response.data.total

    this.totalIncome = response.data.totalAmount
    this.totalActual = response.data.totalAmountActual
    // for (const item of this.list) {
    //  this.totalIncome += item.income
    //  this.totalActual += item.income - item.fee
    // }

    this.listLoading = false
   })
  },

  handleFilter() {
   this.listQuery.storeId = this.formModel.storeId
   this.listQuery.themeId = this.formModel.themeId
   this.listQuery.payChannelId = this.formModel.payChannelId
   this.listQuery.startTime = this.formModel.startTime
   this.listQuery.endTime = this.formModel.endTime
   this.listQuery.systemTypeName = this.formModel.entryName

   this.getList()
  },
  query() {
   this.$refs.multipleTable.query()
  },
 },
}
</script>

<style lang="scss" scoped>
@mixin setWidthHeight($width, $height) {
 width: $width;
 min-width: $width;
 max-width: $width;

 height: $height;
 min-height: $height;
 max-height: $height;
}
@mixin setWidth($width) {
 width: $width;
 min-width: $width;
 max-width: $width;
}
@mixin setHeight($height) {
 height: $height;
 min-height: $height;
 max-height: $height;
}
@mixin setAlign($jc, $ai) {
 justify-content: $jc;
 align-items: $ai;
}
@mixin setFlex($direction, $wrap, $jc, $ai) {
 display: flex !important;
 flex-direction: $direction;

 // flex-wrap: nowrap|wrap|wrap-reverse|initial|inherit;
 flex-wrap: $wrap;

 justify-content: $jc;
 align-items: $ai;
}
//-----------------------------------------------example
//justify-content: flex-start|flex-end|center|space-between|space-around|initial|inherit;
//align-items: stretch|center|flex-start|flex-end|baseline|initial|inherit;
// @include setWidth(100px);
// @include setHeight(100px);
// @include setWidthHeight(100%, calc(100% - #{$cardHeaderHight}));
// @include setAlign(center, center);
// @include setFlex(row/column, nowrap, center, center);
//-----------------------------------------------example

#yogo-otherBusiness {
 .data-table {
  // 60px headbar
  // 233px search block
  // 100px pagination block
  max-height: calc(100vh - 60px - 233px - 100px);
 }
}
</style>
