<template>
  <div id="yogo-operateRecord"> 
    <table-template
      ref="multipleTable"
      :dynamicTableTilteArr="tableTilteArr"
      :dynamicTableContentArr="tableContentArr"
      :dynamicTableComponents="tableComponents"
      :dynamicTableTopBtns="tableTopBtns"
      :dynamicTableLowBtns="tableLowBtns"
      :dynamicFormFields="formFields"
      :dynamicRowform="true"
      dynamicTableTit="操作记录" 
    />
  </div>
</template>
<script> 
import tableTemplate from "@/views/template/onetable";
export default {
  components: { 
    tableTemplate
  },
  data() {
    return {
      tit: "",
      sendArr: null,
      newPayment: false,
      deleteShow:false,
      formFields: {
        mengdian: "",
        historys: "",
        begin: "",
        end: ""
      },
      tableTopBtns:[],
      tableLowBtns:[
           {compKey:'compKey1',labelName:"查 询",type:'primary',className:'btncz',iconClass:'el-icon-search',clickEvent:()=>this.query()},
           {compKey:'compKey2',labelName:"导 出Excel",type:'primary',className:'btnexport',iconClass:'el-icon-download',clickEvent:()=>null}
      ],
      tableComponents: [
        {CompArr: [{code: "默认值",codeField: "mengdian", component: () => import("@/views/template/commonInput"),labelName: "门店"},
                   {code: "默认值",codeField: "historys",component: () => import("@/views/template/commonInput"),labelName: "操作记录"} ,
                   {code: "",codeField: "begin",component: () => import("@/views/template/datePicker"),labelName: "开始时间"},
                   {code: "",codeField: "end",component: () => import("@/views/template/datePicker"),labelName: "结束时间"}]},
      ],
      tableTilteArr: [
        {title: "功能模块",prop: "func", className: ""},
        {title: "操作人员", prop: "handler", className: ""},
        {title: "操作记录时间",prop: "udate",className: "" },
        {title: "操作记录信息",prop: "ucontent",className: ""},
        {title: "是否成功",prop: "issuccess",className: "" } 
      ],
      tableContentArr: [
        {
            func:"密室主题",
            handler:"王小石",
            udate:"2018-08-23 02:47:44",
            ucontent:"创建预订",
            issuccess:"成功"
        },
        {
            func:"密室主题",
            handler:"王小石",
            udate:"2018-08-23 02:47:44",
            ucontent:"创建预订",
            issuccess:"成功"
        },
        {
            func:"密室主题",
            handler:"王小石",
            udate:"2018-08-23 02:47:44",
            ucontent:"创建预订",
            issuccess:"成功"
        },
        {
            func:"密室主题",
            handler:"王小石",
            udate:"2018-08-23 02:47:44",
            ucontent:"创建预订",
            issuccess:"成功"
        },
        {
            func:"密室主题",
            handler:"王小石",
            udate:"2018-08-23 02:47:44",
            ucontent:"创建预订",
            issuccess:"成功"
        },
        {
            func:"密室主题",
            handler:"王小石",
            udate:"2018-08-23 02:47:44",
            ucontent:"创建预订",
            issuccess:"成功"
        }
      ]
    };
  },
  methods: {
    errLogShow() {
      this.sendArr = null;
      this.tit = "新增出错记录";
      this.newPayment = true;
    },
    errLogHide() {
      this.newPayment = false;
    },
    changeCode(item) {
      this.sendArr = item;
      this.tit = "修改出错记录";
      this.newPayment = true;
    },
    modifyHide(item) {
      this.newPayment = false;
    },
    deleteItem(item) {
      this.deleteShow = true;
    },
    deleteHide(item) {
      this.deleteShow = false;
    },
    query(){
      this.$refs.multipleTable.query()
    }
  }
};
</script>
<style scoped>
 
</style>