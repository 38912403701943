<template>
 <div class="modifyPwd">
  <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" @close="handleCancel" class="dialog-wrap" title="修改密码" top="1.46rem" width="4.03rem">
   <form action>
    <!-- <div class="row">
     <div class="column">
      <p class="yogo-input-name">旧密码</p>
      <input placeholder="请输入密码" type="text" />
      <span class="pwdLogo"></span>
     </div>
    </div>
    <div class="row">
     <div class="column">
      <p class="yogo-input-name">旧密码</p>
      <input placeholder="请输入密码" type="text" />
      <span class="pwdLogo"></span>
     </div>
    </div> -->
    <div class="row">
     <div class="column">
      <p class="yogo-input-name">新密码</p>
      <input placeholder="请输入密码" type="password" v-model="user.password" value="" />
      <span class="pwdLogo"></span>
     </div>
    </div>
   </form>
   <div class="row buttons">
    <el-button @click="handleConfirm" type="primary">确 定</el-button>
    <el-button @click="handleCancel">取 消</el-button>
    <el-button @click="handleReset">重置密码</el-button>
   </div>
  </el-dialog>
 </div>
</template>

<script>
export default {
 // 组件的名称
 name: 'modifyPwd',
 data() {
  return {
   dialogVisible: false,
   user: {
    password: null
   },
   callback: null
  }
 },
 methods: {
  handleCancel() {
   this.dialogVisible = false
  },
  closeDialog() {
   this.dialogVisible = false
   this.callback = null
  },
  handleConfirm() {
   if (this.callback) {
    this.callback(this.user, false)
    this.closeDialog()
   }
  },
  handleReset() {
   if (this.callback) {
    this.callback(this.user, true)
    this.closeDialog()
   }
  },
  /**
   * @desc:显示弹窗
   * @param:isVisible          [boolean]  弹窗是否可见
   * */
  isShowDia(isVisible, data, callback) {
   this.dialogVisible = isVisible
   this.user = data
   this.callback = callback
  }
 }
}
</script>
<style lang="less" scoped>
.modifyPwd {
 border-radius: 5px;
 box-sizing: border-box;
 .row {
  display: flex;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 0.29rem;
  &:nth-of-type(3) {
   margin-bottom: 0.4rem;
  }
 }

 .column {
  position: relative;
  width: 2.8rem;
  margin-right: 0.3rem;

  input {
   width: 2.8rem;
   // font-size: 1.23em;
   color: #414141;
   border: 1px solid #d2d2d2;
   border-radius: 5px;
   height: 0.36rem;
   text-indent: 8px;
   font-size: 0.14rem;
  }

  input::-webkit-input-placeholder {
   color: #b1b1b1;
  }
  .pwdLogo {
   position: absolute;
   bottom: 0;
   right: 0;
   width: 0.36rem;
   height: 0.36rem;
   background: #d2d2d2 url(../../statics/images/xtgl/modifyPwd.png) no-repeat center center;
   background-size: 0.14rem 0.1rem;
   border-radius: 0 5px 5px 0;
  }

  .yogo-input-name {
   color: #000000;
   font-size: 2em;
   padding-bottom: 0.2rem;
  }

  .btnicon {
   position: absolute;
   right: 0px;
   height: 0.36rem;
   width: 0.36rem;
   background: #d2d2d2 url(../../statics/images/icondown2.png) center center no-repeat;
   border-radius: 0 5px 5px 0;
  }

  .btnimgdown {
   background-image: url(../../statics/images/icondown2.png);
   background-size: 0.12rem 0.08rem;
  }

  .btnimgdate {
   background-image: url(../../statics/images/icondate2.png);
   background-size: 0.16rem 0.15rem;
  }
 }

 .columndesc {
  textarea {
   // font-size: 1.23em;
   color: #b1b1b1;
   border: 1px solid #d2d2d2;
   border-radius: 5px;
   width: 5.91rem;
   height: 1.2rem;
   text-indent: 8px;
  }
 }

 .buttons {
  text-align: center;
  margin-bottom: 0;
  width: 3.22rem;
  margin: 0 auto;
  button {
   font-size: 0.18rem;
   padding: 0;
   display: flex;
   align-items: center;
   justify-content: center;
  }
 }
}
.modifyPwd {
 .el-dialog__header {
  background: url('../../statics/images/other/标题栏BG@2x.png') no-repeat;
  background-size: 6.71rem 0.4rem;
  width: 4.03rem;
  height: 0.4rem;
  position: relative;
  padding: 0;

  .el-dialog__title {
   font-size: 1.5em;
   line-height: 0.4rem;
   float: left;
   margin-left: 0.4rem;
   color: white;
   font-weight: normal;
  }

  &::before {
   content: '';
   display: block;
   width: 0.24rem;
   height: 0.21rem;
   background: url('../../statics/images/other/yogo@2x.png') no-repeat center;
   background-size: 0.24rem 0.21rem;
   margin: 0.1rem;
   position: absolute;
  }

  .el-dialog__headerbtn {
   width: 0.29rem;
   height: 0.29rem;
   background-color: #fff;
   top: 0.06rem;
   right: -0.48rem;
   border-radius: 50%;

   i::before {
    font-size: 28px;
   }
  }
 }

 .el-dialog__body {
  padding: 0.4rem;
  height: auto;
  overflow-y: auto;

  .el-button--default {
   width: 1.4rem;
   height: 0.4rem;
   border-radius: 6px;
  }

  .el-button--primary {
   width: 1.4rem;
   height: 0.4rem;
   background: rgba(234, 67, 53, 1);
   border: none;
   border-radius: 6px;
  }
 }

 .el-dialog__footer {
  text-align: center;
  height: 40px;
  background: rgba(243, 243, 243, 1);
 }
}
</style>
