<template>
 <div id="compdialogContent">
  <el-dialog :close-on-click-modal="false" :title="tit" :visible.sync="dialogVisible" @close="closeDialog" class="dialog-wrap" width="5.5rem">
   <el-form size="small">
    <div class="diaWrapper">
     <div class="topicHeader">
      客户信息
      <i :class="{foldBtn:true,onFold:this.foldShow1}" @click="fold(1)"></i>
     </div>
     <div class="foldWrap" v-show="foldShow1">
      <el-row :gutter="14">
       <el-col :span="12">
        <el-form-item label="公司名称">
         <el-input placeholder="请输入公司名称" type="text" v-model="currObj.name" />
        </el-form-item>
       </el-col>
       <el-col :span="12">
        <el-form-item label="负责人">
         <el-input placeholder="请输入负责人" type="text" v-model="currObj.manager" />
        </el-form-item>
       </el-col>
       <el-col :span="12">
        <el-form-item label="联系方式1">
         <el-input placeholder="请输入联系方式1" type="text" v-model="currObj.contacts[0]" />
        </el-form-item>
       </el-col>
       <el-col :span="12">
        <el-form-item label="联系方式2">
         <el-input placeholder="请输入联系方式2" type="text" v-model="currObj.contacts[1]" />
        </el-form-item>
       </el-col>
       <el-col :span="12">
        <el-form-item label="开通日期">
         <el-date-picker format="yyyy-MM-dd" placeholder="请选择开通日期" style="width: 100%" v-model="currObj.begin_at" value-format="yyyy-MM-dd HH:mm:ss" />
        </el-form-item>
       </el-col>
       <el-col :span="12">
        <el-form-item label="失效日期">
         <el-date-picker format="yyyy-MM-dd" placeholder="请选择失效日期" style="width: 100%" v-model="currObj.end_at" value-format="yyyy-MM-dd HH:mm:ss" />
        </el-form-item>
       </el-col>
      </el-row>
     </div>
    </div>
    <div class="diaWrapper">
     <div class="topicHeader">
      登记信息
      <i :class="{foldBtn:true,onFold:this.foldShow2}" @click="fold(2)"></i>
     </div>
     <div class="foldWrap" v-show="foldShow2">
      <el-form-item label="登记类型">
       <el-radio-group @change="changeType" v-model="currObj.register_type">
        <el-radio label="personal">个人信息</el-radio>
        <el-radio label="company">公司信息</el-radio>
       </el-radio-group>
      </el-form-item>
      <el-row :gutter="14" v-show="currObj.register_type==='personal'">
       <el-col :span="12">
        <el-form-item label="身份证姓名">
         <el-input placeholder="请输入身份证姓名" type="text" v-model="registerInfo.personal.name" />
        </el-form-item>
       </el-col>
       <el-col :span="12">
        <el-form-item label="身份证号">
         <el-input placeholder="请输入身份证号" type="text" v-model="registerInfo.personal.id" />
        </el-form-item>
       </el-col>
       <el-col :span="12">
        <el-form-item label="手机号码">
         <el-input placeholder="请输入手机号码" type="text" v-model="registerInfo.personal.mobile" />
        </el-form-item>
       </el-col>
      </el-row>
      <el-row :gutter="14" v-show="currObj.register_type==='personal'">
       <el-col :span="12">
        <el-form-item label="银行卡号">
         <el-input placeholder="请输入银行卡号" type="text" v-model="registerInfo.personal.bank_account" />
        </el-form-item>
       </el-col>
       <el-col :span="12">
        <el-form-item label="银行">
         <el-input placeholder="请输入银行" type="text" v-model="registerInfo.personal.bank" />
        </el-form-item>
       </el-col>
      </el-row>
      <el-row :gutter="14" v-show="currObj.register_type==='company'">
       <el-col :span="12">
        <el-form-item label="企业名称">
         <el-input placeholder="请输入企业名称" type="text" v-model="registerInfo.company.name" />
        </el-form-item>
       </el-col>
       <el-col :span="12">
        <el-form-item label="营业执照号">
         <el-input placeholder="请输入营业执照号" type="text" v-model="registerInfo.company.business_id" />
        </el-form-item>
       </el-col>
       <el-col :span="12">
        <el-form-item label="对公账户">
         <el-input placeholder="请输入对公账户" type="text" v-model="registerInfo.company.bank_account" />
        </el-form-item>
       </el-col>
       <el-col :span="12">
        <el-form-item label="开户银行">
         <el-input placeholder="请输入开户银行" type="text" v-model="registerInfo.company.bank" />
        </el-form-item>
       </el-col>
      </el-row>
     </div>
    </div>
   </el-form>

   <div class="row rowbuttons">
    <el-button @click="handleConfirm" type="primary">确 定</el-button>
    <el-button @click="closeDialog">取 消</el-button>
   </div>
  </el-dialog>
 </div>
</template>

<script>
export default {
 data() {
  return {
   tit: '',
   dialogVisible: false,
   currObj: {
    contacts: ['', ''],
    register_info: {},
   },
   registerInfo: {
    personal: {},
    company: {},
   },
   foldShow1: true,
   foldShow2: false,
   currType: true,
   currType1: false,
   callback: null,
  }
 },
 methods: {
  closeDialog() {
   this.dialogVisible = false
   this.callback = null
  },
  cancelDialog() {
   this.dialogVisible = false
   this.callback = null
  },
  showDialog(title = '新增公司', data, callback) {
   this.tit = title
   this.dialogVisible = true
   this.registerInfo = {
    personal: {},
    company: {},
   }
   if (data) {
    this.currObj = data
    if (data.register_type === 'personal') {
     this.registerInfo.personal = JSON.parse(JSON.stringify(data.register_info))
    } else {
     this.registerInfo.company = JSON.parse(JSON.stringify(data.register_info))
    }
   } else {
    this.currObj = {
     contacts: ['', ''],
     register_type: 'personal',
     register_info: {},
    }
   }
   this.callback = callback
  },
  fold(part) {
   if (part === 1) {
    this.foldShow1 = !this.foldShow1
   } else if (part === 2) {
    this.foldShow2 = !this.foldShow2
   }
  },
  changeType(paramsId) {
   if (this.currType1) {
    this.currType = true
    this.currType1 = false
   } else {
    this.currType = false
    this.currType1 = true
   }
  },
  handleConfirm() {
   if (this.callback) {
    const info = this.currObj.register_type === 'personal' ? this.registerInfo.personal : this.registerInfo.company
    this.currObj.register_info = JSON.parse(JSON.stringify(info))
    this.currObj.register_info.type = this.currObj.register_type
    this.callback(this.currObj)
    this.closeDialog()
   }
  },
 },
}
</script>

<style lang="less">
#compdialogContent {
 .el-dialog__header {
  background: url('../../statics/images/other/标题栏BG@2x.png') no-repeat;
  background-size: 7.3rem 0.4rem;
  width: 7.3rem;
  height: 0.4rem;
  position: relative;
  padding: 0;

  .el-dialog__title {
   font-size: 1.5em;
   line-height: 0.4rem;
   float: left;
   margin-left: 0.4rem;
   color: white;
   font-weight: normal;
  }

  &::before {
   content: '';
   display: block;
   width: 0.24rem;
   height: 0.21rem;
   background: url('../../statics/images/other/yogo@2x.png') no-repeat center;
   background-size: 0.24rem 0.21rem;
   margin: 0.1rem;
   position: absolute;
  }

  .el-dialog__headerbtn {
   width: 0.29rem;
   height: 0.29rem;
   background-color: #fff;
   top: 0.06rem;
   right: -0.48rem;
   border-radius: 50%;

   i::before {
    font-size: 28px;
    // line-height: 29px;
   }
  }
 }

 border-radius: 5px;
 box-sizing: border-box;
 overflow-x: hidden;

 .row {
  display: flex;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 0.1rem;

  &:last-of-type {
   margin-bottom: 0;
  }
 }

 .rowbuttons {
  justify-content: center;
 }

 .column {
  position: relative;
  width: 2.1rem;
  margin-right: 15px;

  input {
   width: 2.1rem;
   // font-size: 1.1em;
   color: #b1b1b1;
   border: 1px solid #d2d2d2;
   border-radius: 5px;
   height: 0.3rem;
   text-indent: 8px;
  }

  input[type='radio'] {
   width: 0.16rem;
   height: 0.16rem;
   line-height: 0.16rem;
   vertical-align: middle;
   margin-left: 5px;
  }

  input::-webkit-input-placeholder {
   color: #b1b1b1;
  }

  .yogo-input-name {
   color: #000000;
   font-size: 0.14rem;
   padding-bottom: 0.1rem;
  }

  .radios {
   display: flex;
   color: #b1b1b1;
   width: 100%;

   label {
    width: 25%;
   }
  }

  .btnicon {
   position: absolute;
   right: 0px;
   height: 0.3rem;
   width: 0.3rem;
   background: #d2d2d2 url(../../statics/images/icondown2.png) center center no-repeat;
   border-radius: 0 5px 5px 0;
  }

  .btnimgdown {
   background-image: url(../../statics/images/icondown2.png);
   background-size: 0.12rem 0.08rem;
  }

  .btnimgdate {
   background-image: url(../../statics/images/icondate2.png);
   background-size: 0.16rem 0.15rem;
  }
 }

 .columndesc {
  textarea {
   font-size: 1.1em;
   color: #b1b1b1;
   border: 1px solid #d2d2d2;
   border-radius: 5px;
   width: 4.35rem;
   height: 1rem;
   text-indent: 8px;
  }
 }

 .buttons {
  text-align: center;
  margin-bottom: 0;
  width: 3.22rem;
  margin: 0 auto;
 }

 .diaWrapper {
  width: 5rem;
  border-radius: 6px;
  border: 1px solid #d2d2d2;
  padding: 30/100rem;
  // padding-bottom: 0;
  margin-bottom: 0.4rem;
  background: #f1f1f1;

  .topicHeader {
   font-size: 0.2rem;
   padding-left: 6px;
   background: url('../../statics/images/矩形 1 拷贝 9@2x.png') no-repeat 0 center;
   background-size: 4/100rem 22/100rem;
   line-height: 0.2rem;
   margin-bottom: 0.2rem;
   color: #000;

   i.foldBtn {
    float: right;
    background: url('../../statics/images/icon-up.png') no-repeat center;
    width: 0.14rem;
    height: 0.08rem;
    background-size: contain;
    margin-top: 0.06rem;
    cursor: pointer;
   }

   .onFold {
    transform: rotate(180deg);
   }
  }
 }

 .el-dialog__body {
  padding: 30px;

  .el-button--default {
   width: 0.8rem;
   height: 0.3rem;
   border-radius: 0.06rem;
   padding: 0;
  }

  .el-button--primary {
   width: 0.8rem;
   height: 0.3rem;
   background: rgba(234, 67, 53, 1);
   border: none;
   border-radius: 0.06rem;
   padding: 0;
  }
 }

 .el-dialog[aria-label='修改公司'],
 .el-dialog[aria-label='新增公司'] {
  .el-dialog__header {
   background: url('../../statics/images/other/标题栏BG@2x.png') no-repeat center;
   background-size: 5.5rem 0.4rem;
   width: 5.5rem;
   height: 0.4rem;
   position: relative;
   padding: 0;
  }

  .column {
   input.yzm {
    width: 55%;
   }

   button.getyzmbtn {
    width: 0.8rem;
    height: 0.3rem;
    border: none;
    background: #208def;
    color: #fff;
    margin-left: 10px;
    padding: 0;
   }
  }
 }

 .foldWrap {
  .comptypes {
   color: #818181;
   vertical-align: middle;
   display: flex;

   input.checkinput {
    width: 0.17rem;
    height: 0.17rem;
    vertical-align: middle;
   }
  }
 }
}
</style>
