<template>
 <div id="yogo-systemEntry" class="eltables" v-loading.fullscreen.lock="listLoading" element-loading-background="rgba(0, 0, 0, 0.8)">
  <div class="toolbar-container duku">
   <div>
    <span class="yogo-title toolbar-title">系统条目管理</span>
    <el-button size="small" class="filter-item add" type="primary" icon="el-icon-plus" @click="handleAdd">新增条目</el-button>
   </div>

   <div class="toolbar-box">
    <el-form label-position="top">
     <el-form-item label="门店" class="filter-item">
      <el-select class="item-content" v-model="formModel.storeId" placeholder="" clearable size="small" style="" @change="formModel.themeId = null">
       <el-option v-for="(item, idx) of shopList" :key="idx" :value="item.id" :label="item.name" />
      </el-select>
     </el-form-item>
     <el-form-item label="开始时间" class="filter-item">
      <el-date-picker class="item-content" type="date" clearable size="small" style="" placeholder="" v-model="formModel.startTime" value-format="yyyy-MM-dd HH:mm:ss" />
     </el-form-item>
     <el-form-item label="结束时间" class="filter-item">
      <el-date-picker class="item-content" type="date" clearable size="small" style="" placeholder="" v-model="formModel.endTime" value-format="yyyy-MM-dd HH:mm:ss" />
     </el-form-item>
    </el-form>

    <div class="buttons-div">
     <el-button size="small" class="filter-item" type="primary" icon="el-icon-search" @click="onClick_search($event)">查询</el-button>
     <el-button size="small" class="filter-item" type="primary" icon="el-icon-download" @click="onClick_exportExcel($event)">导出Excel</el-button>
    </div>
   </div>
  </div>

  <el-table :data="list" class="data-table" stripe height="1000">
   <el-table-column prop="type" label="条目类型" align="center" :formatter="convertType" />
   <el-table-column prop="name" label="条目名称" align="center" />
   <el-table-column align="center" label="所属门店" prop="_storeName" />
   <el-table-column prop="start_time" label="生效时间" align="center">
    <template slot-scope="{ row }">
     {{ !_.isEmpty(row.start_time) ? formatTime(row.start_time, '{y}-{m}-{d}') : null }}
    </template>
   </el-table-column>
   <el-table-column prop="expired_time" label="失效时间" align="center">
    <template slot-scope="{ row }">
     {{ !_.isEmpty(row.expired_time) ? formatTime(row.expired_time, '{y}-{m}-{d}') : null }}
    </template>
   </el-table-column>
   <el-table-column prop="price" :formatter="convertYuan" label="条目金额" align="center" />
   <el-table-column prop="actions" label="操作" width="120" align="left">
    <template slot-scope="{ row }">
     <el-button type="primary" size="small" class="el-icon-edit" circle @click="handleEdit(row)"></el-button>
     <el-button type="danger" size="small" class="el-icon-delete" circle @click="handleDelete(row)"></el-button>
    </template>
   </el-table-column>
  </el-table>

  <pagination v-show="true" :total="listSize" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

  <delDialog ref="delDialog" />
  <editDialog ref="editDialog" />
 </div>
</template>

<script>
import delDialog from '@/views/template/delDialog'
import editDialog from '@/views/htgl/systemEntryEdit'
import Pagination from '@/components/Pagination'
import { parseTime } from '@/utils'
import { getSytemEntryList, saveOrUpdateSytemEntry, deleteSytemEntry } from '@/api/systementry'
import { getBusinesses, getShops, getResults } from '@/api/shop'

export default {
 components: {
  delDialog,
  editDialog,
  Pagination,
 },
 data() {
  return {
   shopList: null,
   listExcel: null,

   formModel: {
    storeId: null,
    themeId: null,
    payChannelId: null,
    startTime: null,
    endTime: null,
   },

   list: null,
   listSize: 0,
   listLoading: true,
   listQuery: {
    page: 1,
    limit: 10,
    importance: undefined,
    title: undefined,
    type: undefined,
    status: undefined,
    sort: '+id',
   },
   entryType: ['赠品', '出错惩罚', '其他收入', '支出'],
  }
 },
 beforeCreate() {
  Promise.all([getShops()]).then(result => {
   this.shopList = result[0].data.data
   this.getList()
  })
 },
 methods: {
  convertYuan: function(row) {
   return row.price / 100
  },
  convertType: function(row) {
   return this.entryType[row.type]
  },
  onClick_exportExcel() {
   if (_.isArray(this.listExcel) && !_.isEmpty(this.listExcel)) {
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    let columns = []
    _.entries(this.listExcel[0]).filter((ele, idx, arr) => {
     switch (ele[0]) {
      case 'type_name':
       columns.push({
        prop: ele[0],
        label: '条目类型',
       })
       return true

      case 'name':
       columns.push({
        prop: ele[0],
        label: '条目名称',
       })
       return true

      case '_storeName':
       columns.push({
        prop: ele[0],
        label: '所属门店',
       })
       return true

      case 'start_time':
       columns.push({
        prop: ele[0],
        label: '生效时间',
       })
       return true

      case 'expired_time':
       columns.push({
        prop: ele[0],
        label: '失效时间',
       })
       return true

      default:
       return false
     }
    })
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    this.$export.excel({ columns: columns, data: this.listExcel }).then(() => {})
   } else {
    this.$message.error('没有数据可导出')
   }
  },

  formatTime(v, fmt) {
   return parseTime(v, fmt)
  },
  onClick_search() {
   this.listQuery.storeId = this.formModel.storeId
   this.listQuery.startTime = this.formModel.startTime
   this.listQuery.endTime = this.formModel.endTime

   this.getList()
  },
  getList() {
   this.listLoading = true

   let param = {
    pageSize: this.listQuery.limit,
    pageNum: this.listQuery.page,
    isPage: true,

    storeId: this.listQuery.storeId,
    startTime: this.listQuery.startTime,
    endTime: this.listQuery.endTime,
   }
   getSytemEntryList(param)
    .then(response => {
     response.data.rows.forEach((ele, idx, arr) => {
      ele.start_time = new Date(ele.start_time) == 'Invalid Date' ? null : new Date(ele.start_time).format('yyyy-MM-dd')
      ele.expired_time = new Date(ele.expired_time) == 'Invalid Date' ? null : new Date(ele.expired_time).format('yyyy-MM-dd')

      let foundEle = this.shopList.find((ele_store, idx, arr) => {
       return ele_store.id === ele.storeId
      })
      if (!_.isUndefined(foundEle)) {
       // 找到了!
       ele._storeName = foundEle.name
      }
     })

     this.list = response.data.rows
     this.listSize = response.data.total
    })
    .then(response => {
     this.getExcelList()
    })
  },
  getExcelList() {
   let param = {
    // pageSize: this.listQuery.limit,
    // pageNum: this.listQuery.page,
    isPage: false,
   }
   getSytemEntryList(param).then(response => {
    response.data.forEach((ele, idx, arr) => {
     ele.start_time = new Date(ele.start_time) == 'Invalid Date' ? null : new Date(ele.start_time).format('yyyy-MM-dd')
     ele.expired_time = new Date(ele.expired_time) == 'Invalid Date' ? null : new Date(ele.expired_time).format('yyyy-MM-dd')

     let foundEle = this.shopList.find((ele_store, idx, arr) => {
      return ele_store.id === ele.storeId
     })
     if (!_.isUndefined(foundEle)) {
      // 找到了!
      ele._storeName = foundEle.name
     }
    })

    this.listExcel = response.data
    this.listLoading = false
   })
  },

  handleAdd() {
   this.$refs['editDialog'].showDialog('新增条目', null, this.doCreate)
  },
  handleEdit(item) {
   // create_time: "2019-01-02 12:08:26"
   // expired_time: "CURRENT_TIMESTAMP"
   // hasDelete: 1
   // id: 28
   // name: "通关券"
   // price: 70
   // start_time: "2019-01-02"
   // storeId: 8
   // type: 0
   // type_name: "赠品"
   // update_time: "2019-01-02 12:08:26"

   const target = {
    storeId: item.storeId,
    id: item.id,
    type: item.type,
    name: item.name,
    price: item.price / 100,
    start_time: !_.isEmpty(item.start_time) ? this.formatTime(item.start_time, '{y}-{m}-{d}') : null,
    expired_time: !_.isEmpty(item.expired_time) ? this.formatTime(item.expired_time, '{y}-{m}-{d}') : null,
   }
   this.$refs['editDialog'].showDialog('修改条目', target, this.doUpdate)
  },
  handleDelete(item) {
   this.$refs['delDialog'].showDialog('删除条目', '是否删除当前条目', item, this.doDelete)
  },
  doCreate(data) {
   data.start_time = data.start_time ? new Date(data.start_time).format('yyyy-MM-dd') : undefined
   data.expired_time = data.expired_time ? new Date(data.expired_time).format('yyyy-MM-dd') : undefined
   saveOrUpdateSytemEntry(data).then(response => {
    this.getList()
   })
  },
  doUpdate(data) {
   data.start_time = data.start_time ? new Date(data.start_time).format('yyyy-MM-dd') : undefined
   data.expired_time = data.expired_time ? new Date(data.expired_time).format('yyyy-MM-dd') : undefined
   data.price = data.price * 100
   saveOrUpdateSytemEntry(data).then(response => {
    this.getList()
   })
  },
  doDelete(delTgt) {
   deleteSytemEntry(delTgt.id).then(response => {
    this.getList()
   })
  },
 },
}
</script>

<style lang="less">
@import url(../../statics/css/eltable.css);
</style>

<style lang="scss" scoped>
#yogo-systemEntry {
 .data-table {
  // 60px headbar
  // 233px search block
  // 100px pagination block
  max-height: calc(100vh - 60px - 233px - 100px);
 }

 .el-button.add {
  float: right;
  position: relative;
  top: 10px;
  right: 10px;
 }
}
</style>
