<template>
 <div id="yogo-shopManagement" class="eltables">
  <div class="toolbar-container">
   <span class="yogo-title toolbar-title">门店管理</span>
   <el-button type="primary" class="myicon iconadd" @click="handleAdd"> <i></i>新增门店 </el-button>
   <el-button type="primary" class="myicon iconadd"> <i></i>导出Excel </el-button>
  </div>
  <el-table :data="list" class="data-table" stripe>
   <el-table-column prop="name" label="门店名称" align="center" />
   <el-table-column prop="company.name" label="所属公司" align="center" />
   <el-table-column prop="manager" label="负责人" align="center" />
   <el-table-column prop="contacts" label="联系方式1" align="center">
    <template slot-scope="{ row }">{{ row.contacts.length > 0 ? row.contacts[0] : '' }}</template>
   </el-table-column>
   <el-table-column prop="contacts" label="联系方式2" align="center">
    <template slot-scope="{ row }">{{ row.contacts.length > 1 ? row.contacts[1] : '' }}</template>
   </el-table-column>
   <el-table-column prop="begin_at" label="开通日期" align="center">
    <template slot-scope="{ row }">{{ formatTime(row.begin_at, '{y}-{m}-{d}') }}</template>
   </el-table-column>
   <el-table-column prop="end_at" label="失效日期" align="center">
    <template slot-scope="{ row }">{{ formatTime(row.end_at, '{y}-{m}-{d}') }}</template>
   </el-table-column>
   <el-table-column prop="actions" label="操作" width="120" align="left">
    <template slot-scope="{ row }">
     <el-button type="primary" size="small" class="el-icon-edit" circle @click="handleEdit(row)"></el-button>
     <el-button type="danger" size="small" class="el-icon-delete" circle @click="handleDelete(row)"></el-button>
    </template>
   </el-table-column>
  </el-table>

  <pagination v-show="true" :total="listSize" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getShopList" />

  <delDialog ref="DelDialogForm" :confirm="doDelete" />
  <editDialog ref="EditDialogForm" />
 </div>
</template>

<script>
import delDialog from '@/views/template/delDialog'
import editDialog from '@/views/dmgl/shopManagementEdit'
import Pagination from '@/components/Pagination'
import { parseTime } from '@/utils'
import { getShops, createShop, updateShop, deleteShop } from '@/api/shop'

const pageData = [
 // {
 //   id: 1,
 //   name: "花果山店",
 //   manager: "孙悟空",
 //   address: "",
 //   contacts: ["", ""],
 //   description: "",
 //   company: {
 //     id: 1,
 //     name: "优果"
 //   },
 //   begin_at: 1571900646,
 //   end_at: 1571900646,
 //   register_type: 1
 // },
 // {
 //   id: 2,
 //   name: "青山店",
 //   manager: "老余",
 //   address: "",
 //   contacts: ["", ""],
 //   description: "",
 //   company: {
 //     id: 1,
 //     name: "优果"
 //   },
 //   begin_at: 1571900646,
 //   end_at: 1571900646,
 //   register_type: 1
 // }
]

export default {
 components: {
  delDialog,
  // tableTemplate,
  editDialog,
  Pagination
 },
 data() {
  return {
   list: null,
   listSize: 0,
   listLoading: true,
   listQuery: {
    page: 1,
    limit: 10,
    importance: undefined,
    title: undefined,
    type: undefined,
    status: undefined,
    sort: '+id'
   },
   selectedItem: null
  }
 },
 created() {
  this.getShopList()
 },
 methods: {
  formatTime(v, fmt) {
   return parseTime(v, fmt)
  },
  getShopList() {
   this.listLoading = true
   getShops().then(response => {
    this.list = response.data.data
    this.listSize = this.list.length
   })
   // this.list = pageData;
   // this.listSize = this.list.length;
   this.listLoading = false
  },
  handleAdd() {
   this.$refs['EditDialogForm'].showDialog('新增门店', null, this.doCreate)
  },
  handleEdit(item) {
   const target = {
    id: item.id,
    name: item.name,
    manager: item.manager,
    // phone1: item.contacts.length > 0 ? item.contacts[0] : "",
    // phone2: item.contacts.length > 0 ? item.contacts[1] : "",
    //begin_at: this.formatTime(item.begin_at, "{y}-{m}-{d}"),
    //end_at: this.formatTime(item.end_at, "{y}-{m}-{d}"),
    begin_at: item.begin_at,
    end_at: item.end_at,
    register_type: item.register_type,
    address: item.address,
    description: item.description,
    company: item.company,
    contacts: item.contacts
   }
   this.selectedItem = item
   this.$refs['EditDialogForm'].showDialog('修改门店', target, this.doUpdate)
  },
  handleDelete(item) {
   this.selectedItem = item
   this.$refs['DelDialogForm'].showDialog('删除门店', '是否删除当前门店', item, this.doDelete)
  },
  doCreate(data) {
   createShop(data).then(response => {
    // TODO append new record to list

    this.list.push(data)
   })
  },
  doUpdate(data) {
   updateShop(data).then(response => {
    // TODO update record
    this.selectedItem.name = data.name
    this.selectedItem.company = data.company
    this.selectedItem.manager = data.manager
    this.selectedItem.contacts[0] = data.contacts[0]
    this.selectedItem.contacts[1] = data.contacts[1]
    this.selectedItem.begin_at = data.begin_at
    this.selectedItem.end_at = data.end_at
    this.selectedItem.register_type = data.register_type
    this.selectedItem.register_info = data.register_info
   })
  },
  doDelete() {
   deleteShop(this.selectedItem).then(response => {
    // TODO
    this.list.splice(this.list.indexOf(this.selectedItem), 1)
   })
  }
 }
}
</script>

<style lang="less">
@import url(../../statics/css/eltable.css);
</style>
