<template>
 <div class="yogotheme clearfix" element-loading-background="rgba(0, 0, 0, 0.8)" v-loading.fullscreen.lock="loading">
  <!-- hint -->
  <el-container class="dk-row hint" style="height: fit-content;" direction="horizontal vertical">【按F10可切换全屏】</el-container>

  <!-- 顶栏 -->
  <el-container class="themeheader" direction="horizontal">
   <h1>密室主题运营</h1>

   <el-container class="search" style="height: fit-content;" direction="horizontal">
    <el-input placeholder="请输入客户姓名/电话以搜索相关订单" v-model="search.nameOrPhone" class="input-with-select" @keyup.enter.native="onClick_search">
     <el-switch slot="prepend" style="display: block;" v-model="search.isAllDay" active-color="#b1b1b1" inactive-color="#b1b1b1" active-text="全部" inactive-text="营业日"></el-switch>
     <el-button slot="append" icon="el-icon-search" @click="onClick_search">搜索</el-button>
    </el-input>
   </el-container>

   <el-drawer title="订单查询结果" class="eltables" :visible.sync="search.drawer" :direction="search.direction">
    <el-table :data="search.list" class="data-table" stripe height="1000">
     <el-table-column label="主题照片" align="center">
      <template slot-scope="scope">
       <img :src="combineImgUrl(scope.row._themePicUrl)" style="height: 40px;" />
      </template>
     </el-table-column>

     <el-table-column label="主题名称" prop="_themeName" align="center" />
     <el-table-column label="客户姓名" prop="reserverName" align="center" />
     <el-table-column label="客户电话" prop="reserverPhone" align="center" />
     <el-table-column label="开场日期" prop="_sessionStartDay" align="center" />
     <el-table-column label="开场时间" prop="_startTime" align="center" />
     <el-table-column label="结束时间" prop="_endTime" align="center" />
     <el-table-column label="玩家人数" prop="players" align="center" />

     <el-table-column prop="actions" label="操作" width="300" align="center">
      <template slot-scope="scope">
       <el-button type="danger" size="mini" class="el-icon-delete" @click="onClick_unreserve(scope.row)" :disabled="calc_dayStatus(scope.row._sessionStartDay) != 1">退单</el-button>
       <el-button type="primary" size="mini" class="el-icon-edit" @click="onClick_detail(scope.row)" :disabled="calc_dayStatus(scope.row._sessionStartDay) <= -3">修改</el-button>
       <el-button type="info" size="mini" class="el-icon-info" @click="onClick_readOnly(scope.row)">查看</el-button>
      </template>
     </el-table-column>
    </el-table>

    <pagination v-show="true" :total="search.listSize" :page.sync="search.listQuery.page" :limit.sync="search.listQuery.limit" @pagination="onClick_search" />
   </el-drawer>

   <el-select class="store-select" @change="onChange_selectShop" placeholder="请选择门店" v-model="selectedShopId" size="mini">
    <el-option :key="idx" :label="shop.name" :value="shop.id" v-for="(shop, idx) in allShopArr"></el-option>
   </el-select>
  </el-container>

  <!-- 日期 -->
  <el-container
   class="themedate"
   :class="{
    'active-history-in-2-day': selectedDayStatus == -1,
    'active-history-out-2-day': selectedDayStatus == -3,
   }"
  >
   <div
    @click="onClick_workDay()"
    class="themedate_l"
    style="font-size: 16px;"
    :class="{
     active: isWorkDaySelected,
     inactive: !isWorkDaySelected,
    }"
   >
    营业日
   </div>
   <div @click="onClick_dateLeft()" class="themedate_l">
    <img alt src="../../statics/images/日期翻页箭头左@2x.png" />
   </div>
   <div class="themedate_m">
    <div
     :class="{
      active: date.isSelected,
      inactive: !date.isSelected,
      'active-history-in-2-day': date.isSelected && selectedDayStatus == -1,
      'active-history-out-2-day': date.isSelected && selectedDayStatus == -3,
     }"
     :key="idx"
     @click="onClick_date(date)"
     class="mdate"
     v-for="(date, idx) in dateList"
    >
     <span>{{ date.label }}</span>
    </div>
   </div>
   <div @click="onClick_dateRight()" class="themedate_r">
    <img alt src="../../statics/images/日期翻页箭头右@2x.png" />
   </div>
   <el-container class="themedate_date" direction="vertical">
    <el-date-picker :picker-options="pickerOptions" @change="onChange_datePicker" placeholder="选择日期" type="date" v-model="datePicked"></el-date-picker>
    <span>日期</span>
   </el-container>
  </el-container>

  <!-- 主题 -->
  <el-container class="themes-content-area" id="themes-content-area" direction="horizontal">
   <!-- top-cover-block -->
   <div class="top-cover-block" @click="onClick_clock()">
    <clock class="clock" :time="timeTest" size="100px"></clock>
   </div>

   <!-- time-list -->
   <el-container class="time-list" direction="vertical">
    <el-container class="cover-block" direction="vertical"></el-container>
    <el-container class="lines" direction="vertical">
     <el-container
      :key="idx"
      direction="vertical"
      v-for="(t, idx) in timeList"
      :class="{
       line: true,
       clicked: t._isClicked,
       unclicked: !t._isClicked,
      }"
      @click.native="onClick_time(t, timeList)"
     >
      <p class="time-text">{{ t.time }}</p>
     </el-container>
    </el-container>
   </el-container>

   <!-- current-line -->
   <el-container id="current-line" v-show="isWorkDaySelected" direction="horizontal">
    <el-tooltip effect="dark" placement="left">
     <div slot="content">当前时刻{{ currentTime }}</div>
     <i class="el-icon-time" style="font-size: 15px;"></i>
    </el-tooltip>
   </el-container>

   <!-- clock -->
   <el-container id="clock" v-show="isWorkDaySelected" direction="horizontal">
    <el-tooltip id="elToolTipCurrentTime" ref="elToolTipCurrentTime" effect="dark" placement="left">
     <div slot="content">当前时刻{{ currentTime }}</div>
     <i class="el-icon-time" style="font-size: 15px;"></i>
    </el-tooltip>
   </el-container>

   <!-- selected-time-line -->
   <el-container
    id="selected-time-line"
    direction="horizontal"
    :style="{
     top: selectedTimeLineTop,
    }"
   >
   </el-container>

   <!-- cover-layer -->
   <div class="cover-layer"></div>

   <!-- theme-column list -->
   <el-container
    :key="idxTheme"
    class="theme-column"
    :class="{
     clicked: theme._isClicked,
     unclicked: !theme._isClicked,
    }"
    direction="vertical"
    v-for="(theme, idxTheme) in themeSessionList"
   >
    <el-container class="thumbnail" direction="vertical" @click.native="onClick_thumbnail(theme, themeSessionList)">
     <el-container
      class="name"
      direction="vertical"
      :class="{
       'has-ing-schedule': calc_hasIngSchedule(theme),
      }"
      >{{ theme.themeName }}</el-container
     >
     <el-container class="pic" direction="vertical">
      <el-image fit="cover" draggable="false" :src="combineImgUrl(theme.themePicUrl)" />
     </el-container>
    </el-container>

    <el-container class="time-lines" direction="vertical">
     <el-container :key="idx" :style="idx % 2 ? 'background-color: #262D42' : 'background-color: #242A3B'" class="line" direction="vertical" v-for="(t, idx) in timeList"></el-container>
    </el-container>

    <el-tooltip :enterable="false" :open-delay="30000" effect="light" v-for="(scene, idxItem) in theme.schedules" placement="left" :key="idxItem">
     <div slot="content">
      计划开始时间： {{ scene.startTime }}
      <br />
      计划结束时间： {{ scene.endTime }}
      <br />
     </div>

     <!-- scd -->
     <el-container
      class="scene-card"
      :key="idxItem"
      :style="scene.themeSession._style"
      direction="horizontal"
      :class="{
       'is-not-reserved': !calc_isReserved(scene),
       'is-ing': calc_status(scene) === '进行中',
       'is-over': calc_status(scene) === '已结束未通关' || calc_status(scene) === '已结束已通过',
      }"
     >
      <div class="scene-card-wrapper" :id="'scene-card-wrapper' + '-' + theme.id + '-' + scene.id" @mousedown.self="onMouseEnterSceneCardWrapper('scene-card-wrapper' + '-' + theme.id + '-' + scene.id, scene)" @mouseout.self="onMouseOutSceneCardWrapper('scene-card-wrapper' + '-' + theme.id + '-' + scene.id, scene)">
       <!-- 退 -->
       <template v-if="calc_isHaveUnreservedOrder(scene.themeSession.statistics)">
        <svg-icon icon-class="unreserve" class="unreserve" />
       </template>

       <!-- 锁 -->
       <template v-if="calc_selectedDayStatus() == 1 && !calc_isReserved(scene)">
        <i v-if="!_.isNil(scene.themeSession.lockStatus) && scene.themeSession.lockStatus == 1" class="el-icon-lock big" @click="onClick_unlock(theme, scene)"></i>
        <i v-else class="el-icon-lock" @click="onClick_lock(theme, scene)"></i>
       </template>

       <!-- 第1行 已预定、未预定、已结束-->
       <template v-if="calc_status(scene) === '已结束未通关' || calc_status(scene) === '已结束已通过'">
        <p class="over">
         <i class="el-icon-sort"></i>
         已结束
        </p>
       </template>
       <template v-else-if="calc_isReserved(scene)">
        <el-badge :value="calc_totalPlayers(scene.themeSession.statistics)" class="" type="primary">
         <p class="sale">
          <i class="el-icon-sort" v-if="calc_status(scene) === '进行中'"></i>
          已预定
         </p>
        </el-badge>
       </template>
       <template v-else>
        <p class="unsale">
         未预定
        </p>
       </template>

       <!-- 第2行 可否组队、剩余时间、带场人 -->
       <p class="tip" v-if="calc_status(scene) === '未开场'">
        {{ calc_teamStatus(scene.themeSession.statistics) }}
       </p>
       <p class="tip" v-else-if="calc_status(scene) === '进行中'">
        {{ calc_leftTime(scene) }}
       </p>
       <p class="tip guild-name" v-else-if="calc_status(scene) === '已结束未通关' || calc_status(scene) === '已结束已通过'">
        {{ _.isString(scene.themeSession.guideName) && !_.isEmpty(scene.themeSession.guideName) ? scene.themeSession.guideName : ' - ' }}
       </p>

       <!-- 第3行 时间信息 -->
       <div v-if="calc_status(scene) === '未开场'" class="not-begin">{{ scene.startTime }} 开场</div>
       <div v-else-if="calc_status(scene) === '进行中'" class="progress">
        <el-progress :percentage="calc_status(scene) == '进行中' && _.isNumber(scene._percentage) ? scene._percentage : 0" :stroke-width="16" :text-inside="true" :status="scene._status"></el-progress>
        <div class="text">
         {{ calc_text(scene) }}
        </div>
       </div>
       <div v-else-if="calc_status(scene) === '已结束未通关'" class="game-over">
        <div class="text">
         {{ calc_format(scene) }}
        </div>
       </div>
       <div v-else-if="calc_status(scene) === '已结束已通过'" class="game-over">
        <div class="text">
         {{ calc_format(scene) }}
        </div>
       </div>

       <!-- 第4行 按钮 -->
       <p v-if="calc_status(scene) == '未开场'">
        <el-button @click="openDialog('reserve', scene, theme, null, null, calc_selectedDayStatus())" size="mini" class="btn btnreserve" v-if="calc_isReserved(scene)">
         {{ '订单' }}
        </el-button>
        <el-button @click="openDialog('reserve', scene, theme, null, null, calc_selectedDayStatus())" size="mini" class="btn btnreserve no-order" v-else-if="calc_selectedDayStatus() == 1">
         {{ '开始预定' }}
        </el-button>
        <el-button @click="openDialog('start', scene, theme, theme.schedules)" v-if="calc_isReserved(scene) && calc_selectedDayStatus() == 1" size="mini" class="btn btnbegin">开场</el-button>
       </p>
       <p v-else-if="calc_status(scene) == '进行中'">
        <el-button @click="openDialog('reserve', scene, theme, null, null, calc_selectedDayStatus())" size="mini" class="btn btnreserve">订单</el-button>
        <el-button @click="openDialog('ing', scene, theme)" size="mini" class="btn btning" id="success" v-if="calc_selectedDayStatus() == 1">进行中</el-button>
       </p>
       <p v-else-if="calc_status(scene) == '已结束未通关' || calc_status(scene) == '已结束已通过'">
        <el-button @click="openDialog('reserve', scene, theme, null, null, calc_selectedDayStatus())" size="mini" class="btn btnreserve">订单</el-button>
        <el-button @click="openDialog('gift', scene, theme, theme.schedules, selectedShopId)" size="mini" class="btn btngive" v-if="calc_selectedDayStatus() == 1">赠品</el-button>
       </p>
      </div>
     </el-container>
    </el-tooltip>
   </el-container>
   <!-- ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ -->
  </el-container>

  <!-- 各类窗口 -->
  <themegift ref="themeGift"></themegift>
  <themeing ref="themeIng"></themeing>
  <themereserve ref="themeReserve"></themereserve>
  <themestart ref="themeStart"></themestart>
  <delDialog ref="delDialog" />
 </div>
</template>

<script>
import { getThemeSessionById, getScheduleById, getThemeSessionList, saveOrUpdateThemeSession } from '@/api/theme/themeSession'
import { deleteTheme, getTheme, getThemeList, saveOrUpdateTheme, uploadThemeImage } from '@/api/theme/theme'
import { getShops, createShop, updateShop, deleteShop } from '@/api/shop'
import { getThemeStatisticsById, getThemeStatisticsList, getThemeStatisticsSearch, saveOrUpdateThemeStatistics, deleteThemeStatisticsById } from '@/api/theme/themeStatistics'

import themegift from './themegift.vue'
import themeing from './themeing.vue'
import themereserve from './themereserve.vue'
import themestart from './themestart.vue'
import Pagination from '@/components/Pagination'
import delDialog from '@/views/template/delDialog'

import Clock from 'vue-clock2'

let ds = new Date()
let de = new Date(ds.getFullYear(), ds.getMonth(), ds.getDate(), ds.getHours() + 1, ds.getMinutes(), ds.getSeconds())
let _this

function convert_from_text_to_24() {}

function convert_from_24_to_text() {}

export default {
 data() {
  return {
   isFullScreen: false,
   // +++++++++++++++++++++++++++++++++++++++
   timeTest: '10:40',
   clockInterval: null,
   // +++++++++++++++++++++++++++++++++++++++
   // 搜索相关
   search: {
    nameOrPhone: null,
    isAllDay: true,

    drawer: false,
    direction: 'ttb',

    list: null,
    listSize: 0,
    listLoading: true,
    listQuery: {
     page: 1,
     limit: 5,
    },
   },

   // +++++++++++++++++++++++++++++++++++++++
   selectedTimeLineTop: null,
   doubleSkew: require('@/statics/images/time.png'),

   // 一天的营业时间,会转钟
   workTime: {
    start: '8:00',
    end: '5:00',
   },
   isTurnClock: false,

   // 营业日
   workDate: {
    start: null, // Date类型
    end: null, // Date类型
   },

   handlerInterval: null,
   loading: true,
   currentTime: null,

   // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
   allThemeArr: null,
   allShopArr: null,
   selectedShopId: null,
   themeSessionList: null,

   // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
   pickerOptions: {
    disabledDate(time) {
     return _this.calc_dayInterval(_this.workDate.start, time) > 29
    },
   },
   datePicked: null,
   dateList: [],
   // 赠品弹窗
   giftFlag: false,
   timeList: [],
   topItemFixed: 210,
   percentage: 77,
  }
 },
 components: {
  themegift,
  themeing,
  themereserve,
  themestart,
  Pagination,
  delDialog,
  Clock,
 },

 watch: {
  timeList: {
   handler(val) {
    let foundEle = this.timeList.find((ele_time, idx, arr) => {
     return ele_time._isClicked
    })
    if (!_.isUndefined(foundEle)) {
     // 找到了!
     // todo 如果是转钟的时间，要转成类似25:00的格式
     this.selectedTimeLineTop = this.topItemFixed + this.calc_startTimeLastMin({ startTime: foundEle.time }) * 2 + 'px'
    } else {
     this.selectedTimeLineTop = '100px'
    }
   },
   deep: true,
  },
 },
 computed: {
  selectedDayStatus: function() {
   if (!_.isNil(this.selectedDate)) {
    let dateZeroClock_today = new Date(new Date().format('yyyy-MM-dd'))
    let dateZeroClock_selectedDate = new Date(new Date(this.selectedDate.date).format('yyyy-MM-dd'))
    let dateZeroClock_workDate = new Date(new Date(this.workDate.start).format('yyyy-MM-dd'))

    if (dateZeroClock_selectedDate.getTime() < dateZeroClock_workDate.getTime()) {
     if (dateZeroClock_workDate.getTime() - dateZeroClock_selectedDate.getTime() < 3600000 * 24 * 2) {
      // '过去的2天内'
      return -1
     } else {
      // '过去的2天前'
      return -3
     }
    } else {
     // '今天及以后'
     return 1
    }
   } else {
    // unknown
    return -100
   }
  },

  isWorkDaySelected: function() {
   if (!_.isNil(this.selectedDate)) {
    let dateZeroClock_selectedDate = new Date(new Date(this.selectedDate.date).format('yyyy-MM-dd'))
    let dateZeroClock_workDate = new Date(new Date(this.workDate.start).format('yyyy-MM-dd'))
    return dateZeroClock_selectedDate.getTime() === dateZeroClock_workDate.getTime()
   } else {
    return false
   }
  },
  selectedDate: function() {
   return _this.dateList.find(ele => {
    return ele.isSelected
   })
  },
  selectedShop: function() {
   let findRst = _this.allShopArr.find(ele => {
    return ele.id === _this.selectedShopId
   })
   if (findRst !== undefined) {
    return findRst
   } else {
    return null
   }
  },
 },

 beforeMount() {
  let nowUtc = new Date()
  let nowStr = nowUtc.format('yyyy-MM-dd') + ' 00:00:00'

  let today_zero_clock_utc = new Date(new Date().format('yyyy-MM-dd') + ' 00:00:00').getTime()

  let yesterday_zero_clock_utc = today_zero_clock_utc - 1000 * 60 * 60 * 24

  let yesterday_zero_clock_date = new Date()
  yesterday_zero_clock_date.setTime(yesterday_zero_clock_utc)
  let yesterday_zero_clock_str = yesterday_zero_clock_date.format('yyyy-MM-dd HH:mm:ss')

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  _this = this

  getShops()
   .then(result => {
    _this.allShopArr = result.data.data
    if (_this.allShopArr.length == 0) return null

    _this.selectedShopId = _this.allShopArr[0].id

    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // for test
    if (true) {
     let foundShop = _this.allShopArr.find((eleShop, idx, arr) => {
      return eleShop.name.includes('江汉路')
      // return eleShop.name.includes('新佳丽')
     })
     if (!_.isUndefined(foundShop)) {
      // 找到了!
      _this.selectedShopId = foundShop.id
     }
    }
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

    let param = {
     storeId: _this.selectedShopId,
     isPage: false,
    }
    return getThemeList(param)
   })
   .then(result => {
    if (result == null) return null
    _this.allThemeArr = result.data
    _this.allThemeArr.forEach((ele, idx, arr) => {
     ele._isClicked = false
    })
   })
   .then(result => {
    // 等_this.selectedShopId有值了,再进行日期init,其中的getsession需要以上的参数
    _this.initWorkDate()
   })

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  _this.handlerInterval = setInterval(() => {
   _this.refreshPercentage()
   _this.refreshCurrentLine()
   _this.refreshSceneCardStyle()

   let nowUtc = new Date().getTime()
   if (nowUtc > _this.workDate.end.getTime()) {
    _this.initWorkDate()
   }
  }, 1000 * 60)

  _this.clockInterval = setInterval(() => {
   _this.timeTest = new Date().format('HH:mm:ss')
  }, 1000 * 1)
 },

 mounted() {
  document.onkeydown = function(e) {
   if (e && e.key == 'F10') {
    let leftBar = document.getElementsByClassName('yogo-l-tarbar')[0]
    let topBar = document.getElementsByClassName('yogo-header')[0]
    let coverLayer = document.getElementsByClassName('cover-layer')[0]
    let themesContentAera = document.getElementById('themes-content-area')

    if (_this.isFullScreen) {
     // to not FullScreen
     _this.isFullScreen = false
     leftBar.style.display = 'block'
     topBar.style.display = 'flex'
     coverLayer.style.width = 'calc(100vw - 30px - 220px)'
     coverLayer.style.left = '220px'
     themesContentAera.style.height = 'calc(100vh - 60px - 90px - 60px)'

     // if (document.exitFullscreen) {
     //  document.exitFullscreen()
     // } else if (document.msExitFullscreen) {
     //  document.msExitFullscreen()
     // } else if (document.mozCancelFullScreen) {
     //  document.mozCancelFullScreen()
     // } else if (document.webkitCancelFullScreen) {
     //  document.webkitCancelFullScreen()
     // } else {
     //  window.parent.showTopBottom()
     // }
    } else {
     // to FullScreen
     _this.isFullScreen = true
     leftBar.style.display = 'none'
     topBar.style.display = 'none'
     coverLayer.style.width = 'calc(100vw - 30px)'
     coverLayer.style.left = '0'
     themesContentAera.style.height = 'calc(100vh - 60px - 90px - 15px)'

     // //W3C
     // if (themesContentAera.requestFullscreen) {
     //  themesContentAera.requestFullscreen()
     // }
     // //FireFox
     // else if (themesContentAera.mozRequestFullScreen) {
     //  themesContentAera.mozRequestFullScreen()
     // }
     // //Chrome等
     // else if (themesContentAera.webkitRequestFullScreen) {
     //  themesContentAera.webkitRequestFullScreen()
     // }
     // //IE11
     // else if (themesContentAera.msRequestFullscreen) {
     //  document.body.msRequestFullscreen()
     // }
    }
   }
  }

  function generateTimeList(startHr, endHr) {
   let timeList = []
   for (let hr = startHr; hr < endHr; hr++) {
    if (hr >= 24) {
     // timeList.push('次日 ' + (hr % 24) + ':00')
     // timeList.push('次日 ' + (hr % 24) + ':30')

     timeList.push({
      time: '次日 ' + (hr % 24) + ':00',
      _isClicked: false,
     })
     timeList.push({
      time: '次日 ' + (hr % 24) + ':30',
      _isClicked: false,
     })
    } else {
     // timeList.push('' + hr + ':00')
     // timeList.push('' + hr + ':30')

     timeList.push({
      time: '' + hr + ':00',
      _isClicked: false,
     })
     timeList.push({
      time: '' + hr + ':30',
      _isClicked: false,
     })
    }
   }
   // timeList[0]._isClicked = true

   return timeList
  }

  let startTimeHr_int = parseInt(this.workTime.start.split(':')[0])
  let endTimeHr_int = parseInt(this.workTime.end.split(':')[0])

  if (endTimeHr_int < startTimeHr_int) {
   this.isTurnClock = true
   this.timeList = generateTimeList(startTimeHr_int, 24 + endTimeHr_int)
  } else {
   this.isTurnClock = false
   this.timeList = generateTimeList(startTimeHr_int, endTimeHr_int)
  }
 },
 destroyed() {
  clearInterval(_this.handlerInterval) // 清除定时器
  clearInterval(_this.clockInterval) // 清除定时器

  document.onkeydown = null
 },
 methods: {
  onClick_unlock(theme, scene) {
   let cloneScene = _.cloneDeep(scene)
   cloneScene.themeSession.lockStatus = 0
   cloneScene.themeSession.sessionStartDay = new Date(this.selectedDate.date).format('yyyy-MM-dd')
   saveOrUpdateThemeSession(cloneScene.themeSession).then(response => {
    this.$set(scene.themeSession, 'lockStatus', 0)
    this.$set(scene.themeSession, 'sessionStartDay', cloneScene.themeSession.sessionStartDay)
    this.$message.success('已解锁该场次')
   })
  },

  onClick_lock(theme, scene) {
   let cloneScene = _.cloneDeep(scene)
   cloneScene.themeSession.lockStatus = 1
   cloneScene.themeSession.sessionStartDay = new Date(this.selectedDate.date).format('yyyy-MM-dd')
   saveOrUpdateThemeSession(cloneScene.themeSession).then(response => {
    this.$set(scene.themeSession, 'lockStatus', cloneScene.themeSession.lockStatus)
    this.$set(scene.themeSession, 'sessionStartDay', cloneScene.themeSession.sessionStartDay)
    this.$message.success('已锁定该场次')
   })
  },

  onMouseEnterSceneCardWrapper(id, scene) {
   if (this.calc_status(scene) == '进行中') {
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    let startTime_minutes_schedule = scene.startTime.split(':').reduce(function(accumulator, currentValue, currentIndex, array) {
     return accumulator + parseInt(currentValue) * Math.pow(60, array.length - currentIndex - 1)
    }, 0)

    let startTime_minutes_actual = new Date(scene.themeSession.openTime)
     .format('HH:mm')
     .split(':')
     .reduce(function(accumulator, currentValue, currentIndex, array) {
      return accumulator + parseInt(currentValue) * Math.pow(60, array.length - currentIndex - 1)
     }, 0)
    // +++++++++++++++++++++++++++++++++++++++
    let dom = document.getElementById(id)
    // dom.style.backgroundColor = 'rgba(135, 207, 235, 0.7)' // skyblue
    dom.style.backgroundColor = 'rgba(220, 220, 220, 0.7)' // gainboro
    dom.style.transform = 'translate(0, ' + (startTime_minutes_actual - startTime_minutes_schedule) * 2 + 'px)'
    dom.style.width = '97%'
    dom.style.minWidth = '97%'
    dom.style.maxWidth = '97%'
    dom.style.border = 'dotted 2px green'
    // dom.style.borderTop = 'solid 1px green'
    // dom.style.borderBottom = 'solid 1px green'
    dom.style.transition = 'transform 0.5s ease-in-out 100ms'
   } else if (this.calc_status(scene) == '已结束未通关' || this.calc_status(scene) == '已结束已通过') {
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    let startTime_minutes_schedule = scene.startTime.split(':').reduce(function(accumulator, currentValue, currentIndex, array) {
     return accumulator + parseInt(currentValue) * Math.pow(60, array.length - currentIndex - 1)
    }, 0)
    let endTime_minutes_schedule = scene.endTime.split(':').reduce(function(accumulator, currentValue, currentIndex, array) {
     return accumulator + parseInt(currentValue) * Math.pow(60, array.length - currentIndex - 1)
    }, 0)

    let startTime_minutes_actual = new Date(scene.themeSession.openTime)
     .format('HH:mm')
     .split(':')
     .reduce(function(accumulator, currentValue, currentIndex, array) {
      return accumulator + parseInt(currentValue) * Math.pow(60, array.length - currentIndex - 1)
     }, 0)
    let endTime_minutes_actual = new Date(scene.themeSession.closeTime)
     .format('HH:mm')
     .split(':')
     .reduce(function(accumulator, currentValue, currentIndex, array) {
      return accumulator + parseInt(currentValue) * Math.pow(60, array.length - currentIndex - 1)
     }, 0)
    // +++++++++++++++++++++++++++++++++++++++
    let dom = document.getElementById(id)
    // dom.style.backgroundColor = 'rgba(135, 207, 235, 0.9)' // skyblue
    dom.style.backgroundColor = 'rgba(220, 220, 220, 0.7)' // gainboro
    dom.style.transform = 'translate(0, ' + (startTime_minutes_actual - startTime_minutes_schedule) * 2 + 'px)'
    dom.style.height = ((endTime_minutes_actual - startTime_minutes_actual) / (endTime_minutes_schedule - startTime_minutes_schedule)) * 100 + '%'
    dom.style.minHeight = dom.style.height
    dom.style.maxHeight = dom.style.height
    dom.style.width = '97%'
    dom.style.minWidth = '97%'
    dom.style.maxWidth = '97%'
    dom.style.border = 'dotted 2px green'
    // dom.style.borderTop = 'solid 1px green'
    // dom.style.borderBottom = 'solid 1px green'
    dom.style.transition = 'transform 0.5s ease-in-out 100ms'
   }
  },

  onMouseOutSceneCardWrapper(id, scene) {
   if (this.calc_status(scene) == '进行中' || this.calc_status(scene) == '已结束未通关' || this.calc_status(scene) == '已结束已通过') {
    let dom = document.getElementById(id)
    dom.style.backgroundColor = 'rgba(220, 220, 220, 0)'
    dom.style.transform = 'translate(0, 0)'

    dom.style.height = '100%'
    dom.style.minHeight = '100%'
    dom.style.maxHeight = '100%'
    dom.style.width = '100%'
    dom.style.minWidth = '100%'
    dom.style.maxWidth = '100%'

    dom.style.border = 'dotted 0px green'
    dom.style.transition = 'transform 0.5s ease-in-out'
   } else {
   }
  },

  calc_hasIngSchedule: function(theme) {
   if (!_.isEmpty(theme) && !_.isEmpty(theme.schedules)) {
    return theme.schedules.some((eleSchedule, idx, arr) => {
     return eleSchedule.themeSession.sessionStatus === 1
    })
   } else {
    return false
   }
  },

  calc_dayStatus: function(day) {
   if (_.isString(day) && !_.isEmpty(day)) {
    let dateZeroClock_day = new Date(day)
    let dateZeroClock_workDate = new Date(new Date(this.workDate.start).format('yyyy-MM-dd'))

    if (dateZeroClock_day.getTime() < dateZeroClock_workDate.getTime()) {
     if (dateZeroClock_workDate.getTime() - dateZeroClock_day.getTime() < 3600000 * 24 * 2) {
      // '过去的2天内'
      return -1
     } else {
      // '过去的2天前'
      return -3
     }
    } else {
     // '今天及以后'
     return 1
    }
   } else {
    // unknown
    return -100
   }
  },

  calc_selectedDayStatus: function() {
   if (!_.isNil(this.selectedDate)) {
    let dateZeroClock_today = new Date(new Date().format('yyyy-MM-dd'))
    let dateZeroClock_selectedDate = new Date(new Date(this.selectedDate.date).format('yyyy-MM-dd'))
    let dateZeroClock_workDate = new Date(new Date(this.workDate.start).format('yyyy-MM-dd'))

    if (dateZeroClock_selectedDate.getTime() < dateZeroClock_workDate.getTime()) {
     if (dateZeroClock_workDate.getTime() - dateZeroClock_selectedDate.getTime() < 3600000 * 24 * 2) {
      // '过去的2天内'
      return -1
     } else {
      // '过去的2天前'
      return -3
     }
    } else {
     // '今天及以后'
     return 1
    }
   } else {
    // unknown
    return -100
   }
  },

  onClick_clock() {
   this.refreshScroll()
  },

  calc_orderButtonDisabled(themeSession) {
   if (!_.isEmpty(themeSession.closeTime)) {
    let utcSec_closeTime = new Date(themeSession.closeTime).getTime() / 1000
    let utcSec_now = new Date().getTime() / 1000
    if (utcSec_now - utcSec_closeTime > 60 * 60 * 48) {
     // if (utcSec_now - utcSec_closeTime > 2) {
     return true
    } else {
     return false
    }
   } else {
    return false
   }
  },

  calc_teamStatus(statistics) {
   if (_.isArray(statistics) && !_.isEmpty(statistics) && !_.isNil(statistics[0].team) && statistics[0].team == '1') {
    return '不可组队'
   } else {
    return '可组队'
   }
  },

  calc_totalPlayers(statistics) {
   return statistics.reduce(function(accumulator, currentValue, currentIndex, array) {
    if (_.isNaN(currentValue.players) || _.isNil(currentValue.unreservedStatus) || currentValue.unreservedStatus == 1) {
     return accumulator
    } else {
     return accumulator + currentValue.players
    }
   }, 0)
  },

  onClick_unreserve(order) {
   function _unreserveOrder(orderId) {
    getThemeStatisticsById(orderId).then(response => {
     let orderTemp = response.data.data
     orderTemp.unreservedStatus = 1
     orderTemp.unreservedTime = new Date().format('yyyy-MM-dd HH:mm:ss')

     if (_.isArray(orderTemp.costs) && !_.isEmpty(orderTemp.costs)) {
      orderTemp.costs.forEach((elePayEntry, idx, arr) => {
       if (elePayEntry.inAccountStatus == 1) {
        elePayEntry.inAccountStatus = 2
        elePayEntry.outAccountTime = new Date().format('yyyy-MM-dd HH:mm:ss')
       }
      })
     }

     saveOrUpdateThemeStatistics(orderTemp).then(response => {
      _this.onClick_search()
      _this.$message.success('退订成功')

      // sync
      if (_this.selectedDate.date.format('yyyy-MM-dd') === order._sessionStartDay) {
       _this.themeSessionList.some((eleTheme, idx, arr) => {
        return eleTheme.schedules.some((eleSchedule, idx, arr) => {
         let foundIdx = eleSchedule.themeSession.statistics.findIndex((eleOrder, idx, arr) => {
          return eleOrder.id === order.id
         })

         if (foundIdx !== -1) {
          eleSchedule.themeSession.statistics[foundIdx].unreservedStatus = 1
          return true
         } else {
          return false
         }
        })
       })
      }
     })
    })
   }

   this.$refs['delDialog'].showDialog('退单', '此订单已保存在服务器, 是否退单?', order.id, _unreserveOrder)
  },

  onClick_detail(order) {
   function _edit() {
    _this.onClick_search()
   }

   this.openDialog('reserve', order.theme.schedules[0], order.theme, true, _edit, _this.calc_dayStatus(order._sessionStartDay))
  },

  onClick_readOnly(order) {
   this.openDialog('reserve', order.theme.schedules[0], order.theme, true, null, -100)
  },

  onClick_search() {
   if (_.isEmpty(this.search.nameOrPhone)) {
    this.$message.error('请输入合法值!')
    return
   }

   let param = {
    pageSize: this.search.listQuery.limit,
    pageNum: this.search.listQuery.page,
    isPage: true,

    startTime: null,
    endTime: null,
    format: this.search.nameOrPhone,
    storeId: this.selectedShopId,

    //todo 如果想同时查询已退订和未退订的呢?
    unreservedStatus: 0,
   }

   if (this.search.isAllDay) {
    param.startTime = '2000-01-01'
    param.endTime = '2100-01-01'
   } else {
    let date = new Date()
    param.startTime = date.format('yyyy-MM-dd')
    date.setDate(date.getDate() + 1)
    param.endTime = date.format('yyyy-MM-dd')
   }

   this.loading = true
   getThemeStatisticsSearch(param).then(response => {
    if (_.isArray(response.data.rows) && !_.isEmpty(response.data.rows)) {
     response.data.rows.forEach((eleOrder, idx, arr) => {
      this.$set(eleOrder, '_themePicUrl', eleOrder.theme.themePicUrl)
      this.$set(eleOrder, '_themeName', eleOrder.theme.themeName)

      this.$set(eleOrder, '_startTime', eleOrder.theme.schedules[0].startTime)
      this.$set(eleOrder, '_endTime', eleOrder.theme.schedules[0].endTime)

      this.$set(eleOrder, '_sessionStartDay', eleOrder.theme.schedules[0].themeSession.sessionStartDay)
     })

     this.search.list = response.data.rows
     this.search.listSize = response.data.total

     this.loading = false
     this.search.drawer = true
    } else {
     this.loading = false
     this.$message.info('找不到相关的订单信息')
    }
   })
  },

  refreshScroll() {
   this.currentTime = new Date().format('HH:mm')
   this.scrollToTime(this.currentTime)
  },

  scrollToTime(time) {
   let minute = _this.calc_startTimeLastMin({
    startTime: time,
   })

   let themesContentAreaElement = document.getElementById('themes-content-area')
   if (this.isWorkDaySelected) {
    themesContentAreaElement.scrollTo({
     top: (minute - 60) * 2,
     behavior: 'smooth',
    })

    // +++++++++++++++++++++++++++++++++++++++
    let elementElToolTip = document.getElementById('elToolTipCurrentTime')

    for (let index = 0; index < 5; index++) {
     setTimeout(() => {
      elementElToolTip.style.transform = 'scale(' + ((index % 2) + 1) + ')'
     }, 500 * index)
    }

    _this.$refs['elToolTipCurrentTime'].show()
    setTimeout(() => {
     if (!_.isNil(_this.$refs['elToolTipCurrentTime'])) {
      this.$refs['elToolTipCurrentTime'].hide()
     }
    }, 5000)
    // +++++++++++++++++++++++++++++++++++++++
   } else {
    themesContentAreaElement.scrollTo({
     top: 0,
     behavior: 'smooth',
    })
   }
  },

  refreshSceneCardStyle() {
   if (!this.isWorkDaySelected) {
    return
   }

   // let clone_themeSessionList = _.cloneDeep(_this.themeSessionList)
   // _this.themeSessionList.splice(0)

   // clone_themeSessionList.forEach((eleTheme, idx, arr) => {
   _this.themeSessionList.forEach((eleTheme, idx, arr) => {
    eleTheme.schedules.forEach((eleSchedule, idx, arr) => {
     let reduceRst_startTimeMinutes = eleSchedule.startTime.split(':').reduce(function(accumulator, currentValue, currentIndex, array) {
      return accumulator + parseInt(currentValue) * Math.pow(60, array.length - 1 - currentIndex)
     }, 0)

     let reduceRst_nowMinutes = new Date()
      .format('HH:mm')
      .split(':')
      .reduce(function(accumulator, currentValue, currentIndex, array) {
       return accumulator + parseInt(currentValue) * Math.pow(60, array.length - 1 - currentIndex)
      }, 0)

     if (reduceRst_startTimeMinutes < reduceRst_nowMinutes) {
      // eleSchedule.themeSession._style.opacity = 0.7
      eleSchedule.themeSession._style.opacity = 0.95
     } else {
      eleSchedule.themeSession._style.opacity = 0.95
     }
    })
   })

   // _this.themeSessionList = clone_themeSessionList
  },
  refreshCurrentLine() {
   this.currentTime = new Date().format('HH:mm')
   let minute = this.calc_startTimeLastMin({
    startTime: this.currentTime,
   })

   let currentLineElement = document.getElementById('current-line')

   if (!_.isNil(currentLineElement)) {
    currentLineElement.style.top = this.topItemFixed + minute * 2 + 'px'
   }

   let clockElement = document.getElementById('clock')
   if (!_.isNil(clockElement)) {
    clockElement.style.top = this.topItemFixed + minute * 2 + 'px'
   }
  },
  refreshPercentage() {
   _this.themeSessionList.forEach((eleTheme, idxTheme) => {
    eleTheme.schedules.forEach((eleSchedule, idxSchedule) => {
     if (_.isString(eleSchedule.themeSession.openTime) && !_.isEmpty(eleSchedule.themeSession.openTime)) {
      this.$set(_this.themeSessionList[idxTheme].schedules[idxSchedule], '_percentage', _this.calc_percentage(eleSchedule))
      this.$set(_this.themeSessionList[idxTheme].schedules[idxSchedule], '_usingTime', _this.calc_format(eleSchedule))
      this.$set(_this.themeSessionList[idxTheme].schedules[idxSchedule], '_status', _this.calc_isTimeOut(eleSchedule) ? 'warning' : null)
     } else {
      this.$set(_this.themeSessionList[idxTheme].schedules[idxSchedule], '_percentage', 0)
      this.$set(_this.themeSessionList[idxTheme].schedules[idxSchedule], '_usingTime', '')
      this.$set(_this.themeSessionList[idxTheme].schedules[idxSchedule], '_status', null)
     }
    })
   })
  },
  combineImgUrl(value) {
   let url = '' + process.env.VUE_APP_BASE_API + '/' + value
   return url
  },
  calc_startTimeLastMin(scene) {
   // shop start time 默认为8:00
   // shop end time 默认为23:00
   // scene.startTime - 8:00

   let strArray_sceneStartTime = scene.startTime.split(':')
   let num_sceneStartTime = parseInt(strArray_sceneStartTime[0]) * 60 + parseInt(strArray_sceneStartTime[1])

   let strArray_shopStartTime = '8:00'.split(':')
   let num_shopStartTime = parseInt(strArray_shopStartTime[0]) * 60 + parseInt(strArray_shopStartTime[1])

   return num_sceneStartTime - num_shopStartTime
  },
  calc_duration(scene) {
   let strArray_sceneStartTime = scene.startTime.split(':')
   let num_sceneStartTime = parseInt(strArray_sceneStartTime[0]) * 60 + parseInt(strArray_sceneStartTime[1])

   let strArray_sceneEndTime = scene.endTime.split(':')
   let num_sceneEndTime = parseInt(strArray_sceneEndTime[0]) * 60 + parseInt(strArray_sceneEndTime[1])

   return num_sceneEndTime - num_sceneStartTime
  },
  calc_isHaveUnreservedOrder(statistics) {
   return statistics.some((eleOrder, idx, arr) => {
    return eleOrder.unreservedStatus === 1
   })
  },
  calc_isReserved(scene) {
   let cloneScene = _.cloneDeep(scene)

   if (!_.isNil(cloneScene) && !_.isNil(cloneScene.themeSession) && !_.isNil(cloneScene.themeSession.statistics) && _.isArray(cloneScene.themeSession.statistics) && !_.isEmpty(cloneScene.themeSession.statistics)) {
    cloneScene.themeSession.statistics = cloneScene.themeSession.statistics.filter((eleOrder, idx, arr) => {
     return !_.isNil(eleOrder.unreservedStatus) && eleOrder.unreservedStatus !== 1
    })

    if (!_.isEmpty(cloneScene.themeSession.statistics)) {
     return true
    } else {
     return false
    }
   } else {
    return false
   }
  },
  calc_status(scene) {
   switch (scene.themeSession.sessionStatus) {
    case 0:
     return '未开场'

    case 1:
     return '进行中'

    case 2:
     return '已结束未通关'

    case 3:
     return '已结束已通过'

    default:
     return '未开场'
   }
  },
  refreshThemeSessionList() {
   let findRst = _this.dateList.find(ele => {
    return ele.isSelected
   })
   if (findRst !== undefined) {
    let s1 = new Date(findRst.date)
    let s2 = new Date()
    s2.setTime(s1.getTime() + 1000 * 60 * 60 * 24)

    let param = {
     storeId: _this.selectedShopId,
     startTime: s1.format('yyyy-MM-dd'),
     endTime: s2.format('yyyy-MM-dd'),
     session: '',
    }
    _this.loading = true
    getThemeSessionList(param).then(result => {
     result.data[0].forEach((ele, idx, arr) => {
      ele._isClicked = false
     })
     _this.themeSessionList = result.data[0]

     _this.themeSessionList.forEach((eleTheme, idx, arr) => {
      eleTheme.schedules.forEach((eleSchedule, idx, arr) => {
       eleSchedule.themeSession.storeId = _this.selectedShopId

       // 为每一个eleSchedule 算出 _style
       eleSchedule.themeSession._style = {
        top: this.topItemFixed + this.calc_startTimeLastMin(eleSchedule) * 2 + 'px',
        height: this.calc_duration(eleSchedule) * 2 + 'px',
        opacity: 0.95,
       }
      })
     })

     _this.refreshPercentage()
     _this.refreshCurrentLine()
     _this.refreshScroll()
     _this.refreshSceneCardStyle()
     _this.loading = false
    })
   }
  },
  onChange_selectShop() {
   _this.refreshThemeSessionList()
  },
  onChange_datePicker(selectedDate) {
   this.loading = true
   this.expandDateForOneWeek(selectedDate)
  },
  onClick_workDay() {
   this.loading = true
   this.datePicked = null
   this.expandDateForOneWeek(this.workDate.start)
  },
  onClick_dateLeft() {
   this.loading = true
   this.datePicked = null

   let findRst = this.dateList.find(ele => {
    return ele.isSelected
   })

   let date = new Date(findRst.date)
   date.setDate(date.getDate() - 1)

   let intervalDay = this.calc_dayInterval(date, this.workDate.start)

   // +++++++++++++++++++++++++++++++++++++++
   this.expandDateForOneWeek(date)
   // +++++++++++++++++++++++++++++++++++++++
   // if (intervalDay > 0) {
   //  this.$message.error('不能选择营业日以前的日期!')
   // } else {
   //  this.expandDateForOneWeek(date)
   // }
  },
  onClick_dateRight() {
   this.loading = true
   this.datePicked = null

   let findRst = this.dateList.find(ele => {
    return ele.isSelected
   })

   let date = new Date(findRst.date)
   date.setDate(date.getDate() + 1)

   let intervalDay = this.calc_dayInterval(this.workDate.start, date)
   if (intervalDay > 29) {
    this.$message.error('不能选择30天以后的日期!')
    this.loading = false
   } else {
    this.expandDateForOneWeek(date)
   }
  },
  onClick_time(time, timeList) {
   let clone_time = _.cloneDeep(time)

   timeList.forEach((ele, idx, arr) => {
    ele._isClicked = false
   })

   time._isClicked = !clone_time._isClicked
  },
  onClick_thumbnail(theme, themes) {
   themes.forEach((ele, idx, arr) => {
    ele._isClicked = false
   })
   theme._isClicked = true

   // +++++++++++++++++++++++++++++++++++++++
   if (this.calc_hasIngSchedule(theme)) {
    let foundSchedule = theme.schedules.find((eleSchedule, idx, arr) => {
     return eleSchedule.themeSession.sessionStatus === 1
    })

    if (!_.isUndefined(foundSchedule)) {
     // 找到了!
     this.scrollToTime(foundSchedule.startTime)
    }
   }
  },
  onClick_date(selectedDate) {
   this.loading = true
   this.datePicked = null

   this.dateList.forEach((ele_, idx, arr) => {
    ele_.isSelected = false
   })

   setTimeout(() => {
    this.expandDateForOneWeek(selectedDate.date)
   }, 300)
  },
  getPrevDayWorkTimeStart(date = new Date()) {
   let prev_date = new Date(date)
   prev_date.setDate(date.getDate() - 1)
   let prev_workTimeStart_str = prev_date.format('yyyy-MM-dd') + ' ' + this.workTime.start
   let prev_workTimeStart = new Date(prev_workTimeStart_str)
   return prev_workTimeStart
  },
  getNextDayWorkTimeEnd(date = new Date()) {
   let next_date = new Date(date)
   next_date.setDate(date.getDate() + 1)
   let next_workTimeEnd_str = next_date.format('yyyy-MM-dd') + ' ' + this.workTime.end
   let next_workTimeEnd = new Date(next_workTimeEnd_str)
   return next_workTimeEnd
  },
  getNextDayWorkTimeStart(date = new Date()) {
   let next_date = new Date(date)
   next_date.setDate(date.getDate() + 1)
   let next_workTimeStart_str = next_date.format('yyyy-MM-dd') + ' ' + this.workTime.start
   let next_workTimeStart = new Date(next_workTimeStart_str)
   return next_workTimeStart
  },
  initWorkDate() {
   let now = new Date()
   let nowUtc = now.getTime()

   let today_workTimeEnd_str = new Date().format('yyyy-MM-dd') + ' ' + this.workTime.end
   let today_workTimeEnd_utc = new Date(today_workTimeEnd_str).getTime()

   if (this.isTurnClock) {
    if (nowUtc < today_workTimeEnd_utc) {
     this.workDate.start = this.getPrevDayWorkTimeStart(now)
     this.workDate.end = new Date(today_workTimeEnd_str)
    } else {
     let today_workTimeStart_str = new Date().format('yyyy-MM-dd') + ' ' + this.workTime.start
     this.workDate.start = new Date(today_workTimeStart_str)
     this.workDate.end = this.getNextDayWorkTimeEnd(now)
    }
   } else {
    if (nowUtc < today_workTimeEnd_utc) {
     let today_workTimeStart_str = new Date().format('yyyy-MM-dd') + ' ' + this.workTime.start
     this.workDate.start = new Date(today_workTimeStart_str)
     this.workDate.end = new Date(today_workTimeEnd_str)
    } else {
     this.workDate.start = this.getNextDayWorkTimeStart(now)
     this.workDate.end = this.getNextDayWorkTimeEnd(now)
    }
   }

   this.expandDateForOneWeek(this.workDate.start)
   this.refreshThemeSessionList()
  },
  calc_dayInterval(date1, date2) {
   let date1_str = date1.format('yyyy-MM-dd')
   let date1_utc = new Date(date1_str).getTime()

   let date2_str = date2.format('yyyy-MM-dd')
   let date2_utc = new Date(date2_str).getTime()

   return (date2_utc - date1_utc) / (1000 * 60 * 60 * 24)
  },
  expandDateForOneWeek(selectedDate) {
   let dayStatus = this.calc_dayStatus(selectedDate.format('yyyy-MM-dd'))
   if (dayStatus < 1) {
    this.$message.warning('请注意：选择的是历史日期,部分功能受限')
   }

   let arr_week = new Array('星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六')
   this.dateList.splice(0)

   let intervalDay = this.calc_dayInterval(this.workDate.start, selectedDate)

   let startIndex = 0
   if (intervalDay >= 0 && intervalDay < 3) {
    startIndex = -intervalDay
   } else if (intervalDay > 26) {
    startIndex = -(7 - (30 - intervalDay))
   } else {
    startIndex = -3
   }

   for (let index = startIndex; index < startIndex + 7; index++) {
    let time = new Date(selectedDate)
    time.setDate(time.getDate() + index)
    let timeLabel = time.format('MM-dd') + '(' + arr_week[time.getDay()] + ')'

    this.dateList.push({
     date: time,
     label: timeLabel,
     isSelected: index === 0 ? true : false,
    })
   }

   this.refreshThemeSessionList()
  },
  calc_day() {
   var now = new Date()
   var year = now.getFullYear() //得到年份
   var month = now.getMonth() + 1 //得到月份
   var date = now.getDate() //得到日期
   var day = now.getDay() //得到周几
   var hour = now.getHours() //得到小时
   var minu = now.getMinutes() //得到分钟
   var sec = now.getSeconds() //得到秒
   var MS = now.getMilliseconds() //获取毫秒
   if (month < 10) month = '0' + month
   if (date < 10) date = '0' + date
   if (hour < 10) hour = '0' + hour
   if (minu < 10) minu = '0' + minu
   if (sec < 10) sec = '0' + sec
   if (MS < 100) MS = '0' + MS
   var week
   var arr_week = new Array('星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六')
   week = arr_week[day]
   var time = ''
   time = year + '年' + month + '月' + date + '日' + ' ' + hour + ':' + minu + ':' + sec + ' ' + week
   //当前日期赋值给当前日期输入框中（jQuery easyUI）
   $('#currentDate').html(time)
   //设置得到当前日期的函数的执行间隔时间，每1000毫秒刷新一次。
   var timer = setTimeout('writeCurrentDate()', 1000)
  },
  calc_text(scene) {
   switch (this.calc_status(scene)) {
    case '未开场':
     return '未开场'
     break

    case '进行中':
     return scene._usingTime
     break

    case '已结束未通关':
    case '已结束已通过':
     return '已结束'
     break

    default:
     return ''
     break
   }
  },
  calc_percentage(scene) {
   if (_.isNil(scene.themeSession) || _.isNil(scene.themeSession.openTime) || !_.isString(scene.themeSession.openTime)) {
    return 0
   }

   let strArray_sceneStartTime = scene.startTime.split(':')
   let num_sceneStartTime = parseInt(strArray_sceneStartTime[0]) * 60 * 60 + parseInt(strArray_sceneStartTime[1]) * 60
   let strArray_sceneEndTime = scene.endTime.split(':')
   let num_sceneEndTime = parseInt(strArray_sceneEndTime[0]) * 60 * 60 + parseInt(strArray_sceneEndTime[1]) * 60
   let duration_sec = num_sceneEndTime - num_sceneStartTime

   let openTime_sec = new Date(scene.themeSession.openTime).getTime() / 1000
   let usingTime_sec = Math.ceil(new Date().getTime() / 1000 - openTime_sec)
   let usingTime_min = Math.ceil(new Date().getTime() / (1000 * 60) - openTime_sec / 60)
   let percentage = Math.ceil((usingTime_sec / duration_sec) * 100)

   return percentage > 100 ? 100 : percentage
  },
  calc_leftTime(scene) {
   // return percentage === 100 ? '满' : `${percentage}%`
   // return '进行中 ' + percentage
   // return percentage === 100 ? '已结束' : `${percentage}%`
   // return ''

   if (_.isNil(scene.themeSession) || _.isNil(scene.themeSession.openTime) || !_.isString(scene.themeSession.openTime)) {
    return '未开始'
   }

   let strArray_sceneStartTime = scene.startTime.split(':')
   let num_sceneStartTime = parseInt(strArray_sceneStartTime[0]) * 60 * 60 + parseInt(strArray_sceneStartTime[1]) * 60
   let strArray_sceneEndTime = scene.endTime.split(':')
   let num_sceneEndTime = parseInt(strArray_sceneEndTime[0]) * 60 * 60 + parseInt(strArray_sceneEndTime[1]) * 60
   let duration_sec = num_sceneEndTime - num_sceneStartTime
   let duration_min = duration_sec / 60

   let openTime_sec = new Date(scene.themeSession.openTime).getTime() / 1000
   let usingTime_sec = Math.ceil(new Date().getTime() / 1000 - openTime_sec)
   let usingTime_min = Math.ceil(new Date().getTime() / (1000 * 60) - openTime_sec / 60)
   let percentage = (usingTime_sec / duration_sec) * 100

   let strArr_openTime_today_sec = new Date(scene.themeSession.openTime).format('HH:mm').split(':')
   let num_openTime_today_sec = strArr_openTime_today_sec[0] * 60 * 60 + strArr_openTime_today_sec[1] * 60
   let estimateEndTime_sec = num_openTime_today_sec + duration_sec

   if (_.isNaN(usingTime_min)) {
    return ''
   } else {
    if (usingTime_min > duration_min) {
     return '已超时' + (usingTime_min - duration_min) + '分钟'
    } else {
     // return '已开场' + usingTime_min + '分钟'
     return '剩余' + (duration_min - usingTime_min) + '分钟'
    }
   }
  },

  calc_format(scene) {
   // return percentage === 100 ? '满' : `${percentage}%`
   // return '进行中 ' + percentage
   // return percentage === 100 ? '已结束' : `${percentage}%`
   // return ''

   if (_.isNil(scene.themeSession) || _.isNil(scene.themeSession.openTime) || !_.isString(scene.themeSession.openTime)) {
    return '未开始'
   }

   let strArray_sceneStartTime = scene.startTime.split(':')
   let num_sceneStartTime = parseInt(strArray_sceneStartTime[0]) * 60 * 60 + parseInt(strArray_sceneStartTime[1]) * 60
   let strArray_sceneEndTime = scene.endTime.split(':')
   let num_sceneEndTime = parseInt(strArray_sceneEndTime[0]) * 60 * 60 + parseInt(strArray_sceneEndTime[1]) * 60
   let duration_sec = num_sceneEndTime - num_sceneStartTime
   let duration_min = duration_sec / 60

   let openTime_sec = new Date(scene.themeSession.openTime).getTime() / 1000
   let usingTime_sec = Math.ceil(new Date().getTime() / 1000 - openTime_sec)
   let usingTime_min = Math.ceil(new Date().getTime() / (1000 * 60) - openTime_sec / 60)
   let percentage = (usingTime_sec / duration_sec) * 100

   let strArr_openTime_today_sec = new Date(scene.themeSession.openTime).format('HH:mm').split(':')
   let num_openTime_today_sec = strArr_openTime_today_sec[0] * 60 * 60 + strArr_openTime_today_sec[1] * 60
   let estimateEndTime_sec = num_openTime_today_sec + duration_sec

   if (this.calc_status(scene) === '已结束未通关' || this.calc_status(scene) === '已结束已通过') {
    return new Date(scene.themeSession.openTime).format('HH:mm') + ' - ' + new Date(scene.themeSession.closeTime).format('HH:mm')
   } else {
    return (
     new Date(scene.themeSession.openTime).format('HH:mm') +
     ' - ' +
     parseInt(estimateEndTime_sec / 3600)
      .toString()
      .padStart(2, '0') +
     ':' +
     (parseInt(estimateEndTime_sec % 3600) / 60).toString().padStart(2, '0')
    )
   }

   // if (_.isNaN(usingTime_min)) {
   //  return ''
   // } else {
   //  if (usingTime_min > duration_min) {
   //   return '已超时' + (usingTime_min - duration_min) + '分钟'
   //  } else {
   //   return '已开场' + usingTime_min + '分钟'
   //  }
   // }
  },

  calc_isTimeOut(scene) {
   if (_.isNil(scene.themeSession) || _.isNil(scene.themeSession.openTime) || !_.isString(scene.themeSession.openTime)) {
    return '未开始'
   }

   let strArray_sceneStartTime = scene.startTime.split(':')
   let num_sceneStartTime = parseInt(strArray_sceneStartTime[0]) * 60 * 60 + parseInt(strArray_sceneStartTime[1]) * 60
   let strArray_sceneEndTime = scene.endTime.split(':')
   let num_sceneEndTime = parseInt(strArray_sceneEndTime[0]) * 60 * 60 + parseInt(strArray_sceneEndTime[1]) * 60
   let duration_sec = num_sceneEndTime - num_sceneStartTime
   let duration_min = duration_sec / 60

   let openTime_sec = new Date(scene.themeSession.openTime).getTime() / 1000
   let usingTime_sec = Math.ceil(new Date().getTime() / 1000 - openTime_sec)
   let usingTime_min = Math.ceil(new Date().getTime() / (1000 * 60) - openTime_sec / 60)
   let percentage = (usingTime_sec / duration_sec) * 100

   let strArr_openTime_today_sec = new Date(scene.themeSession.openTime).format('HH:mm').split(':')
   let num_openTime_today_sec = strArr_openTime_today_sec[0] * 60 * 60 + strArr_openTime_today_sec[1] * 60
   let estimateEndTime_sec = num_openTime_today_sec + duration_sec

   if (_.isNaN(usingTime_min)) {
    return ''
    return false
   } else {
    if (usingTime_min > duration_min) {
     // return '已超时' + (usingTime_min - duration_min) + '分钟'
     return true
    } else {
     // return '已开场' + usingTime_min + '分钟'
     return false
    }
   }
  },

  calc_time(startTime, endTime) {
   startTime = startTime.toString()
   endTime = endTime.toString()
   var param = {}
   var status = status || 'h'
   var beginTime = Date.parse(startTime.replace(/-/g, '/'))
   var startTime = new Date(beginTime)
   var nowTime = endTime == null ? new Date() : new Date(endTime)
   /*****相差秒数*****/
   var subTime = Math.floor((nowTime.getTime() - startTime.getTime()) / 1000)
   param.s = subTime
   /*****相差分钟*****/
   var cal = Math.floor(subTime / 60)
   param.m = cal
   var minute = (cal % 60 < 10 ? '0' + (cal % 60) : +cal % 60) + ''
   var leftTime
   if (cal > 60) {
    leftTime = Math.floor(cal / 60 < 10 ? '0' + cal / 60 : cal / 60 + '')
   } else {
    leftTime = '00'
   }
   param.time = leftTime + ':' + minute.charAt(0) + minute.charAt(1)
   return param
  },
  getStartTime(startt, endt) {
   startt = Date.parse(startt)
   endt = Date.parse(endt)
   let currDate = ds
   //2019-10-29 18:00 < 2019-10-30 09:00   && 2019-10-29 19:00 < 2019-10-30 09:00
   //开始时间<=当前时间   && 结束时间<=当前时间  表示已结束
   if (startt <= currDate && endt <= currDate) {
    return ['0%', '已结束']
   }
   //2019-10-31 00:45  > 2019-10-31 00:30 && 2019-10-31 01:30< 2019-10-31 00:30
   // 开始时间 >当前时间  && 结束时间>当前时间   表示进行中
   else if (startt >= currDate && endt > currDate) {
    let count = this.calc_time(startt, currDate)
    let sum = this.calc_time(startt, endt)
    let percentage = count / sum
    return [percentage + '%', '已开场' + count]
   }
   // 2019-11-01 19:00> 2019-10-30 09:00
   // 结束时间 >= 当前时间
   else if (endt > currDate) {
    return ['0%', '未开场']
   }
  },
  openDialog(type, data, data2, data3, data4, dayStatus) {
   if (type == 'gift') {
    // 赠品
    this.$refs['themeGift'].showDialog(true, data, data2, this.selectedDate.date.format('yyyy-MM-dd'), data4)
   } else if (type == 'ing') {
    // 进行中
    this.$refs['themeIng'].showDialog(true, data, data2, this.selectedDate.date.format('yyyy-MM-dd'))
   } else if (type == 'reserve') {
    // 预定
    this.$refs['themeReserve'].showDialog('预定信息', data, data2, this.selectedDate.date.format('yyyy-MM-dd'), data3, data4, dayStatus)
   } else if (type == 'start') {
    // 开始
    let scene = data

    // +++++++++++++++++++++++++++++++++++++++
    if (_.isNil(scene.themeSession) || _.isNil(scene.themeSession.statistics) || !_.isArray(scene.themeSession.statistics) || _.isEmpty(scene.themeSession.statistics)) {
     this.$message({
      message: '尚未预定,不能开始',
      type: 'error',
     })
     return
    }

    // +++++++++++++++++++++++++++++++++++++++
    this.calc_dayInterval(this.workDate.start, new Date(scene.themeSession.sessionStartDay))

    let today = new Date().format('yyyy-MM-dd')

    let nowUtc = new Date().getTime()
    let sessionStartDayUtc = new Date(scene.themeSession.sessionStartDay).getTime()

    if (this.calc_dayInterval(this.workDate.start, new Date(scene.themeSession.sessionStartDay)) !== 0) {
     this.$message({
      message: '非营业日的场次,不能开始',
      type: 'error',
     })
     return
    }

    // +++++++++++++++++++++++++++++++++++++++
    let haveStarted = data3.some((ele, idx, arr) => {
     return ele.themeSession.sessionStatus === 1
    })
    if (haveStarted) {
     this.$message({
      message: '此主题当天已有开场,不能有多个开场',
      type: 'error',
     })
     return
    }

    // +++++++++++++++++++++++++++++++++++++++
    this.$refs['themeStart'].showDialog(true, data, data2, this.selectedDate.date.format('yyyy-MM-dd'), this.doStart)
   }
  },

  doStart() {
   _this.refreshPercentage()
  },
 },
}
</script>

<style lang="less" scoped>
@import url(../../statics/css/eltable.css);

body {
 padding: 0 !important;
}

// 统一设置滚动条样式
.themes-content-area::-webkit-scrollbar-track {
 box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
 -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
 background-color: #1c2135;
}

// 滚动条整体部分
.themes-content-area::-webkit-scrollbar {
 width: 15px;
 height: 15px;
}

// 滚动条里面的小方块
.themes-content-area::-webkit-scrollbar-thumb {
 // background-color: #222840; //#1c2135;
 background-color: hsl(228, 31%, 20%);
}

// 横向滚动条和纵向滚动条相交处尖角的颜色
.themes-content-area::-webkit-scrollbar-corner {
 // background-color: #222840; //#1c2135;
 background-color: hsl(228, 31%, 30%);
}
</style>
<style lang="less">
@themesWidth: calc(100vw - 220px - 30px);
@themesHeight: calc(100vh - 60px - 90px - 60px);
@lineHeight: 60px;
@timelistWidth: 110px;
@themeThumbnailHeight: 200px;
@themeWidth: 140px;

.clearfix:after {
 content: '';
 display: block;
 clear: both;
}

.yogotheme {
 -webkit-user-select: none;
 -moz-user-select: none;
 -ms-user-select: none;
 user-select: none;
 // padding: 23px 19px;
 padding: 0 0.15rem 0.15rem 0.15rem;
 background-color: #101529;

 .hint {
  position: fixed;
  top: 18px;
  left: 48vw;
  font-size: 16px;
 }

 //头部
 .themeheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;

  h1 {
   color: white;
   height: 0.5rem;
   line-height: 0.5rem;
   font-size: 1.5em;
  }

  .search /deep/ .el-select .el-input__inner,
  .store-select /deep/ .el-input__inner {
   width: 350px;
   font-size: 12px;
   color: #b1b1b1;
   background-color: #282e43;
   border: 1px solid #ee9911;
   border-radius: 0.06rem;
   // height: 0.3rem;
   text-indent: 4px;
   padding-left: 0;
  }

  /deep/ .el-input__icon {
   // border: solid 1px red !important; //aqua/black/blue/fuchsia/gray/green/lime/maroon/navy/olive/orange/purple/red/silver/teal/white/yellow
   // position: relative;
   // top: 10px;
  }
 }

 //日期部分
 .themedate {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 0.7rem;
  border-radius: 0.06rem;
  margin-bottom: 0.2rem;
  background-color: #1c2034;
  display: flex;
  cursor: pointer;

  .themedate_l,
  .themedate_r {
   width: 8%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   border-right: 1px solid rgba(52, 67, 94, 1);
   font-size: 22.6px;
   color: #acadad;
  }

  .themedate_m {
   flex: 1;
   display: flex;
   color: #b1b1b1;
   font-size: 16px;

   .mdate {
    flex: 1;
    border-right: 1px solid #2b364e;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   }
  }

  .active {
   transition: all 0.5s ease;
   background-color: #55eedd;
   color: black;
  }

  .inactive {
   transition: all 0.5s ease;
   background-color: #1c2034;
   color: rgb(158, 158, 158);
  }

  .active-history-in-2-day {
   background-color: #235e57;
  }

  .active-history-out-2-day {
   background-color: #606161;
  }

  .themedate_date {
   height: 100%;
   min-height: 100%;
   max-height: 100%;
   width: 8.7%;
   min-width: 8.7%;
   max-width: 8.7%;
   font-size: 16px;
   color: #8b8c91;
   //  display: flex;
   //  flex-direction: column;
   justify-content: center;
   align-items: center;
   position: relative;

   img {
    width: 19px;
   }

   span {
    position: absolute;
    bottom: 5px;
   }

   /deep/ .el-date-editor {
    // border: solid 1px wheat;
    // width: 35%;
    // min-width: 35%;
    // max-width: 35%;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 120%;
    min-height: 120%;
    max-height: 120%;
    position: absolute;
    bottom: 0;
    left: 0;

    .el-input__prefix {
     left: 0;
     top: 0;
     width: 100%;
     min-width: 100%;
     max-width: 100%;
    }

    .el-input__inner {
     visibility: hidden;
     // display: none;
    }

    .el-icon-date {
     font-size: 20px;
     cursor: pointer;
     width: 100%;
     min-width: 100%;
     max-width: 100%;
     height: 100%;
     min-height: 100%;
     max-height: 100%;
    }

    .el-icon-circle-close {
     font-size: 50px;
     cursor: pointer;
     display: none;
    }
   }
  }
 }

 .themedate.active-history-in-2-day {
  opacity: 0.7;
  border: solid 3px #235e57;
 }

 .themedate.active-history-out-2-day {
  opacity: 0.7;
  border: solid 3px #606161;
 }

 //主题内容
 .themes-content-area {
  // width: @themesWidth;
  // min-width: @themesWidth;
  // max-width: @themesWidth;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: @themesHeight;
  // min-height: @themesHeight;
  // max-height: @themesHeight;
  // height: 90vh;
  // min-height: 90vh;
  // max-height: 90vh;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: horizontal;
  justify-content: flex-start;
  align-items: flex-start;

  .top-cover-block {
   z-index: 100;
   width: @timelistWidth;
   min-width: @timelistWidth;
   max-width: @timelistWidth;
   height: @themeThumbnailHeight;
   min-height: @themeThumbnailHeight;
   max-height: @themeThumbnailHeight;
   background-color: #101529;
   // background-color: red;
   // background-color: #0fec46;
   position: fixed;
   // left: 0px;
   // top: 0px;
   display: flex;
   justify-content: center;
   align-items: center;

   .clock {
    // width: 350px;
    // height: 350px;
    color: white;
    // font-size: 9px;
    // pointer-events: none;
   }

   .clock:hover {
    cursor: pointer;
    background-color: rgba(0, 255, 255, 0.486);
   }
  }

  .time-list {
   z-index: 10;
   background-color: #101529;
   left: 0px;
   position: sticky;
   width: @timelistWidth;
   min-width: @timelistWidth;
   max-width: @timelistWidth;
   height: fit-content;
   min-height: fit-content;
   max-height: fit-content;

   .cover-block {
    z-index: 1;
    width: @timelistWidth;
    min-width: @timelistWidth;
    max-width: @timelistWidth;
    height: @themeThumbnailHeight;
    min-height: @themeThumbnailHeight;
    max-height: @themeThumbnailHeight;
    background-color: #101529;
    // background-color: red;
    // background-color: #0fec46;
    position: sticky;
    left: 0px;
    top: 0px;
   }

   .lines {
    width: @timelistWidth;
    min-width: @timelistWidth;
    max-width: @timelistWidth;
    height: fit-content;
    min-height: fit-content;
    max-height: fit-content;
    margin: 10px 0;
    position: relative;

    .line {
     border: solid 1px rgba(192, 119, 24, 0);
     // @include setWidthHeight(150px, 60px);
     // margin-top: 100px;
     height: @lineHeight;
     min-height: @lineHeight;
     max-height: @lineHeight;
     pointer-events: none;

     .time-text {
      cursor: pointer;
      color: white;
      position: relative;
      top: -2px;
      left: 30px;
      width: fit-content;
      pointer-events: all;
     }
    }

    .line:hover,
    .clicked {
     .time-text {
      color: gainsboro !important;
      font-size: 25px;
      top: -8px;
      left: 0px;
     }
    }

    .unclicked {
    }
   }
  }

  #current-line {
   border-top: solid 1px rgba(0, 255, 255, 0.5);
   // width: calc(@themesWidth - 110px);
   width: calc(100% - 110px);
   // width: calc(100%);
   height: 0px;
   position: absolute;
   left: 110px - 20px;
   pointer-events: none;

   i {
    pointer-events: all;
    color: rgba(0, 255, 255, 0.5);
    position: relative;
    top: 2px;
   }
  }

  #clock {
   z-index: 99;
   position: absolute;
   left: 110px - 20px;

   i {
    color: rgba(0, 255, 255, 1);
    position: relative;
    top: -7px;
   }

   .el-tooltip {
    transition: all 0.5s ease-in-out;
   }
  }

  #selected-time-line {
   border-top: dashed 1px rgba(255, 255, 255, 0.5);
   width: calc(@themesWidth - 110px);
   // width: calc(100%);
   height: 0px;
   position: absolute;
   left: 110px - 20px;
   pointer-events: none;
  }

  .cover-layer {
   z-index: 8;
   width: @themesWidth;
   // min-width: @themesWidth;
   // max-width: @themesWidth;
   height: @themeThumbnailHeight;
   min-height: @themeThumbnailHeight;
   max-height: @themeThumbnailHeight;
   position: fixed;
   left: 220px;
   opacity: 1 !important;
   background-color: #101529;
   pointer-events: none;
  }

  .theme-column {
   z-index: 9;
   position: relative;
   background-color: #101529;
   width: @themeWidth;
   min-width: @themeWidth;
   max-width: @themeWidth;

   .thumbnail {
    width: @themeWidth;
    min-width: @themeWidth;
    max-width: @themeWidth;
    height: @themeThumbnailHeight;
    min-height: @themeThumbnailHeight;
    max-height: @themeThumbnailHeight;
    position: sticky;
    top: 0px;
    z-index: 100;
    padding: 10px;
    background-color: #101529;
    opacity: 1 !important;

    .name {
     border-top-left-radius: 0.06rem;
     border-top-right-radius: 0.06rem;
     height: 40px;
     min-height: 40px;
     max-height: 40px;
     font-size: 12px;
     display: flex;
     justify-content: center;
     align-items: center;
     background-color: #364064;
     color: white;
    }

    .name.has-ing-schedule {
     // border: solid 1px green;
     // background-color: rgba(0, 128, 0, 0.7);
     border-top: solid 5px green;
    }

    .pic {
     .el-image {
      height: calc(@themeThumbnailHeight - 60px);
      min-height: calc(@themeThumbnailHeight - 60px);
      max-height: calc(@themeThumbnailHeight - 60px);
     }
    }
   }

   .thumbnail:hover {
    cursor: pointer;
   }

   .time-lines {
    width: @themeWidth;
    min-width: @themeWidth;
    max-width: @themeWidth;
    margin: 10px 0;

    .line {
     border: solid 1px rgba(192, 119, 24, 0);
     width: @themeWidth;
     min-width: @themeWidth;
     max-width: @themeWidth;
     height: @lineHeight;
     min-height: @lineHeight;
     max-height: @lineHeight;
     // margin-top: 100px;
    }
   }

   .scene-card {
    position: absolute;
    background: rgba(45, 51, 74, 0.5);
    left: 10px;
    right: 10px;
    justify-content: center;
    align-items: flex-start;
    box-shadow: 1px 1px 4px 0px rgba(4, 0, 0, 0.16);
    border-radius: 0.06rem;
    text-align: center;
    font-size: 14px;
    padding: 0;
    border-top: solid 1px rgba(255, 255, 255, 0.3);

    .unreserve {
     color: white;
     position: absolute;
     top: 2px;
     left: 3px;
     // color: orange;
     color: white;
     font-size: 16px;
    }

    .el-icon-lock {
     position: absolute;
     top: 2px;
     right: 3px;
     color: white;
     font-size: 16px;
     transition: all 0.2s linear;
    }

    .el-icon-lock:hover {
     cursor: pointer;
    }

    .el-icon-lock.big {
     // border: solid 1px red;
     font-size: 80px;
     background-color: rgba(220, 220, 220, 0.5);
     border-radius: 4px;
     top: 0px;
     bottom: 1px;
     left: 1px;
     right: 1px;
     opacity: 0.5;
    }

    .el-badge {
     pointer-events: none;

     .el-badge__content {
      height: auto;
      right: 0px;
     }
    }

    .sale {
     color: rgba(34, 147, 118, 1);
     font-weight: bold;
     pointer-events: none;

     .el-icon-sort {
      position: absolute;
      left: -15px;
      top: 3px;
     }
    }

    .unsale {
     color: rgba(242, 94, 35, 1);
    }

    .over {
     color: gray;
     font-weight: bold;
     pointer-events: none;
     position: relative;

     .el-icon-sort {
      position: absolute;
      left: -15px;
      top: 3px;
     }
    }

    .tip {
     color: rgba(119, 136, 170, 1);
     height: 20px;
     min-height: 0px;
     pointer-events: none;
    }

    .tip.guild-name {
     white-space: nowrap;
     text-overflow: ellipsis;
     overflow: hidden;
     min-height: 0px;
     pointer-events: none;
    }

    p {
     margin-bottom: 2px;
     // font-size: 12px;
    }

    .not-begin {
     color: white;
     font-size: 14px;
     height: 16px;
     text-decoration: blink;
    }

    .game-over {
     color: gray;
     font-size: 9px;
     min-height: 0px;
    }

    .progress {
     // display: none;
     width: 100px;
     height: 16px;
     box-shadow: 1px 1px 1px 0 #060614;
     border-radius: 7px;
     background: #1f2438;
     // margin-bottom: 9px;
     overflow: hidden;
     position: relative;

     .text {
      position: absolute;
      color: white;
      font-size: 12px;
      top: 0px;
      left: 0;
      width: 100%;
      text-align: center;
      line-height: 16px;
      height: 16px;
     }

     .el-progress {
      // border: solid 1px rgb(0, 255, 21);
      width: 100%;

      // height: 100%;
      // height: 50%;
      // width: @themeWidth;
      // min-width: @themeWidth;
      // max-width: @themeWidth;
      /deep/ .el-progress-bar__outer {
       background: #1f2438;
      }

      /deep/ .el-progress-bar__innerText {
       // background-color: red;
       color: rgba(255, 255, 255, 0);
       // position: fixed;;
       width: 100% !important;
       min-width: 100% !important;
       max-width: 100% !important;
      }
     }

     .inner {
      //  border-radius: 7px;
      //  width: 20%;
      height: 16px;
      // background: #fff;
      position: relative;

      span {
       border-radius: 50%;
       height: 16px;
       width: 16px;
       position: absolute;
       left: 0;
       display: inline-block;
       background: #fff;
      }
     }
    }

    .btn {
     width: 43px;
     height: 24px;
     min-height: 0px !important;
     background: rgba(136, 136, 136, 1);
     border-radius: 4px;
     color: #fff;
     font-size: 12px;
     font-weight: 400;
     margin: 5px 5px 0 5px;
     padding: 0;
     border: solid 0px red;

     &.btnover {
      background: rgb(163, 163, 163);
      color: rgb(87, 87, 87);
      cursor: not-allowed;
     }

     &.btngive {
      background: rgba(238, 102, 34, 1);
     }

     &.btning {
      width: 43px;
      height: 24px;
      background: rgba(34, 172, 56, 1);
      border-radius: 4px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
     }

     &.btnreserve {
      width: 43px;
      height: 24px;
      background: rgba(85, 102, 170, 1);
      border-radius: 4px;
      // z-index: 100;
     }

     &.no-order {
      width: 66px;
     }

     &.btnbegin {
      width: 43px;
      height: 24px;
      background: rgba(255, 68, 68, 1);
      border-radius: 4px;
     }
    }
   }

   .scene-card-wrapper {
    // border: solid 3px red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 9px 0 9px 0;
    overflow: visible;
    // position: absolute;
    // border: solid 1px red;
    // background-color: green;
    // opacity: 1;
    // padding: 10px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    // z-index: 300;
   }

   @keyframes cursor-change {
    from {
     cursor: none;
    }

    // 50% {
    //  opacity: 0.3;
    // }
    to {
     cursor: normal;
    }
   }

   // 弃用
   .scene-card-wrapper:hoverr {
    z-index: 100;
    background-color: rgba(220, 220, 220, 0.267);
    opacity: 0.5 !important;
    border: dotted 2px green;
    height: 120%;
    min-height: 120%;
    max-height: 120%;
    transform: translate(0, 50px);
    transition: transform 0.5s ease-in-out, height 0.5s ease-in-out 0.5s, min-height 0.5s ease-in-out 0.5s, max-height 0.5s ease-in-out 0.5s;
    // animation: cursor-change 1s ease-in 1;
   }

   .is-not-reserved {
    // border: solid 1px rgba(242, 94, 35, 0.5);
    background-color: rgba(242, 94, 35, 0.25);
   }

   @keyframes bling {
    from {
     border: solid 2px rgba(0, 128, 0, 0);
    }

    20% {
     border: solid 2px rgba(0, 128, 0, 0);
     border-top: solid 2px rgba(0, 128, 0, 1);
    }

    40% {
     border: solid 2px rgba(0, 128, 0, 0);
     border-right: solid 2px rgba(0, 128, 0, 1);
    }

    60% {
     border: solid 2px rgba(0, 128, 0, 0);
     border-bottom: solid 2px rgba(0, 128, 0, 1);
    }

    80% {
     border: solid 2px rgba(0, 128, 0, 0);
     border-left: solid 2px rgba(0, 128, 0, 1);
    }

    to {
     border: solid 2px rgba(0, 128, 0, 0);
    }
   }

   .is-ing {
    border: solid 2px rgba(0, 128, 0, 1);
    background-color: rgba(255, 0, 0, 0);
    // animation: bling 2s linear infinite;
   }

   .is-over {
    border: solid 2px gray;
    background-color: rgba(255, 0, 0, 0);
   }
  }

  .theme-column.clicked {
   opacity: 0.95;
  }

  .theme-column.unclicked {
   opacity: 0.7;
  }
 }
}
</style>
<style lang="scss" scoped>
@mixin setWidthHeight($width, $height) {
 width: $width;
 min-width: $width;
 max-width: $width;
 height: $height;
 min-height: $height;
 max-height: $height;
}

@mixin setWidth($width) {
 width: $width;
 min-width: $width;
 max-width: $width;
}

@mixin setHeight($height) {
 height: $height;
 min-height: $height;
 max-height: $height;
}

@mixin setAlign($jc, $ai) {
 justify-content: $jc;
 align-items: $ai;
}

@mixin setFlex($direction, $wrap, $jc, $ai) {
 display: flex !important;
 flex-direction: $direction;
 // flex-wrap: nowrap|wrap|wrap-reverse|initial|inherit;
 flex-wrap: $wrap;
 justify-content: $jc;
 align-items: $ai;
}

.el-container.search {
 @include setWidth(fit-content);

 /deep/ .el-input-group__prepend,
 /deep/ .el-input__inner,
 /deep/ .el-input-group__append {
  font-size: 12px;
  // color: #b1b1b1;
  background-color: #282e43;
  border: 1px solid #ee9911;
  border-right: 0px solid #ee9911;
  height: 28px;
  text-indent: 2px;
 }

 /deep/ .el-input-group__prepend {
  padding: 0 5px;

  .el-switch__label span {
   color: #b1b1b1;
   font-size: 9px !important;
  }

  .el-switch__label.is-active span {
   color: aqua;
   font-size: 9px !important;
  }
 }

 /deep/ .el-input__inner {
  width: 250px;
  color: white;

  /deep/ .el-input__clear {
   border: solid 1px red;
   top: 0;
  }
 }

 /deep/ .el-input-group__append {
  border-right: 1px solid #ee9911;
  padding: 0 5px 0 0;
  color: white !important;
 }
}

.el-dialog__wrapper {
 border: solid 0px red;

 /deep/ .el-drawer {
  border: solid 0px red;
  background-color: #101529;
  height: 600px;
  min-height: 600px;
  max-height: 600px;

  /deep/ .el-drawer__header span {
   border: solid 0px red;
   font-size: 20px;
   color: white;
  }

  /deep/ .el-drawer__body {
   border: solid 0px red;

   .data-table {
    max-height: 400px;
   }
  }
 }
}

.el-drawer__wrapper {
 border: solid 0px red;

 /deep/ .el-drawer {
  border: solid 0px red;
  background-color: #101529;
  height: 600px;
  min-height: 600px;
  max-height: 600px;

  /deep/ .el-drawer__header span {
   border: solid 0px red;
   font-size: 20px;
   color: white;
  }

  /deep/ .el-drawer__body {
   border: solid 0px red;

   .data-table {
    max-height: 400px;
   }
  }
 }
}
</style>
