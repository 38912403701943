<template>
 <div class="dialogContent">
  <el-dialog :close-on-click-modal="false" :title="tit" :visible.sync="dialogVisible" @close="closeDialog" class="dialog-wrap" width="6.71rem">
   <form action>
    <div class="row">
     <div class="column">
      <p class="yogo-input-name">门店</p>
      <input placeholder="请选择" type="text" v-model="currObj.store" />
      <i class="btnicon btnimgdown"></i>
     </div>
     <div class="column">
      <p class="yogo-input-name">门店状态</p>
      <input placeholder="请选择门店状态" type="text" v-model="currObj.status" />
     </div>
    </div>
    <div class="row">
     <div class="column">
      <p class="yogo-input-name">门店费用(￥)</p>
      <input placeholder="请输入门店费用" type="text" v-model="currObj.price" />
     </div>
    </div>
    <div class="row">
     <div class="column">
      <p class="yogo-input-name">生效时间</p>
      <input placeholder="请选择生效时间" type="text" v-model="currObj.startdate" />
      <i class="btnicon btnimgdown"></i>
     </div>
     <div class="column">
      <p class="yogo-input-name">失效时间</p>
      <input placeholder="请选择失效时间" type="text" v-model="currObj.enddate" />
      <i class="btnicon btnimgdown"></i>
     </div>
    </div>
    <div class="row rowbuttons">
     <el-button @click="closeDialog" type="primary">确 定</el-button>
     <el-button @click="closeDialog">取 消</el-button>
    </div>
   </form>
  </el-dialog>
 </div>
</template>

<script>
export default {
 data() {
  return {
   tit: '',
   dialogVisible: false,
   currObj: {},
  }
 },
 methods: {
  closeDialog() {
   this.dialogVisible = false
  },
  cancelDialog() {
   this.dialogVisible = false
  },
  showDialog(title = '新增门店状态', data = {}) {
   this.tit = title
   this.dialogVisible = true
   this.currObj = data
  },
 },
}
</script>

<style lang="less">
@import url('../../statics/css/editDialog.css');
</style>
