import request from '@/utils/request'

export function getPayChannelList(params) {
  return request({
    url: '/api/payChannel/list',
    method: 'get',
    params,
  })
}

export function getPayChannel(id) {
  return request({
    url: '/api/payChannel/get/' + id,
    method: 'get',
  })
}

export function saveOrUpdatePayChannel(data) {
  return request({
    url: '/api/payChannel/saveOrUpdate',
    method: 'post',
    data,
  })
}

export function deletePayChannel(id) {
  return request({
    url: '/api/payChannel/delete/' +id,
    method: 'delete',
  })
}
