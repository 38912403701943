<template>
 <div class="dialogContent">
  <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" @close="closeDialog" class="dialog-wrap" title="会员充值" width="4.85rem">
   <form action>
    <div class="row">
     <div class="column">
      <p class="yogo-input-name">会员名称</p>
      <input placeholder="请输入会员名称" type="text" />
     </div>
     <div class="column">
      <p class="yogo-input-name">会员卡号</p>
      <input placeholder="请输入会员卡号" type="text" />
     </div>
    </div>
    <div class="row">
     <div class="column">
      <p class="yogo-input-name">手机号码</p>
      <input placeholder="请选择手机号码" type="text" />
     </div>
     <div class="column">
      <p class="yogo-input-name">会员生日</p>
      <input placeholder="请选择生日日期" type="text" />
      <i class="btnicon btnimgdate"></i>
     </div>
    </div>
    <div class="row">
     <div class="column">
      <p class="yogo-input-name">门店</p>
      <input placeholder="请选择" type="text" />
      <i class="btnicon btnimgdown"></i>
     </div>
     <div class="column">
      <p class="yogo-input-name">充值人员</p>
      <input placeholder="请选择" type="text" />
      <i class="btnicon btnimgdown"></i>
     </div>
    </div>
    <div class="row">
     <div class="column columndesc">
      <p class="yogo-input-name">备注</p>
      <textarea placeholder="请输入备注信息" type="text" valign="top"></textarea>
     </div>
    </div>
    <div class="row rowbuttons">
     <el-button @click="closeDialog" type="primary">确 定</el-button>
     <el-button @click="closeDialog">取 消</el-button>
    </div>
   </form>
   <span class="dialog-footer" slot="footer"></span>
  </el-dialog>
 </div>
</template>

<script>
export default {
 data() {
  return {
   dialogVisible: this.inDialogVisible,
  }
 },
 // 组件的名称
 name: 'memberRecharge',
 methods: {
  closeDialog() {
   this.dialogVisible = false
  },
  showDialog() {
   this.dialogVisible = true
  },
 },
}
</script>
<style lang="less" scoped>
@import url('../../statics/css/editDialog.css');
</style>
