<template>
  <!-- 该页面布局与onetable不同，暂时未使用统一模板修改 -->
  <div id="yogo-notice">
    <div class="yogo-title">第三方通知</div>
    <div class="yogo-search">
      <form action>
        <div class="row">
          <div class="column">
            <p class="yogo-input-name">开始时间</p>
            <el-date-picker align="left" v-model="startDate" format="yyyy年MM月dd日" value-format="yyyy-MM-dd" type="date"
              placeholder="选择开始时间"></el-date-picker>
            <i class="btnicon btnimgdate"></i>
          </div>
          <div class="column">
            <p class="yogo-input-name">结束时间</p>
            <el-date-picker align="left" v-model="endDate" type="date" format="yyyy年MM月dd日" value-format="yyyy-MM-dd"
              placeholder="选择结束时间"></el-date-picker>
            <i class="btnicon btnimgdate"></i>
          </div>
          <div class="column">
            <p class="yogo-input-name">通知状态</p>
            <input type="text" value="请选择" />
            <i class="btnicon btnimgdown"></i>
          </div>
           <div class="column column-last">
            <!-- <p class="yogo-input-name">&nbsp;</p> -->
            <el-button type="primary" class="btncz">
              <i class="el-icon-search"></i> 查 询
            </el-button>
            <el-button type="primary" class="btnexport">
              <i class="el-icon-download"></i> 导 出Excel
            </el-button>
          </div>
        </div>
        <div class="row">
        
        </div>
      </form>
    </div>
    <div class="yogo-table">
      <div class="yogo-table-th">
        <p v-for="(item, index) in tableTilteArr" :key="index">{{item.title}}</p>
      </div>
      <div class="yogo-table-td" v-for="(item, index) in tableContentArr" :key="index">
        <p>{{item.times}}</p>
        <p>{{item.type}}</p>
        <p>{{item.from}}</p>
        <p>{{item.name}}</p>
        <p>{{item.peploe}}</p>
        <p>{{item.price}}</p>
        <p>
          <img :src="item.url" class="itemedit" alt />
          <span :class="{active:item.read}"></span>
        </p>
      </div>
    </div>
    <div class="yogo-pages">
      <div class="yogo-pages-left">
        <span>共1页&nbsp; 每页&nbsp;</span>
        <div class="pagesel">
          <span>10</span>
          <i class="btnicon btnimgpage"></i>
        </div>
        <span>&nbsp;条&nbsp; 共6条</span>
      </div>
      <div class="yogo-pages-right">
        <el-pagination background layout="prev, pager, next" :total="1"></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "notice",
    data() {
      return {
        startDate: "",
        endDate: "",
        tableTilteArr: [{
            title: "时间"
          },
          {
            title: "订单类型"
          },
          {
            title: "订单来源"
          },
          {
            title: "主体名称"
          },
          {
            title: "人数"
          },
          {
            title: "订单金额(￥)"
          },
          {
            title: "操作"
          }
        ],
        tableContentArr: [{
            times: "2018-08-23 02:47:44",
            type: "订票",
            from: "美团",
            name: "记忆碎片PLUS",
            peploe: "5",
            price: "50.00",
            url: require("../../statics/images/notice/信息@2x(2).png"),
            read: true
          },
          {
            times: "2018-08-23 02:47:44",
            type: "订票",
            from: "大众点评",
            name: "黑暗之夜",
            peploe: "10",
            price: "1000.00",
            url: require("../../statics/images/notice/信息@2x(2).png"),
            read: true
          },
          {
            times: "2018-08-23 02:47:44",
            type: "订票",
            from: "美团",
            name: "黑暗之夜",
            peploe: "8",
            price: "800.00",
            url: require("../../statics/images/notice/信息@2x(2).png"),
            read: true
          },
          {
            times: "2018-08-23 02:47:44",
            type: "订票",
            from: "微信公众号",
            name: "柯南的抉择",
            peploe: "5",
            price: "500.00",
            url: require("../../statics/images/notice/信息@2x(2).png"),
            read: false
          },
          {
            times: "2018-08-23 02:47:44",
            type: "订票",
            from: "美团",
            name: "奇幻大冒险",
            peploe: "5",
            price: "500.00",
            url: require("../../statics/images/notice/信息@2x(2).png"),
            read: false
          },
          {
            times: "2018-08-23 02:47:44",
            type: "订票",
            from: "微信公众号",
            name: "绝命逃生",
            peploe: "5",
            price: "600.00",
            url: require("../../statics/images/notice/信息@2x(2).png"),
            read: false
          },
          {
            times: "2018-08-23 02:47:44",
            type: "订票",
            from: "美团",
            name: "奇幻大冒险",
            peploe: "5",
            price: "500.00",
            url: require("../../statics/images/notice/信息@2x(2).png"),
            read: false
          },
          {
            times: "2018-08-23 02:47:44",
            type: "订票",
            from: "微信公众号",
            name: "绝命逃生",
            peploe: "5",
            price: "600.00",
            url: require("../../statics/images/notice/信息@2x(2).png"),
            read: false
          },
          {
            times: "2018-08-23 02:47:44",
            type: "订票",
            from: "美团",
            name: "奇幻大冒险",
            peploe: "5",
            price: "500.00",
            url: require("../../statics/images/notice/信息@2x(2).png"),
            read: false
          },
          {
            times: "2018-08-23 02:47:44",
            type: "订票",
            from: "微信公众号",
            name: "绝命逃生",
            peploe: "5",
            price: "600.00",
            url: require("../../statics/images/notice/信息@2x(2).png"),
            read: false
          }
        ]
      };
    }
  };
</script>
<style scoped>
  @import "../../statics/css/yygl/notice.css";
</style>