// 使用场景：文本输入框
export const inputMixins = {
  props: {
    // 文本框图标
    suffixIcon: { type: String, default: '' },
    // 是否显示标签
    isShowLabel: { type: Boolean, default: true },
    // 控件label名称
    labelName: { type: String, default: '' },
    // 组件类型标识（用于标识不同组件，每个视图中唯一）(预留)
    comType: { type: String, default: '' },
    // 组件传值字段名称（与code对象绑定的字段名称，即视图中：formField对应的字段名称）
    codeField: { type: String, default: '' },
    // 是否不可用
    disabled: { type: Boolean, default: false },
    // 是否不填/选
    isRequire: { type: Boolean, default: false },
    // 控件值
    code: { type: String, default: '' },
  },
  data() {
    return {
      curLabelName: this.labelName,
      // 绑定传入值并同步界面数据
      modelCode: this.code

    }
  },
  methods: {
    sendCode() { 
      if (this.modelCode === null) {
        this.modelCode = ''
      }
      var code = this.modelCode
      var text = 'text'
      this.$emit('changeCode', code, text, this.codeField, this.comType)
    }
  }
}

// 使用场景：单网格模板使用（只适用于模板）
export const oneTableTemplateMixins = {
  props: {
    dynamicTableTilteArr: { type: Array, default: function () { return [] } },
    dynamicTableContentArr: { type: Array, default: function () { return [] } },
    dynamicTableComponents: { type: Array, default: function () { return [] } },
    dynamicTableTopBtns: { type: Array, default: function () { return [] } },
    dynamicTableLowBtns: { type: Array, default: function () { return [] } },
    dynamicFormFields: { type: Object, default: function () { return {} } },
    valueObject: { type: Object, default: function () { return null } },
    dynamicTableTit: { type: String, default: "" },
    dynamicRowform:{type:Boolean,default:false}
  },
  data() {
    return {
      curValueObject: this.valueObject,
      tableTilteArr: this.dynamicTableTilteArr,
      tableContentArr: this.dynamicTableContentArr,
      tableComponents: this.dynamicTableComponents,
      tableTopBtns: this.dynamicTableTopBtns,
      tableLowBtns: this.dynamicTableLowBtns,
      formFields: this.dynamicFormFields,
      tableTit: this.dynamicTableTit,
      rowform:this.dynamicRowform
    };
  },
  created() {
    // 赋值formField 
    if (this.curValueObject && typeof this.curValueObject === 'object') {
      for (var key in this.formFields) {
        if (this.curValueObject[key]) {
          this.formFields[key] = this.curValueObject[key]
        } else {
          this.formFields[key] = ''
        }
      }
    }
  },
  methods: {
    getComponentCode(val, txt, codeField) { 
      this.formFields[codeField] = val;  
    }
  }
}

// 使用场景：编辑页面  (未使用)
// export const oneEditTemplateMixins = {
//   props: { 
//     //标题
//     dynamicEditTit: { type: String, default: "" },
//     //编辑组件
//     dynamicEditComponents: { type: Array, default: function () { return [] } }, 
//     //按钮
//     dynamicEditLowBtns: { type: Array, default: function () { return [] } },
//     //表单组件
//     dynamicFormFields: { type: Object, default: function () { return {} } },
//     //默认值
//     valueObject: { type: Object, default: function () { return null } },
    
//   },
//   data() {
//     return { 
//       editTit: this.dynamicEditTit, 
//       editComponents: this.dynamicEditComponents, 
//       editBtns: this.dynamicEditLowBtns,
//       formFields: this.dynamicFormFields,
//       curValueObject: this.valueObject,
//     };
//   },
//   created() {
//     // 赋值formField 
//     if (this.curValueObject && typeof this.curValueObject === 'object') {
//       for (var key in this.formFields) {
//         if (this.curValueObject[key]) {
//           this.formFields[key] = this.curValueObject[key]
//         } else {
//           this.formFields[key] = ''
//         }
//       }
//     }
//   },
//   methods: {
//     getComponentCode(val, txt, codeField) { 
//       this.formFields[codeField] = val;  
//     }
//   }
// }