<template>
 <div class="dialogContent">
  <el-dialog :close-on-click-modal="false" :title="tit" :visible.sync="dialogVisible" @close="closeDialog" class="dialog-wrap" width="4.85rem">
   <el-form size="small">
    <el-row :gutter="14">
     <el-col :span="12">
      <el-form-item label="门店">
       <el-select placeholder="请选择" style="width: 100%" v-model="currObj.storeId">
        <el-option v-for="(item, idx) of shopList" :key="idx" :value="item.id" :label="item.name" />
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="12">
      <el-form-item label="出错人员">
       <el-select placeholder="出错人员" v-model="currObj.mistakePeopleId">
        <el-option v-for="(item, idx) of allUser" :key="idx" :value="item.userId" :label="item.username" />
       </el-select>
      </el-form-item>
     </el-col>

     <el-col :span="12">
      <el-form-item label="处罚内容">
       <el-select placeholder="请选择内容" v-model="currObj.systemEntryId">
        <el-option v-for="(item, idx) of allEntry" :key="idx" :value="item.id" :label="item.name" />
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="24">
      <el-form-item label="处罚日期" class="filter-item">
       <el-date-picker class="item-content" type="date" clearable size="small" v-model="currObj.mistakeTime" placeholder="处罚日期" />
      </el-form-item>
     </el-col>

     <el-col :span="12">
      <el-form-item label="处罚金额(￥)">
       <el-input placeholder="处罚金额(￥)" type="text" v-model="currObj.punishMoney" />
      </el-form-item>
     </el-col>
     <el-col :span="24">
      <el-form-item label="备注信息">
       <el-input :rows="2" placeholder="请输入备注信息" type="textarea" v-model="currObj.description" />
      </el-form-item>
     </el-col>
    </el-row>

    <div class="row rowbuttons">
     <el-button @click="handleConfirm" type="primary">确 定</el-button>
     <el-button @click="closeDialog">取 消</el-button>
    </div>
   </el-form>
   <span class="dialog-footer" slot="footer"></span>
  </el-dialog>
 </div>
</template>

<script>
import { getShops } from '@/api/shop'
import { getSytemEntryList } from '@/api/systementry'
import { getUserList } from '@/api/user'

export default {
 data() {
  return {
   tit: '',
   dialogVisible: this.inDialogVisible,
   currObj: {},
   callback: null,

   shopList: null,
   payChannelList: null,
   allEntry: null,
   allUser: null
  }
 },
 beforeCreate() {
  let param = {
   isPage: false,
   type: 1
  }
  Promise.all([getShops(), getUserList(), getSytemEntryList(param)]).then(result => {
   this.shopList = result[0].data.data
   this.allUser = result[1].data.data
   this.allEntry = result[2].data
  })
 },
 methods: {
  closeDialog() {
   this.dialogVisible = false
  },
  cancelDialog() {
   this.dialogVisible = false
  },
  showDialog(title = '新增出错记录', data = {}, callback) {
   this.tit = title
   this.dialogVisible = true
   if (data) {
    this.currObj = data
   } else {
    this.currObj = {}
   }
   this.callback = callback
  },
  handleConfirm() {
   if (!this.$paramsValidate(this.currObj, ['storeId', 'mistakePeopleId', 'systemEntryId', 'mistakeTime', 'punishMoney', 'description'])) return

   if (this.callback) {
    this.callback(this.currObj)
    this.closeDialog()
   }
  }
 }
}
</script>
<style lang="less" scoped>
@import url('../../statics/css/editDialog.css');
</style>
