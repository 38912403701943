<template>
 <div id="yogo-guide" class="eltables" v-loading.fullscreen.lock="listLoading" element-loading-background="rgba(0, 0, 0, 0.8)">
  <div class="toolbar-container duku">
   <div>
    <span class="yogo-title toolbar-title">引导人员统计</span>
   </div>
   <div class="toolbar-box">
    <el-form label-position="top">
     <el-form-item label="门店" class="filter-item">
      <el-select class="item-content" v-model="formModel.storeId" placeholder="" clearable size="small" style="" @change="formModel.themeId = null">
       <el-option v-for="(item, idx) of shopList" :key="idx" :value="item.id" :label="item.name" />
      </el-select>
     </el-form-item>
     <el-form-item label="主题" class="filter-item">
      <el-select class="item-content" v-model="formModel.themeId" placeholder="" clearable size="small" style="">
       <el-option v-for="(item, idx) of filteredThemeList" :key="idx" :value="item.id" :label="item.themeName" />
      </el-select>
     </el-form-item>
     <el-form-item label="引导人员" class="filter-item">
      <el-select class="item-content" v-model="formModel.userId" placeholder="" clearable size="small" style="">
       <el-option v-for="(item, idx) of filteredStaff" :key="idx" :value="item.userId" :label="item.username" />
      </el-select>
     </el-form-item>
     <el-form-item label="开始时间" class="filter-item">
      <el-date-picker class="item-content" type="date" clearable size="small" style="" placeholder="" v-model="formModel.startTime" value-format="yyyy-MM-dd HH:mm:ss" />
     </el-form-item>
     <el-form-item label="结束时间" class="filter-item">
      <el-date-picker class="item-content" type="date" clearable size="small" style="" placeholder="" v-model="formModel.endTime" value-format="yyyy-MM-dd HH:mm:ss" />
     </el-form-item>
    </el-form>

    <div>
     <el-button size="small" class="filter-item" type="primary" icon="el-icon-search" @click="onClick_search($event)">查询 </el-button>
     <el-button size="small" class="filter-item" type="primary" icon="el-icon-download" @click="onClick_exportExcel($event)">导出Excel</el-button>
    </div>
   </div>
  </div>

  <el-table :data="list" class="data-table" stripe height="1000">
   <el-table-column label="引导人员" prop="guideName" align="center" />
   <el-table-column label="主题名称" prop="_themeName" align="center" />
   <el-table-column label="场次开始时间" prop="_startTime" align="center" />
   <el-table-column label="场次结束时间" prop="_endTime" align="center" />
   <el-table-column label="引导时间" prop="guideTime" align="center" />
  </el-table>

  <pagination v-show="true" :total="listSize" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
 </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { getBusinesses, getShops, getResults, getGuideStatistics } from '@/api/shop'
import { deleteTheme, getTheme, getThemeList, saveOrUpdateTheme, uploadThemeImage, getPlaybacks } from '@/api/theme/theme'
import { getPayChannelList, getPayChannel, saveOrUpdatePayChannel, deletePayChannel } from '@/api/paychannel'
import { getStaffUserList, create, update, delete_ } from '@/api/user'

export default {
 components: {
  Pagination,
 },
 data() {
  let today = new Date(new Date().format('yyyy-MM-dd'))
  let tommorrow = new Date(new Date().format('yyyy-MM-dd'))
  tommorrow.setDate(tommorrow.getDate() + 1)

  return {
   listExcel: null,

   shopList: null,
   themeList: null,
   allStaff: null,
   formModel: {
    storeId: null,
    themeId: null,
    userId: null,
    startTime: today.format('yyyy-MM-dd HH:mm:ss'),
    endTime: tommorrow.format('yyyy-MM-dd HH:mm:ss'),
   },
   // +++++++++++++++++++++++++++++++++++++++
   list: null,
   listSize: 0,
   listLoading: true,
   listQuery: {
    page: 1,
    limit: 10,
   },
  }
 },
 computed: {
  filteredThemeList() {
   if (!_.isEmpty(this.themeList)) {
    let clone_themeList = _.cloneDeep(this.themeList)
    if (_.isInteger(this.formModel.storeId) && this.formModel.storeId > 0) {
     return clone_themeList.filter((ele_theme, idx, arr) => {
      return ele_theme.storeId === this.formModel.storeId
     })
    } else {
     return clone_themeList
    }
   } else {
    return null
   }
  },
  filteredStaff() {
   if (!_.isEmpty(this.allStaff)) {
    let clone_allStaff = _.cloneDeep(this.allStaff)
    if (_.isInteger(this.formModel.storeId) && this.formModel.storeId > 0) {
     return clone_allStaff.filter((eleStaff, idx, arr) => {
      return eleStaff.stores.some((eleStore, idx, arr) => {
       return eleStore.id === this.formModel.storeId
      })
     })
    } else {
     return clone_allStaff
    }
   } else {
    return null
   }
  },
 },
 beforeCreate() {
  Promise.all([getShops(), getThemeList(), getStaffUserList()]).then(result => {
   this.shopList = result[0].data.data
   this.themeList = result[1].data
   this.allStaff = result[2].data.data

   this.getList()
  })
 },
 methods: {
  onClick_exportExcel() {
   if (_.isArray(this.listExcel) && !_.isEmpty(this.listExcel)) {
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    let columns = []
    _.entries(this.listExcel[0]).filter((ele, idx, arr) => {
     switch (ele[0]) {
      case 'guideName':
       columns.push({
        prop: ele[0],
        label: '引导人员',
       })
       return true

      case '_themeName':
       columns.push({
        prop: ele[0],
        label: '主题名称',
       })
       return true

      case '_startTime':
       columns.push({
        prop: ele[0],
        label: '场次开始时间',
       })
       return true

      case '_endTime':
       columns.push({
        prop: ele[0],
        label: '场次结束时间',
       })
       return true

      case 'guideTime':
       columns.push({
        prop: ele[0],
        label: '引导时间',
       })
       return true

      default:
       return false
     }
    })
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    this.$export.excel({ columns: columns, data: this.listExcel }).then(() => {})
   } else {
    this.$message.error('没有数据可导出')
   }
  },
  onClick_search() {
   this.listQuery.storeId = this.formModel.storeId
   this.listQuery.themeId = this.formModel.themeId
   this.listQuery.guidePerson = this.formModel.userId
   this.listQuery.startTime = this.formModel.startTime
   this.listQuery.endTime = this.formModel.endTime

   this.getList()
  },
  getList() {
   this.listLoading = true

   let param = {
    pageSize: this.listQuery.limit,
    pageNum: this.listQuery.page,
    isPage: true,

    storeId: this.listQuery.storeId,
    themeId: this.listQuery.themeId,
    guidePerson: this.listQuery.guidePerson,
    startTime: this.listQuery.startTime,
    endTime: this.listQuery.endTime,
   }

   getGuideStatistics(param)
    .then(response => {
     response.data.rows.forEach((ele, idx, arr) => {
      let foundEle = this.themeList.find((ele_theme, idx, arr) => {
       return ele_theme.id === ele.themeId
      })
      if (!_.isUndefined(foundEle)) {
       // 找到了!
       ele._themeName = foundEle.themeName
      }

      ele._startTime = ele.schedule.startTime
      ele._endTime = ele.schedule.endTime
     })

     this.list = response.data.rows
     this.listSize = response.data.total
    })
    .then(response => {
     this.getExcelList()
    })
  },
  getExcelList() {
   let param = {
    // pageSize: this.listQuery.limit,
    // pageNum: this.listQuery.page,
    isPage: false,

    storeId: this.listQuery.storeId,
    themeId: this.listQuery.themeId,
    guidePerson: this.listQuery.userId,
    startTime: this.listQuery.startTime,
    endTime: this.listQuery.endTime,
   }

   getGuideStatistics(param).then(response => {
    response.data.forEach((ele, idx, arr) => {
     let foundEle = this.themeList.find((ele_theme, idx, arr) => {
      return ele_theme.id === ele.themeId
     })
     if (!_.isUndefined(foundEle)) {
      // 找到了!
      ele._themeName = foundEle.themeName
     }

     ele._startTime = ele.schedule.startTime
     ele._endTime = ele.schedule.endTime
    })

    this.listExcel = response.data
    this.listLoading = false
   })
  },

  handleFilter() {},
 },
}
</script>

<style lang="scss" scoped>
#yogo-guide {
 .data-table {
  // 60px headbar
  // 200px search block
  // 100px pagination block
  max-height: calc(100vh - 60px - 200px - 100px);
 }
}
</style>
