import request from '@/utils/request'

// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
export function deleteTheme(id) {
 return request({
  url: '/api/theme/delete/' + id,
  method: 'delete',
 })
}

export function setThemeOffline(id, params) {
 return request({
  url: '/api/theme/offline/' + id,
  method: 'PUT',
  params,
 })
}

export function getTheme(id) {
 return request({
  url: '/api/theme/get/' + id,
  method: 'get',
 })
}

export function getLatestReservedDay(id) {
 return request({
  url: '/api/theme/getLatestReservedDay/' + id,
  method: 'get',
  // params,
 })
}

export function canOffline(id, params) {
 return request({
  url: '/api/theme/canOffline/' + id,
  method: 'get',
  params,
 })
}

export function getThemeList(params) {
 return request({
  url: '/api/theme/list',
  method: 'get',
  params,
 })
}

export function saveOrUpdateTheme(data) {
 return request({
  url: '/api/theme/saveOrUpdate',
  method: 'post',
  data,
 })
}

// imageFile
// themeId
export function uploadThemeImage(data) {
 return request({
  url: '/api/theme/uploadImage',
  method: 'post',
  data,
  headers: {
   'Content-Type': 'multipart/form-data',
  },
 })
}

export function getPlaybacks(params) {
 return request({
  url: '/api/themeSession/playBack/' + params.id,
  method: 'get',
  params,
 })
}

// export const addNewProject = (data) => {
//  return axios(
//   {
//    url: '/Project',
//    method: 'post',
//    data,
//    headers: {
//     'Content-Type': 'multipart/form-data',
//    },
//   },
//   config,
//  )
// }
