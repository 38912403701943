<template>
 <div id="yogo-payment" class="eltables" v-loading.fullscreen.lock="listLoading" element-loading-background="rgba(0, 0, 0, 0.8)">
  <div class="toolbar-container duku">
   <div>
    <span class="yogo-title toolbar-title">店面支出</span>
    <el-button type="primary" class="myicon iconadd" @click="handleAdd"><i></i>新增支出</el-button>
   </div>
   <div class="toolbar-box">
    <el-form label-position="top">
     <el-form-item label="门店" class="filter-item">
      <el-select class="item-content" v-model="formModel.storeId" placeholder="门店" clearable size="small" style="">
       <el-option v-for="(item, idx) of shopList" :key="idx" :value="item.id" :label="item.name" />
      </el-select>
     </el-form-item>
     <!-- <el-form-item label="密室主题" class="filter-item">
      <el-select class="item-content" v-model="listQuery.theme" placeholder="密室主题" clearable size="small" style="">
       <el-option v-for="(item, idx) of filteredThemeList" :key="idx" :value="item.id" :label="item.themeName" />
      </el-select>
     </el-form-item> -->
     <el-form-item label="开始时间" class="filter-item">
      <el-date-picker class="item-content" type="date" clearable size="small" v-model="formModel.startTime" placeholder="开始时间" value-format="yyyy-MM-dd HH:mm:ss" />
     </el-form-item>
     <el-form-item label="结束时间" class="filter-item">
      <el-date-picker class="item-content" type="date" clearable size="small" v-model="formModel.endTime" placeholder="结束时间" value-format="yyyy-MM-dd HH:mm:ss" />
     </el-form-item>
    </el-form>
    <div>
     <el-button size="small" class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">查询 </el-button>
     <el-button size="small" class="filter-item" type="primary" icon="el-icon-download">导出Excel</el-button>
    </div>
    <p style="margin-top: 5px; text-align: right; color: white">支出总金额：¥ {{ Number(totalUsed).toFixed(2) }}</p>
   </div>
  </div>

  <el-table :data="list" class="data-table" stripe>
   <el-table-column prop="storeName" label="门店" align="center" />
   <el-table-column prop="payContentName" label="开支内容" align="center" />
   <el-table-column prop="payAmount" label="金额" align="center">
    <template slot-scope="{ row }">
     ¥ {{ Number(row.payAmount / 100.0).toFixed(2) }}
    </template>
   </el-table-column>
   <el-table-column prop="payUserName" label="支出人" align="center" />
   <el-table-column prop="desc" label="备注" align="center" />
   <el-table-column prop="createPeopleName" label="录入人" align="center" />
   <el-table-column prop="actions" label="操作" width="120" align="left">
    <template slot-scope="{ row }">
     <el-button type="primary" size="small" class="el-icon-edit" circle @click="handleEdit(row)"></el-button>
     <el-button type="danger" size="small" class="el-icon-delete" circle @click="handleDelete(row)"></el-button>
    </template>
   </el-table-column>
  </el-table>

  <pagination v-show="true" :total="listSize" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

  <paymentEdit ref="EditDialogForm" />
  <delDialog ref="DelDialogForm" />
 </div>
</template>

<script>
import delDialog from '@/views/template/delDialog'
import paymentEdit from '@/views/jygl/paymentEdit'
import Pagination from '@/components/Pagination'
import { getStorePayOuts, createStorePayOut, deleteStorePayOut, getShops } from '@/api/shop'
import { getThemeList } from '@/api/theme/theme'
import { getPayChannelList } from '@/api/paychannel'

const pageData = [
 {
  id: 1,
  store: {
   id: 1,
   name: '花果山店'
  },
  entry: '买糖吃',
  user: '八戒',
  amount: 12000,
  remarks: '',
  writer: '沙僧'
 }
]

export default {
 components: {
  paymentEdit,
  delDialog,
  Pagination
 },
 data() {
  return {
   list: null,
   listSize: 0,
   listLoading: true,
   listQuery: {
    page: 1,
    limit: 10,
    importance: undefined,
    title: undefined,
    type: undefined,
    status: undefined,
    sort: '+id'
   },
   selectedItem: null,
   totalUsed: 0,
   shopList: null,
   themeList: null,
   payChannelList: null,
   formModel: {
    storeId: null,
    themeId: null,
    payChannelId: null,
    startTime: null,
    endTime: null
   },
   user: null
  }
 },
 computed: {
  filteredThemeList() {
   if (!_.isEmpty(this.themeList)) {
    let clone_themeList = _.cloneDeep(this.themeList)
    if (_.isInteger(this.formModel.storeId) && this.formModel.storeId > 0) {
     return clone_themeList.filter((ele_theme, idx, arr) => {
      return ele_theme.storeId === this.formModel.storeId
     })
    } else {
     return clone_themeList
    }
   } else {
    return null
   }
  }
 },
 beforeCreate() {
  Promise.all([getShops(), getThemeList(), getPayChannelList()]).then(result => {
   this.shopList = result[0].data.data
   this.themeList = result[1].data
   this.payChannelList = result[2].data

   this.getList()
  })
 },
 created() {
  this.user = this.$store.getters.user
  this.getList()
 },
 methods: {
  getList() {
   this.listLoading = true
   let param = {
    pageSize: this.listQuery.limit,
    pageNum: this.listQuery.page,
    isPage: true,

    storeId: this.listQuery.storeId,
    themeId: this.listQuery.themeId,
    payChannelId: this.listQuery.payChannelId,
    startTime: this.listQuery.startTime,
    endTime: this.listQuery.endTime
   }
   getStorePayOuts(param).then(response => {
    if (response.data.rows) {
     this.list = response.data.rows
     if (response.data && response.data.total) {
      this.listSize = response.data.total
      this.totalUsed = response.data.totalamount / 100
     } else {
      this.listSize = this.list.length
     }
    }
   })
   this.list = pageData
   this.listSize = this.list.length
   this.listLoading = false
  },
  handleFilter() {
   this.listQuery.storeId = this.formModel.storeId
   this.listQuery.themeId = this.formModel.themeId
   this.listQuery.payChannelId = this.formModel.payChannelId
   this.listQuery.startTime = this.formModel.startTime
   this.listQuery.endTime = this.formModel.endTime

   this.getList()
  },
  handleAdd() {
   this.$refs['EditDialogForm'].showDialog('新增支出', null, this.doCreate)
  },
  handleEdit(item) {
   this.selectedItem = item
   const target = JSON.parse(JSON.stringify(item))
   target.payAmount = target.payAmount / 100.0
   this.$refs['EditDialogForm'].showDialog('修改支出', target, this.doUpdate)
  },
  handleDelete(item) {
   this.selectedItem = item
   this.$refs['DelDialogForm'].showDialog('删除支出', '是否删除当前支出', item, this.doDelete)
  },
  doCreate(data) {
   data.createPeople = this.user.userId
   data.payAmount = data.payAmount * 100.0
   createStorePayOut(data).then(response => {
    console.log(response)
    this.getList()
   })
  },
  doUpdate(data) {
   // TODO 金额 12.0 格式化为 1200
   data.createPeople = this.user.userId
   data.payAmount = data.payAmount * 100.0
   createStorePayOut(data).then(response => {
    // TODO
    console.log(response)
    this.getList()
   })
  },
  doDelete() {
   deleteStorePayOut(this.selectedItem).then(response => {
    console.log(response)
    this.list.splice(this.list.indexOf(this.selectedItem), 1)
   })
  }
 }
}
</script>
<style scoped></style>
