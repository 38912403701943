var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.listLoading),expression:"listLoading",modifiers:{"fullscreen":true,"lock":true}}],staticClass:"eltables",attrs:{"id":"yogo-staffManagement","element-loading-background":"rgba(0, 0, 0, 0.8)"}},[_c('div',{staticClass:"toolbar-container"},[_c('span',{staticClass:"yogo-title toolbar-title"},[_vm._v("人员管理")]),_c('el-button',{staticClass:"myicon iconadd",attrs:{"type":"primary"},on:{"click":_vm.handleAdd}},[_c('i'),_vm._v("新增人员 ")]),_c('el-button',{staticClass:"myicon iconadd",attrs:{"type":"primary"}},[_c('i'),_vm._v("导出Excel ")])],1),_c('el-table',{staticClass:"data-table",attrs:{"data":_vm.list,"stripe":""}},[_c('el-table-column',{attrs:{"prop":"username","label":"姓名","align":"center"}}),_c('el-table-column',{attrs:{"prop":"ssex","label":"性别","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.ssex == '1' ? '男' : '女')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"age","label":"年龄","align":"center"}}),_c('el-table-column',{attrs:{"prop":"mobile","label":"电话","align":"center"}}),_c('el-table-column',{attrs:{"prop":"email","label":"邮箱","align":"center"}}),_c('el-table-column',{attrs:{"prop":"qq","label":"QQ","align":"center"}}),_c('el-table-column',{attrs:{"prop":"wechat","label":"微信","align":"center"}}),_c('el-table-column',{attrs:{"prop":"stores","label":"门店","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.stores),function(storeOne){return _c('li',{key:storeOne.id},[_vm._v(" "+_vm._s(storeOne.name)+" ")])})}}])}),_c('el-table-column',{attrs:{"prop":"roles","label":"角色","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.roles),function(roleOne){return _c('li',{key:roleOne.id},[_vm._v(" "+_vm._s(roleOne.name)+" ")])})}}])}),_c('el-table-column',{attrs:{"prop":"actions","label":"操作","width":"120","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{staticClass:"el-icon-edit",attrs:{"type":"primary","size":"small","circle":""},on:{"click":function($event){return _vm.handleEdit(row)}}}),_c('el-button',{staticClass:"el-icon-delete",attrs:{"type":"danger","size":"small","circle":""},on:{"click":function($event){return _vm.handleDelete(row)}}})]}}])})],1),_c('pagination',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],attrs:{"total":_vm.listSize,"page":_vm.listQuery.page,"limit":_vm.listQuery.limit},on:{"update:page":function($event){return _vm.$set(_vm.listQuery, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.listQuery, "limit", $event)},"pagination":_vm.getList}}),_c('delDialog',{ref:"DelDialogForm"}),_c('editDialog',{ref:"EditDialogForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }