<template>
 <div class="dialogContent">
  <el-dialog :close-on-click-modal="false" :title="tit" :visible.sync="dialogVisible" @close="closeDialog" class="dialog-wrap" width="4.85rem">
   <el-form size="small">
    <el-row :gutter="14">
     <el-col :span="12">
      <el-form-item label="门店">
       <el-select placeholder="请选择" style="width: 100%" v-model="currObj.storeId" @change="currObj.payUserId = null">
        <el-option v-for="(item, idx) of shopList" :key="idx" :value="item.id" :label="item.name" />
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="12">
      <el-form-item label="开支内容">
       <el-select placeholder="请选择内容" v-model="currObj.payContentId">
        <el-option v-for="(item, idx) of allEntry" :key="idx" :value="item.id" :label="item.name" />
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="12">
      <el-form-item label="支出人">
       <el-select placeholder="请选择人员" v-model="currObj.payUserId">
        <el-option v-for="(item, idx) of filteredStaff" :key="idx" :value="item.userId" :label="item.username" />
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="12">
      <el-form-item label="支出金额">
       <el-input placeholder="请输入支出金额" type="text" v-model="currObj.payAmount" />
      </el-form-item>
     </el-col>
     <el-col :span="24">
      <el-form-item label="备注信息">
       <el-input :rows="2" placeholder="请输入备注信息" type="textarea" v-model="currObj.desc" />
      </el-form-item>
     </el-col>
    </el-row>
   </el-form>
   <div class="row rowbuttons">
    <el-button @click="handleConfirm" type="primary">确 定</el-button>
    <el-button @click="closeDialog">取 消</el-button>
   </div>
  </el-dialog>
 </div>
</template>

<script>
import { getShops } from '@/api/shop'
import { getSytemEntryList } from '@/api/systementry'
import { getStaffUserList } from '@/api/user'
/* global _ */
export default {
 data() {
  return {
   tit: '',
   dialogVisible: this.inDialogVisible,
   currObj: {},
   callback: null,

   shopList: null,
   payChannelList: null,
   allEntry: null,
   allStaff: null
  }
 },
 // 组件的名称
 name: 'memberRecharge',
 computed: {
  filteredStaff() {
   if (!_.isEmpty(this.allStaff)) {
    let clone_allStaff = _.cloneDeep(this.allStaff)
    if (_.isInteger(this.currObj.storeId) && this.currObj.storeId > 0) {
     return clone_allStaff.filter(eleStaff => {
      return eleStaff.stores.some(eleStore => {
       return eleStore.id === this.currObj.storeId
      })
     })
    } else {
     return clone_allStaff
    }
   } else {
    return null
   }
  }
 },
 beforeCreate() {
  let param = {
   isPage: false,
   type: 3
  }
  Promise.all([getShops(), getStaffUserList(), getSytemEntryList(param)]).then(result => {
   this.shopList = result[0].data.data
   this.allStaff = result[1].data.data
   this.allEntry = result[2].data
  })
 },
 methods: {
  closeDialog() {
   this.dialogVisible = false
   this.callback = null
  },
  cancelDialog() {
   this.dialogVisible = false
   this.callback = null
  },
  showDialog(title = '新增支出', data, callback) {
   this.tit = title
   this.dialogVisible = true
   if (data) {
    this.currObj = data
   } else {
    this.currObj = {}
   }
   this.callback = callback
  },
  handleConfirm() {
   if (!this.$paramsValidate(this.currObj, ['storeId', 'payAmount', 'payUserId', 'payContentId', 'desc'])) return
   if (this.callback) {
    this.callback(this.currObj)
    this.closeDialog()
   }
  }
 }
}
</script>
<style lang="less" scoped>
@import url('../../statics/css/editDialog.css');
</style>
