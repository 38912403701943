let demoData = {
  ageRangeArr: [
    {
      id: 1,
      range: '14-18',
    },
    {
      id: 2,
      range: '18-30',
    },
    {
      id: 3,
      range: '30以上',
    },
  ],

  gameTypeArr: [
    {
      id: 1,
      name: '冒险类',
    },
    {
      id: 2,
      name: '逻辑类',
    },
    {
      id: 3,
      name: '剧情类',
    },
  ],

  gameStyleArr: [
    {
      id: 1,
      name: '恐怖风格',
    },
    {
      id: 2,
      name: '唯美风格',
    },
    {
      id: 3,
      name: '爱情风格',
    },
  ],

  payMethodArr: [
    {
      id: 1,
      name: '支付宝[扫码]',
    },
    {
      id: 2,
      name: '支付宝[验券]',
    },
    {
      id: 3,
      name: '微信[扫码]',
    },
    {
      id: 4,
      name: '微信[验券]',
    },
    {
      id: 5,
      name: '刷卡',
    },
    {
      id: 6,
      name: '现金',
    },
    {
      id: 7,
      name: '大众/美团[闪惠]',
    },
    {
      id: 8,
      name: '大众/美团[订座]',
    },
    {
      id: 9,
      name: '大众/美团[验券]',
    },
    {
      id: 10,
      name: '会员储值金消费',
    },
  ],

  shopArr: [
    {
      id: 1,
      name: '步行街店',
      workTimeStart: '8:00',
      workTimeEnd: '24:00',

      themeArr: [
        {
          id: 1,

          //++++++++++++++++++++++++++++
          name: '局',
          difficulty: null, // int, 1-5
          gameTypeId: null, // int
          gameStyleId: null, // int
          minPeopleNumber: null, // int
          entireBookPeopleNumber: null, // int
          suggestPeopleNumber: null, // int
          shopId: null, // int
          introduce: null, // string
          ticketPriceBusy: null, // float
          ticketPriceIdle: null, // float
          imgSrc: require('../../statics/images/nav/1@2x.png'),
          imgSrcFile: null, // File
          workTime: {
            startTime: null, // string
            endTime: null, // string
          },
          //----------------------------

          sceneArr: [
            {
              id: 1,

              //++++++++++++++++++++++++++++
              startTime: '8:15',
              endTime: '9:15',
              //----------------------------

              startTimeLastMin: 15, // startTime - workTimeStart
              duration: 60,
              hasGuider: false,
              status: '未开场', // '未开场' '进行中' '已结束'

              orderArr: [
                {
                  id: 1,

                  //++++++++++++++++++++++++++++
                  reservePeopleName: '刘德华',
                  reservePeoplePhone: '13418987657',
                  ageRangeId: 1,
                  numberOfPeople: 3,
                  payMethodId: 27, // 27-38
                  payMoney: 896.0,
                  //----------------------------
                },
              ],
            },
            {
              id: 2,

              //++++++++++++++++++++++++++++
              startTime: '9:30',
              endTime: '10:30',
              //----------------------------

              startTimeLastMin: 90, // startTime - workTimeStart
              duration: 60,
              hasGuider: false,
              status: '未开场', // '未开场' '进行中' '已结束'

              orderArr: [
                {
                  id: 1,

                  //++++++++++++++++++++++++++++
                  reservePeopleName: '刘德华',
                  reservePeoplePhone: '13418987657',
                  ageRangeId: 1,
                  numberOfPeople: 3,
                  payMethodId: 27, // 27-38
                  payMoney: 896.0,
                  //----------------------------
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 2,
      name: '循礼门店',
      workTimeStart: '8:00',
      workTimeEnd: '24:00',
    },
    {
      id: 3,
      name: '中南路店',
      workTimeStart: '8:00',
      workTimeEnd: '24:00',
    },
  ],
}

export default demoData

let demoData2 = {
  id: 0,
  openTime: 'string',
  closeTime: 'string',

  createPeople: 0,
  createTime: '2020-01-16T04:17:44.840Z',
  hardNum: 0,

  busyPrice: 0,
  idlePrice: 0,

  minPeople: 0,
  maxPeople: 0,
  lockPeople: 0,

  polyStyle: 'string',
  refundKnown: 'string',

  schedules: [
    {
      createPeople: 0,
      createTime: '2020-01-16T04:17:44.840Z',
      endTime: 'string',
      id: 0,
      startTime: 'string',
      themeId: 0,
      themeSession: {
        closeTime: 'string',
        gifts: [
          {
            themeSessionid: 'string',
            gift: 'string',
            id: 0,
            note: 'string',
            num: 0,
          },
        ],
        guidePeople: 0,
        id: 0,
        openTime: 'string',
        schedulesId: 0,
        sessionEndTime: 'string',
        sessionStartDay: 'string',
        sessionStartTime: 'string',
        sessionStatus: 0,
        statistics: [
          {
            ages: 'string',
            createTime: '2020-01-16T04:17:44.840Z',
            endTime: 'string',
            id: 0,
            money: 0,
            payChannelName: 'string',
            payChannelRate: 'string',
            players: 0,
            price: 0,
            startTime: 'string',
            storeId: 0,
            storeName: 'string',
            themeId: 0,
            themeName: 'string',
            themePass: 0,
            themePic: 'string',
            themeScheduleId: 0,
            themeSessionid: 0,
            themeTime: 0,
          },
        ],
        storeId: 0,
        team: 0,
        themeId: 0,
        themeMaxPeople: 0,
        themeMinPeople: 0,
        themeSalePeople: 0,
        timeInterval: 'string',
      },
      timeInterval: 'string',
    },
  ],

  status: 'string',
  storeId: 0,
  themeDes: 'string',
  themeName: 'string',
  themeParentId: 0,
  themePicUrl: 'string',
  updatePeople: 0,
  updateTime: '2020-01-16T04:17:44.840Z',
}

/*
date+shop -> theme -> scene -> order


/////////////////////////////////////////////////////
// order
          //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++

//通过某一天的日期及门店来获取那一天的所有主题信息
getThemesByDateShop(date, shopId)

//预定,相当于新增订单
//orderInfo: 基本的订单信息,可参看预定界面
//belongSceneId: 所属的场次id.
reserveOrder(orderInfo, belongSceneId)

//退订,相当于删除订单
//orderInfoId: 订单id
unreserveOrder(orderInfoId)

//修改订单信息
//orderInfoId: 订单id
//newOrderInfo: 修改后的订单信息
editOrder(orderInfoId, newOrderInfo)

//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// order
/////////////////////////////////////////////////////

/////////////////////////////////////////////////////
// theme && scene
          //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++

//通过某一天的日期及门店来获取那一天的所有主题信息
getThemesByDateShop(date, shopId)

//预定,相当于新增订单
//orderInfo: 基本的订单信息,可参看预定界面
//belongSceneId: 所属的场次id.
reserveOrder(orderInfo, belongSceneId)

//退订,相当于删除订单
//orderInfoId: 订单id
unreserveOrder(orderInfoId)

//修改订单信息
//orderInfoId: 订单id
//newOrderInfo: 修改后的订单信息
editOrder(orderInfoId, newOrderInfo)

//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// theme && scene
/////////////////////////////////////////////////////









*/
